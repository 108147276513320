import React from 'react';
import classnames from 'classnames';
import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';

import './button.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<any> {
  label?: string;
  className?: string;
  flat?: boolean; // no-shadow
  normalize?: boolean;
  warning?: boolean;
  error?: boolean;
  action?: boolean;
  gtmId?: GtmID;
  color?: 'primary' | 'danger' | 'success' | 'alchemer';
  forwardRef?: React.RefObject<any>;
  reverted?: boolean;
}

const bem = bemPrefix('app-button');

export const Button: React.FC<ButtonProps> = ({
  className = '',
  type = 'button',
  flat,
  normalize,
  warning,
  action,
  error,
  color,
  onClick,
  label,
  forwardRef,
  children,
  reverted,
  gtmId,
  ...props
}) => {
  const classNames = classnames(
    bem('', { inverted: !!reverted, flat: !!flat, disabled: !!props.disabled }),
    className,
    {
      button: true,
      'button-normalize': normalize,
      'warning-submit': warning,
      [bem('action-button')]: action,
      [bem('color', color)]: !!color && !reverted,
      [bem('background', color)]: !!color && reverted,
      error,
    },
  );
  return (
    <button className={classNames} type={type} onClick={onClick} ref={forwardRef} gtm-id={gtmId} {...props}>
      {label || children}
    </button>
  );
};

Button.displayName = 'Button';
