export default {
  data: [
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.615,
        2: 0.797,
        3: 2.426,
        4: 12.758,
        5: 81.404,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {
        1: 5749,
        2: 1753,
        3: 5333,
        4: 28049,
        5: 178965,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.263,
        3: 15.79,
        4: 26.316,
        5: 52.632,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'BR',
      ingest_time: 1710940708333,
      store_observed_time: 1710806400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.771,
        2: 2.409,
        3: 4.021,
        4: 14.283,
        5: 72.516,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 179611,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.1.29',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'AE',
      ingest_time: 1715260712243,
      store_observed_time: 1715126400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.087,
        2: 2.181,
        3: 3.776,
        4: 14.073,
        5: 73.883,
      },
      all_ratings_average: 4.5,
      all_ratings_count: 203582,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.1.31',
    },
  ],
  type: 'RATINGS_HISTOGRAMS_UNIQUE',
  page_size: 2000,
  has_more: false,
  min_key: 0,
};
