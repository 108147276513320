import { merge } from 'ramda';
import * as actions from '../actions/check_update';

const initialState = {
  loading: false,
  fetchError: false,
  error: false,
  description: '',
  update: false,
  latest: null,
  deployed: null,
  sha: null,
};

const checkUpdate = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_CHECK_UPDATE:
      return merge(state, {
        loading: true,
        fetchError: false,
        error: false,
        description: '',
      });
    case actions.RECEIVE_CHECK_UPDATE:
      return merge(state, {
        loading: false,
        fetchError: false,
        error: false,
        description: '',
        ...action.payload,
      });
    case actions.ERRORED_CHECK_UPDATE:
      return merge(state, {
        loading: false,
        fetchError: true,
        error: true,
        ...action.payload,
      });
    default:
      return state;
  }
};

export default checkUpdate;
