import React, { useMemo } from 'react';
import { Features, UserFeature } from 'src/types/core';
import { DownloadButton, DownloadButtonProps } from 'src/components/molecules';
import { DownloadAppHealthDialogContainer } from 'src/dashboard/components/download-apphealth-dialog';
import { DownloadFanSignalsDialogOptionsContainer } from 'src/dashboard/components/download-fan-signals-dialog-options';
import { Events } from 'src/actions/event-metrics';
import { FanSignalDayCount } from 'src/services/fan-signals-service';

export interface SubHeaderTabActionsProps {
  pathname: string;
  appStoreId: string;
  features: Features[];
  live: boolean;
  withPrimaryCid: boolean;
  fanSignalCounts: FanSignalDayCount[];
  hasSeenExportInfoModal?: boolean;
  isReporter: boolean;
  acknowledgeFsExportInfoModal?(): void;
  sendEvent(event: Events): void;
}

/**
 * See for details src/dashboard/components/routes-container.tsx#L80
 * @param pathname - location pathname from router
 */
export const SubHeaderTabActions: React.FC<SubHeaderTabActionsProps> = ({
  pathname,
  appStoreId,
  features,
  live,
  withPrimaryCid,
  fanSignalCounts,
  hasSeenExportInfoModal = false,
  isReporter,
  acknowledgeFsExportInfoModal,
  sendEvent,
}) => {
  const isAppHealth = pathname.match('/dashboard');
  const isFanSignals = pathname.match('/fan_signals');
  const displayXls = features.includes(UserFeature.CsvExports) && (appStoreId || live);

  const isDropDown = hasSeenExportInfoModal && withPrimaryCid;

  const props: DownloadButtonProps = useMemo(
    () => ({
      open: false,
      isModal: !isDropDown,
      acknowledgeFsExportInfoModal: !hasSeenExportInfoModal ? acknowledgeFsExportInfoModal : () => null,
      downloadDialog: isDropDown ? <DownloadFanSignalsDialogOptionsContainer /> : undefined,
      hasSeenExportInfoModal,
      fanSignalCounts,
      isReporter,
      sendEvent,
    }),
    [withPrimaryCid, hasSeenExportInfoModal]
  );

  if (isAppHealth && displayXls) {
    return <DownloadButton downloadDialog={<DownloadAppHealthDialogContainer />} />;
  }

  if (isFanSignals) {
    return <DownloadButton {...props} />;
  }

  return null;
};

SubHeaderTabActions.displayName = 'SubHeaderTabActions';
