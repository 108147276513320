/* eslint-disable arrow-body-style, no-use-before-define, @typescript-eslint/no-use-before-define */

import { AnyAction } from 'redux';
import { getTagParams } from './tag_params';

import { GetState } from '../../reducers';

// selectors
import { getCurrentOrgId, getCurrentAppId } from '../../selectors/current_app';
import { TagsService, TagBaseParams, TagParams, InsightsBaseParams } from '../../services';
import { getAppParams } from '../utils/app_params';
import { TagsActions } from './tags.action-types';

interface IFetchOrgTagsAction {
  (payload: any): AnyAction;
}

const fetchMostUsedTagsPending: IFetchOrgTagsAction = (payload) => ({
  type: TagsActions.FETCH_MOST_USER_TAGS_PENDING,
  payload,
});
const fetchMostUsedTagsSuccess: IFetchOrgTagsAction = (payload) => ({
  type: TagsActions.FETCH_MOST_USER_TAGS_SUCCESS,
  payload,
});
const fetchMostUsedTagsError: IFetchOrgTagsAction = (payload) => ({
  type: TagsActions.FETCH_MOST_USER_TAGS_ERROR,
  payload,
});

const fetchAppTagsSuccess = (payload: { tags: string[] }) => ({ type: TagsActions.FETCH_APP_TAGS_SUCCESS, payload });
const fetchAppTagsError = (error: Error) => ({ type: TagsActions.FETCH_APP_TAGS_ERROR, error });

export interface ITagAction<P, M> {
  (payload: P, meta: M): AnyAction;
}

const fetchUpdatedTagLists = (dispatch: Function, withAppTags: boolean = false) => {
  dispatch(tags.fetchAllTags());
  dispatch(tags.fetchMostUsedTags());
  if (withAppTags) {
    dispatch(tags.fetchAppTags());
  }
};

const addTag = (
  baseParams: TagBaseParams,
  pendingCb: ITagAction<any, { elasticsearchId: string }>,
  successCb: ITagAction<any, { elasticsearchId: string; tagName: string }>,
  errorCb: ITagAction<any, any>
): Function => (dispatch: Function, getState: GetState): Promise<any> => {
  const params = getTagParams(baseParams, getState);
  const { elasticsearchId, tagName } = params;
  dispatch(pendingCb(params, { elasticsearchId }));
  return TagsService.addTagV2(params)
    .then((data: any): void => {
      dispatch(successCb(data, { elasticsearchId, tagName }));
      fetchUpdatedTagLists(dispatch);
    })
    .catch((error): void => dispatch(errorCb(error, { elasticsearchId, tagName })));
};

const renameTag = (
  baseParams: TagBaseParams & { newTagName: string },
  pendingCb: ITagAction<any, { elasticsearchId: string }>,
  successCb: ITagAction<any, { elasticsearchId: string; tagName: string; newTagName: string }>,
  errorCb: ITagAction<any, any>
): Function => (dispatch: Function, getState: GetState): Promise<any> => {
  const params = getTagParams(baseParams, getState) as TagParams & { newTagName: string };
  const { elasticsearchId, tagName, newTagName } = params;
  dispatch(pendingCb(params, { elasticsearchId }));
  return TagsService.renameTagV2(params)
    .then((data: any): void => {
      dispatch(successCb(data, { elasticsearchId, tagName, newTagName }));
      fetchUpdatedTagLists(dispatch);
    })
    .catch((error): void => dispatch(errorCb(error, { elasticsearchId, tagName, newTagName })));
};

const removeTag = (
  baseParams: TagBaseParams,
  pendingCb: ITagAction<any, { elasticsearchId: string }>,
  successCb: ITagAction<any, { elasticsearchId: string; tagName: string }>,
  errorCb: ITagAction<any, any>
): Function => (dispatch: Function, getState: GetState): Promise<any> => {
  const params = getTagParams(baseParams, getState);
  const { elasticsearchId, tagName } = params;
  dispatch(pendingCb(params, { elasticsearchId }));
  return TagsService.removeTagV2(params)
    .then((data: any): void => {
      dispatch(successCb(data, { elasticsearchId, tagName }));
      fetchUpdatedTagLists(dispatch);
    })
    .catch((error): void => dispatch(errorCb(error, { elasticsearchId, tagName })));
};

export const tags = {
  addTag,
  renameTag,
  removeTag,

  // TODO: Avoid to read on BE props elasticsearchId, type, tagDate
  renameTagGlobal: (newTagName: string, tagName: string) => {
    return (dispatch: Function, getState: GetState) => {
      if (newTagName === tagName) {
        return Promise.resolve();
      }
      const params = getTagParams({ tagName }, getState);
      dispatch({ type: TagsActions.RENAME_TAG_PENDING });
      return TagsService.renameTagGlobally(newTagName, params)
        .then((data) => {
          dispatch({
            type: TagsActions.RENAME_TAG_SUCCESS,
            meta: { newTagName, tagName },
            payload: data,
          });
          fetchUpdatedTagLists(dispatch, true);
        })
        .catch((error) => {
          dispatch({ type: TagsActions.RENAME_TAG_ERROR, error });
        });
    };
  },

  removeTagGlobal: (tagName: string) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getTagParams({ tagName }, getState);
      dispatch({ type: TagsActions.REMOVE_TAG_PENDING });
      return TagsService.removeTagGlobally(params)
        .then((data) => {
          dispatch({
            type: TagsActions.REMOVE_TAG_SUCCESS,
            payload: data,
            meta: { tagName },
          });
          fetchUpdatedTagLists(dispatch, true);
        })
        .catch((error) => dispatch({ type: TagsActions.REMOVE_TAG_ERROR, error }));
    };
  },

  fetchAllTags: () => {
    return (dispatch: Function, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const orgId = getCurrentOrgId(state);

      dispatch({ type: TagsActions.FETCH_ALL_TAGS_PENDING });

      return TagsService.fetchAllTags(appId, orgId)
        .then((data) => {
          dispatch({ type: TagsActions.FETCH_ALL_TAGS_SUCCESS, payload: data });
        })
        .catch((error) => {
          dispatch({ type: TagsActions.FETCH_ALL_TAGS_ERROR, payload: error });
        });
    };
  },

  fetchAppTags: () => {
    return (dispatch: Function, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: TagsActions.FETCH_APP_TAGS_PENDING });

      return TagsService.fetchAppTags(appId)
        .then((data: string[]) => dispatch(fetchAppTagsSuccess({ tags: data })))
        .catch((error) => dispatch(fetchAppTagsError(error)));
    };
  },

  fetchMostUsedTags: () => {
    return (dispatch: Function, getState: Function): Promise<any> => {
      const state = getState();
      const appId = getCurrentAppId(state);
      dispatch(fetchMostUsedTagsPending(appId));

      return TagsService.fetchMostUsedTags(appId)
        .then((data: any) => dispatch(fetchMostUsedTagsSuccess(data)))
        .catch((error) => dispatch(fetchMostUsedTagsError(error)));
    };
  },

  fetchAggregationTags: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const appParams = getAppParams(getState);
      const params = { ...appParams, ...baseParams };

      dispatch({ type: TagsActions.FETCH_AGGREG_TAGS_PENDING });
      return TagsService.fetchAggregationTags(params)
        .then((data) => dispatch({ type: TagsActions.FETCH_AGGREG_TAGS_SUCCESS, payload: data }))
        .catch((error) => dispatch({ type: TagsActions.FETCH_AGGREG_TAGS_ERROR, error }));
    };
  },
};
