import React, { useLayoutEffect } from 'react';
import { isEmpty } from 'ramda';

export function useOnOutsideClick(
  refs: React.RefObject<Element | undefined>[],
  handler: React.EventHandler<any>,
  stopPropagation = true,
) {
  useLayoutEffect(() => {
    const listener = (event: any) => {
      const containsEvent = !!refs.find((item) => item.current && item.current.contains(event.target));
      if (containsEvent) {
        stopPropagation && event.stopPropagation();
        return;
      }
      handler(event);
    };

    if (!isEmpty(invokeCurrents(refs))) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
}

function invokeCurrents(refs: React.RefObject<Element | undefined>[]): Element[] {
  return refs.map((item) => item.current).filter((current) => !!current) as Element[];
}
