import React, { ChangeEvent, useCallback, useMemo, useState, useRef, useEffect, ReactNode } from 'react';
import { bemPrefix } from 'src/utils';
import { KeyboardKey } from '../util-handlers';
import { SimplePopover } from '../popover';
import { PopoverPlacement } from '../popover/simple-popover';
import { InfoText } from '../info-text/info-text';
import { InputLabel } from './input-label';
import { getValidationMessage } from './input-validator.utils';
import { useFieldBlurHighlight } from './useFieldBlurHighlight';

import './inputs.scss';

type InputTextType = 'password' | 'text' | 'radio' | 'number' | 'submit' | 'email' | 'search' | 'tel' | 'url'; // README: Add if needed another type
export interface InputTextProps<T = any>
  extends Omit<React.InputHTMLAttributes<T>, 'onChange' | 'type'>,
    Pick<React.TextareaHTMLAttributes<T>, 'rows'> {
  className?: string;
  label?: string;
  labelInline?: string;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  errorText?: string;
  helpText?: string;
  type?: InputTextType | 'textarea';
  preventDefault?: boolean;
  tooltipError?: string;
  innerRef?: React.RefObject<InputElement>;
  invalid?: boolean;
  minLength?: number;
  maxLength?: number;
  softMaxLength?: number;
  optional?: boolean;
  isTrim?: boolean;
  prefix?: string;
  suffix?: string;
  richTextMock?: React.JSX.Element;
  onChange?(value: T): void;
  onEnter?(e: React.KeyboardEvent): void;
  onEscape?(e: React.KeyboardEvent): void;
}

const bem = bemPrefix('app-input-text');
const noop = () => null;

type InputElement = HTMLInputElement | HTMLTextAreaElement;

export const InputText: React.FC<InputTextProps> & { selectorInput: string; selectorTextarea: string } = ({
  className = '',
  type = 'text',
  helpText,
  errorText: errorMsg,
  labelInline,
  labelClassName = '',
  inputClassName = '',
  innerRef,
  invalid,
  disabled,
  preventDefault,
  tooltipError,
  maxLength,
  softMaxLength,
  optional,
  isTrim,
  prefix,
  suffix,
  richTextMock,
  onChange = noop,
  onEnter = noop,
  onEscape = noop,
  onKeyDown = noop,
  ...props
}) => {
  const inputRef = useRef<InputElement>(null);
  const ref = innerRef || inputRef;
  const { focused, onBlurEvent } = useFieldBlurHighlight();
  const [errorText, setErrorText] = useState('');
  const [focusActive, setFocusActive] = useState(false);

  const onInputChange = useCallback(
    (e: ChangeEvent<InputElement>) => onChange(e.target ? e.target.value : undefined),
    [onChange],
  );

  const handleBlur = useCallback(
    (e: React.KeyboardEvent | React.FocusEvent<any>) => {
      setFocusActive(false);
      if (props.value && isTrim) {
        onChange(e.target.value.trim());
      }
      onBlurEvent();
    },
    [props.value, isTrim, onChange],
  );

  const handleFocus = useCallback(() => {
    setFocusActive(true);
  }, []);

  const onKeyDownEvent = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === KeyboardKey.ENTER) {
        handleBlur(e);
        onEnter(e);
        preventDefault && e.preventDefault();
      } else if (e.key === KeyboardKey.ESC) {
        onEscape(e);
      }
      onKeyDown(e);
    },
    [onEnter, onEscape, onKeyDown],
  );

  const runValidation = useCallback(() => {
    const validity = ref.current ? ref.current.validity : undefined;
    const invalidValue = invalid || (focused && !disabled && validity ? !validity.valid : false);
    if (ref.current) {
      ref.current.setCustomValidity(invalidValue && invalid && errorMsg ? errorMsg : '');
    }
    if (focused && invalidValue && errorMsg !== undefined) {
      return setErrorText(errorMsg);
    }
    if (focused && invalidValue && props.required) {
      return setErrorText(getValidationMessage('required'));
    }
    return setErrorText('');
  }, [focused, ref, errorMsg, props.required, invalid, disabled]);

  useEffect(runValidation, [props.required, props.value, runValidation]);

  const label = props.label || labelInline;

  const inputProps = {
    className: `${bem('', modifiers({ tooltipError, focused }))} ${inputClassName}`,
    ref: ref as React.RefObject<any>,
    onChange: onInputChange,
    onKeyDown: onKeyDownEvent,
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled,
    maxLength,
    label,
    ...props,
    value: props.value === null ? undefined : props.value,
  };

  const maxLengthHint = useMemo(
    () => (maxLength && type === 'textarea' ? `${String(inputProps.value).length} / ${maxLength}` : undefined),
    [maxLength, type, inputProps.value],
  );

  const softMaxLengthHint = useMemo(
    () =>
      softMaxLength ? `${String(inputProps.value).length} / ${softMaxLength} (suggested maximum length)` : undefined,
    [softMaxLength, inputProps.value],
  );

  return (
    <div
      className={`${bem('wrapper', {
        inline: !!labelInline,
        helpText: !!helpText,
        errorText: !!errorText,
      })} ${className}`}
    >
      {maxLengthHint && <span className={bem('limit')}>{maxLengthHint}</span>}
      {softMaxLengthHint && ref && (
        <SimplePopover
          className={bem('popover-soft-max-length')}
          isOpen={focusActive}
          targetEl={ref.current}
          placement={PopoverPlacement.top}
        >
          {softMaxLengthHint}
        </SimplePopover>
      )}
      {label && <InputLabel className={labelClassName} label={label} required={props.required} optional={optional} />}
      {prefix && <span className={bem('prefix')}>{prefix}</span>}
      {Boolean(richTextMock) && richTextMock}
      {type !== 'textarea' ? <input {...inputProps} type={type} /> : <textarea {...inputProps} />}
      {suffix && <span className={bem('suffix')}>{suffix}</span>}
      {errorText && <InfoText className={bem('error-text')} text={errorText} />}
      {helpText && <InfoText className={bem('help-text')} text={helpText} />}
      {tooltipError && ref && (
        // FIXME: Arrow must be disabled here because styling is currently broken
        <SimplePopover className={bem('popover-error')} isOpen targetEl={ref.current} withArrow={false}>
          {tooltipError}
        </SimplePopover>
      )}
    </div>
  );
};

InputText.displayName = 'InputText';
InputText.selectorInput = `input.${bem()}`;
InputText.selectorTextarea = `textarea.${bem()}`;

function modifiers(props: Pick<InputTextProps, 'tooltipError'> & { focused: boolean }) {
  return [props.tooltipError ? 'error' : '', props.focused ? 'focused' : ''];
}
