export default {
  data: [{
    appId: '',
    day: '2020-03-27T00:00:00Z',
    fans: 285,
    opportunities: 89,
    repeatFans: 240,
    repeatOpportunities: 40,
    reclaimedFans: 51,
    lostFans: 50,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-03-28T00:00:00Z',
    fans: 339,
    opportunities: 93,
    repeatFans: 317,
    repeatOpportunities: 64,
    reclaimedFans: 59,
    lostFans: 48,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-03-29T00:00:00Z',
    fans: 298,
    opportunities: 83,
    repeatFans: 323,
    repeatOpportunities: 57,
    reclaimedFans: 60,
    lostFans: 40,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-03-30T00:00:00Z',
    fans: 285,
    opportunities: 73,
    repeatFans: 244,
    repeatOpportunities: 56,
    reclaimedFans: 42,
    lostFans: 45,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-03-31T00:00:00Z',
    fans: 247,
    opportunities: 68,
    repeatFans: 198,
    repeatOpportunities: 39,
    reclaimedFans: 41,
    lostFans: 32,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-01T00:00:00Z',
    fans: 342,
    opportunities: 106,
    repeatFans: 257,
    repeatOpportunities: 50,
    reclaimedFans: 40,
    lostFans: 37,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-02T00:00:00Z',
    fans: 296,
    opportunities: 79,
    repeatFans: 200,
    repeatOpportunities: 34,
    reclaimedFans: 36,
    lostFans: 33,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-03T00:00:00Z',
    fans: 391,
    opportunities: 110,
    repeatFans: 236,
    repeatOpportunities: 44,
    reclaimedFans: 49,
    lostFans: 37,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-04T00:00:00Z',
    fans: 358,
    opportunities: 90,
    repeatFans: 242,
    repeatOpportunities: 47,
    reclaimedFans: 51,
    lostFans: 39,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-05T00:00:00Z',
    fans: 276,
    opportunities: 87,
    repeatFans: 199,
    repeatOpportunities: 42,
    reclaimedFans: 44,
    lostFans: 29,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-06T00:00:00Z',
    fans: 224,
    opportunities: 62,
    repeatFans: 207,
    repeatOpportunities: 36,
    reclaimedFans: 55,
    lostFans: 27,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-07T00:00:00Z',
    fans: 233,
    opportunities: 53,
    repeatFans: 183,
    repeatOpportunities: 32,
    reclaimedFans: 30,
    lostFans: 40,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-08T00:00:00Z',
    fans: 387,
    opportunities: 91,
    repeatFans: 291,
    repeatOpportunities: 58,
    reclaimedFans: 56,
    lostFans: 49,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-09T00:00:00Z',
    fans: 321,
    opportunities: 96,
    repeatFans: 251,
    repeatOpportunities: 61,
    reclaimedFans: 62,
    lostFans: 49,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-10T00:00:00Z',
    fans: 445,
    opportunities: 98,
    repeatFans: 328,
    repeatOpportunities: 61,
    reclaimedFans: 70,
    lostFans: 52,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-11T00:00:00Z',
    fans: 234,
    opportunities: 60,
    repeatFans: 233,
    repeatOpportunities: 32,
    reclaimedFans: 42,
    lostFans: 32,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-12T00:00:00Z',
    fans: 174,
    opportunities: 53,
    repeatFans: 208,
    repeatOpportunities: 43,
    reclaimedFans: 47,
    lostFans: 38,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-13T00:00:00Z',
    fans: 251,
    opportunities: 83,
    repeatFans: 245,
    repeatOpportunities: 58,
    reclaimedFans: 42,
    lostFans: 52,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-14T00:00:00Z',
    fans: 343,
    opportunities: 86,
    repeatFans: 314,
    repeatOpportunities: 71,
    reclaimedFans: 68,
    lostFans: 54,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-15T00:00:00Z',
    fans: 272,
    opportunities: 91,
    repeatFans: 1067,
    repeatOpportunities: 247,
    reclaimedFans: 205,
    lostFans: 157,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-16T00:00:00Z',
    fans: 543,
    opportunities: 179,
    repeatFans: 3539,
    repeatOpportunities: 754,
    reclaimedFans: 665,
    lostFans: 508,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-17T00:00:00Z',
    fans: 506,
    opportunities: 132,
    repeatFans: 3985,
    repeatOpportunities: 880,
    reclaimedFans: 709,
    lostFans: 663,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-18T00:00:00Z',
    fans: 457,
    opportunities: 156,
    repeatFans: 3353,
    repeatOpportunities: 731,
    reclaimedFans: 622,
    lostFans: 584,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-19T00:00:00Z',
    fans: 295,
    opportunities: 114,
    repeatFans: 2652,
    repeatOpportunities: 598,
    reclaimedFans: 472,
    lostFans: 442,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-20T00:00:00Z',
    fans: 515,
    opportunities: 167,
    repeatFans: 2902,
    repeatOpportunities: 647,
    reclaimedFans: 523,
    lostFans: 524,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-21T00:00:00Z',
    fans: 359,
    opportunities: 144,
    repeatFans: 2321,
    repeatOpportunities: 500,
    reclaimedFans: 469,
    lostFans: 396,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-22T00:00:00Z',
    fans: 438,
    opportunities: 131,
    repeatFans: 2251,
    repeatOpportunities: 494,
    reclaimedFans: 461,
    lostFans: 362,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-23T00:00:00Z',
    fans: 270,
    opportunities: 104,
    repeatFans: 1607,
    repeatOpportunities: 314,
    reclaimedFans: 316,
    lostFans: 270,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-24T00:00:00Z',
    fans: 324,
    opportunities: 76,
    repeatFans: 1443,
    repeatOpportunities: 287,
    reclaimedFans: 285,
    lostFans: 254,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-25T00:00:00Z',
    fans: 355,
    opportunities: 122,
    repeatFans: 1617,
    repeatOpportunities: 329,
    reclaimedFans: 337,
    lostFans: 303,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-26T00:00:00Z',
    fans: 527,
    opportunities: 151,
    repeatFans: 2024,
    repeatOpportunities: 391,
    reclaimedFans: 396,
    lostFans: 344,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-27T00:00:00Z',
    fans: 337,
    opportunities: 96,
    repeatFans: 1385,
    repeatOpportunities: 286,
    reclaimedFans: 258,
    lostFans: 218,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-28T00:00:00Z',
    fans: 446,
    opportunities: 142,
    repeatFans: 1585,
    repeatOpportunities: 301,
    reclaimedFans: 306,
    lostFans: 290,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-29T00:00:00Z',
    fans: 466,
    opportunities: 164,
    repeatFans: 1439,
    repeatOpportunities: 280,
    reclaimedFans: 302,
    lostFans: 251,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-04-30T00:00:00Z',
    fans: 424,
    opportunities: 111,
    repeatFans: 1066,
    repeatOpportunities: 235,
    reclaimedFans: 197,
    lostFans: 205,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-01T00:00:00Z',
    fans: 369,
    opportunities: 85,
    repeatFans: 968,
    repeatOpportunities: 203,
    reclaimedFans: 217,
    lostFans: 176,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-02T00:00:00Z',
    fans: 250,
    opportunities: 78,
    repeatFans: 855,
    repeatOpportunities: 168,
    reclaimedFans: 172,
    lostFans: 156,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-03T00:00:00Z',
    fans: 255,
    opportunities: 82,
    repeatFans: 918,
    repeatOpportunities: 200,
    reclaimedFans: 178,
    lostFans: 162,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-04T00:00:00Z',
    fans: 428,
    opportunities: 133,
    repeatFans: 1387,
    repeatOpportunities: 250,
    reclaimedFans: 218,
    lostFans: 239,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-05T00:00:00Z',
    fans: 330,
    opportunities: 118,
    repeatFans: 959,
    repeatOpportunities: 184,
    reclaimedFans: 143,
    lostFans: 163,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-06T00:00:00Z',
    fans: 347,
    opportunities: 117,
    repeatFans: 854,
    repeatOpportunities: 212,
    reclaimedFans: 176,
    lostFans: 159,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-07T00:00:00Z',
    fans: 208,
    opportunities: 82,
    repeatFans: 690,
    repeatOpportunities: 147,
    reclaimedFans: 131,
    lostFans: 123,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-08T00:00:00Z',
    fans: 175,
    opportunities: 52,
    repeatFans: 664,
    repeatOpportunities: 141,
    reclaimedFans: 138,
    lostFans: 126,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-09T00:00:00Z',
    fans: 303,
    opportunities: 84,
    repeatFans: 735,
    repeatOpportunities: 129,
    reclaimedFans: 126,
    lostFans: 136,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-10T00:00:00Z',
    fans: 156,
    opportunities: 40,
    repeatFans: 368,
    repeatOpportunities: 82,
    reclaimedFans: 67,
    lostFans: 95,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-11T00:00:00Z',
    fans: 278,
    opportunities: 91,
    repeatFans: 473,
    repeatOpportunities: 94,
    reclaimedFans: 80,
    lostFans: 110,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-12T00:00:00Z',
    fans: 193,
    opportunities: 74,
    repeatFans: 335,
    repeatOpportunities: 66,
    reclaimedFans: 69,
    lostFans: 70,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-13T00:00:00Z',
    fans: 391,
    opportunities: 107,
    repeatFans: 485,
    repeatOpportunities: 97,
    reclaimedFans: 87,
    lostFans: 74,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-14T00:00:00Z',
    fans: 329,
    opportunities: 86,
    repeatFans: 395,
    repeatOpportunities: 65,
    reclaimedFans: 75,
    lostFans: 67,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-15T00:00:00Z',
    fans: 259,
    opportunities: 65,
    repeatFans: 242,
    repeatOpportunities: 58,
    reclaimedFans: 50,
    lostFans: 54,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-16T00:00:00Z',
    fans: 294,
    opportunities: 97,
    repeatFans: 295,
    repeatOpportunities: 55,
    reclaimedFans: 47,
    lostFans: 40,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-17T00:00:00Z',
    fans: 249,
    opportunities: 86,
    repeatFans: 210,
    repeatOpportunities: 42,
    reclaimedFans: 40,
    lostFans: 42,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-18T00:00:00Z',
    fans: 278,
    opportunities: 81,
    repeatFans: 238,
    repeatOpportunities: 42,
    reclaimedFans: 52,
    lostFans: 46,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-19T00:00:00Z',
    fans: 327,
    opportunities: 112,
    repeatFans: 227,
    repeatOpportunities: 33,
    reclaimedFans: 46,
    lostFans: 45,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-20T00:00:00Z',
    fans: 324,
    opportunities: 86,
    repeatFans: 203,
    repeatOpportunities: 43,
    reclaimedFans: 36,
    lostFans: 37,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-21T00:00:00Z',
    fans: 161,
    opportunities: 59,
    repeatFans: 102,
    repeatOpportunities: 21,
    reclaimedFans: 18,
    lostFans: 28,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-22T00:00:00Z',
    fans: 141,
    opportunities: 56,
    repeatFans: 110,
    repeatOpportunities: 28,
    reclaimedFans: 23,
    lostFans: 24,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-23T00:00:00Z',
    fans: 165,
    opportunities: 47,
    repeatFans: 123,
    repeatOpportunities: 30,
    reclaimedFans: 29,
    lostFans: 32,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-24T00:00:00Z',
    fans: 144,
    opportunities: 61,
    repeatFans: 93,
    repeatOpportunities: 16,
    reclaimedFans: 20,
    lostFans: 27,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-25T00:00:00Z',
    fans: 130,
    opportunities: 65,
    repeatFans: 88,
    repeatOpportunities: 27,
    reclaimedFans: 16,
    lostFans: 29,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-26T00:00:00Z',
    fans: 99,
    opportunities: 42,
    repeatFans: 68,
    repeatOpportunities: 19,
    reclaimedFans: 24,
    lostFans: 12,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-27T00:00:00Z',
    fans: 210,
    opportunities: 59,
    repeatFans: 126,
    repeatOpportunities: 14,
    reclaimedFans: 22,
    lostFans: 26,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-28T00:00:00Z',
    fans: 129,
    opportunities: 45,
    repeatFans: 92,
    repeatOpportunities: 20,
    reclaimedFans: 20,
    lostFans: 30,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-29T00:00:00Z',
    fans: 217,
    opportunities: 56,
    repeatFans: 114,
    repeatOpportunities: 15,
    reclaimedFans: 24,
    lostFans: 23,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-30T00:00:00Z',
    fans: 118,
    opportunities: 46,
    repeatFans: 72,
    repeatOpportunities: 18,
    reclaimedFans: 17,
    lostFans: 17,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-05-31T00:00:00Z',
    fans: 91,
    opportunities: 26,
    repeatFans: 69,
    repeatOpportunities: 16,
    reclaimedFans: 14,
    lostFans: 12,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-01T00:00:00Z',
    fans: 63,
    opportunities: 27,
    repeatFans: 52,
    repeatOpportunities: 13,
    reclaimedFans: 11,
    lostFans: 26,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-02T00:00:00Z',
    fans: 40,
    opportunities: 16,
    repeatFans: 40,
    repeatOpportunities: 12,
    reclaimedFans: 4,
    lostFans: 9,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-03T00:00:00Z',
    fans: 41,
    opportunities: 13,
    repeatFans: 130,
    repeatOpportunities: 40,
    reclaimedFans: 17,
    lostFans: 28,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-04T00:00:00Z',
    fans: 64,
    opportunities: 29,
    repeatFans: 294,
    repeatOpportunities: 59,
    reclaimedFans: 41,
    lostFans: 73,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-05T00:00:00Z',
    fans: 44,
    opportunities: 20,
    repeatFans: 312,
    repeatOpportunities: 79,
    reclaimedFans: 49,
    lostFans: 80,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-06T00:00:00Z',
    fans: 32,
    opportunities: 16,
    repeatFans: 246,
    repeatOpportunities: 63,
    reclaimedFans: 48,
    lostFans: 55,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-07T00:00:00Z',
    fans: 22,
    opportunities: 13,
    repeatFans: 200,
    repeatOpportunities: 51,
    reclaimedFans: 38,
    lostFans: 45,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-08T00:00:00Z',
    fans: 37,
    opportunities: 20,
    repeatFans: 331,
    repeatOpportunities: 73,
    reclaimedFans: 52,
    lostFans: 73,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-09T00:00:00Z',
    fans: 63,
    opportunities: 44,
    repeatFans: 444,
    repeatOpportunities: 102,
    reclaimedFans: 71,
    lostFans: 111,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-10T00:00:00Z',
    fans: 72,
    opportunities: 33,
    repeatFans: 357,
    repeatOpportunities: 91,
    reclaimedFans: 77,
    lostFans: 95,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-11T00:00:00Z',
    fans: 114,
    opportunities: 41,
    repeatFans: 546,
    repeatOpportunities: 118,
    reclaimedFans: 65,
    lostFans: 101,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-12T00:00:00Z',
    fans: 73,
    opportunities: 36,
    repeatFans: 381,
    repeatOpportunities: 83,
    reclaimedFans: 66,
    lostFans: 88,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-13T00:00:00Z',
    fans: 63,
    opportunities: 18,
    repeatFans: 346,
    repeatOpportunities: 76,
    reclaimedFans: 54,
    lostFans: 72,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-14T00:00:00Z',
    fans: 75,
    opportunities: 35,
    repeatFans: 350,
    repeatOpportunities: 80,
    reclaimedFans: 63,
    lostFans: 91,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-15T00:00:00Z',
    fans: 89,
    opportunities: 31,
    repeatFans: 343,
    repeatOpportunities: 85,
    reclaimedFans: 58,
    lostFans: 74,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-16T00:00:00Z',
    fans: 92,
    opportunities: 42,
    repeatFans: 356,
    repeatOpportunities: 97,
    reclaimedFans: 69,
    lostFans: 89,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-17T00:00:00Z',
    fans: 116,
    opportunities: 42,
    repeatFans: 488,
    repeatOpportunities: 78,
    reclaimedFans: 80,
    lostFans: 124,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-18T00:00:00Z',
    fans: 85,
    opportunities: 41,
    repeatFans: 373,
    repeatOpportunities: 77,
    reclaimedFans: 60,
    lostFans: 69,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-19T00:00:00Z',
    fans: 69,
    opportunities: 37,
    repeatFans: 303,
    repeatOpportunities: 74,
    reclaimedFans: 55,
    lostFans: 65,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-20T00:00:00Z',
    fans: 62,
    opportunities: 35,
    repeatFans: 334,
    repeatOpportunities: 85,
    reclaimedFans: 62,
    lostFans: 71,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-21T00:00:00Z',
    fans: 84,
    opportunities: 31,
    repeatFans: 339,
    repeatOpportunities: 95,
    reclaimedFans: 69,
    lostFans: 71,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-22T00:00:00Z',
    fans: 98,
    opportunities: 28,
    repeatFans: 359,
    repeatOpportunities: 75,
    reclaimedFans: 63,
    lostFans: 64,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-23T00:00:00Z',
    fans: 146,
    opportunities: 52,
    repeatFans: 563,
    repeatOpportunities: 112,
    reclaimedFans: 90,
    lostFans: 109,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-24T00:00:00Z',
    fans: 126,
    opportunities: 41,
    repeatFans: 423,
    repeatOpportunities: 92,
    reclaimedFans: 50,
    lostFans: 81,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-25T00:00:00Z',
    fans: 98,
    opportunities: 41,
    repeatFans: 361,
    repeatOpportunities: 95,
    reclaimedFans: 77,
    lostFans: 73,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-26T00:00:00Z',
    fans: 98,
    opportunities: 30,
    repeatFans: 370,
    repeatOpportunities: 64,
    reclaimedFans: 68,
    lostFans: 72,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-27T00:00:00Z',
    fans: 62,
    opportunities: 24,
    repeatFans: 331,
    repeatOpportunities: 79,
    reclaimedFans: 49,
    lostFans: 78,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-28T00:00:00Z',
    fans: 99,
    opportunities: 33,
    repeatFans: 341,
    repeatOpportunities: 82,
    reclaimedFans: 48,
    lostFans: 77,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-29T00:00:00Z',
    fans: 60,
    opportunities: 30,
    repeatFans: 284,
    repeatOpportunities: 68,
    reclaimedFans: 55,
    lostFans: 69,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-06-30T00:00:00Z',
    fans: 110,
    opportunities: 38,
    repeatFans: 390,
    repeatOpportunities: 88,
    reclaimedFans: 73,
    lostFans: 84,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-01T00:00:00Z',
    fans: 105,
    opportunities: 37,
    repeatFans: 388,
    repeatOpportunities: 81,
    reclaimedFans: 64,
    lostFans: 94,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-02T00:00:00Z',
    fans: 124,
    opportunities: 46,
    repeatFans: 416,
    repeatOpportunities: 80,
    reclaimedFans: 93,
    lostFans: 108,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-03T00:00:00Z',
    fans: 138,
    opportunities: 56,
    repeatFans: 548,
    repeatOpportunities: 113,
    reclaimedFans: 94,
    lostFans: 99,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-04T00:00:00Z',
    fans: 108,
    opportunities: 38,
    repeatFans: 432,
    repeatOpportunities: 94,
    reclaimedFans: 55,
    lostFans: 99,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-05T00:00:00Z',
    fans: 174,
    opportunities: 61,
    repeatFans: 535,
    repeatOpportunities: 122,
    reclaimedFans: 63,
    lostFans: 120,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-06T00:00:00Z',
    fans: 220,
    opportunities: 80,
    repeatFans: 689,
    repeatOpportunities: 114,
    reclaimedFans: 104,
    lostFans: 115,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-07T00:00:00Z',
    fans: 198,
    opportunities: 73,
    repeatFans: 577,
    repeatOpportunities: 114,
    reclaimedFans: 108,
    lostFans: 123,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-08T00:00:00Z',
    fans: 194,
    opportunities: 70,
    repeatFans: 630,
    repeatOpportunities: 104,
    reclaimedFans: 98,
    lostFans: 135,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-09T00:00:00Z',
    fans: 236,
    opportunities: 88,
    repeatFans: 732,
    repeatOpportunities: 125,
    reclaimedFans: 121,
    lostFans: 139,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-10T00:00:00Z',
    fans: 191,
    opportunities: 62,
    repeatFans: 529,
    repeatOpportunities: 107,
    reclaimedFans: 94,
    lostFans: 91,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-11T00:00:00Z',
    fans: 223,
    opportunities: 95,
    repeatFans: 611,
    repeatOpportunities: 122,
    reclaimedFans: 96,
    lostFans: 79,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-12T00:00:00Z',
    fans: 224,
    opportunities: 90,
    repeatFans: 603,
    repeatOpportunities: 118,
    reclaimedFans: 92,
    lostFans: 110,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-13T00:00:00Z',
    fans: 319,
    opportunities: 84,
    repeatFans: 761,
    repeatOpportunities: 146,
    reclaimedFans: 117,
    lostFans: 115,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-14T00:00:00Z',
    fans: 243,
    opportunities: 67,
    repeatFans: 541,
    repeatOpportunities: 92,
    reclaimedFans: 88,
    lostFans: 100,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-15T00:00:00Z',
    fans: 224,
    opportunities: 86,
    repeatFans: 375,
    repeatOpportunities: 71,
    reclaimedFans: 79,
    lostFans: 85,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-16T00:00:00Z',
    fans: 167,
    opportunities: 64,
    repeatFans: 504,
    repeatOpportunities: 107,
    reclaimedFans: 91,
    lostFans: 94,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-17T00:00:00Z',
    fans: 180,
    opportunities: 65,
    repeatFans: 429,
    repeatOpportunities: 90,
    reclaimedFans: 69,
    lostFans: 71,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-18T00:00:00Z',
    fans: 117,
    opportunities: 41,
    repeatFans: 333,
    repeatOpportunities: 62,
    reclaimedFans: 56,
    lostFans: 58,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-19T00:00:00Z',
    fans: 123,
    opportunities: 36,
    repeatFans: 357,
    repeatOpportunities: 47,
    reclaimedFans: 54,
    lostFans: 57,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-20T00:00:00Z',
    fans: 173,
    opportunities: 52,
    repeatFans: 463,
    repeatOpportunities: 85,
    reclaimedFans: 73,
    lostFans: 104,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-21T00:00:00Z',
    fans: 162,
    opportunities: 51,
    repeatFans: 418,
    repeatOpportunities: 90,
    reclaimedFans: 67,
    lostFans: 90,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-22T00:00:00Z',
    fans: 193,
    opportunities: 53,
    repeatFans: 483,
    repeatOpportunities: 96,
    reclaimedFans: 92,
    lostFans: 87,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-23T00:00:00Z',
    fans: 236,
    opportunities: 83,
    repeatFans: 549,
    repeatOpportunities: 82,
    reclaimedFans: 96,
    lostFans: 132,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-24T00:00:00Z',
    fans: 163,
    opportunities: 62,
    repeatFans: 456,
    repeatOpportunities: 83,
    reclaimedFans: 78,
    lostFans: 99,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-25T00:00:00Z',
    fans: 204,
    opportunities: 55,
    repeatFans: 564,
    repeatOpportunities: 102,
    reclaimedFans: 89,
    lostFans: 94,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-26T00:00:00Z',
    fans: 147,
    opportunities: 39,
    repeatFans: 374,
    repeatOpportunities: 72,
    reclaimedFans: 67,
    lostFans: 79,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-27T00:00:00Z',
    fans: 181,
    opportunities: 56,
    repeatFans: 456,
    repeatOpportunities: 90,
    reclaimedFans: 83,
    lostFans: 89,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-28T00:00:00Z',
    fans: 191,
    opportunities: 65,
    repeatFans: 472,
    repeatOpportunities: 107,
    reclaimedFans: 72,
    lostFans: 98,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-29T00:00:00Z',
    fans: 205,
    opportunities: 48,
    repeatFans: 478,
    repeatOpportunities: 72,
    reclaimedFans: 76,
    lostFans: 90,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-30T00:00:00Z',
    fans: 213,
    opportunities: 71,
    repeatFans: 435,
    repeatOpportunities: 83,
    reclaimedFans: 76,
    lostFans: 105,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-07-31T00:00:00Z',
    fans: 141,
    opportunities: 46,
    repeatFans: 313,
    repeatOpportunities: 66,
    reclaimedFans: 69,
    lostFans: 65,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-01T00:00:00Z',
    fans: 128,
    opportunities: 51,
    repeatFans: 343,
    repeatOpportunities: 63,
    reclaimedFans: 41,
    lostFans: 51,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-02T00:00:00Z',
    fans: 155,
    opportunities: 60,
    repeatFans: 446,
    repeatOpportunities: 80,
    reclaimedFans: 63,
    lostFans: 65,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-03T00:00:00Z',
    fans: 186,
    opportunities: 77,
    repeatFans: 484,
    repeatOpportunities: 97,
    reclaimedFans: 68,
    lostFans: 95,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-04T00:00:00Z',
    fans: 170,
    opportunities: 54,
    repeatFans: 401,
    repeatOpportunities: 71,
    reclaimedFans: 59,
    lostFans: 87,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-05T00:00:00Z',
    fans: 179,
    opportunities: 71,
    repeatFans: 504,
    repeatOpportunities: 98,
    reclaimedFans: 82,
    lostFans: 96,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-06T00:00:00Z',
    fans: 148,
    opportunities: 64,
    repeatFans: 391,
    repeatOpportunities: 85,
    reclaimedFans: 61,
    lostFans: 80,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-07T00:00:00Z',
    fans: 157,
    opportunities: 61,
    repeatFans: 304,
    repeatOpportunities: 59,
    reclaimedFans: 56,
    lostFans: 62,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-08T00:00:00Z',
    fans: 148,
    opportunities: 47,
    repeatFans: 355,
    repeatOpportunities: 59,
    reclaimedFans: 64,
    lostFans: 66,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-09T00:00:00Z',
    fans: 111,
    opportunities: 33,
    repeatFans: 343,
    repeatOpportunities: 72,
    reclaimedFans: 53,
    lostFans: 60,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-10T00:00:00Z',
    fans: 161,
    opportunities: 55,
    repeatFans: 437,
    repeatOpportunities: 71,
    reclaimedFans: 70,
    lostFans: 64,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-11T00:00:00Z',
    fans: 109,
    opportunities: 40,
    repeatFans: 279,
    repeatOpportunities: 68,
    reclaimedFans: 57,
    lostFans: 40,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-12T00:00:00Z',
    fans: 146,
    opportunities: 47,
    repeatFans: 341,
    repeatOpportunities: 68,
    reclaimedFans: 49,
    lostFans: 71,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-13T00:00:00Z',
    fans: 189,
    opportunities: 62,
    repeatFans: 853,
    repeatOpportunities: 168,
    reclaimedFans: 141,
    lostFans: 167,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-14T00:00:00Z',
    fans: 166,
    opportunities: 57,
    repeatFans: 2425,
    repeatOpportunities: 517,
    reclaimedFans: 346,
    lostFans: 447,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-15T00:00:00Z',
    fans: 180,
    opportunities: 65,
    repeatFans: 3270,
    repeatOpportunities: 731,
    reclaimedFans: 499,
    lostFans: 562,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-16T00:00:00Z',
    fans: 201,
    opportunities: 74,
    repeatFans: 3208,
    repeatOpportunities: 646,
    reclaimedFans: 496,
    lostFans: 587,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-17T00:00:00Z',
    fans: 167,
    opportunities: 61,
    repeatFans: 2663,
    repeatOpportunities: 598,
    reclaimedFans: 436,
    lostFans: 486,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-18T00:00:00Z',
    fans: 164,
    opportunities: 66,
    repeatFans: 2490,
    repeatOpportunities: 542,
    reclaimedFans: 423,
    lostFans: 449,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-19T00:00:00Z',
    fans: 219,
    opportunities: 76,
    repeatFans: 2578,
    repeatOpportunities: 541,
    reclaimedFans: 440,
    lostFans: 494,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-20T00:00:00Z',
    fans: 173,
    opportunities: 60,
    repeatFans: 2240,
    repeatOpportunities: 498,
    reclaimedFans: 408,
    lostFans: 425,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-21T00:00:00Z',
    fans: 169,
    opportunities: 78,
    repeatFans: 1907,
    repeatOpportunities: 440,
    reclaimedFans: 297,
    lostFans: 368,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-22T00:00:00Z',
    fans: 136,
    opportunities: 52,
    repeatFans: 1586,
    repeatOpportunities: 344,
    reclaimedFans: 271,
    lostFans: 299,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-23T00:00:00Z',
    fans: 150,
    opportunities: 49,
    repeatFans: 1416,
    repeatOpportunities: 295,
    reclaimedFans: 256,
    lostFans: 266,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-24T00:00:00Z',
    fans: 138,
    opportunities: 41,
    repeatFans: 1415,
    repeatOpportunities: 289,
    reclaimedFans: 237,
    lostFans: 240,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-25T00:00:00Z',
    fans: 215,
    opportunities: 61,
    repeatFans: 1837,
    repeatOpportunities: 383,
    reclaimedFans: 288,
    lostFans: 371,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-26T00:00:00Z',
    fans: 224,
    opportunities: 64,
    repeatFans: 1709,
    repeatOpportunities: 329,
    reclaimedFans: 251,
    lostFans: 300,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-27T00:00:00Z',
    fans: 252,
    opportunities: 79,
    repeatFans: 1567,
    repeatOpportunities: 283,
    reclaimedFans: 267,
    lostFans: 260,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-28T00:00:00Z',
    fans: 148,
    opportunities: 39,
    repeatFans: 1134,
    repeatOpportunities: 250,
    reclaimedFans: 213,
    lostFans: 208,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-29T00:00:00Z',
    fans: 317,
    opportunities: 117,
    repeatFans: 2015,
    repeatOpportunities: 344,
    reclaimedFans: 353,
    lostFans: 370,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-30T00:00:00Z',
    fans: 179,
    opportunities: 60,
    repeatFans: 881,
    repeatOpportunities: 195,
    reclaimedFans: 163,
    lostFans: 151,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-08-31T00:00:00Z',
    fans: 184,
    opportunities: 66,
    repeatFans: 1088,
    repeatOpportunities: 250,
    reclaimedFans: 188,
    lostFans: 205,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-01T00:00:00Z',
    fans: 149,
    opportunities: 58,
    repeatFans: 861,
    repeatOpportunities: 167,
    reclaimedFans: 149,
    lostFans: 159,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-02T00:00:00Z',
    fans: 156,
    opportunities: 57,
    repeatFans: 884,
    repeatOpportunities: 182,
    reclaimedFans: 170,
    lostFans: 157,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-03T00:00:00Z',
    fans: 121,
    opportunities: 35,
    repeatFans: 888,
    repeatOpportunities: 203,
    reclaimedFans: 141,
    lostFans: 153,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-04T00:00:00Z',
    fans: 208,
    opportunities: 66,
    repeatFans: 1209,
    repeatOpportunities: 211,
    reclaimedFans: 210,
    lostFans: 212,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-05T00:00:00Z',
    fans: 112,
    opportunities: 39,
    repeatFans: 697,
    repeatOpportunities: 184,
    reclaimedFans: 144,
    lostFans: 161,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-06T00:00:00Z',
    fans: 110,
    opportunities: 37,
    repeatFans: 704,
    repeatOpportunities: 145,
    reclaimedFans: 119,
    lostFans: 152,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-07T00:00:00Z',
    fans: 120,
    opportunities: 40,
    repeatFans: 724,
    repeatOpportunities: 166,
    reclaimedFans: 122,
    lostFans: 140,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-08T00:00:00Z',
    fans: 93,
    opportunities: 47,
    repeatFans: 625,
    repeatOpportunities: 119,
    reclaimedFans: 104,
    lostFans: 132,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-09T00:00:00Z',
    fans: 90,
    opportunities: 33,
    repeatFans: 439,
    repeatOpportunities: 103,
    reclaimedFans: 71,
    lostFans: 76,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-10T00:00:00Z',
    fans: 111,
    opportunities: 41,
    repeatFans: 575,
    repeatOpportunities: 107,
    reclaimedFans: 104,
    lostFans: 124,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-11T00:00:00Z',
    fans: 151,
    opportunities: 40,
    repeatFans: 610,
    repeatOpportunities: 132,
    reclaimedFans: 95,
    lostFans: 115,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-12T00:00:00Z',
    fans: 153,
    opportunities: 44,
    repeatFans: 521,
    repeatOpportunities: 121,
    reclaimedFans: 99,
    lostFans: 99,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-13T00:00:00Z',
    fans: 122,
    opportunities: 44,
    repeatFans: 401,
    repeatOpportunities: 82,
    reclaimedFans: 73,
    lostFans: 86,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-14T00:00:00Z',
    fans: 92,
    opportunities: 29,
    repeatFans: 320,
    repeatOpportunities: 62,
    reclaimedFans: 58,
    lostFans: 72,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-15T00:00:00Z',
    fans: 167,
    opportunities: 73,
    repeatFans: 566,
    repeatOpportunities: 121,
    reclaimedFans: 99,
    lostFans: 130,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-16T00:00:00Z',
    fans: 124,
    opportunities: 51,
    repeatFans: 382,
    repeatOpportunities: 63,
    reclaimedFans: 69,
    lostFans: 68,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-17T00:00:00Z',
    fans: 207,
    opportunities: 68,
    repeatFans: 610,
    repeatOpportunities: 93,
    reclaimedFans: 110,
    lostFans: 90,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-18T00:00:00Z',
    fans: 88,
    opportunities: 27,
    repeatFans: 244,
    repeatOpportunities: 43,
    reclaimedFans: 56,
    lostFans: 47,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-19T00:00:00Z',
    fans: 109,
    opportunities: 44,
    repeatFans: 333,
    repeatOpportunities: 78,
    reclaimedFans: 59,
    lostFans: 65,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-20T00:00:00Z',
    fans: 114,
    opportunities: 36,
    repeatFans: 304,
    repeatOpportunities: 64,
    reclaimedFans: 42,
    lostFans: 53,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-21T00:00:00Z',
    fans: 135,
    opportunities: 51,
    repeatFans: 385,
    repeatOpportunities: 78,
    reclaimedFans: 71,
    lostFans: 85,
    nonRespondents: 0,
  }, {
    appId: '',
    day: '2020-09-22T00:00:00Z',
    fans: 24,
    opportunities: 10,
    repeatFans: 90,
    repeatOpportunities: 16,
    reclaimedFans: 23,
    lostFans: 18,
    nonRespondents: 0,
  }],
};
