import React from 'react';
import PropTypes from 'prop-types';

import './hover-info.scss';
import { bemPrefix } from '../../../utils';

const bem = bemPrefix('hover-info');

const getSummaries = (dynamicTitle, infoFor, infoSummary) => {
  const summaries = {};
  if (dynamicTitle || infoFor) {
    summaries.infoFor = dynamicTitle || infoFor;
  }
  if (infoSummary) {
    summaries.infoSummary = infoSummary;
  }
  return summaries;
};

/**
 * HoverInfo used within graphs
 */
const HoverInfo = ({ className = '', dynamicTitle, histogram, infoFor, infoOn, infoSummary, renderSummary, renderTitle, series, tooltipXVal, coloredTitle }) => {
  const total = series.reduce((tot, { value }) => tot + value, 0);

  const barPercentage = (serie, i) => (
    serie.value ? <div key={`horizontal-bar-serie-${i}`} className={`series series-${i}-color`} style={{ width: `${(serie.value / total) * 100}%` }} /> : null
  );

  const histogramBar = histogram ? (<div className="horizontal-bar">{series.length > 1 ? series.map(barPercentage) : null}</div>) : null;
  const summaries = getSummaries(dynamicTitle, infoFor, infoSummary);

  return (
    <aside className={`${bem()} ${className}`}>
      {infoOn && renderTitle(infoOn, tooltipXVal, coloredTitle)}
      <div className="breakdown">
        <div className={`${bem('summaries')} summaries`}>
          {summaries.infoFor && <h4 className="summary info-for">{summaries.infoFor}</h4>}
          {summaries.infoSummary && <h4 className="summary info-summary">{summaries.infoSummary}</h4>}
        </div>
        {histogramBar}
      </div>
      <div className={`${bem('series-summaries')} series-summaries`}>
        {series.map((serie, i) => renderSummary(total, serie, i))}
      </div>
    </aside>
  );
};

HoverInfo.propTypes = {
  className: PropTypes.string,
  dynamicTitle: PropTypes.string,
  coloredTitle: PropTypes.bool,
  histogram: PropTypes.bool,
  infoFor: PropTypes.string,
  infoOn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  infoSummary: PropTypes.string,
  tooltipXVal: PropTypes.number,
  renderTitle: PropTypes.func,
  renderSummary: PropTypes.func,
  series: PropTypes.arrayOf(PropTypes.shape(
    {
      label: PropTypes.string.isRequired,
      value: PropTypes.number,
      display: PropTypes.string.isRequired,
      detail: PropTypes.string,
    }
  )),
};

HoverInfo.defaultProps = {
  className: '',
  dynamicTitle: '',
  coloredTitle: false,
  histogram: false,
  infoFor: '',
  infoOn: '',
  infoSummary: '',
  renderTitle: (title, x, colored) => (
    <h3 className={`${bem('title')} info-on`}>
      {colored ? <span className={`${bem('color-label')} series-${x}-color`}>{title}</span> : title}
    </h3>
  ),
  renderSummary: (total, { detail, display, label, _value, x }) => (
    <div key={`series-summary-${label}`} className="series-summary">
      {label && <h5 className={`series-summary-label series-${x}-color`}>{label}</h5>}
      {display && <h2 className="series-summary-value">{display}</h2>}
      {detail && <h3 className="series-summary-detail">{detail}</h3>}
    </div>
  ),
  series: [
    {
      label: '',
      value: 0,
      display: '',
      detail: '',
    },
  ],
};

export default HoverInfo;
