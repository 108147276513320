import React from 'react';
import { isEmpty } from 'ramda';

// LoveDialogStyles
export enum LoveDialogStyles {
  ld_background_color = 'ld_background_color',
  ld_close_font_color = 'ld_close_font_color',
  ld_font_color = 'ld_font_color',
  ld_button_font_color = 'ld_button_font_color',
  ld_button_color = 'ld_button_color',
}

export const LoveDialogColors = {
  [LoveDialogStyles.ld_background_color]: '#FCFCFC',
  [LoveDialogStyles.ld_close_font_color]: '#555555',
  [LoveDialogStyles.ld_font_color]: '#555555',
  [LoveDialogStyles.ld_button_font_color]: '#2B98F0',
  [LoveDialogStyles.ld_button_color]: '#EFEFEF',
};

export const LoveDialogColorsTitles = {
  [LoveDialogStyles.ld_background_color]: 'Background Color',
  [LoveDialogStyles.ld_close_font_color]: '✕ (Close) Color',
  [LoveDialogStyles.ld_font_color]: 'Title & Content Color',
  [LoveDialogStyles.ld_button_font_color]: 'Button Font Color',
  [LoveDialogStyles.ld_button_color]: 'Button Color',
};

// MessageCenterStyles
export enum MessageCenterStyles {
  mc_header_color = 'mc_header_color',
  mc_header_font_color = 'mc_header_font_color',
  mc_close_font_color = 'mc_close_font_color',
  mc_content_font_color = 'mc_content_font_color',
  mc_background_color = 'mc_background_color',
  mc_submit_button_color = 'mc_submit_button_color',
  mc_submit_button_font_color = 'mc_submit_button_font_color',
}

export const MessageCenterColors = {
  [MessageCenterStyles.mc_header_color]: '#DC2636',
  [MessageCenterStyles.mc_header_font_color]: '#FFFFFF',
  [MessageCenterStyles.mc_close_font_color]: '#FFFFFF',
  [MessageCenterStyles.mc_content_font_color]: '#555555',
  [MessageCenterStyles.mc_background_color]: '#FCFCFC',
  [MessageCenterStyles.mc_submit_button_color]: '#2B98F0',
  [MessageCenterStyles.mc_submit_button_font_color]: '#FFFFFF',
};

export const MessageCenterColorsTitles = {
  [MessageCenterStyles.mc_header_color]: 'Header Color',
  [MessageCenterStyles.mc_header_font_color]: 'Header Font Color',
  [MessageCenterStyles.mc_close_font_color]: '✕ (Close) Color',
  [MessageCenterStyles.mc_content_font_color]: 'Title & Content Color',
  [MessageCenterStyles.mc_background_color]: 'Background Color',
  [MessageCenterStyles.mc_submit_button_color]: 'Button Color',
  [MessageCenterStyles.mc_submit_button_font_color]: 'Button Font Color',
};

// SurveyStyles
export enum SurveyStyles {
  survey_header_color = 'survey_header_color',
  survey_header_icon_datauri = 'survey_header_icon_datauri',
  survey_header_font_color = 'survey_header_font_color',
  survey_close_font_color = 'survey_close_font_color',
  survey_content_font_color = 'survey_content_font_color',
  survey_background_color = 'survey_background_color',
  survey_submit_button_color = 'survey_submit_button_color',
  survey_submit_button_font_color = 'survey_submit_button_font_color',
}

export const SurveyColors = {
  [SurveyStyles.survey_header_color]: '#DC2636',
  [SurveyStyles.survey_header_font_color]: '#FFFFFF',
  [SurveyStyles.survey_close_font_color]: '#FFFFFF',
  [SurveyStyles.survey_content_font_color]: '#555555',
  [SurveyStyles.survey_background_color]: '#FCFCFC',
  [SurveyStyles.survey_submit_button_color]: '#2B98F0',
  [SurveyStyles.survey_submit_button_font_color]: '#FFFFFF',
};

export const SurveyColorsTitles = {
  [SurveyStyles.survey_header_color]: 'Header Color',
  [SurveyStyles.survey_header_icon_datauri]: 'Header Icon Upload',
  [SurveyStyles.survey_header_font_color]: 'Header Font Color',
  [SurveyStyles.survey_close_font_color]: '✕ (Close) Color',
  [SurveyStyles.survey_content_font_color]: 'Title & Content Color',
  [SurveyStyles.survey_background_color]: 'Background Color',
  [SurveyStyles.survey_submit_button_color]: 'Button Color',
  [SurveyStyles.survey_submit_button_font_color]: 'Button Font Color',
};

// NoteStyles
export enum NoteStyles {
  background_color = 'background_color',
  font_color = 'font_color',
  button_font_color = 'button_font_color',
  overlay_color = 'overlay_color',
  overlay_opacity = 'overlay_opacity',
}

export const NoteColors: Record<NoteStyles, string> = {
  [NoteStyles.background_color]: '#FFFFFF',
  [NoteStyles.font_color]: '#555555',
  [NoteStyles.button_font_color]: '#2B98F0',
  [NoteStyles.overlay_color]: '#000000',
  [NoteStyles.overlay_opacity]: '0.15',
};

export const NoteColorsTitles = {
  [NoteStyles.background_color]: 'Background Color',
  [NoteStyles.font_color]: 'Title & Content Color',
  [NoteStyles.button_font_color]: 'Button Color',
  [NoteStyles.overlay_color]: 'Fill Background',
  [NoteStyles.overlay_opacity]: 'Fill Opacity % (Alpha)',
};

export const Styles = {
  ...LoveDialogColors,
  ...MessageCenterColors,
  ...SurveyColors,
  ...NoteColors,
};

const Titles = {
  ...LoveDialogColorsTitles,
  ...MessageCenterColorsTitles,
  ...SurveyColorsTitles,
  ...NoteColorsTitles,
};

type ColorTypes = typeof LoveDialogColors | typeof MessageCenterColors | typeof SurveyColors | typeof NoteColors;

export const invokeColors = (styles: Partial<typeof Styles>, colors: ColorTypes) => Object.keys({ ...colors, ...styles })
  .filter((key: keyof ColorTypes) => !!colors[key])
  .map((key: keyof ColorTypes) => ({
    label: Titles[key] as string,
    value: key,
    color: styles[key] || colors[key],
    opacity: [NoteStyles.overlay_opacity].includes(key),
  }));

export function invokeStyles(styles: React.CSSProperties) {
  const result = Object.keys(styles).reduce((res: React.CSSProperties, key: keyof React.CSSProperties) => {
    if (styles[key]) {
      Object.assign(res, { [key]: styles[key] });
    }
    return res;
  }, {});
  return isEmpty(result) ? undefined : result;
}
