// https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/jquery/jquery.validate.js#L352
const messages = {
  required: 'This field is required.',
  remote: 'Please fix this field.',
  email: 'Please enter a valid email address.',
  url: 'Please enter a valid URL.',
  date: 'Please enter a valid date.',
  dateISO: 'Please enter a valid date (ISO).',
  number: 'Please enter a valid number.',
  digits: 'Please enter only digits.',
  equalTo: 'Please enter the same value again.',
  // maxlength: $.validator.format( "Please enter no more than {0} characters." ),
  // minlength: $.validator.format( "Please enter at least {0} characters." ),
  // rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long." ),
  range: (from?: string | number, to?: string | number) => `Please enter a value between ${from} and ${to}.`,
  max: (value?: string | number) => `Please enter a value less than or equal to ${value}.`,
  min: (value?: string | number) => `Please enter a value greater than or equal to ${value}.`,
  // step: $.validator.format( "Please enter a multiple of {0}." )
};

const isFunction = (func: any): func is Function => typeof func === 'function';

export const getValidationMessage = (
  key: keyof typeof messages,
  value1?: string | number,
  value2?: string | number
) => {
  if (messages[key]) {
    const message = messages[key];
    return isFunction(message) ? message(value1, value2) : message;
  }
  return 'Value is invalid';
};
