/* global ApptentiveSDK */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Features } from 'src/types/core';
import { fetchCheckUpdate } from '../actions/check_update';
import { closeContactForm, openContactForm } from '../actions/contact_form';
import { isContactFormOpen } from '../selectors';
import { getUpdateAvailable } from '../selectors/update_check';
import {
  getCurrentUserEmail,
  getCurrentUserId,
  getCurrentUserName,
  getCurrentUserProfessionalRole,
  getFeatures,
} from '../selectors/user';
import Session from '../utils/shared_session';
import { State } from '../reducers';
import {
  getCurrentAppId,
  getCurrentAppMembershipRole,
  getCurrentAppTitle,
  getCurrentOrgId,
} from '../selectors/current_app';
import { getCurrentOrgName, getOrgTier } from '../selectors/organizations';
import buildWebSDKProfile from '../utils/build_web_sdk_profile';
import { Icon } from './molecules/icons';

import './styles/contact_us.scss';
import './styles/footer.scss';

export interface IFooterDisplayProps {
  closeContactFormCB: (args: { type: string }) => void;
  email: string;
  isOpen: boolean;
  name: string;
  openContactFormCB: (args: { type: string }) => void;
  fetchCheckUpdate: (sha: string) => any;
  updateAvailable: boolean;
  // webSDK profile
  currentAppId: string;
  currentAppTitle: string;
  currentAppRole: string;
  currentOrgId: string;
  currentOrgName: string;
  currentOrgTier: string;
  professionalRole: string;
  userFeatures: Features[];
  currentUserId: string;
}

interface IFooterDisplayState {
  errors: string[];
}

export class FooterDisplay extends Component<IFooterDisplayProps, IFooterDisplayState> {
  // eslint-disable-next-line react/sort-comp
  public interval: NodeJS.Timeout | number | null;

  public static defaultProps: Partial<IFooterDisplayProps> = {
    email: '',
    fetchCheckUpdate: (_sha: string) => null,
    isOpen: false,
    name: '',
    updateAvailable: false,
  };

  public componentDidMount(): void {
    /* istanbul ignore else */
    if (typeof ApptentiveSDK !== 'undefined') {
      setTimeout(() => {
        ApptentiveSDK.engage('BD30sec');
      }, 30000);

      setTimeout(() => {
        ApptentiveSDK.engage('BD15sec');
        const label = new URLSearchParams(window.location.search).get('utm_campaign');
        if (label) {
          ApptentiveSDK.engage(label);
        }
        const oldLabel = Session.get('utm_campaign');
        Session.remove('utm_campaign');
        if (oldLabel && label !== oldLabel) {
          ApptentiveSDK.engage(oldLabel);
        }
      }, 15000);

      this.updateWebSDKProfile();
    }
    if (process.env.VERSION) {
      this.interval = window.setInterval(this.checkVersion, 1000 * 60 * 60);
    }
  }

  public componentDidUpdate(_prevProps: Readonly<IFooterDisplayProps>, _nextContext: any): void {
    const webSDKProps = new Set([
      'currentAppId',
      'currentAppRole',
      'currentOrgId',
      'currentOrgTier',
      'professionalRole',
      'currentAppTitle',
      'currentOrgName',
      'currentUserId',
      'userFeatures',
    ]);

    if (Object.keys(this.props).some((prop) => webSDKProps.has(prop))) {
      this.updateWebSDKProfile();
    }
  }

  public componentWillUnmount(): void {
    if (process.env.VERSION && this.interval) {
      window.clearInterval(this.interval as any);
    }
  }

  public checkVersion = () => {
    if (process.env.VERSION) {
      this.props.fetchCheckUpdate(process.env.VERSION);
    }
  };

  public refreshPage = () => {
    window.location.reload();
  };

  updateWebSDKProfile(): void {
    const {
      currentAppId,
      currentAppRole,
      currentOrgId,
      currentOrgTier,
      professionalRole,
      currentAppTitle,
      currentOrgName,
      currentUserId,
    } = this.props;

    const properties = {
      cortex_link: `https://cortex.apptentive.com/users/${currentUserId}`,
      currentAppId,
      currentAppRole,
      currentOrgId,
      currentOrgTier,
      professionalRole,
      currentAppTitle,
      currentOrgName,
    };

    const profile = buildWebSDKProfile(properties, this.props.userFeatures);

    ApptentiveSDK.updatePerson({
      custom_data: profile,
    });
  }

  public render() {
    const { updateAvailable } = this.props;
    return (
      <>
        <div id="bottom">
          <div className="footer">
            <div className="sub-footer" onClick={() => ApptentiveSDK.showMessageCenter()}>
              <Icon name="alchemerContactUs" className="contact" />
            </div>
          </div>
        </div>
        {updateAvailable ? (
          <div className="update-available">
            <button type="button" className="button-normalize" onClick={this.refreshPage}>
              An update is available for this page, please click here to refresh.
            </button>
          </div>
        ) : null}
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: State): Partial<IFooterDisplayProps> => ({
  email: getCurrentUserEmail(state),
  isOpen: isContactFormOpen(state),
  name: getCurrentUserName(state),
  updateAvailable: getUpdateAvailable(state),
  currentAppId: getCurrentAppId(state),
  currentAppTitle: getCurrentAppTitle(state),
  currentAppRole: getCurrentAppMembershipRole(state),
  currentOrgId: getCurrentOrgId(state),
  currentOrgName: getCurrentOrgName(state),
  currentOrgTier: getOrgTier(state),
  professionalRole: getCurrentUserProfessionalRole(state),
  userFeatures: getFeatures(state),
  currentUserId: getCurrentUserId(state),
});

/* istanbul ignore next */
const mapDispatchToProps = {
  closeContactFormCB: closeContactForm,
  fetchCheckUpdate,
  openContactFormCB: openContactForm,
};

// Make the default export the redux wrapped component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterDisplay);
