export enum SurveyV12Actions {
  CREATE_SURVEY_V12_PENDING = 'SURVEY_V12_CREATE_PENDING',
  CREATE_SURVEY_V12_SUCCESS = 'SURVEY_V12_CREATE_SUCCESS',
  CREATE_SURVEY_V12_ERROR = 'SURVEY_V12_CREATE_ERROR',

  FETCH_SURVEY_V12_PENDING = 'FETCH_SURVEY_V12_PENDING',
  FETCH_SURVEY_V12_SUCCESS = 'FETCH_SURVEY_V12_SUCCESS',
  FETCH_SURVEY_V12_ERROR = 'FETCH_SURVEY_V12_ERROR',

  FETCH_SURVEYS_V12_BY_ID_PENDING = 'FETCH_SURVEYS_V12_BY_ID_PENDING',
  FETCH_SURVEYS_V12_BY_ID_SUCCESS = 'FETCH_SURVEYS_V12_BY_ID_SUCCESS',
  FETCH_SURVEYS_V12_BY_ID_ERROR = 'FETCH_SURVEYS_V12_BY_ID_ERROR',

  GET_SURVEY_V12_STATS_PENDING = 'GET_SURVEY_V12_STATS_PENDING',
  GET_SURVEY_V12_STATS_SUCCESS = 'GET_SURVEY_V12_STATS_SUCCESS',
  GET_SURVEY_V12_STATS_ERROR = 'GET_SURVEY_V12_STATS_ERROR',

  GET_SURVEY_V12_TEXT_ANSWERS_PENDING = 'GET_SURVEY_V12_TEXT_ANSWERS_PENDING',
  GET_SURVEY_V12_TEXT_ANSWERS_SUCCESS = 'GET_SURVEY_V12_TEXT_ANSWERS_SUCCESS',
  GET_SURVEY_V12_TEXT_ANSWERS_ERROR = 'GET_SURVEY_V12_TEXT_ANSWERS_ERROR',

  GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_PENDING = 'GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_PENDING',
  GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_SUCCESS = 'GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_SUCCESS',
  GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_ERROR = 'GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_ERROR',

  UPDATE_SURVEY_V12_PENDING = 'UPDATE_SURVEY_V12_PENDING',
  UPDATE_SURVEY_V12_SUCCESS = 'UPDATE_SURVEY_V12_SUCCESS',
  UPDATE_SURVEY_V12_ERROR = 'UPDATE_SURVEY_V12_ERROR',

  BOOTSTRAP_SURVEYS_V12 = 'BOOTSTRAP_SURVEYS_V12',

  REQUEST_FETCH_ACTIVE_SURVEYS_V12 = 'REQUEST_FETCH_ACTIVE_SURVEYS_V12',
  RECEIVE_FETCH_ACTIVE_SURVEYS_V12 = 'RECEIVE_FETCH_ACTIVE_SURVEYS_V12',
  ERRORED_FETCH_ACTIVE_SURVEYS_V12 = 'ERRORED_FETCH_ACTIVE_SURVEYS_V12',

  REQUEST_FETCH_NOT_ACTIVE_SURVEYS_V12 = 'REQUEST_FETCH_NOT_ACTIVE_SURVEYS_V12',
  RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_V12 = 'RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_V12',
  ERRORED_FETCH_NOT_ACTIVE_SURVEYS_V12 = 'ERRORED_FETCH_NOT_ACTIVE_SURVEYS_V12',

  REQUEST_UPDATE_SURVEY_V12_ACTIVE = 'REQUEST_UPDATE_SURVEY_V12_ACTIVE',
  RECEIVE_UPDATE_SURVEY_V12_ACTIVE = 'RECEIVE_UPDATE_SURVEY_V12_ACTIVE',
  ERRORED_UPDATE_SURVEY_V12_ACTIVE = 'ERRORED_UPDATE_SURVEY_V12_ACTIVE',

  REQUEST_DELETE_SURVEY_V12 = 'REQUEST_DELETE_SURVEY_V12',
  RECEIVE_DELETE_SURVEY_V12 = 'RECEIVE_DELETE_SURVEY_V12',
  ERRORED_DELETE_SURVEY_V12 = 'ERRORED_DELETE_SURVEY_V12',

  RECEIVE_SURVEY_V12_EMAIL_SUMMARY_APP_IDS = 'RECEIVE_SURVEY_V12_EMAIL_SUMMARY_APP_IDS',

  CLONE_SURVEY_V12_TO_APP_PENDING = 'CLONE_SURVEY_V12_TO_APP_PENDING',
  CLONE_SURVEY_V12_TO_APP_SUCCESS = 'CLONE_SURVEY_V12_TO_APP_SUCCESS',
  CLONE_SURVEY_V12_TO_APP_ERROR = 'CLONE_SURVEY_V12_TO_APP_ERROR',
}
