import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const textField = ({ input, id, label, type, className, inputRef, instructions, prefix, suffix, width, meta: { touched, error, warning }, ...rest }) => {
  let message;
  if (touched) {
    /* istanbul ignore else */
    if (error) {
      message = (<label htmlFor={id} className="error">{error}</label>);
    } else if (warning) {
      message = (<label htmlFor={id} className="warning">{warning}</label>);
    }
  }
  const classes = classnames({
    error: touched && error,
    warning: touched && warning,
  });
  return (
    <div className={className}>
      {label ? <label htmlFor={id}>{label}</label> : null }
      {instructions ? <div className="instructions">{instructions}</div> : null}
      {prefix ? <span className="input-prefix">{prefix}</span> : null}
      <input {...input} className={classes} type={type} {...rest} ref={inputRef} style={width ? { width } : null} />
      {suffix ? <span className="input-suffix">{suffix}</span> : null}
      {message}
    </div>
  );
};

textField.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  instructions: PropTypes.string,
  inputRef: PropTypes.instanceOf(Object),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  width: PropTypes.string,
};

textField.defaultProps = {
  label: '',
  className: '',
};

export default textField;
