import { assoc } from 'ramda';

import {
  DISPLAY_DOWNLOAD_MODAL,
  CLEAR_DOWNLOAD_MODAL,
  UPDATE_DOWNLOAD_MODAL_MSG,
} from '../actions/downloads.js';

import {
  DISPLAY_DOWNLOAD_CENTER_WELCOME,
  CLEAR_DOWNLOAD_CENTER_WELCOME,
  DISPLAY_FS_EXPORT_INFO_MODAL,
  CLEAR_FS_EXPORT_INFO_MODAL,
} from '../actions/ui';

import { QUICK_RESPONSE_MODAL_TOGGLE } from '../actions/quick_responses';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case 'APP_DRAWER_TOGGLE':
      return assoc('appDrawerOpen', !state.appDrawerOpen, state);
    case 'APP_DRAWER_CLOSE':
      return assoc('appDrawerOpen', false, state);
    case QUICK_RESPONSE_MODAL_TOGGLE:
      return assoc('createQuickResponseModalOpen', !state.createQuickResponseModalOpen, state);
    case DISPLAY_DOWNLOAD_MODAL:
      return assoc('downloadsToastVisible', true, state);
    case CLEAR_DOWNLOAD_MODAL:
      return assoc('downloadsToastVisible', false, state);
    case DISPLAY_DOWNLOAD_CENTER_WELCOME:
      return assoc('displayDownloadCenterWelcome', true, state);
    case CLEAR_DOWNLOAD_CENTER_WELCOME:
      return assoc('displayDownloadCenterWelcome', false, state);
    case DISPLAY_FS_EXPORT_INFO_MODAL:
      return assoc('displayFsExportInfoModal', true, state);
    case CLEAR_FS_EXPORT_INFO_MODAL:
      return assoc('clearFsExportInfoModal', false, state);
    case UPDATE_DOWNLOAD_MODAL_MSG:
      return assoc('downloadsToastMessage', action.payload, state);
    default:
      return state;
  }
};
