import { assocPath } from 'ramda';
import { Action } from 'redux';

import { FanSignalDayCount } from 'src/services/fan-signals-service';
import { FanSignalReducerAction, FanSignalActions } from 'src/actions/fan-signals';
import { DateLastPeriod, CustomPeriodName, CUSTOM_PERIOD_NAME } from 'src/utils/time';
import { UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';

type AppId = string;

export type FanSignalsState = Record<AppId, FanSignalsAppState>;

export interface FanSignalsAppState {
  period: Record<
    DateLastPeriod | CustomPeriodName,
    {
      loading: boolean;
      counts: FanSignalDayCount[];
    }
  >;
}

type FsActions = FanSignalReducerAction | (Action<typeof UPDATE_CURRENT_APP_SUCCESS> & { payload: { appId: string } });

export const fanSignals = (state: FanSignalsState = {}, action: FsActions = {} as FsActions) => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_SUCCESS: {
      const appId = action.payload.appId;
      return {
        ...state,
        [appId]: {},
      };
    }
    case FanSignalActions.FETCH_FS_COUNTS: {
      const { appId, lastPeriod = CUSTOM_PERIOD_NAME } = action.payload;
      return appId && lastPeriod ? assocPath([appId, 'period', lastPeriod, 'loading'], true, state) : state;
    }
    case FanSignalActions.FETCH_FS_COUNTS_SUCCESS: {
      const { appId, counts, lastPeriod = CUSTOM_PERIOD_NAME } = action.payload;
      if (appId && lastPeriod) {
        return {
          ...state,
          [appId]: {
            ...state[appId],
            period: {
              ...state[appId].period,
              [lastPeriod]: {
                loading: false,
                counts,
              },
            },
          },
        };
      }
      return state;
    }
    case FanSignalActions.FETCH_FS_COUNTS_ERROR: {
      const { appId, lastPeriod = CUSTOM_PERIOD_NAME } = action.payload;
      return appId && lastPeriod ? assocPath([appId, 'period', lastPeriod, 'loading'], false, state) : state;
    }
    default:
      return state;
  }
};
