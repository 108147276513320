import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from '../../theme';

export default styled.div`
  background: ${rgba(colors.white, 0.75)};
  color: ${colors.shadow};
  position: absolute;
  font-size: 1rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
