import { Action } from 'redux';
import { LoveDialogActions } from 'src/actions/love-dialog/love-dialog.action-types';
import { EventDistributionData } from 'src/types/core';
import { RatingFlowItem } from './love-dialog.types';
import { LoveDialogState, initialState } from './love-dialog.state';

export type LoveDialogReducerAction = Action<LoveDialogActions> & {
  payload: {
    flows?: RatingFlowItem[];
    flow?: RatingFlowItem;
    flowId?: RatingFlowItem['id'];
    active?: boolean;
    appId?: string;
    stats?: EventDistributionData[];
    interaction_id?: string;
  };
  error?: Error;
};

export const loveDialogReducer = (
  state: LoveDialogState = initialState,
  action: LoveDialogReducerAction = {} as LoveDialogReducerAction
): LoveDialogState => {
  switch (action.type) {
    case LoveDialogActions.SAVE_MESSAGE_CENTER_PENDING:
    case LoveDialogActions.GET_LD_FLOWS_PENDING: {
      return { ...state, loading: true };
    }
    case LoveDialogActions.GET_LD_FLOWS_SUCCESS: {
      const flow = (action.payload.flows || []).find((item) => item.version === 2);
      return {
        ...state,
        loading: false,
        ratingFlow: flow,
      };
    }

    case LoveDialogActions.SAVE_MESSAGE_CENTER_ERROR:
    case LoveDialogActions.ACTIVATE_LOVE_DIALOG_ERROR:
    case LoveDialogActions.GET_LD_FLOWS_ERROR: {
      return { ...state, loading: false, activating: false };
    }

    case LoveDialogActions.ACTIVATE_LOVE_DIALOG_PENDING: {
      return { ...state, activating: true };
    }

    case LoveDialogActions.SAVE_MESSAGE_CENTER_SUCCESS:
    case LoveDialogActions.SAVE_LOVE_DIALOG_SUCCESS:
    case LoveDialogActions.ACTIVATE_LOVE_DIALOG_SUCCESS: {
      const flow = action.payload.flow;
      return { ...state, ratingFlow: flow, activating: false, loading: false };
    }

    case LoveDialogActions.FETCH_PREV_STATS_PENDING:
    case LoveDialogActions.FETCH_STATS_PENDING: {
      const { interaction_id } = action.payload
      return {
        ...state,
        stats: {
          ...state.stats,
          [interaction_id as string]: {
            ...state.stats[interaction_id as string],
            loading: true,
          },
        },
      };
    }

    case LoveDialogActions.FETCH_PREV_STATS_SUCCESS: {
      const { interaction_id } = action.payload
      return {
        ...state,
        stats: {
          ...state.stats,
          [interaction_id as string]: {
            ...state.stats[interaction_id as string],
            loading: false,
            error: false,
            prevData: action.payload.stats as EventDistributionData[],
          },
        },
      };
    }

    case LoveDialogActions.FETCH_STATS_SUCCESS: {
      const { interaction_id } = action.payload
      return {
        ...state,
        stats: {
          ...state.stats,
          [interaction_id as string]: {
            ...state.stats[interaction_id as string],
            loading: false,
            error: false,
            currentData: action.payload.stats as EventDistributionData[],
          },
        },
      };
    }

    case LoveDialogActions.FETCH_PREV_STATS_ERROR:
    case LoveDialogActions.FETCH_STATS_ERROR: {
      const { interaction_id } = action.payload
      return {
        ...state,
        stats: {
          ...state.stats,
          [interaction_id as string]: {
            ...state.stats[interaction_id as string],
            loading: false,
            error: true,
          },
        },
      };
    }

    default:
      return state;
  }
};
