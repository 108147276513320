export interface Tag {
  name: string;
  count?: number;
}

export interface TagsState {
  allTags: Tag[];
  fetchAllTagsPending: boolean;
  fetchAllTagsError: boolean;
  mostUsedTags: Tag[];
  fetchMostUsedTagsPending: boolean;
  fetchOrgTagsError: boolean;
  appTags: Tag[];
  recentTags: Tag[];
  aggregatedTags: Tag[];
  aggregatedTagsPending: boolean;
}

export const initialState: TagsState = {
  allTags: [],
  fetchAllTagsPending: false,
  fetchAllTagsError: false,
  mostUsedTags: [],
  fetchMostUsedTagsPending: false,
  fetchOrgTagsError: false,
  appTags: [],
  recentTags: [],
  aggregatedTags: [],
  aggregatedTagsPending: false,
};
