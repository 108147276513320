import { Rest, API } from 'src/api';
import { CustomDataAttribute } from 'src/reducers/apps.types';
import { BaseService } from './base-service';

export type GetAttrResp = {
  keys: Record<string, CustomDataAttribute>
}

export type CustomDataPayload = CustomDataAttribute & {key: string}

export type UpdAttrPayload = {
  device: CustomDataPayload[];
  person: CustomDataPayload[];
}

export class AttributesServiceV2 extends BaseService {
  static getSuggestedAttr(app_id: string) {
    return Rest.httpGet(API.ATTRIBUTES_SERVICE_V2.GET_SUGGESTED_ATTRIBUTES(app_id), {
      errorTitle: '[API] Error Fetching Suggested Attributes',
    }).promise;
  }

  static getAttr(app_id: string, isPerson = true): Promise<GetAttrResp> {
    return Rest.httpGet(API.ATTRIBUTES_SERVICE_V2.GET_ATTRIBUTES(app_id, isPerson), {
      errorTitle: '[API] Error Fetching Attributes',
    }).promise;
  }

  static updAttr(app_id: string, payload: UpdAttrPayload): Promise<UpdAttrPayload> {
    return Rest.httpPut(API.ATTRIBUTES_SERVICE_V2.UPD_ATTRIBUTES(app_id), {
      body: payload,
      errorTitle: '[API] Error Fetching Attributes',
    }).promise;
  }
}
