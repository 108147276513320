import React from 'react';
import { FanSignalsExportType } from 'src/reducers/insights';
import { FanSignalsOptionLabel } from './fan-signals-dialog-options-list';

import './download-fan-signals-dialog-options.scss';

export interface FanSignalsOptionProps {
  type: FanSignalsExportType;
}

export const FSExportLabel: React.FC<FanSignalsOptionProps> = ({ type }) => (
  <div>
    <h3>{FanSignalsOptionLabel[type]}</h3>
    <p>{getDescriptionText(type)}</p>
  </div>
);

export function getDescriptionText(type: FanSignalsExportType): string {
  if (type === FanSignalsExportType.CURRENT_SIGNAL) {
    return 'The most recent fan signal of all of your customers.';
  }
  if (type === FanSignalsExportType.SIGNALS_OVER_TIME) {
    return 'Fan signals that reveal a customers’s emotional response to an event such as app updates or new feature releases.';
  }
  return 'The total number of Fan Signals displayed in daily amounts.';
}
