import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { NavLink } from 'react-router-dom';
import { bemPrefix, featureSettings, FeatureFlag } from 'src/utils';
import { State } from 'src/reducers';
import { app, AppDataActions } from 'src/actions/app-data';
import {
  getCurrentAppSelectedCIDKeys,
  isAttributeChoicesPersonFieldsBootstrapped,
  getCurrentAppAttributes,
  getCurrentAppAttributesWithConvId,
  getCurrentAppId,
  getCurrentAppMembershipRole,
} from 'src/selectors/current_app';
import { SelectedCidKey, AppAttributes, AppMemberRole } from 'src/reducers/apps.types';
import { StoreActions } from 'src/store';
import { DropDown } from 'src/components/molecules';
import { CustomerCareButton } from 'src/components/customer-care-button';
import { EventMetricsSDK } from 'src/utils/event-metrics';
import { attributes } from 'src/actions/attributes';
import { orderItemsByName } from '../customer_attributes/customer_attributes_list';
import { useResultsOnButton, ButtonWithResult } from './use-results-on-button';

import './attributes-dropdown.scss';

const bem = bemPrefix('customer-attributes-dropdown');

const recomendAttrTitle = {
  label: 'Recommended',
  value: 'Recommended',
  disabled: false,
  isTitle: true,
};
const availableAttrTitle = {
  label: 'Available',
  value: 'Available',
  disabled: false,
  isTitle: true,
};

export interface ItemOptions {
  name: string;
  type?: number | string | boolean;
}

export interface AttributesDropdownProps {
  appId: string;
  isAttributesBootstrapped: boolean;
  customDataAttributes?: AppAttributes;
  selectedCidKeys: SelectedCidKey[];
  isCDMLink?: boolean;
  role: string;
  setPrimaryKey(primaryKey: SelectedCidKey['name']): void;
  fetchAttributes(): void;
}

export const AttributesDropdown: React.FC<AttributesDropdownProps> = ({
  appId,
  customDataAttributes,
  selectedCidKeys,
  isCDMLink,
  role,
  setPrimaryKey,
  fetchAttributes,
}) => {
  const [selected, setSelected] = useState('');
  const { savingState, isError, setButtonSaving, setButtonSuccess, setButtonFail, setButtonReset } =
    useResultsOnButton();

  useEffect(() => {
    fetchAttributes();
  }, []);

  const options = useMemo(() => {
    let optionList = [];
    const key = selectedCidKeys.find((key) => key.primary === true);
    const selectedAttribute = key ? key.name : '';
    const hasRecommendedKeys =
      customDataAttributes && customDataAttributes.suggested_keys && !isEmpty(customDataAttributes.suggested_keys);
    const recommendedAttrs = hasRecommendedKeys && customDataAttributes ? customDataAttributes.suggested_keys : [];
    const availableAttrs = customDataAttributes && customDataAttributes.keys ? customDataAttributes.keys : [];

    if (hasRecommendedKeys) {
      optionList[0] = recomendAttrTitle;
      const suggestions = orderItemsByName(recommendedAttrs, (item) => item).map((name) => ({
        label: name === selectedAttribute ? `${name} (Current Customer ID)` : name,
        value: name,
        disabled: name === selectedAttribute,
      }));

      for (let i = 0; i < suggestions.length; i++) {
        optionList[i + 1] = suggestions[i];
      }
    }

    if (availableAttrs) {
      const keys = orderItemsByName(availableAttrs, (item) => item).map((name) => ({
        label: name === selectedAttribute ? `${name} (Current Customer ID)` : name,
        value: name,
        disabled: name === selectedAttribute,
      }));
      optionList = hasRecommendedKeys ? [...optionList, availableAttrTitle, ...keys] : keys;
    }

    return optionList;
  }, [customDataAttributes, selectedCidKeys]);

  const onSelect = useCallback((value: string) => {
    setSelected(value);
    setButtonReset();
  }, []);

  const onSubmit = useCallback(() => {
    setPrimaryKey(selected);
    setButtonSaving();
    if (!window.location.pathname.includes('customer-id')) {
      EventMetricsSDK.send(EventMetricsSDK.Metrics.CUSTOMER_ID_SET_FROM_PROMT);
      return;
    }
    if (selectedCidKeys.find((key) => key.primary === true)) {
      EventMetricsSDK.send(EventMetricsSDK.Metrics.CUSTOMER_ID_UPDATE);
      return;
    }
    EventMetricsSDK.send(EventMetricsSDK.Metrics.CUSTOMER_ID_SET_FROM_PAGE);
  }, [selected, selectedCidKeys]);

  useEffect(() => {
    const store = StoreActions.getInstance();
    const subs = [
      store.on(AppDataActions.SET_APP_PRIMARY_CID_KEY_SUCCESS, () => setButtonSuccess()),
      store.on(AppDataActions.SET_APP_PRIMARY_CID_KEY_ERROR, () => setButtonFail()),
    ];
    return () => subs.forEach((s) => s.dispose());
  }, []);

  const isReporter = role === AppMemberRole.Reporter;

  return (
    <>
      <div className={bem()}>
        <DropDown
          placeholder="Select one..."
          className={bem('select-dropdown')}
          options={options}
          selectedValue={selected}
          onSelect={onSelect}
          disabled={isReporter}
          hideCheckIcon
        />
        <div className={bem('manage-attributes')}>
          {isCDMLink && (
            <div className={bem('manage-link')}>
              <p className="text">Need to manage attributes?</p>
              <NavLink className="link" to={`/apps/${appId}/settings/custom_data`}>
                Custom Data Management
              </NavLink>
            </div>
          )}
          <ButtonWithResult
            className={bem('submit', { [savingState]: !!savingState })}
            state={savingState}
            disabled={!selected}
            onClick={onSubmit}
          />
        </div>
      </div>
      {isError && (
        <div className={bem('save-error-text')}>
          We&apos;re having a trouble with that attribute. Try again or contact <CustomerCareButton /> team to resolve
          the issue.
        </div>
      )}
    </>
  );
};

AttributesDropdown.displayName = 'AttributesDropdown';

export const AttributesDropdownContainer = connect(
  (state: State) => ({
    appId: getCurrentAppId(state),
    isAttributesBootstrapped: !!isAttributeChoicesPersonFieldsBootstrapped(state),
    customDataAttributes: featureSettings.get(FeatureFlag.CONVERSATION_ID)
      ? getCurrentAppAttributesWithConvId(state)
      : getCurrentAppAttributes(state),
    selectedCidKeys: getCurrentAppSelectedCIDKeys(state),
    role: getCurrentAppMembershipRole(state),
  }),
  (dispatch: Function) => ({
    fetchAttributes: () => dispatch(attributes.getSuggestions()),
    setPrimaryKey: (key: SelectedCidKey['name']) => dispatch(app.setPrimaryCidKey(key)),
  })
)(AttributesDropdown);
