import React, { PropsWithChildren } from 'react';

import Content from './content';
import Actions from './actions';

import '../styles/notification_banner.scss';

const NotificationBanner = ({ children, className = 'notification-banner' }: PropsWithChildren<{ className?: string }>) => (
  <div className={className}>
    {children}
  </div>
);

export { Content, Actions };
export default NotificationBanner;
