import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Platform } from 'src/types/core';
import { bemPrefix, useFeatureSettings } from 'src/utils';
import { Button } from 'src/components/molecules';
import { State } from 'src/reducers';
import { getCurrentAppPlatform } from 'src/selectors/current_app';
import { eventMetrics, Events } from 'src/actions/event-metrics';
import { useCIDActions } from './useCidActions';

export interface CustomerIdActionsProps {
  className: string;
  shiftedCounts: number;
  onAction(): void;
  platform: Platform;
  sendEvent(event: string): void;
}

const CustomerIdActions: React.FC<CustomerIdActionsProps> = ({
  className,
  shiftedCounts,
  onAction,
  platform,
  sendEvent,
}) => {
  const bem = useMemo(() => bemPrefix(className), [className]);
  const { runExportData, createSurvey, reviewInsights } = useCIDActions();
  const isWeb = platform === Platform.Web;
  const { flag, hasFeature } = useFeatureSettings();
  const hasUnlimExportFlag = hasFeature(flag.UNLIMITED_FAN_SIGNALS_EXPORT);

  const onExport = useCallback(() => {
    runExportData(hasUnlimExportFlag);
    sendEvent(Events.EXPORT_SIGNALS_CID_MODAL);
    onAction();
  }, []);

  return (
    <div>
      <div>
        {!!shiftedCounts && (
          <p className={bem('shifted-text')}>
            Investigate why&nbsp;<span className={bem('count')}>{shiftedCounts}</span>
            &nbsp;of your customers recently shifted by exporting the data&nbsp;
            {!isWeb && ', targeting them with our recommended survey '}
            or reviewing their feedback in Insights.
          </p>
        )}
      </div>
      <div className={bem('actions')}>
        <Button color="primary" onClick={onExport}>
          Export
        </Button>
        {!isWeb && (
          <Button color="primary" onClick={createSurvey}>
            Survey
          </Button>
        )}
        <Button color="primary" onClick={reviewInsights}>
          Insights
        </Button>
      </div>
    </div>
  );
};

export const CustomerIdActionsContainer = connect<
  Pick<CustomerIdActionsProps, 'platform'>,
  Pick<CustomerIdActionsProps, 'sendEvent'>
>(
  (state: State) => ({
    platform: getCurrentAppPlatform(state),
  }),
  (dispatch: Function) => ({
    sendEvent: (event: Events) => dispatch(eventMetrics.sendEvent(event)),
  })
)(CustomerIdActions);

CustomerIdActions.displayName = 'CustomerIdActions';
