import { uniq } from 'ramda';
import { ICurrentSearch, PhraseItem, PhrasesData, IInsight, SentimentItem } from './insights.types';
import { initialState } from './insights.state';
import { IInsightsParams } from '../../services';

export const invokeCurrentSearch = (params: IInsightsParams): ICurrentSearch => {
  const { regions, types, sentiment, rating, phrases, text, tags, fs_state, survey_title } = params;
  return { regions, types, sentiment, rating, phrases, text, tags, fs_state, survey_title };
};

export const resetStateDataPagination = (state: any) => {
  const { dataPagination: initialDataPagination } = initialState;
  return {
    ...state,
    dataPagination: {
      ...initialDataPagination,
    },
  };
};

export const getRedistributedPhrases = (phrases: PhraseItem[]): PhrasesData => phrases.reduce(
  (acc: any, phrase: PhraseItem) => {
    const status = (phrase.phrase_state && phrase.phrase_state.state) || null;
    switch (status) {
      case 'PINNED':
        acc.pinned.push(phrase);
        break;
      case 'MUTED':
        acc.muted.push(phrase);
        break;
      default:
        acc.default.push(phrase);
    }
    return acc;
  },
  {
    pinned: [],
    default: [],
    muted: [],
  }
);

export const getFlattenPhrases = (phrases: any): any[] => [...phrases.pinned, ...phrases.default, ...phrases.muted];

export const getPhrase = (phrases: any[], phrase: string): PhraseItem | undefined => phrases.find(({ phrase: _phrase }: any) => _phrase === phrase);

// Mutates original phrase
export const updatePhraseStatus = (phrase: any, status: string): void => {
  if (status) {
    phrase.phrase_state = {
      ...phrase.phrase_state,
      state: status,
    };
  } else {
    delete phrase.phrase_state;
  }
};

/**
 * @param tagName existed name
 * @param newTagName: string - to rename, null - to remove the tag
 */
export const getUpdatedInsightTags = (
  { customer_tags = [], ...item }: IInsight,
  tagName: string,
  newTagName: string | null
): IInsight => {
  const tagIndex = customer_tags.findIndex((tag: any): boolean => (tag.tag || tag) === tagName);
  const newTags = tagIndex > -1 ? [...customer_tags] : null;
  if (newTags) {
    if (newTagName) {
      // rename
      newTags.splice(tagIndex, 1, newTagName);
    } else {
      // remove
      newTags.splice(tagIndex, 1);
    }
  }
  return {
    ...item,
    customer_tags: newTags ? uniq(newTags) : customer_tags,
  };
};
// change sentiment
export const getSentiment = (num: number) => {
  if (num >= 0 && num <= 599) {
    return 0
  } if (num >= -1000 && num <= -1) {
    return -1000;
  }
  return 600;
}

export const getDestribution = (array: SentimentItem[], sentiment: number, existingSentiment: number) => {
  return array.map((arr) => {
    if (parseFloat(arr.sentiment) === sentiment) {
      return {
        ...arr,
        count: arr.count + 1,
      }
    } if (parseFloat(arr.sentiment) === existingSentiment) {
      return {
        ...arr,
        count: arr.count - 1,
      }
    }
    return arr;
  })
}
