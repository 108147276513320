// This is shimmed in with webpack on each entry to ensure that polyfills are
// loaded first and globally available.
import 'mdn-polyfills/Number.isNaN';
import 'es6-promise/auto';
import 'mdn-polyfills/String.prototype.includes';
import 'mdn-polyfills/String.prototype.startsWith';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/Array.prototype.fill';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'mdn-polyfills/Array.prototype.forEach';
import 'mdn-polyfills/Array.prototype.includes';

// Polyfill the Number.isFinite function
// https://gist.github.com/marlun78/885eb0021e980c6ce0fb
if (typeof Number.isFinite !== 'function') {
  Number.isFinite = function isFinite(value) {
    // 1. If Type(number) is not Number, return false.
    if (typeof value !== 'number') {
      return false;
    }
    // 2. If number is NaN, +∞, or −∞, return false.
    // eslint-disable-next-line no-self-compare
    if (value !== value || value === Infinity || value === -Infinity) {
      return false;
    }
    // 3. Otherwise, return true.
    return true;
  };
}
