import React from 'react';
import { bemPrefix } from 'src/utils';
import { FanSignalsExportType } from 'src/reducers/insights';

import './download-fan-signals-dialog-options.scss';

export const FanSignalsOptionLabel: Record<FanSignalsExportType, string> = {
  [FanSignalsExportType.CURRENT_SIGNAL]: 'Fan Signal by Customer',
  [FanSignalsExportType.SIGNALS_OVER_TIME]: 'Signals Over Time',
  [FanSignalsExportType.SIGNAL_SUMMARY]: 'Fan Signal Buckets by Day',
};

export const menuOptions = [
  {
    value: FanSignalsExportType.CURRENT_SIGNAL,
  },
  {
    value: FanSignalsExportType.SIGNAL_SUMMARY,
  },
  {
    value: FanSignalsExportType.SIGNALS_OVER_TIME,
  },
].map((item) => ({ ...item, label: FanSignalsOptionLabel[item.value] }));

export interface FanSignalsDialogOptionsListProps {
  onSelect(id: string): void;
  isUnlimitedExport: boolean;
}

const bem = bemPrefix('download-fan-signals-dialog-options');

export const FanSignalsDialogOptionsList: React.FC<FanSignalsDialogOptionsListProps> = ({
  onSelect,
  isUnlimitedExport,
}) => (
  <ul className={bem('list')}>
    {menuOptions.map((option) => (
      <li
        onClick={() => onSelect(option.value)}
        className={
          option.value === FanSignalsExportType.SIGNALS_OVER_TIME && !isUnlimitedExport
            ? bem('option-disabled')
            : bem('list-option')
        }
        key={option.label}
      >
        {option.label}
      </li>
    ))}
  </ul>
);
