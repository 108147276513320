import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router';

import { fanSignals } from 'src/actions/fan-signals';
import { FanSignalStatus, FanSignalsExportType, AggsType } from 'src/reducers/insights';
import { getCurrentAppId } from 'src/selectors/current_app';
import { LocalStorage } from 'src/services';
import { InsightsContextProvider } from 'src/dashboard/components/insights-context';
import { buildSurveyTemplateUrl } from 'src/interactions/components/surveys/survey_template_select';
import { createDate } from 'src/utils/date_format';
import { daysAgo } from 'src/utils/time';
import { FanSignalTemplateName, createSurveyTemplateFromFanSignals } from 'src/reducers/surveys-v12/survey-templates';

export function useCIDActions() {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();

  const today = new Date();
  const monthAgo = daysAgo(29);
  const endDate = createDate(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999).valueOf();
  const startDate = createDate(monthAgo.getFullYear(), monthAgo.getMonth(), monthAgo.getDate()).valueOf();

  const runExportData = useCallback(
    (isUnlimitedExport = false) => {
      const config = {
        exportType: FanSignalsExportType.SIGNALS_OVER_TIME,
        statuses: [FanSignalStatus.SHIFTED_TO_RISK],
        startDate,
        endDate,
        isUnlimitedExport,
      };

      dispatch(fanSignals.runExport(config));
    },
    [dispatch, store]
  );

  const reviewInsights = useCallback(() => {
    const currentAppId = getCurrentAppId(store.getState());
    const filtersUrl = InsightsContextProvider.getInsightsUrlFromFilters(
      {
        fs_state: [FanSignalStatus.SHIFTED_TO_RISK],
        types: [AggsType.MESSAGE, AggsType.SURVEY],
        startDate,
        endDate,
      },
      currentAppId
    );
    history.push(filtersUrl);
  }, [history, store]);

  const createSurvey = useCallback(() => {
    const currentAppId = getCurrentAppId(store.getState());
    const url = buildSurveyTemplateUrl(currentAppId, FanSignalTemplateName.LostFan);

    // README: See for details SurveyModel.getTemplate src/reducers/surveys/survey.models.ts#L91
    // getDefaultFanSignalsFromToDate src/reducers/interactions/criteria-builder/interaction-criteria-builder.ts#L467
    const templateJson = createSurveyTemplateFromFanSignals(startDate, endDate);
    LocalStorage.setValue(FanSignalTemplateName.LostFan, templateJson);

    history.push(url);
  }, [store]);

  return {
    runExportData,
    reviewInsights,
    createSurvey,
  };
}
