import * as SessionActions from '../actions/session';

export default (state = '', action = {}) => {
  switch (action.type) {
    case (SessionActions.UPDATE_CURRENT_APP_PENDING):
      return action.payload.appId || '';
    case (SessionActions.UPDATE_CURRENT_APP_SUCCESS):
      return action.payload.appId || '';
    case (SessionActions.RECEIVE_USER):
      return action.payload.currentApp || '';
    default:
      return state;
  }
};
