import * as Sentry from '@sentry/browser';
import { isNetlifyProd, isTestMode } from './environment';

export const initSentry = (userAgent = window.navigator.userAgent) => {
  // Due to many old bots, we want to filter out old Chrome versions, which Sentry does not let us do in the settings.
  const OLDEST_SUPPORTED_CHROME = 60;
  let shouldUseSentry = true;
  const chromeVersion = userAgent ? userAgent.match(/Chrom(e|ium)\/([0-9]+)\./) : false;
  if (chromeVersion) {
    const currentVersion = parseInt(chromeVersion[2], 10);
    shouldUseSentry = currentVersion >= OLDEST_SUPPORTED_CHROME;
  }

  if ((isNetlifyProd() || isTestMode()) && shouldUseSentry) {
    const dsn = process.env.SENTRY_DSN && `${process.env.SENTRY_DSN}`;
    if (dsn) {
      // https://docs.sentry.io/error-reporting/configuration/?platform=browsernpm
      Sentry.init({
        dsn,
        release: `${process.env.VERSION}`,
        blacklistUrls: [
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Firefox extensions
          /^resource:\/\//i,
          // Gravatar
          /secure\.gravatar\.com\//i,
          // Apptentive Specific
          /\.fullstory\.com\//i,
          /bam\.nr-data\.net\//i,
          'https://cdn.bizible.com/scripts/bizible.js',
          'https://s.adroll.com/j/roundtrip.js',
        ],
        whitelistUrls: [/sdk\.apptentive\.com/, /be\.apptentive\.com/],
      });
    }
    return true;
  }

  return false;
};

export default initSentry;
