/* eslint-disable @typescript-eslint/array-type */

import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { identity, values } from 'ramda';

import { State } from 'src/reducers';
import { createDownload } from 'src/actions/downloads';
import { localDateString } from 'src/utils/date_format';
import { getCurrentUserId } from 'src/selectors/user';
import { getDashboardStartDate, getDashboardEndDate, getSelectedCountries } from 'src/selectors/dashboard';
import {
  getCurrentApp,
  getCurrentAppId,
  getCurrentAppStore,
  getCurrentAppTitle,
  getCurrentAppFeatures,
  getCurrentAppPlatform,
  getCurrentStoreApp,
  getCurrentStoreAppId,
  getIntlAppStorePref,
} from 'src/selectors/current_app';
import { Button, DownloadOption } from 'src/components/molecules';

const noop = () => null;

export interface DownloadAppHealthDialogProps {
  appId: string;
  appPlatform: string;
  appStore: { appVersions: [{ version: string }]; version: string };
  appStoreId: string;
  appTitle: string;
  live: boolean;
  integratedFeatures: {
    rating_flow: boolean;
    message_center: boolean;
    survey: boolean;
    text_modal: boolean;
  };
  international: boolean;
  startDate: number;
  store: Object;
  regions: string[];
  endDate: number;
  onClose?(): void;
  createDownload(userId: string, download: {}): void;
  userId: string;
}

interface DownloadAppHealthDialogState {
  enjoymentDialog: boolean;
  interactions: boolean;
  starRating: boolean;
}

export class DownloadAppHealthDialog extends React.PureComponent<
  DownloadAppHealthDialogProps,
  DownloadAppHealthDialogState
> {
  state = {
    enjoymentDialog: true,
    interactions: true,
    starRating: true,
  };

  static displayName = 'DownloadAppHealthDialog';

  static defaultProps = {
    appId: '',
    appPlatform: '',
    appTitle: '',
    createDownload: () => {},
    international: false,
    onClose: noop,
    regions: [],
    store: {},
    userId: '',
  };

  downloadXls = () => {
    const source: Array<keyof DownloadAppHealthDialogState> = [];
    const { enjoymentDialog, interactions, starRating } = this.state;
    const {
      appId,
      appStore,
      appTitle,
      appPlatform,
      live,
      integratedFeatures,
      international,
      regions,
      appStoreId,
      createDownload,
      startDate,
      store,
      endDate,
      userId,
    } = this.props;

    const { rating_flow, message_center, survey, text_modal } = integratedFeatures;
    if (live && rating_flow && enjoymentDialog) {
      source.push('enjoymentDialog');
    }
    const interactionsIntegrated = message_center || survey || text_modal;
    if (live && interactionsIntegrated && interactions) {
      source.push('interactions');
    }
    if (appStoreId && starRating) {
      source.push('starRating');
    }

    let version;
    if (store === 'itunes' && Array.isArray(appStore.appVersions) && appStore.appVersions.length > 0) {
      version = appStore.appVersions[0].version;
    } else if (appStore.version) {
      version = appStore.version;
    }

    const download = {
      app_id: appId,
      user_id: userId,
      date_requested: new Date(),
      filename: `${appTitle} (${appPlatform}) Apptentive Data Export ${localDateString(startDate)} - ${localDateString(
        endDate
      )}.xlsx`,
      filesize: 0,
      parameters: JSON.stringify({
        start_date: localDateString(startDate),
        end_date: localDateString(endDate),
        store_app_id: appStoreId,
        store,
        sources: source,
        version,
        regions: international ? regions : [store === 'android' ? 'L:en' : 'US'],
      }),
      status: 'running',
      type: 'AppHealth',
    };
    createDownload(userId, download);
  };

  toggleDownloadAll = (value: boolean) => {
    this.setState({
      enjoymentDialog: value,
      interactions: value,
      starRating: value,
    });
  };

  updateDownload = (key: keyof DownloadAppHealthDialogState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, [key]: event.target.checked });
  };

  render() {
    const { appStoreId, live, integratedFeatures, onClose = noop } = this.props;
    const { enjoymentDialog, interactions, starRating } = this.state;
    const allChildrenChecked = values(this.state).every(identity);

    const withLoveDialog = live && integratedFeatures.rating_flow;
    const withInteractions = live && (integratedFeatures.message_center || integratedFeatures.survey || integratedFeatures.text_modal);
    const withStarRatings = appStoreId;
    const showSelectAll = [withLoveDialog, withInteractions, withStarRatings].filter(identity).length > 1;

    const submit = (e: FormEvent) => {
      if (e) {
        e.preventDefault();
      }
      this.downloadXls();
      onClose();
    };

    return (
      <div className="download-apphealth-dialog">
        <div className="apphealth-download-picker">
          <h3>Export Data to XLS</h3>
          <p>Select data to export:</p>
          <form onSubmit={submit}>
            <div className="download-options">
              {showSelectAll && (
                <DownloadOption
                  id="allData-xls-download"
                  name="allData"
                  onChange={() => this.toggleDownloadAll(!allChildrenChecked)}
                  checked={allChildrenChecked}
                >
                  All Features
                </DownloadOption>
              )}
              {live && integratedFeatures.rating_flow ? (
                <DownloadOption
                  id="enjoymentDialog-xls-download"
                  name="enjoymentDialog"
                  onChange={this.updateDownload('enjoymentDialog')}
                  checked={enjoymentDialog}
                >
                  Love Dialog
                </DownloadOption>
              ) : null}
              {live
              && (integratedFeatures.message_center || integratedFeatures.survey || integratedFeatures.text_modal) ? (
                <DownloadOption
                  id="interactions-xls-download"
                  name="interactions"
                  onChange={this.updateDownload('interactions')}
                  checked={interactions}
                >
                  Interactions
                </DownloadOption>
                ) : null}
              {appStoreId ? (
                <DownloadOption
                  id="starRatings-xls-download"
                  name="starRating"
                  onChange={this.updateDownload('starRating')}
                  checked={starRating}
                >
                  Star Ratings
                </DownloadOption>
              ) : null}
            </div>
            <div className="export-buttons">
              <Button className={'submit'} onClick={submit} disabled={!(enjoymentDialog || interactions || starRating)}>
                Export
              </Button>
              <Button className={'cancel'} onClick={onClose}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  appId: getCurrentAppId(state),
  appStore: getCurrentStoreApp(state),
  appStoreId: getCurrentStoreAppId(state),
  appTitle: getCurrentAppTitle(state),
  appPlatform: getCurrentAppPlatform(state),
  endDate: getDashboardEndDate(state),
  integratedFeatures: getCurrentAppFeatures(state),
  international: getIntlAppStorePref(state),
  live: getCurrentApp(state).live,
  regions: getSelectedCountries(state),
  startDate: getDashboardStartDate(state),
  store: getCurrentAppStore(state),
  userId: getCurrentUserId(state),
});

const mapDispatchToProps = {
  createDownload: createDownload as any,
};

export const DownloadAppHealthDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadAppHealthDialog);
