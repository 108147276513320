// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icon-question.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.button-question-icon{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;background-repeat:no-repeat;width:22px;height:22px}`, "",{"version":3,"sources":["webpack://./src/components/styles/molecules/question_icon.scss"],"names":[],"mappings":"AAAA,4BACE,mEAAA,CACA,2BAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["button.button-question-icon {\n  background-image: url(../../assets/icon-question.svg) !important;\n  background-repeat: no-repeat;\n  width: 22px;\n  height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
