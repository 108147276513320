import React from 'react';
import classNames from 'classnames';
import { Platform } from 'src/types/core';
import { DropDown } from 'src/components/molecules';

import './device-preview.scss';

interface DevicePreviewProps {
  platformClassName?: string;
  devicePlatformOption: string;
  onChangeDevicePreview: (value: string) => void;
}

export const DevicePreview: React.FC<DevicePreviewProps> = ({
  devicePlatformOption,
  onChangeDevicePreview,
  platformClassName,
}) => {
  return (
    <div className={classNames('device-container', platformClassName)}>
      <div className="title">Device Preview</div>
      <div className="dropdown">
        <DropDown
          options={[
            { label: 'Choose a device...', value: 'Choose a device...', disabled: true },
            { label: 'iOS', value: Platform.iOS },
            { label: 'Android', value: Platform.Android },
            { label: 'Web', value: Platform.Web },
          ]}
          selectedValue={devicePlatformOption}
          onSelect={onChangeDevicePreview}
        />
      </div>
    </div>
  );
};

export default DevicePreview;
