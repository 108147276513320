/* tslint:disable:no-console */
import { AbortableResult } from '../api/util';

type Results = Record<string, AbortableResult<any>>;
const initReqeustsContainer = (): Results => ({});

const buildResultId = (name: string, resultId: string) => (resultId.startsWith(name) ? resultId : `${name}_${resultId}`);

export class BaseService {
  static REQUESTS = initReqeustsContainer();

  static get requests(): Results {
    return getRequestsByName(this.name, this.REQUESTS);
  }

  static abortAll() {
    this.abortRequests(Object.keys(this.requests));
  }

  static abortRequests(ids: string[]) {
    ids.forEach((id) => this.abortRequestById(id));
  }

  static abortRequestById(resultId: string) {
    const id = buildResultId(this.name, resultId);
    if (this.REQUESTS[id]) {
      this.REQUESTS[id].abort();
      delete this.REQUESTS[id];
    }
  }

  static handleAbortablePromise<T = any>(resultId: string, result: AbortableResult<T>): Promise<T> {
    const id = buildResultId(this.name, resultId);
    this.abortRequestById(id);
    this.REQUESTS[id] = result;
    return result.promise.finally(() => {
      // README: Do not do "delete this.REQUESTS[id]" here, it makes side effect with upcoming promise in cache REQUESTS
      // result.abort() -> result.promise.finally() => handleAbortablePromise - result is undefined, but in fact it is pending
    });
  }
}

function getRequestsByName(name: string, container: Results): Results {
  return Object.keys(container).reduce(
    (res, key) => (key.startsWith(name) ? { ...res, [key]: container[key] } : res),
    {}
  );
}
