import React from 'react';
import numeral from 'numeral';
import GraphContainer from './container';
import InfoTooltip from '../molecules/info_tooltip';

import '../styles/graphs/aggregate.scss';

const noop = () => null

export interface SimpleCalloutProps {
  className?: string;
  startDate: number;
  endDate: number;
  fetchDataCB?: () => void;
  series: any[];
  loading:boolean;
  labels?: string[];
  withPercent?: boolean;
  fetchError?: boolean;
  legacyFetch?: boolean;
  customLabel?: React.ReactNode;
  tooltipContent?: React.ReactNode;
}

const SimpleCallout: React.FC<SimpleCalloutProps> = ({
  className = '',
  startDate,
  endDate,
  fetchDataCB = noop,
  series = [],
  loading = false,
  labels = [],
  withPercent = false,
  fetchError = false,
  legacyFetch = false,
  customLabel = null,
  tooltipContent = null,
}) => {
  const main = series[0] || {};
  const sub = series[1] || {};
  const rows = series.slice(2);
  const mainClass = rows.length > 0 ? 'main-callout' : 'without-extra-info main-callout';

  const subDisplay = sub.count === null ? 'N/A' : sub.count;
  const mainLabel = tooltipContent ? (
    <div className="tooltip-row">
      <p>
        {main.label}
        <InfoTooltip>
          {tooltipContent}
        </InfoTooltip>
      </p>

    </div>
  ) : <p>{main.label}</p>;

  return (
    <GraphContainer
      className={`graph aggregate-data-graph simple-callout ${className}`}
      startDate={startDate}
      endDate={endDate}
      fetchDataCB={fetchDataCB}
      loading={loading}
      fetchError={fetchError}
      legacyFetch={legacyFetch}
    >
      <div className={mainClass}>
        <div className="main-callout-title">
          <h1>{getMainCountFormat(main.count)}</h1>
          { mainLabel }
          { (Object.keys(sub).length > 0) ? <p><span>{subDisplay}</span> {sub.label}</p> : null }
          {customLabel}
        </div>
        <div className={'callout-row-1'}>
          {
            rows.map((subGroup, i) => (
              <div className={`callout-grouping-${i + 1}`} key={`callout-grouping-${i + 1}`}>
                {
                  subGroup.map((datum:any, j:number) => {
                    const percent = withPercent
                      ? (
                        <h5 key={`callout-rate-${j + 1}`}>
                          ({getPercentsText(datum.count, main.count)})%
                        </h5>
                      ) : null;
                    // eslint-disable-next-line react/no-array-index-key
                    return (
                      <div className="datum-container" key={`callout-grouping-${i + 1}-datum-${j + 1}`}>
                        <h3 className={`callout-datum-${j + 1}`}>
                          &nbsp;{datum.count.toLocaleString()}&nbsp;
                        </h3>
                        { percent }
                      </div>
                    );
                  })
                }
                <p>{labels[i]}</p>
              </div>
            ))
          }
        </div>
      </div>
    </GraphContainer>
  );
};

export function getMainCountFormat(count: number): string {
  if (typeof count !== 'number') {
    return count;
  }

  const format = count % 1 === 0 ? '0,0' : '0,0.[00]';
  return numeral(count).format(format);
}

function getPercentsText(datumCount: number, mainCount: number): string {
  const percentText = ((datumCount / mainCount) * 100);
  return Number.isNaN(percentText) ? '0' : percentText.toFixed(2);
}

export default SimpleCallout;
