import { PromptMultiAppsItem } from './prompts-multi-apps.types';

export interface PromptsState {
  prompts: PromptMultiAppsItem[];
  loadingIds: string[];
  activatingIds: string[];
  loading: boolean;
}

export const initialState: PromptsState = {
  prompts: [],
  loadingIds: [],
  activatingIds: [],
  loading: false,
};
