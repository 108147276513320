import { isDemoEnv } from 'src/utils/environment';
import { fetchFanSignalsDemoData, fetchLoveScoreDemoData } from 'src/api/demo_util';
import { AbortableResult, abortableAtriumCall, report } from 'src/api/util';
import { localDateString } from 'src/utils/date_format';
import { FanSignalStatus } from 'src/reducers/insights';
import { API } from 'src/api/api';
import { BaseService } from './base-service';

export type FanSignalDayCount = {
  appId: string;
  day: string;
  fans?: number;
  opportunities?: number;
  reclaimedFans?: number;
  lostFans?: number;
  repeatFans?: number;
  repeatOpportunities?: number;
  newFans?: number;
  newOpportunities?: number;
  nonRespondents?: number;
};

export type FanSignalLoveScore = {
  appId: string;
  score: number;
  categoryScore: number;
  categoryName: string;
};

export const FS_STATUS_COUNT_MAP = {
  [FanSignalStatus.NEW_FAN]: 'fans',
  [FanSignalStatus.NEW_RISK]: 'opportunities',
  [FanSignalStatus.REPEAT_FAN]: 'repeatFans',
  [FanSignalStatus.REPEAT_RISK]: 'repeatOpportunities',
  [FanSignalStatus.SHIFTED_TO_FAN]: 'reclaimedFans',
  [FanSignalStatus.SHIFTED_TO_RISK]: 'lostFans',
  [FanSignalStatus.NON_RESPONDENT]: 'nonRespondents',
} as Record<
  FanSignalStatus,
  keyof Pick<
    FanSignalDayCount,
    'fans' | 'opportunities' | 'repeatFans' | 'repeatOpportunities' | 'reclaimedFans' | 'lostFans' | 'nonRespondents'
  >
>;

export class FanSignalsService extends BaseService {
  static fetchFanSignalsTimeseries(
    appId: string,
    start: number | Date,
    end: number | Date
  ): AbortableResult<{ data: FanSignalDayCount[] }> {
    if (isDemoEnv()) {
      return {
        promise: fetchFanSignalsDemoData(appId, start, end),
        abort: () => null,
      };
    }

    return abortableAtriumCall(API.FAN_SIGNALS.GET_COUNTS(appId, localDateString(start), localDateString(end)), {
      method: 'GET',
      error: (e) => report('[API] Error fetching Fan Signals counts from Fan Signals Service for app', appId, start, end, e),
    });
  }

  static fetchLoveScore(
    appId: string,
    start: number | Date,
    end: number | Date
  ): AbortableResult<{ data: FanSignalLoveScore }> {
    if (isDemoEnv()) {
      return {
        promise: fetchLoveScoreDemoData(appId),
        abort: () => null,
      };
    }

    return abortableAtriumCall(API.FAN_SIGNALS.GET_LOVE_SCORE(appId, localDateString(start), localDateString(end)), {
      method: 'GET',
      error: (e) => report('[API] Error fetching Fan Signals love_score from Fan Signals Service for app', appId, start, end, e),
    });
  }
}
