import React from 'react';
import classNames from 'classnames';

import { Button, ButtonProps } from '../button';
import { bemPrefix } from '../../../utils';

import './dialog-buttons.scss';

const bem = bemPrefix('dialog-buttons');

export interface DialogButtonsProps extends Pick<React.DOMAttributes<any>, 'onClick'> {
  className?: string;
  onExtraAction?(e?: React.SyntheticEvent): void;
  onCancel?(e?: React.SyntheticEvent): void;
  onSubmit?(e?: React.SyntheticEvent): void;
  acceptLabel?: string | JSX.Element;
  cancelLabel?: string;
  extraActionLabel?: string;
  submitDisabled?: boolean;
  gtmId?: ButtonProps['gtmId'];
  submitColor?: ButtonProps['color'] | undefined;
  reverted?: ButtonProps['reverted'];
  submitClassName?: string;
}

export const DialogButtons: React.FC<DialogButtonsProps> = ({
  className = '',
  onClick,
  onExtraAction,
  onSubmit,
  onCancel,
  gtmId,
  submitDisabled,
  submitClassName,
  reverted = false,
  extraActionLabel,
  acceptLabel = 'SUBMIT',
  cancelLabel = 'CANCEL',
  submitColor = 'primary',
}) => {
  if (!onSubmit && !onCancel) {
    return null;
  }

  return (
    <div className={`${bem()} ${className}`} onClick={onClick}>
      {onExtraAction && extraActionLabel && (
        <Button className={bem('extra')} onClick={onExtraAction} action>
          {extraActionLabel}
        </Button>
      )}
      {onCancel && (
        <Button className={bem('cancel')} onClick={onCancel} action>
          {cancelLabel}
        </Button>
      )}
      {onSubmit && (
        <Button
          gtmId={gtmId}
          className={classNames(bem('submit'), submitClassName)}
          onClick={onSubmit}
          color={submitColor}
          disabled={submitDisabled}
          action
          reverted={reverted}
        >
          {acceptLabel}
        </Button>
      )}
    </div>
  );
};

DialogButtons.displayName = 'DialogButtons';
