import moment from 'moment';
import { checkLS } from 'src/api/demo_util';

const days = (i) => i * (60 * 60 * 24 * 1000);

const getRandomArbitrary = (min, max, random = false) => {
  let rand;
  if (random) {
    rand = Math.random();
  } else {
    const d = new Date();
    rand = (d.getMonth() + d.getDay() + d.getUTCHours()) / 100;
  }
  rand *= max - min;
  return rand + min;
};

const getDates = (
  startDate = new Date(),
  endDate = new Date(new Date().setDate(new Date().getDate() - 30)),
  bucket = 'day',
) => {
  const daysOfData = moment(endDate).startOf(bucket).diff(moment(startDate).startOf(bucket), bucket);
  return Array.from({ length: daysOfData }, (_v, i) => new Date() - days(i));
};

const getDatums = (range, min, max, random) => {
  const datums = [];
  for (let i = 0; i < range.length; i += 1) {
    datums.push({
      date: range[i],
      y: Math.floor(getRandomArbitrary(min, max, random)),
    });
  }
  return datums;
};

export default (startDate, endDate) => {
  const dates = getDates(startDate, endDate);

  const datesKey = `${startDate}-${endDate}`;
  const submitPercents = checkLS(
    `surveysDemoDataSubmitPercent-${datesKey}`,
    getDatums(dates, 75, 100, true)
      .reverse()
      .map(({ y }) => y / 100),
    'json',
  );

  const dismissPercents = submitPercents.map((y) => 1 - y);

  const surveysDemoDataLaunch = checkLS(
    `surveysDemoDataLaunch-${datesKey}`,
    getDatums(dates, 300, 350, true).reverse(),
    'json',
  );
  const surveysDemoDataSubmit = checkLS(
    `surveysDemoDataSubmit-${datesKey}`,
    surveysDemoDataLaunch.map((day, i) => ({
      ...day,
      y: Math.round(day.y * submitPercents[i]),
    })),
    'json',
  );

  const notesDemoDataLaunch = checkLS(
    `notesDemoDataLaunch-${datesKey}`,
    getDatums(dates, 290, 350, true).reverse(),
    'json',
  );

  const notesDemoDataInteraction = checkLS(
    `notesDemoDataInteraction-${datesKey}`,
    notesDemoDataLaunch.map((day, i) => ({
      ...day,
      y: Math.round(day.y * submitPercents[i]),
    })),
    'json',
  );

  const notesDemoDataDismiss = checkLS(
    `notesDemoDataDismiss-${datesKey}`,
    notesDemoDataLaunch.map((day, i) => ({
      ...day,
      y: Math.round(day.y * dismissPercents[i]),
    })),
    'json',
  );

  const summarizeY = (acc, day) => (acc += day.y);

  return {
    INBOUND_MESSAGES: {
      type: 'INBOUND_MESSAGES',
      data: [
        {
          label: 'inbound-messages',
          datums: getDatums(dates, 10, 20, true).reverse(),
        },
      ],
    },

    SURVEYS_TIMESERIES: {
      type: 'SURVEYS_TIMESERIES',
      data: [
        {
          label: 'survey.launch',
          datums: surveysDemoDataLaunch,
        },
        {
          label: 'survey.submit',
          datums: surveysDemoDataSubmit,
        },
      ],
    },
    SURVEYS_AGGREGATE: {
      type: 'SURVEYS_AGGREGATE',
      data: [
        {
          label: 'survey.launch',
          count: surveysDemoDataLaunch.reduce(summarizeY, 0),
        },
        {
          label: 'survey.submit',
          count: surveysDemoDataSubmit.reduce(summarizeY, 0),
        },
      ],
    },

    NOTES_TIMESERIES: {
      type: 'NOTES_TIMESERIES',
      data: [
        {
          label: 'text_modal.launch',
          datums: notesDemoDataLaunch,
        },
        {
          label: 'text_modal.interaction',
          datums: notesDemoDataInteraction,
        },
        {
          label: 'text_modal.dismiss',
          datums: notesDemoDataDismiss,
        },
      ],
    },
    NOTES_AGGREGATE: {
      type: 'NOTES_AGGREGATE',
      data: [
        {
          label: 'text_modal.launch',
          count: notesDemoDataLaunch.reduce(summarizeY, 0),
        },
        {
          label: 'text_modal.interaction',
          count: notesDemoDataInteraction.reduce(summarizeY, 0),
        },
        {
          label: 'text_modal.dismiss',
          count: notesDemoDataDismiss.reduce(summarizeY, 0),
        },
      ],
    },

    INTERACTIONS_TIMESERIES: {
      type: 'INTERACTIONS_TIMESERIES',
      data: [
        {
          label: 'text_modal.launch',
          datums: notesDemoDataLaunch,
        },
      ],
    },
    INTEGRATED_FEATURES: {
      survey: true,
      text_modal: true,
      message_center: true,
      rating_flow: true,
    },
    getEventsTimeSeries(events) {
      return {
        type: 'SURVEYS_TIMESERIES',
        data: events.map((eventName, i) => ({
          label: eventName,
          datums: getDatums(dates, 0, (i + 1) * 5, true),
        })),
      };
    },
  };
};
