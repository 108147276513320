import { $isAutoLinkNode, $isLinkNode, LinkNode } from "@lexical/link";
import {
  $getSelection,
  $isElementNode,
  $isNodeSelection,
  $isRangeSelection,
  $isTextNode,
  LexicalNode,
  TextNode,
} from "lexical";

/**
 * Get the first node in the selection matching the given predicate, if any.
 *
 * @param {function} filter The predicate to test whether a given node matches.
 * @returns {LexicalNode?} The matching node, if any.
 */
export function $getMatchingSelectedNode(filter: (n: LexicalNode) => boolean): LexicalNode | undefined {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    // First, try to find a node within the selection.
    const containedNode = selection.getNodes().find(filter);
    if (containedNode) return containedNode;

    // Next, see if a matching node is touching the cursor.
    const anchorAndFocus = selection.getStartEndPoints();
    if (!anchorAndFocus) return undefined;

    const cursor = anchorAndFocus[0];
    const nodeAtCursor = selection.getNodes()[0];

    if (!nodeAtCursor || !$isTextNode(nodeAtCursor)) return undefined;

    if (filter(nodeAtCursor)) {
      // Inside a matching node
      return nodeAtCursor;
    }

    const matchingParent = nodeAtCursor.getParents().find(filter);
    if (matchingParent) return matchingParent;

    if (cursor.offset === 0) {
      // At beginning of next node
      const prev = nodeAtCursor.getPreviousSibling();
      if (prev && filter(prev)) return prev;
    } else if (cursor.offset === nodeAtCursor.getTextContentSize()) {
      // At end of previous node
      const next = nodeAtCursor.getNextSibling();
      if (next && filter(next)) return next;
    }

    return undefined;
  }

  if ($isNodeSelection(selection)) {
    // TODO: I've not managed to get the editor into a state where it creates a node selection. This
    // code is untested.

    return selection.getNodes().find(filter);
  }

  return undefined;
}

export function $selectCurrentWord() {
  const selection = $getSelection();
  // Check that we have the correct kind of selection, and that it's collapsed (just a single cursor, not a range of selected text).
  if (!selection || !$isRangeSelection(selection) || !selection.isCollapsed()) return;

  const anchorAndFocus = selection.getStartEndPoints();
  if (!anchorAndFocus) return;

  const cursor = anchorAndFocus[0];
  const currentNode = cursor.getNode();

  if (!$isTextNode(currentNode)) return;

  // Find all words in the current node.
  const words = currentNode.getTextContent().matchAll(/\w+/g);
  if (!words) return;

  // Find the node containing the cursor.
  const containingWord = Array.from(words).find(word =>
    word.index != null ? (
      word.index <= cursor.offset &&
      cursor.offset <= (word.index + word[0].length)
    ) : false
  );

  // If successful, select the entire containing word.
  // NOTE: There's a strong assumption here that selection indicies map to string indices.
  if (containingWord && containingWord.index != null) {
    currentNode.select(
      containingWord.index,
      containingWord.index + containingWord[0].length,
    );
  }
}

/**
 * Update the URL and contents of the given link node.
 * Will remove any existing formatting if the text is changed.
 *
 * @param {LexicalNode} node The link node to be updated.
 * @param {string} text The new text contents.
 * @param {string} url The new URL.
 */
export function $updateLinkNode(node: LexicalNode, text: string, url: string) {
  if ($isAutoLinkNode(node)) {
    // This case has to be handled specially, as changing the text node inside an autolink
    // node causes the link to be removed
    const newLink = new LinkNode(url);
    newLink.append(new TextNode(text));

    node.replace(newLink);
    newLink.selectStart();
  } else if ($isLinkNode(node)) {
    node.setURL(url);

    if (text === node.getTextContent()) return;

    const innerTextNode = node.getChildAtIndex(0);

    // If the link only contains a single text node, update it, otherwise replace what's there with
    // a single text node.
    if (innerTextNode && $isTextNode(innerTextNode) && node.getChildrenSize() === 1) {
      innerTextNode.setTextContent(text);
    } else {
      node.clear();
      node.append(new TextNode(text));
    }
  }
}

/**
 * Remove the given node while leaving its children in its place.
 *
 * @param {LexicalNode} node The node to be removed.
 */
export function $pruneNode(node: LexicalNode) {
  if (!$isElementNode(node)) return;

  const children = node.getChildren();

  for (let i = 0; i < children.length; i++) {
    node.insertBefore(children[i]);
  }

  node.remove();
}
