import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { bemPrefix } from 'src/utils';
import { EventMetricsSDK } from 'src/utils/event-metrics';
import { Events } from 'src/actions/event-metrics';
import { DateRangeSelectorContainer, DateRangeSelectorPickerProps } from '../date-range-selector-picker';
import './sub-header-nav.scss';

interface TabProps {
  label: string;
  link: string;
  gtmId?: string;
  isActive?: NavLinkProps['isActive'];
  sendEvent?(event: Events): void;
}

export interface SubHeaderNavProps {
  title: string;
  withDatePicker?: boolean;
  analyticsEventKey?: DateRangeSelectorPickerProps['analyticsEventKey'];
  tabs: TabProps[];
  tabActions?: React.ReactNode;
  sendEvent?(event: Events): void;
}

const bem = bemPrefix('sub-header-nav');

const noop = () => null;

const TabLink: React.FC<TabProps> = ({ link, label, isActive, gtmId, sendEvent = noop }) => {
  const onSendEvent = () => {
    EventMetricsSDK.send(EventMetricsSDK.Metrics.FAN_SIGNALS_PAGE);
    sendEvent(Events.FAN_SIGNALS_PAGE);
  };
  const isFanSignalsPage = label === 'Fan Signals';
  return (
    <NavLink
      className={bem('tab-link')}
      to={link}
      isActive={isActive}
      gtm-id={gtmId}
      onClick={isFanSignalsPage ? onSendEvent : undefined}
    >
      {label}
    </NavLink>
  );
};

export const SubHeaderNav: React.FC<SubHeaderNavProps> = ({
  withDatePicker = true,
  tabs,
  tabActions,
  title,
  sendEvent,
}) => (
  <div className={bem()}>
    <div className={bem('title')}>
      <h1>{title}</h1>
      <div className={bem('actions')}>{withDatePicker && <DateRangeSelectorContainer />}</div>
    </div>
    <div className={bem('tabs')}>
      <div className={bem('tab-links')}>
        {tabs.map((tab) => (
          <TabLink key={tab.label} {...tab} sendEvent={sendEvent} />
        ))}
      </div>
      {tabActions && <div className={bem('tab-actions')}>{tabActions}</div>}
    </div>
  </div>
);
