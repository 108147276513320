import React, { Component } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import { Typeahead, Menu, MenuItem, Token } from 'react-bootstrap-typeahead';
import { getRegionMap } from 'src/utils/region_map';
import { GtmID } from 'src/types/google-tag-manager';

import './styles/insight_region_typeahead.scss';

const getRegionCount = (selected, allCounts) => allCounts[selected] || 0;

class InsightRegionTypeahead extends Component {
  static propTypes = {
    multiple: PropTypes.bool,
    regions: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(PropTypes.string),
    regionCounts: PropTypes.instanceOf(Object),
    platform: PropTypes.string,
    onSetRegions: PropTypes.func.isRequired,
    showCounts: PropTypes.bool,
    showOptionCounts: PropTypes.bool,
    hasClearButton: PropTypes.bool,
  };

  static defaultProps = {
    platform: '',
    showCounts: true,
    showOptionCounts: true,
    hasClearButton: true,
  };

  clearSelection = () => {
    this.regionTypeahead.getInstance().clear();
  };

  filterRegions = (option, { text }) => {
    if (!text) return true;
    if (!option) return false;
    const countryDisplay = getRegionMap(option);
    return countryDisplay && countryDisplay.toLowerCase().indexOf(text.toLowerCase()) !== -1;
  };

  /**
   * selection: string[]
   */
  handleFormFieldChange = (selection) => this.props.onSetRegions(selection);

  renderMenu = (results, menuProps) => {
    const sortedResults = results.sort((a, b) => (
      getRegionCount(b, this.props.regionCounts) - getRegionCount(a, this.props.regionCounts)
    ));

    return (
      <Menu {...menuProps} id="region-menu" className="static-dropdown-menu">
        {sortedResults.map((result, index) => (
          <MenuItem key={result} option={result} position={index} className="static-dropdown-option" gtm-id={GtmID.FilterReviewRegion}>
            <span gtm-id={GtmID.FilterReviewRegion}>
              {this.props.showOptionCounts ? `${getRegionMap(result)} (${getRegionCount(result, this.props.regionCounts)})` : getRegionMap(result)}
            </span>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  renderToken = (option, handleRemove, index) => (
    <Token
      key={index}
      option={option}
      onRemove={handleRemove}
    >
      {this.props.showCounts
        ? `${getRegionMap(option)} (${this.props.regionCounts[option] || 0})`
        : getRegionMap(option)
      }
    </Token>
  );

  getHasOptionsToChoose = () => this.props.options && !isEmpty(this.props.options);
  getHasSelectedOptions = () => this.props.regions && !isEmpty(this.props.regions);
  getIsFilterBlocked = () => !this.getHasOptionsToChoose() && !this.getHasSelectedOptions();
  getPlaceholder = () => (this.getIsFilterBlocked() ? 'No country data available' : 'Search for specific country');

  render() {
    const { options, multiple, regions, hasClearButton } = this.props;

    return (
      <div className="insight-region-typeahead">
        <Typeahead
          ref={(ref) => this.regionTypeahead = ref}
          id="region-typeahead"
          clearButton={hasClearButton}
          options={options}
          placeholder={this.getPlaceholder()}
          selected={regions}
          labelKey={option => (getRegionMap(option) || option)}
          multiple={multiple}
          filterBy={this.filterRegions}
          renderMenu={this.renderMenu}
          renderToken={this.renderToken}
          onChange={this.handleFormFieldChange}
          disabled={this.getIsFilterBlocked()}
          emptyLabel="All available countries selected"
          paginate={false}
        />
      </div>
    );
  }
}

export default InsightRegionTypeahead;
