/* eslint-disable arrow-body-style */

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { equals, pick } from 'ramda';
import { getAppParams, IAppParams } from 'src/actions/utils/app_params';

import {
  InsightsService,
  InsightsBaseParams,
  IInsightsParams,
  ExportInsightParams,
  IPhraseBaseParams,
  IPhraseParams,
  InsightsPhrasesParams,
  InsightsExportManager,
} from 'src/services';
import { ElasticSearchService } from 'src/services/elastic-search-service';
import { TagModel } from 'src/services/tags-service';

// selectors
import { getCurrentUserId } from 'src/selectors/user';
import { getCurrentAppId, getCurrentOrgId } from 'src/selectors/current_app';
import { GetState, State } from 'src/reducers';
import { getSelectedCountByDistribution, getSavedInsightById } from 'src/selectors/insights.selectors';
import { getDashboardDateRange } from 'src/selectors/dashboard';
import { SavedInsight, AggsType, SentimentData } from 'src/reducers/insights';
import { InsightsFilterParams } from 'src/dashboard/components/insights-context/insights-context.types';
import { InsightActions } from './insights.action-types';

// README: to be compatible, in future remove this re-export
export {
  InsightsBaseParams,
  IInsightsParams,
  ExportInsightParams,
  IPhraseBaseParams,
  IPhraseParams,
  InsightsAggsParams,
  InsightsPhrasesParams,
} from 'src/services';

export type InsightAction = Action<InsightActions>;
export type InsightAsyncAction = ThunkAction<Promise<InsightAction | void>, State, Action<any>, any>;
export type InsightDispatchThunk = ThunkDispatch<State, {}, InsightAction>;

const getInsightsParams = (baseParams: InsightsBaseParams, getState: GetState): IInsightsParams => {
  const appParams = getAppParams(getState);
  return {
    ...appParams,
    ...baseParams,
  };
};

const getInsightsPhrasesParams = (baseParams: InsightsBaseParams, getState: GetState): InsightsPhrasesParams => {
  const insightsV2Params: IInsightsParams = getInsightsParams(baseParams, getState);
  const state = getState();
  const userId = getCurrentUserId(state);

  return {
    userId,
    ...insightsV2Params,
  };
};

const getPhraseParams = (baseParams: IPhraseBaseParams, getState: GetState): IPhraseParams => {
  const state = getState();
  const userId = getCurrentUserId(state);
  const appId = getCurrentAppId(state);
  return {
    userId,
    appId,
    ...baseParams,
  };
};

export const insights = {
  init: () => ({ type: InsightActions.INIT }),
  cleanup: () => {
    return (dispatch: Function) => {
      InsightsService.abortAll();
      dispatch({ type: InsightActions.CLEANUP });
    };
  },

  fetchAggs: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsParams(baseParams, getState);
      dispatch(insights.fetchAggsPending(params));
      return InsightsService.fetchInsightsAggs(params)
        .then((data: any) => dispatch(insights.fetchAggsSuccess(data)))
        .catch((error) => dispatch(insights.fetchAggsError(error)));
    };
  },
  fetchAggsPending: (payload: IInsightsParams) => ({
    type: InsightActions.FETCH_AGGS_PENDING,
    payload,
  }),
  fetchAggsSuccess: (payload: any) => ({ type: InsightActions.FETCH_AGGS_SUCCESS, payload }),
  fetchAggsError: (error: Error) => ({ type: InsightActions.FETCH_AGGS_ERROR, error }),

  // separate action to fetch data for full range
  fetchSentimentAgg: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsParams(baseParams, getState);
      dispatch(insights.fetchSentimentAggPending(params));
      return InsightsService.fetchSentimentAgg(params)
        .then((data: any) => dispatch(insights.fetchSentimentAggSuccess(data)))
        .catch((error) => dispatch(insights.fetchSentimentAggError(error)));
    };
  },
  fetchSentimentAggPending: (payload: any) => ({
    type: InsightActions.FETCH_SENTIMENT_AGG_PENDING,
    payload,
  }),
  fetchSentimentAggSuccess: (payload: any) => ({
    type: InsightActions.FETCH_SENTIMENT_AGG_SUCCESS,
    payload,
  }),
  fetchSentimentAggError: (error: Error) => ({
    type: InsightActions.FETCH_SENTIMENT_AGG_ERROR,
    error,
  }),

  fetchFanSignalsAgg: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsParams(baseParams, getState);
      dispatch(insights.fetchFanSignalsAggPending(params));
      return InsightsService.fetchFanSignalsAgg(params)
        .then((data: any) => dispatch(insights.fetchFanSignalsAggSuccess(data)))
        .catch((error) => dispatch(insights.fetchFanSignalsAggError(error)));
    };
  },
  fetchFanSignalsAggPending: (payload: any) => ({
    type: InsightActions.FETCH_FAN_SIGNALS_AGG_PENDING,
    payload,
  }),
  fetchFanSignalsAggSuccess: (payload: any) => ({
    type: InsightActions.FETCH_FAN_SIGNALS_AGG_SUCCESS,
    payload,
  }),
  fetchFanSignalsAggError: (error: Error) => ({
    type: InsightActions.FETCH_FAN_SIGNALS_AGG_ERROR,
    error,
  }),

  fetchSurveyNames: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsParams(baseParams, getState);
      dispatch(insights.fetchSurveyNamesPending(params));
      return InsightsService.fetchSurveyNames(params)
        .then((data: any) => dispatch(insights.fetchSurveyNamesSuccess(data)))
        .catch((error) => dispatch(insights.fetchSurveyNamesError(error)));
    };
  },
  fetchSurveyNamesPending: (payload: any) => ({
    type: InsightActions.FETCH_SURVEY_NAMES_PENDING,
    payload,
  }),
  fetchSurveyNamesSuccess: (payload: any) => ({
    type: InsightActions.FETCH_SURVEY_NAMES_SUCCESS,
    payload,
  }),
  fetchSurveyNamesError: (error: Error) => ({
    type: InsightActions.FETCH_SURVEY_NAMES_ERROR,
    error,
  }),

  // separate action to fetch data for full range
  fetchTypesAgg: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsParams(baseParams, getState);
      dispatch(insights.fetchTypesAggPending(params));
      return InsightsService.fetchTypesAgg(params)
        .then((data: any) => dispatch(insights.fetchTypesAggSuccess(data)))
        .catch((error) => dispatch(insights.fetchTypesAggError(error)));
    };
  },
  fetchTypesAggPending: (payload: any) => ({
    type: InsightActions.FETCH_TYPES_AGG_PENDING,
    payload,
  }),
  fetchTypesAggSuccess: (payload: any) => ({
    type: InsightActions.FETCH_TYPES_AGG_SUCCESS,
    payload,
  }),
  fetchTypesAggError: (error: Error) => ({ type: InsightActions.FETCH_TYPES_AGG_ERROR, error }),

  fetchData: (baseParams: InsightsBaseParams, resetPagination = false) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsParams(baseParams, getState);
      dispatch(insights.fetchDataPending(params));

      return InsightsService.fetchInsightsData(params)
        .then((data: any) => dispatch(insights.fetchDataSuccess(data, { resetPagination })))
        .catch((error: Error) => dispatch(insights.fetchDataError(error)));
    };
  },
  fetchDataPending: (payload: any) => ({ type: InsightActions.FETCH_DATA_PENDING, payload }),
  fetchDataSuccess: (payload: any, meta: any) => ({
    type: InsightActions.FETCH_DATA_SUCCESS,
    payload,
    meta,
  }),
  fetchDataError: (error: Error) => ({ type: InsightActions.FETCH_DATA_ERROR, error }),

  // fetch all available regions
  fetchRegions: () => {
    return (dispatch: Function, getState: GetState) => {
      const params = {
        ...getAppParams(getState),
        ...getDashboardDateRange(getState()),
      };
      dispatch(insights.fetchRegionsPending(params));
      return InsightsService.fetchRegions(params)
        .then((data: any) => dispatch(insights.fetchRegionsSuccess(data)))
        .catch((error) => dispatch(insights.fetchRegionsError(error)));
    };
  },
  fetchRegionsPending: (payload: IAppParams) => ({
    type: InsightActions.FETCH_REGIONS_PENDING,
    payload,
  }),
  fetchRegionsSuccess: (payload: any) => ({ type: InsightActions.FETCH_REGIONS_SUCCESS, payload }),
  fetchRegionsError: (error: Error) => ({ type: InsightActions.FETCH_REGIONS_ERROR, error }),

  fetchRegionsAgg: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsParams(baseParams, getState);
      dispatch(insights.fetchRegionsAggPending(params));
      return InsightsService.fetchRegionsAgg(params)
        .then((data: any) => dispatch(insights.fetchRegionsAggSuccess(data)))
        .catch((error) => dispatch(insights.fetchRegionsAggError(error)));
    };
  },
  fetchRegionsAggPending: (payload: IInsightsParams) => ({
    type: InsightActions.FETCH_REGIONS_AGG_PENDING,
    payload,
  }),
  fetchRegionsAggSuccess: (payload: any) => ({
    type: InsightActions.FETCH_REGIONS_AGG_SUCCESS,
    payload,
  }),
  fetchRegionsAggError: (error: Error) => ({
    type: InsightActions.FETCH_REGIONS_AGG_ERROR,
    error,
  }),

  initExport: (params: InsightsFilterParams, exportProps: ExportInsightParams) => {
    const manager = InsightsExportManager.getInstance();

    return async (dispatch: InsightDispatchThunk, getState: GetState) => {
      const state = getState();
      const userId = getCurrentUserId(state);
      const exportParams = {
        ...params,
        ...getAppParams(getState),
        orgId: getCurrentOrgId(state),
      };

      const type = InsightsService.getExportType(exportProps);

      if (manager) {
        manager.initExportSession({
          params: { userId, ...exportParams },
          type,
          onComplete: (data: { url: string } | any) => {
            InsightsService.downloadExportFile(data.url, exportProps)
              .then(() => {
                dispatch(insights.exportSuccess());
              })
              .catch((error) => {
                dispatch(insights.exportError(error));
              });
          },
          onError: (error: any) => {
            dispatch(insights.exportError(error));
          },
        });
      }
      dispatch(insights.exportPending(params));
    };
  },

  exportPending: (payload: any) => ({ type: InsightActions.EXPORT_PENDING, payload }),
  exportSuccess: () => ({ type: InsightActions.EXPORT_SUCCESS }),
  exportError: (error: Error) => ({ type: InsightActions.EXPORT_ERROR, error }),

  fetchPhrases: (baseParams: InsightsBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getInsightsPhrasesParams(baseParams, getState);
      dispatch(insights.fetchPhrasesPending(params));
      return InsightsService.fetchPhrases(params)
        .then((data: any) => dispatch(insights.fetchPhrasesSuccess(data)))
        .catch((error) => dispatch(insights.fetchPhrasesError(error)));
    };
  },
  fetchPhrasesPending: (payload: IInsightsParams) => ({
    type: InsightActions.FETCH_PHRASES_PENDING,
    payload,
  }),
  fetchPhrasesSuccess: (payload: any) => ({ type: InsightActions.FETCH_PHRASES_SUCCESS, payload }),
  fetchPhrasesError: (error: Error) => ({ type: InsightActions.FETCH_PHRASES_ERROR, error }),

  updatePhrase: (baseParams: IPhraseBaseParams) => {
    return (dispatch: Function, getState: GetState) => {
      const params = getPhraseParams(baseParams, getState);
      const { action, phrase } = params;
      const meta = { action, phrase };
      dispatch(insights.updatePhrasePending(params, meta));
      return InsightsService.updatePhrase(params)
        .then((data: any) => dispatch(insights.updatePhraseSuccess(data, meta)))
        .catch((error) => dispatch(insights.updatePhraseError(error, meta)));
    };
  },
  updatePhrasePending: (payload: IPhraseParams, meta: any) => ({
    type: InsightActions.UPDATE_PHRASE_PENDING,
    payload,
    meta,
  }),
  updatePhraseSuccess: (payload: any, meta: any) => ({
    type: InsightActions.UPDATE_PHRASE_SUCCESS,
    payload,
    meta,
  }),
  updatePhraseError: (error: Error, meta: any) => ({
    type: InsightActions.UPDATE_PHRASE_ERROR,
    error,
    meta,
  }),

  updatePage: (page: number) => ({ type: InsightActions.UPDATE_DATA_PAGE, payload: { page } }),

  addTagPending: (payload: any, meta: any) => ({
    type: InsightActions.TAG_ADD_PENDING,
    payload,
    meta,
  }),
  addTagSuccess: (payload: TagModel, meta: any) => ({
    type: InsightActions.TAG_ADD_SUCCESS,
    payload,
    meta,
  }),
  addTagError: (error: Error, meta: any) => ({ type: InsightActions.TAG_ADD_ERROR, error, meta }),

  renameTagPending: (payload: any, meta: any) => ({
    type: InsightActions.TAG_RENAME_PENDING,
    payload,
    meta,
  }),
  renameTagSuccess: (payload: { tags: TagModel[] }, meta: any) => ({
    type: InsightActions.TAG_RENAME_SUCCESS,
    payload,
    meta,
  }),
  renameTagError: (error: Error, meta: any) => ({
    type: InsightActions.TAG_RENAME_ERROR,
    error,
    meta,
  }),

  removeTagPending: (payload: any, meta: any) => ({
    type: InsightActions.TAG_REMOVE_PENDING,
    payload,
    meta,
  }),
  removeTagSuccess: (payload: any, meta: any) => ({
    type: InsightActions.TAG_REMOVE_SUCCESS,
    payload,
    meta,
  }),
  removeTagError: (error: Error, meta: any) => ({
    type: InsightActions.TAG_REMOVE_ERROR,
    error,
    meta,
  }),

  fetchSavedInsights: () => {
    return (dispatch: Function, getState: GetState) => {
      dispatch({ type: InsightActions.FETCH_SAVED_INSIGHTS_PENDING });
      const appId = getCurrentAppId(getState());
      return InsightsService.fetchSavedInsights(appId)
        .then((data: SavedInsight[]) => dispatch({ type: InsightActions.FETCH_SAVED_INSIGHTS_SUCCESS, payload: data }))
        .catch((error) => dispatch({ type: InsightActions.FETCH_SAVED_INSIGHTS_ERROR, error }));
    };
  },

  saveInsights: (payload: { name: string; description: string; filters: InsightsFilterParams }) => {
    return async (dispatch: Function, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const userId = getCurrentUserId(state);
      const orgId = getCurrentOrgId(state);
      let count = getSelectedCountByDistribution(state);
      const modifiedDate = Date.now();

      const { startDate, endDate } = getDashboardDateRange(state);

      dispatch({ type: InsightActions.SAVE_INSIGHTS_PENDING, payload });

      if (!equals({ startDate, endDate }, pick(['startDate', 'endDate'], payload.filters))) {
        count = await InsightsService.fetchTypesAgg({
          appId,
          ...payload.filters,
        }).then(({ aggs }) => {
          return aggs.type_distribution
            ? aggs.type_distribution
                .filter(({ type }: { type: AggsType }) => payload.filters.types.includes(type))
                .reduce((res: number, type: { count: number }) => (res += type.count), 0)
            : count;
        });
      }

      return InsightsService.saveInsights({ appId, orgId, userId }, { ...payload, count, modifiedDate })
        .then((data: any) => {
          dispatch({ type: InsightActions.SAVE_INSIGHTS_SUCCESS, payload: data });
        })
        .catch((error) => {
          dispatch({ type: InsightActions.SAVE_INSIGHTS_ERROR, error });
        });
    };
  },

  deleteSavedInsight: (payload: { id: string }) => {
    return (dispatch: Function) => {
      dispatch({ type: InsightActions.DELETE_SAVED_INSIGHT_PENDING });

      return InsightsService.deleteSavedInsight(payload.id)
        .then(() =>
          dispatch({
            type: InsightActions.DELETE_SAVED_INSIGHT_SUCCESS,
            payload,
          }),
        )
        .catch((error) => dispatch({ type: InsightActions.DELETE_SAVED_INSIGHT_ERROR, error }));
    };
  },

  updateSavedInsight: (payload: Pick<SavedInsight, 'name' | 'description' | 'id'>) => {
    return (dispatch: Function, getState: GetState) => {
      dispatch({ type: InsightActions.UPDATE_SAVED_INSIGHTS_PENDING });

      const storedInsight = getSavedInsightById(getState(), payload.id) as SavedInsight;

      return InsightsService.updateSavedInsight({
        ...storedInsight,
        name: payload.name,
        description: payload.description,
      })
        .then((response) =>
          dispatch({
            type: InsightActions.UPDATE_SAVED_INSIGHTS_SUCCESS,
            payload: response,
          }),
        )
        .catch((error) => dispatch({ type: InsightActions.UPDATE_SAVED_INSIGHTS_ERROR, error }));
    };
  },

  updateSentiment: (payload: SentimentData) => {
    return (dispatch: Function) => {
      dispatch({ type: InsightActions.UPDATE_SENTIMENT_PENDING });
      return ElasticSearchService.updateSentiment(payload)
        .then((data: SentimentData) => {
          dispatch({
            type: InsightActions.UPDATE_SENTIMENT_SUCCESS,
            payload: data,
          });
        })
        .catch((error: Error) => {
          dispatch({ type: InsightActions.UPDATE_SENTIMENT_ERROR, error });
        });
    };
  },
};
