import { sortBy } from 'ramda';
import { RECEIVED_NOTICES } from '../actions';

const sortByCreated = (list) => sortBy((item) => -new Date(item.created_at).valueOf(), list);

export default (state = [], action = {}) => {
  switch (action.type) {
    case RECEIVED_NOTICES:
      return sortByCreated(action.payload);
    default:
      return state;
  }
};
