import React, { Component } from 'react';

import PropTypes from 'prop-types';

import onClickOutside from 'react-onclickoutside';

class Tooltip extends Component {
  static propTypes = {
    closeTooltip: PropTypes.func.isRequired,
    children: PropTypes.node,
    learnLink: PropTypes.string,
  };

  static defaultProps = {
    children: null,
  };

  state = {
    bottomOrTop: 'top',
    leftOrRight: 'left',
  };

  // TODO: remove ignored coverage here pending investigation into testing
  // component relative window position.
  /* istanbul ignore next: pending research on browser position testing */
  componentDidMount() {
    this.applyGravity();
    window.addEventListener('resize', this.applyGravity);
  }

  /* istanbul ignore next: pending research on browser position testing */
  componentWillUnmount() {
    window.removeEventListener('resize', this.applyGravity);
  }

  handleClickOutside() {
    this.props.closeTooltip();
  }

  learnLink = () => {
    if (!this.props.learnLink) {
      return null;
    }
    return (
      <a href={this.props.learnLink} target="_blank" rel="noopener noreferrer">
        <button className="info-tooltip-action info-tooltip-learn">
          Help documentation
        </button>
      </a>
    );
  };

  /* istanbul ignore next: pending research on browser position testing */
  applyGravity = () => {
    const tooltipRect = this.tooltipElem.getBoundingClientRect();
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    const currentYPos = tooltipRect[this.state.bottomOrTop];
    const currentXPos = tooltipRect[this.state.leftOrRight];

    const newBottomOrTop = currentYPos < centerY ? 'top' : 'bottom';
    const newLeftOrRight = currentXPos < centerX ? 'left' : 'right';

    this.setState({ bottomOrTop: newBottomOrTop, leftOrRight: newLeftOrRight });
  };

  render() {
    const styles = {
      [this.state.bottomOrTop]: '10px',
      [this.state.leftOrRight]: '10px',
    };
    return (
      <div
        className="info-tooltip-box"
        style={styles}
        ref={(tooltipElem) => { this.tooltipElem = tooltipElem; }}
      >
        <div className="info-tooltip-content">
          {this.props.children}
        </div>
        <div className="info-tooltip-actions">
          {this.learnLink()}
          <button
            className="button info-tooltip-action info-tooltip-close"
            onClick={this.props.closeTooltip}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

export const UnwrappedTooltip = Tooltip;
export default onClickOutside(Tooltip);
