import moment from 'moment';
import { IDateAnnotation, IAppStoreVersion, DateAnnotationType } from '../types/core';

export default function collapseAndFormatAppReleases(
  referenceLines: IAppStoreVersion[],
  startDate: number,
  endDate: number
): IDateAnnotation[] {
  const graphTimeRange: number = moment(endDate).diff(moment(startDate), 'days');
  let minimumDaysDiff: number;
  if (graphTimeRange <= 30) {
    minimumDaysDiff = 3;
  } else if (graphTimeRange <= 60) {
    minimumDaysDiff = 5;
  } else {
    minimumDaysDiff = 7;
  }
  return referenceLines
    .reduceRight((refLines: IAppStoreVersion[], xRef: IAppStoreVersion) => {
      if (refLines.length < 1) return [xRef];
      const lastRefDate = refLines[0].date;
      const thisRefDate = xRef.date;
      if (Math.abs(moment(lastRefDate).diff(moment(thisRefDate), 'days')) < minimumDaysDiff) {
        const labelCount = refLines[0].version.split('+').length;
        let newLabel;
        if (labelCount < 3) {
          newLabel = ` + ${xRef.version}`;
        } else if (labelCount === 3) {
          newLabel = ' + ...';
        } else {
          newLabel = '';
        }
        return [{ ...refLines[0], version: `${refLines[0].version}${newLabel}` }, ...refLines.slice(1)];
      }
      return [xRef, ...refLines];
    }, [])
    .map((version) => ({
      id: version.version,
      label: version.version,
      startDate: new Date(version.date),
      endDate: new Date(version.date),
      type: 'appRelease' as DateAnnotationType,
      color: '#000',
      comment: '',
      createdBy: '',
      createdAt: new Date(version.date).toISOString(),
    }));
}
