import { NoteItem } from './notes.types';

export interface NotesState {
  notes: NoteItem[];
  loadingIds: string[];
  activatingIds: string[];
  loading: boolean;
}

export const initialState: NotesState = {
  notes: [],
  loadingIds: [],
  activatingIds: [],
  loading: false,
};
