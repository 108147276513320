/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { SimplePopover, SimplePopoverProps } from '../popover';
import { bemPrefix } from '../../../utils';
import { DialogButtons, DialogButtonsProps } from './dialog-buttons';

import './dialog.scss';

export interface DialogProps
  extends Pick<SimplePopoverProps, 'isOpen' | 'targetEl' | 'withArrow' | 'onClose' | 'placement' | 'className'>,
    DialogButtonsProps {
  viewId?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
}

const bem = bemPrefix('dropdown-dialog');

export const Dialog: React.FC<DialogProps> & { PopoverPlacement: typeof SimplePopover.PopoverPlacement } = ({
  children,
  isOpen,
  viewId,
  innerRef,
  onCancel,
  onSubmit,
  submitDisabled,
  className = '',
  ...props
}) => (
  <div className={`${bem()} ${className}`} id={`dialog-view-${viewId}`} ref={innerRef}>
    {isOpen && (
      <SimplePopover className={bem('container')} isOpen={isOpen} {...props}>
        <div className={bem('content')}>{children}</div>
        <DialogButtons onSubmit={onSubmit} onCancel={onCancel} submitDisabled={submitDisabled} {...props} />
      </SimplePopover>
    )}
  </div>
);

Dialog.PopoverPlacement = SimplePopover.PopoverPlacement;
