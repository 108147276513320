import { createSelector } from 'reselect';

import { DateLastPeriod, CustomPeriodName } from 'src/utils/time';
import { State } from 'src/reducers';
import { FanSignalsAppState } from 'src/reducers/dashboard/fan-signals.reducer';
import { getCurrentAppId } from 'src/selectors/current_app';

import { getDashboard } from './dashboard';

export const getFanSignalsCurrentApp = createSelector<
  State,
  ReturnType<typeof getDashboard>,
  ReturnType<typeof getCurrentAppId>,
  FanSignalsAppState
>(
  getDashboard,
  getCurrentAppId,
  (dashboard, appId) => (dashboard.fanSignals ? dashboard.fanSignals[appId] || {} : {})
);

const getFanSignalByPeriod = createSelector(
  (_store: State, period: DateLastPeriod | CustomPeriodName) => period,
  getFanSignalsCurrentApp,
  (period, fanSignals) => (fanSignals.period && fanSignals.period[period] ? fanSignals.period[period] : undefined)
);

export const getFanSignalsCountsByPeriod = createSelector(
  getFanSignalByPeriod,
  (fsData) => (fsData ? fsData.counts || [] : [])
);

export const getFanSignalsCountsByDashboard = createSelector(
  (state: State) => getFanSignalByPeriod(state, DateLastPeriod.DAYS_30),
  (fsData) => (fsData ? fsData.counts || [] : [])
);

export const isFanSignalsCountsLoadingByPeriod = createSelector(
  getFanSignalByPeriod,
  (fsData) => (fsData ? fsData.loading : false)
);
