import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';

import { Time, bemPrefix } from 'src/utils';
import { DialogButtons, DateRangeCustomSelector } from 'src/components/molecules';
import { DateLastPeriod, DAY, daysAgoInbound } from 'src/utils/time';

import RangeOption from './range_option';

import '../../styles/molecules/date_range_picker.scss';

const bem = bemPrefix('date-range-picker');

export default class DateRangePicker extends Component {
  static CUSTOM_NAME = 'custom';

  static propTypes = {
    className: PropTypes.string,
    fieldName: PropTypes.string,
    dateNoticeRange: PropTypes.bool,
    dateNoticeUtc: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    startDateOpen: PropTypes.func,
    startDateClose: PropTypes.func,
    endDateOpen: PropTypes.func,
    endDateClose: PropTypes.func,
    presetsOnTop: PropTypes.bool,
    presets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      startDate: PropTypes.object,
      endDate: PropTypes.object,
      name: PropTypes.string,
    })),
  };

  static defaultProps = {
    className: '',
    fieldName: '',
    dateNoticeRange: true,
    dateNoticeUtc: true,
    presetsOnTop: false,
    startDate: daysAgoInbound(30).valueOf(),
    endDate: new Date().valueOf(),
    startDateOpen: () => {},
    startDateClose: () => {},
    endDateOpen: () => {},
    endDateClose: () => {},
  };

  state = {
    ...DateRangeCustomSelector.momentifyDatesRanges(this.props.startDate || daysAgoInbound(30).valueOf(), this.props.endDate),
    name: DateLastPeriod.DAYS_30,
    $pristine: true, // represents if form fields have been changed
  };

  componentDidMount() {
    Mousetrap.bind(['enter'], this.submitDateRange);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      startDate: nextStartDate,
      endDate: nextEndDate,
    } = nextProps;
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
    } = prevState;

    return {
      $pristine: (
        prevStartDate.isSame(nextStartDate)
        && prevEndDate.isSame(nextEndDate)
      ),
    };
  }

  componentWillUnmount() {
    Mousetrap.unbind(['enter'], this.submitDateRange);
  }

  submitDateRange = event => {
    if (event) {
      event.preventDefault();
    }

    this.props.onSubmit(this.state.startDate.valueOf(), this.state.endDate.valueOf(), this.state.name);
  };

  isItemSelected = (item) => {
    if (typeof item.isSelected === 'function') {
      return item.isSelected(DateRangeCustomSelector.momentifyDatesRanges(this.state.startDate, this.state.endDate));
    }

    const { startDate, endDate } = DateRangeCustomSelector.momentifyDatesRanges(item.startDate, item.endDate);
    return this.state.startDate.isSame(startDate, 'day') && this.state.endDate.isSame(endDate, 'day');
  };

  onItemSelect = (item) => () => this.setState({
    ...DateRangeCustomSelector.momentifyDatesRanges(item.startDate, item.endDate),
    name: item.name,
  });

  onRangeCustomChange = (startDate, endDate) => {
    this.setState({
      startDate: Time.create(startDate),
      endDate: Time.create(endDate),
      name: DateRangePicker.CUSTOM_NAME,
    });
  };

  render() {
    const dateNotice = this.props.dateNoticeRange && this.state.endDate.valueOf() > (new Date().valueOf() - (4 * DAY));

    return (
      <form
        className={`${bem('', { presetsOnTop: this.props.presetsOnTop })} ${this.props.className}`}
        onSubmit={this.submitDateRange}
      >
        <h3>Select Date Range</h3>
        {this.props.dateNoticeUtc && (
          <p>
            Data is collected and displayed in UTC (Coordinated Universal Time).
            The current UTC time is <strong>{Time.create.utc().format(Time.FORMAT.LLLL)}</strong>.
          </p>
        )}
        {!this.props.presetsOnTop && <DateRangeCustomSelector
          startDate={this.state.startDate.valueOf()}
          endDate={this.state.endDate.valueOf()}
          onRangeChange={this.onRangeCustomChange}
        />}
        <div className="preset-ranges">
          <ul className="preset-ranges">
            {this.props.presets.map(item => <RangeOption
              {...item}
              key={item.name}
              selected={this.isItemSelected(item)}
              onSelect={this.onItemSelect(item)}
            />
            )}
          </ul>
        </div>
        {this.props.presetsOnTop && <DateRangeCustomSelector
          startDate={this.state.startDate.valueOf()}
          endDate={this.state.endDate.valueOf()}
          onRangeChange={this.onRangeCustomChange}
        />}
        {
          dateNotice ? (
            <div className="date-notice-container">
              <div className="date-notice">
                We’re still receiving data for the date range selected. Your results may change as additional data comes in.
              </div>
            </div>
          ) : null
        }
        <DialogButtons
          onSubmit={this.submitDateRange}
          onCancel={this.props.close}
          submitDisabled={this.state.$pristine}
        />
      </form>
    );
  }
}
