import { Subscription, fromEvent } from 'rxjs';
import { generateUUID } from 'src/utils';
import jwt from 'src/utils/jwt';

export interface IBaseWebSocket<T, D> {
  socket: WebSocket;
  init(): void;
  send(data: any): void;
  on(type: T, handler: (data: D) => void): Subscription;
  destroy(): void;
}

export type EventHandler<T = any> = (args: T) => void;

export class BaseWebSocket {
  static isMessageEvent = isMessageEvent;

  subs = new Subscription();
  id = generateUUID();
  socket: WebSocket;

  constructor(WS_URL: string) {
    // Web socket atrium may not be updated to use access token, use legacy token
    this.socket = new WebSocket(`${WS_URL}?token=${jwt.getLegacyToken()}`);
  }

  isOpen = () => isOpen(this.socket);
  isConnecting = () => isConnecting(this.socket);
  isClosed = () => isClosed(this.socket);

  protected onSocketEvent = (event: keyof WebSocketEventMap, handler: EventHandler): Subscription => {
    const sub = fromEvent(this.socket, event).subscribe(handler);
    this.subs.add(sub);
    return sub;
  };

  onSocketOpen = (handler: EventHandler) => {
    if (this.isOpen()) {
      handler(null);
    } else {
      this.onSocketEvent('open', handler);
    }
  };

  onSocketClose = (handler: EventHandler) => {
    if (this.isClosed()) {
      handler(null);
    } else {
      this.onSocketEvent('close', handler);
    }
  };

  destroy() {
    if (!this.isClosed()) {
      this.socket.close();
    }
    this.subs.unsubscribe();
  }
}

function isClosed(socket: WebSocket): boolean {
  return [WebSocket.CLOSED, WebSocket.CLOSING].includes(socket.readyState);
}

function isOpen(socket: WebSocket): boolean {
  return WebSocket.OPEN === socket.readyState;
}

function isConnecting(socket: WebSocket): boolean {
  return WebSocket.CONNECTING === socket.readyState;
}

function isMessageEvent(event: MessageEvent | Event): boolean {
  return event.type === 'message' && !!(event as MessageEvent).data;
}
