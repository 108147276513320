import React from 'react';

export const preventEventDefault = (e?: React.SyntheticEvent<any>) => e && e.preventDefault();
export const stopEventPropagation = (e?: React.SyntheticEvent<any>) => e && e.stopPropagation();

export const preventEventStop = (e?: React.SyntheticEvent) => {
  preventEventDefault(e);
  stopEventPropagation(e);
};

export const preventEventType = (types: React.SyntheticEvent['type'][]) => (e?: React.SyntheticEvent) => {
  if (e && types.includes(e.type)) {
    preventEventStop(e);
  }
};
