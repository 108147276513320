import { createSelector } from 'reselect';
import { State } from 'src/reducers';
import { InitiatorsState, initialState } from 'src/reducers/initiators';

const withInitiatorId = (_: State, initiatorId: string) => initiatorId;

export const getInitiators = (state: State): InitiatorsState => state.initiators || initialState;

export const getInitiatorsWithInteractionTarget = createSelector([getInitiators], (initiators) =>
  initiators.initiators.filter((item) => item.self_targeting === false),
);

export const getInitiatorsList = createSelector(getInitiators, (initiators) => initiators.initiators || []);

export const getInitiatorsListIsLoading = createSelector(getInitiators, (initiators) => initiators.loading);
export const getInitiatorById = createSelector([getInitiatorsList, withInitiatorId], (initiators, initiatorId) =>
  initiators.find((item) => item.id === initiatorId),
);

export const getLoadingInitiatorById = createSelector([getInitiators, withInitiatorId], ({ loadingIds }, initiatorId) =>
  loadingIds.includes(initiatorId),
);

export const getActivatingInitiatorById = createSelector(
  [getInitiators, withInitiatorId],
  ({ activatingIds }, initiatorId) => activatingIds.includes(initiatorId),
);

export const getLoadingInitiatorEventsTimeSeriesById = createSelector(
  [getInitiators, withInitiatorId],
  ({ loadingTimeSeriesIds }, initiatorId) => loadingTimeSeriesIds.includes(initiatorId),
);
