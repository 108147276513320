/* eslint-disable no-duplicate-case */
import { SurveyReducerActions } from 'src/actions/surveys-v12/survey.actions'
import { SurveyV12Actions } from 'src/actions/surveys-v12/survey.action-types';
import { LoveDialogActions } from 'src/actions/love-dialog/love-dialog.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';

import { SurveyV12Item, SurveyResponseStat, SurveyTextAnswers } from './surveys.types';

// Implementation inspired by:
// https://medium.com/@peatiscoding/typescripts-with-redux-redux-thunk-recipe-fcce4ffca405

type SurveyId = string;
type QuestionId = string;
// TODO: Move to survey.types
export interface ISurveysV12ReduxState {
  loadingActive: boolean;
  loadingNotActive: boolean;
  bootstrapped: boolean;
  fetchActiveError?: boolean;
  fetchNotActiveError?: boolean;
  deleteError?: boolean;
  surveysActive: SurveyV12Item[];
  surveysNotActive: SurveyV12Item[];
  surveyStatistics: Partial<
    Record<SurveyId, { stats?: SurveyResponseStat[]; textAnswers?: Record<QuestionId, SurveyTextAnswers> }>
  >;
  loadingIds: SurveyId[];
  // The id of the survey whose active status is being updated
  updatingSurveyActiveId?: SurveyId;
  // The id of the survey that is being deleted
  deletingSurveyId?: SurveyId;
  // The list of appIds to which the current user is subscribed to survey summary emails
  emailSummaryAppIds?: string[];
  // The total number of active/not active surveys.
  totalActive: number;
  totalNotActive: number;
  // The last page of active/not active surveys that was fetched.
  pageActive: number;
  pageNotActive: number;
}

export const initialState: ISurveysV12ReduxState = {
  loadingActive: false,
  loadingNotActive: false,
  bootstrapped: false,
  fetchActiveError: false,
  fetchNotActiveError: false,
  deleteError: false,
  surveysActive: [],
  surveysNotActive: [],
  surveyStatistics: {},
  loadingIds: [],
  totalActive: 0,
  totalNotActive: 0,
  pageActive: 0,
  pageNotActive: 0,
};

export const surveysV12 = (
  state: ISurveysV12ReduxState = initialState,
  action: SurveyReducerActions = {} as SurveyReducerActions
): ISurveysV12ReduxState => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_PENDING:
    case UPDATE_CURRENT_APP_SUCCESS: {
      return {
        ...state,
        ...initialState,
      };
    }

    case LoveDialogActions.SAVE_LOVE_DIALOG_SUCCESS: {
      return {
        ...state,
        bootstrapped: false,
      };
    }
    case SurveyV12Actions.CREATE_SURVEY_V12_SUCCESS: {
      const survey = action.payload as SurveyV12Item
      return {
        ...state,
        bootstrapped: false,
        surveysActive: survey.active ? [survey, ...state.surveysActive] : state.surveysActive,
        surveysNotActive: survey.active ? state.surveysNotActive : [survey, ...state.surveysNotActive],
      };
    }
    case SurveyV12Actions.UPDATE_SURVEY_V12_SUCCESS: {
      const { id, active } = action.payload as SurveyV12Item

      return {
        ...state,
        surveysActive: active ? [...state.surveysActive.filter(survey => survey.id !== id), action.payload] : [...state.surveysActive.filter(survey => survey.id !== id)],
        surveysNotActive: active ? [...state.surveysNotActive.filter(survey => survey.id !== id)] : [...state.surveysNotActive.filter(survey => survey.id !== id), action.payload],
      };
    }
    case SurveyV12Actions.FETCH_SURVEY_V12_PENDING: {
      return {
        ...state,
        loadingIds: [action.payload.id, ...state.loadingIds],
      };
    }
    case SurveyV12Actions.FETCH_SURVEY_V12_SUCCESS: {
      const createdSurvey = action.payload as SurveyV12Item
      return {
        ...state,
        surveysActive: createdSurvey.active ? [...state.surveysActive.filter(survey => survey.id !== createdSurvey.id), createdSurvey] : state.surveysActive,
        surveysNotActive: createdSurvey.active ? state.surveysNotActive : [...state.surveysNotActive.filter(survey => survey.id !== createdSurvey.id), createdSurvey],
        loadingIds: [...state.loadingIds.filter(id => id !== action.payload.id)],
      };
    }
    case SurveyV12Actions.RECEIVE_FETCH_ACTIVE_SURVEYS_V12: {
      const storedActiveSurveysSet = new Map()
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysActive.forEach(surv => storedActiveSurveysSet.set(surv.id, true))
      // check if new surveys are already stored
      const newActiveSurvs = action.surveys?.filter(surv => !storedActiveSurveysSet.has(surv.id))
      return {
        ...state,
        surveysActive: [
          ...state.surveysActive,
          ...newActiveSurvs as SurveyV12Item[],
        ],
        loadingActive: false,
        bootstrapped: true,
        pageActive: action.page as number,
        totalActive: action.total as number,
        fetchActiveError: false,
      };
    }

    case SurveyV12Actions.RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_V12: {
      const storedNotActiveSurveysSet = new Map()
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysNotActive.forEach(surv => storedNotActiveSurveysSet.set(surv.id, true))
      // check if new surveys are already stored
      const newNotActiveSurvs = action.surveys?.filter(surv => !storedNotActiveSurveysSet.has(surv.id))
      return {
        ...state,
        surveysNotActive: [
          ...state.surveysNotActive,
          ...newNotActiveSurvs as SurveyV12Item[],
        ],
        loadingNotActive: false,
        bootstrapped: true,
        pageNotActive: action.page as number,
        totalNotActive: action.total as number,
        fetchNotActiveError: false,
      }
    }

    case SurveyV12Actions.FETCH_SURVEY_V12_ERROR: {
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => id !== action.payload.id),
      };
    }

    case SurveyV12Actions.FETCH_SURVEYS_V12_BY_ID_PENDING: {
      return {
        ...state,
        loadingIds: [...state.loadingIds, ...action.payload.ids],
      };
    }
    case SurveyV12Actions.FETCH_SURVEYS_V12_BY_ID_SUCCESS: {
      const survsIdItemMap = new Map()
      action.payload.forEach((survey: SurveyV12Item) => survsIdItemMap.set(survey.id, survey))
      return {
        ...state,
        surveysActive: state.surveysActive.map(survey => survsIdItemMap.has(survey.id) ? survsIdItemMap.get(survey.id) : survey),
        surveysNotActive: state.surveysNotActive.map(survey => survsIdItemMap.has(survey.id) ? survsIdItemMap.get(survey.id) : survey),
        loadingIds: [...state.loadingIds.filter((id) => !survsIdItemMap.has(id))],
      };
    }
    case SurveyV12Actions.FETCH_SURVEYS_V12_BY_ID_ERROR: {
      const surveysIdMap = new Map()
      action.payload.ids.forEach((id: string) => surveysIdMap.set(id, true))
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => !surveysIdMap.has(id)),
      };
    }

    case SurveyV12Actions.GET_SURVEY_V12_STATS_SUCCESS: {
      const { surveyId, stats } = action.payload;
      return {
        ...state,
        surveyStatistics: {
          ...state.surveyStatistics,
          [surveyId]: {
            ...state.surveyStatistics[surveyId],
            stats,
          },
        },
      };
    }
    case SurveyV12Actions.GET_SURVEY_V12_STATS_ERROR: {
      const { surveyId } = action.payload as { surveyId: string };
      const surveyStats = state.surveyStatistics ? { ...state.surveyStatistics } : {};
      const statistics = surveyStats[surveyId];
      if (statistics) {
        statistics.stats = [];
      }
      return {
        ...state,
        surveyStatistics: surveyStats,
      };
    }

    case SurveyV12Actions.GET_SURVEY_V12_TEXT_ANSWERS_SUCCESS: {
      const { surveyId, textAnswers } = action.payload;
      return {
        ...state,
        surveyStatistics: {
          ...state.surveyStatistics,
          [surveyId]: {
            ...state.surveyStatistics[surveyId],
            textAnswers,
          },
        },
      };
    }

    case SurveyV12Actions.GET_SURVEY_V12_TEXT_ANSWERS_ERROR: {
      const { surveyId } = action.payload as { surveyId: string };
      const surveyStats = state.surveyStatistics ? { ...state.surveyStatistics } : {};
      const statistics = surveyStats[surveyId];
      if (statistics) {
        statistics.textAnswers = {};
      }
      return {
        ...state,
        surveyStatistics: surveyStats,
      };
    }

    case SurveyV12Actions.GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_SUCCESS: {
      const { surveyId, textAnswers } = action.payload;
      const statistics = state.surveyStatistics[surveyId];
      const surveyTextAnswers = statistics ? { ...statistics.textAnswers } : {};
      return {
        ...state,
        surveyStatistics: {
          ...state.surveyStatistics,
          [surveyId]: {
            ...state.surveyStatistics[surveyId],
            textAnswers: {
              ...surveyTextAnswers,
              ...textAnswers,
            },
          },
        },
      };
    }

    case SurveyV12Actions.GET_SURVEY_V12_QUESTION_TEXT_ANSWERS_ERROR: {
      const { surveyId } = action.payload as { surveyId: string };
      const surveyStats = state.surveyStatistics ? { ...state.surveyStatistics } : {};
      const statistics = surveyStats[surveyId];
      if (statistics) {
        statistics.textAnswers = {};
      }
      return {
        ...state,
        surveyStatistics: surveyStats,
      };
    }

    case SurveyV12Actions.REQUEST_FETCH_ACTIVE_SURVEYS_V12:
      return { ...state, loadingActive: true };
    case SurveyV12Actions.REQUEST_FETCH_NOT_ACTIVE_SURVEYS_V12:
      return { ...state, loadingNotActive: true };

    case SurveyV12Actions.RECEIVE_FETCH_ACTIVE_SURVEYS_V12:
      const storedActiveSurveysSet = new Map()
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysActive.forEach(surv => storedActiveSurveysSet.set(surv.id, true))
      // check if new surveys are already stored
      const newActiveSurvs = action.surveys?.filter(surv => !storedActiveSurveysSet.has(surv.id))
      return {
        ...state,
        surveysActive: [
          ...state.surveysActive,
          ...newActiveSurvs as SurveyV12Item[],
        ],
        loadingActive: false,
        bootstrapped: true,
        pageActive: action.page as number,
        totalActive: action.total as number,
        fetchActiveError: false,
      };

    case SurveyV12Actions.RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_V12:
      const storedNotActiveSurveysSet = new Map()
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysNotActive.forEach(surv => storedNotActiveSurveysSet.set(surv.id, true))
      // check if new surveys are already stored
      const newNotActiveSurvs = action.surveys?.filter(surv => !storedNotActiveSurveysSet.has(surv.id))
      return {
        ...state,
        surveysNotActive: [
          ...state.surveysNotActive,
          ...newNotActiveSurvs as SurveyV12Item[],
        ],
        loadingNotActive: false,
        bootstrapped: true,
        pageNotActive: action.page as number,
        totalNotActive: action.total as number,
        fetchNotActiveError: false,
      };

    case SurveyV12Actions.ERRORED_FETCH_ACTIVE_SURVEYS_V12:
      return {
        ...state,
        loadingActive: false,
        fetchActiveError: true,
        surveysActive: [],
      };

    case SurveyV12Actions.ERRORED_FETCH_NOT_ACTIVE_SURVEYS_V12:
      return {
        ...state,
        loadingNotActive: false,
        fetchNotActiveError: true,
        surveysNotActive: [],
      };

    case SurveyV12Actions.REQUEST_UPDATE_SURVEY_V12_ACTIVE:
      return {
        ...state,
        updatingSurveyActiveId: action.surveyId,
      };

    case SurveyV12Actions.RECEIVE_UPDATE_SURVEY_V12_ACTIVE:
      const updatedSurvey = [...state.surveysNotActive, ...state.surveysActive].find(s => s.id === action.surveyId)
      return {
        ...state,
        updatingSurveyActiveId: undefined,
        surveysActive: action.active ? [...state.surveysActive, { ...updatedSurvey, active: !!action.active } as SurveyV12Item] : [...state.surveysActive.filter(s => s.id !== action.surveyId)],
        surveysNotActive: action.active ? [...state.surveysNotActive.filter(s => s.id !== action.surveyId)] : [...state.surveysNotActive, { ...updatedSurvey, active: !!action.active } as SurveyV12Item],
      };
    case SurveyV12Actions.REQUEST_DELETE_SURVEY_V12:
      return { ...state, deletingSurveyId: action.surveyId, deleteError: false };

    case SurveyV12Actions.RECEIVE_DELETE_SURVEY_V12:
      return {
        ...state,
        bootstrapped: false,
        surveysActive: (state.surveysActive || []).filter((survey) => survey.id !== action.surveyId),
        surveysNotActive: (state.surveysNotActive || []).filter((survey) => survey.id !== action.surveyId),
        deletingSurveyId: undefined,
        deleteError: false,
      };
    case SurveyV12Actions.ERRORED_DELETE_SURVEY_V12:
      return { ...state, deletingSurveyId: undefined, deleteError: true };

    case SurveyV12Actions.RECEIVE_SURVEY_V12_EMAIL_SUMMARY_APP_IDS:
      return { ...state, emailSummaryAppIds: action.emailSummaryAppIds };

    default:
      return state;
  }
};
