export default {
  fanSignals: {
    aggs: {
      fs_distribution: [
        { fs_state: 'ReclaimedFan', count: 2094 },
        { fs_state: 'NonRespondent', count: 2084 },
        { fs_state: 'LostFan', count: 2008 },
        { fs_state: 'RepeatFan', count: 1991 },
        { fs_state: 'RepeatOpportunity', count: 1984 },
        { fs_state: 'Opportunity', count: 1967 },
        { fs_state: 'Fan', count: 1949 },
      ],
    },
  },
  tags: {
    tags: [{ name: 'feature request', count: 1 }],
  },
  surveys: {
    aggs: {
      survey_title_distribution: [
        {
          survey_title: 'Mailing List Signup',
          count: 4288,
        },
        {
          survey_title: 'NPS Surveys',
          count: 2330,
        },
        {
          survey_title: 'Getting To Know You',
          count: 2249,
        },
        {
          survey_title: 'User Experience',
          count: 1860,
        },
        {
          survey_title: 'Store Finder Feature',
          count: 1200,
        },
        {
          survey_title: 'Rating Prompt Survey',
          count: 900,
        },
      ],
    },
  },
};
