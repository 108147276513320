import { GetState, DispatchFunc } from 'src/reducers';
import { getCurrentAppId } from 'src/selectors/current_app';
import { getCurrentUserId } from 'src/selectors/user';
import { EventMetricsService } from 'src/services';

export enum Events {
  EXPORT_SIGNALS_BY_CUSTOMER = 'export_signals_by_customer',
  EXPORT_SIGNALS_BY_DAY = 'export_signals_by_day',
  EXPORT_SIGNALS_OVER_TIME = 'export_signals_over_time',
  EXPORT_MENU_MAIN = 'export_menu_main',
  MODAL_EXPORT_SIGNALS_BY_CUSTOMER = 'modal_export_signals_by_customer',
  MODAL_EXPORT_SIGNALS_BY_DAY = 'modal_export_signals_by_day',
  EXPORT_LEARN_MORE = 'export_learn_more',
  EXPORT_SIGNALS_INFO_MODAL_HAS_SEEN = 'export_signals_info_modal_has_seen',
  EXPORT_SIGNALS_CID_MODAL = 'export_signals_cid_modal',
  EXPORT_SIGNALS_TAKE_ACTION_DROPDOWN = 'export_signals_take_action_dropdown',
  EXPORT_SIGNALS_DC_DOWNLOAD = 'export_signals_dc_download',
  NFS_INFO_ICON = 'nfs_info_icon',
  FAN_SIGNALS_PAGE = 'fan_signals_page',
  NFS_FAN_SIGNALS_LINK = 'nfs_fan_signals_link',
  NEW_SDK_LINK_SURVEY_LOGIC = 'new_sdk_link_survey_logic',
  SURVEY_LOGIC_ICON_OPENED ='survey_logic_icon_opened',
  QM_SESSION = 'qm_session',
  STAR_RATINGS_ANDROID_FILTER = 'star_ratings_android_filter',
  CDM_PAGE_ENTER = 'cdm_page_enter',
  CDM_ARCHIEVE_CLICK = 'cdm_archieve_click',
  CDM_SHOW_CLICK = 'cdm_show_click'
}

type EventMetricsDispatch = DispatchFunc<any>;

export const eventMetrics = {
  sendEvent: (event_name: Events) => (dispatch: EventMetricsDispatch, getState: GetState) => {
    const state = getState();
    const app_id = getCurrentAppId(state);
    const user_id = getCurrentUserId(state);
    const created_at = new Date().toISOString();

    return EventMetricsService.sendEvent({ app_id, user_id, event_name, created_at });
  },
};
