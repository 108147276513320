export enum InsightActions {
  INIT = 'INSIGHTS_V2_INIT',
  CLEANUP = 'INSIGHTS_V2_CLEANUP',

  FETCH_AGGS_PENDING = 'INSIGHTS_V2_FETCH_AGGS_PENDING',
  FETCH_AGGS_SUCCESS = 'INSIGHTS_V2_FETCH_AGGS_SUCCESS',
  FETCH_AGGS_ERROR = 'INSIGHTS_V2_FETCH_AGGS_ERROR',

  // separate action to fetch data for full range
  FETCH_SENTIMENT_AGG_PENDING = 'INSIGHTS_V2_FETCH_SENTIMENT_AGG_PENDING',
  FETCH_SENTIMENT_AGG_SUCCESS = 'INSIGHTS_V2_FETCH_SENTIMENT_AGG_SUCCESS',
  FETCH_SENTIMENT_AGG_ERROR = 'INSIGHTS_V2_FETCH_SENTIMENT_AGG_ERROR',

  FETCH_FAN_SIGNALS_AGG_PENDING = 'INSIGHTS_V2_FETCH_FAN_SIGNALS_AGG_PENDING',
  FETCH_FAN_SIGNALS_AGG_SUCCESS = 'INSIGHTS_V2_FETCH_FAN_SIGNALS_AGG_SUCCESS',
  FETCH_FAN_SIGNALS_AGG_ERROR = 'INSIGHTS_V2_FETCH_FAN_SIGNALS_AGG_ERROR',

  FETCH_SURVEY_NAMES_PENDING = 'INSIGHTS_V2_FETCH_SURVEY_NAMES_PENDING',
  FETCH_SURVEY_NAMES_SUCCESS = 'INSIGHTS_V2_FETCH_SURVEY_NAMES_SUCCESS',
  FETCH_SURVEY_NAMES_ERROR = 'INSIGHTS_V2_FETCH_SURVEY_NAMES_ERROR',

  FETCH_TYPES_AGG_PENDING = 'INSIGHTS_V2_FETCH_TYPES_AGG_PENDING',
  FETCH_TYPES_AGG_SUCCESS = 'INSIGHTS_V2_FETCH_TYPES_AGG_SUCCESS',
  FETCH_TYPES_AGG_ERROR = 'INSIGHTS_V2_FETCH_TYPES_AGG_ERROR',

  FETCH_DATA_PENDING = 'INSIGHTS_V2_FETCH_DATA_PENDING',
  FETCH_DATA_SUCCESS = 'INSIGHTS_V2_FETCH_DATA_SUCCESS',
  FETCH_DATA_ERROR = 'INSIGHTS_V2_FETCH_DATA_ERROR',

  FETCH_PHRASES_PENDING = 'INSIGHTS_V2_FETCH_PHRASES_PENDING',
  FETCH_PHRASES_SUCCESS = 'INSIGHTS_V2_FETCH_PHRASES_SUCCESS',
  FETCH_PHRASES_ERROR = 'INSIGHTS_V2_FETCH_PHRASES_ERROR',

  UPDATE_PHRASE_PENDING = 'INSIGHTS_V2_UPDATE_PHRASE_PENDING',
  UPDATE_PHRASE_SUCCESS = 'INSIGHTS_V2_UPDATE_PHRASE_SUCCESS',
  UPDATE_PHRASE_ERROR = 'INSIGHTS_V2_UPDATE_PHRASE_ERROR',

  UPDATE_DATA_PAGE = 'INSIGHTS_V2_UPDATE_DATA_PAGE',

  EXPORT_PENDING = 'INSIGHTS_V2_EXPORT_PENDING',
  EXPORT_SUCCESS = 'INSIGHTS_V2_EXPORT_SUCCESS',
  EXPORT_ERROR = 'INSIGHTS_V2_EXPORT_ERROR',

  // saved insights
  SAVE_INSIGHTS_PENDING = 'INSIGHTS_V2_SAVE_INSIGHTS_PENDING',
  SAVE_INSIGHTS_SUCCESS = 'INSIGHTS_V2_SAVE_INSIGHTS_SUCCESS',
  SAVE_INSIGHTS_ERROR = 'INSIGHTS_V2_SAVE_INSIGHTS_ERROR',

  FETCH_SAVED_INSIGHTS_PENDING = 'INSIGHTS_V2_FETCH_SAVED_INSIGHTS_PENDING',
  FETCH_SAVED_INSIGHTS_SUCCESS = 'INSIGHTS_V2_FETCH_SAVED_INSIGHTS_SUCCESS',
  FETCH_SAVED_INSIGHTS_ERROR = 'INSIGHTS_V2_FETCH_SAVED_INSIGHTS_ERROR',

  DELETE_SAVED_INSIGHT_PENDING = 'INSIGHTS_V2_DELETE_SAVED_INSIGHT_PENDING',
  DELETE_SAVED_INSIGHT_SUCCESS = 'INSIGHTS_V2_DELETE_SAVED_INSIGHT_SUCCESS',
  DELETE_SAVED_INSIGHT_ERROR = 'INSIGHTS_V2_DELETE_SAVED_INSIGHT_ERROR',

  UPDATE_SAVED_INSIGHTS_PENDING = 'INSIGHTS_V2_UPDATE_SAVED_INSIGHTS_PENDING',
  UPDATE_SAVED_INSIGHTS_SUCCESS = 'INSIGHTS_V2_UPDATE_SAVED_INSIGHTS_SUCCESS',
  UPDATE_SAVED_INSIGHTS_ERROR = 'INSIGHTS_V2_UPDATE_SAVED_INSIGHTS_ERROR',

  // fetch all available regions
  FETCH_REGIONS_PENDING = 'INSIGHTS_V2_FETCH_REGIONS_PENDING',
  FETCH_REGIONS_SUCCESS = 'INSIGHTS_V2_FETCH_REGIONS_SUCCESS',
  FETCH_REGIONS_ERROR = 'INSIGHTS_V2_FETCH_REGIONS_ERROR',

  FETCH_REGIONS_AGG_PENDING = 'INSIGHTS_V2_FETCH_REGIONS_AGG_PENDING',
  FETCH_REGIONS_AGG_SUCCESS = 'INSIGHTS_V2_FETCH_REGIONS_AGG_SUCCESS',
  FETCH_REGIONS_AGG_ERROR = 'INSIGHTS_V2_FETCH_REGIONS_AGG_ERROR',

  // tags
  TAG_ADD_PENDING = 'INSIGHTS_V2_TAG_ADD_PENDING',
  TAG_ADD_SUCCESS = 'INSIGHTS_V2_TAG_ADD_SUCCESS',
  TAG_ADD_ERROR = 'INSIGHTS_V2_TAG_ADD_ERROR',

  TAG_RENAME_PENDING = 'INSIGHTS_V2_TAG_RENAME_PENDING',
  TAG_RENAME_SUCCESS = 'INSIGHTS_V2_TAG_RENAME_SUCCESS',
  TAG_RENAME_ERROR = 'INSIGHTS_V2_TAG_RENAME_ERROR',

  TAG_REMOVE_PENDING = 'INSIGHTS_V2_TAG_REMOVE_PENDING',
  TAG_REMOVE_SUCCESS = 'INSIGHTS_V2_TAG_REMOVE_SUCCESS',
  TAG_REMOVE_ERROR = 'INSIGHTS_V2_TAG_REMOVE_ERROR',

  // update sentiment
  UPDATE_SENTIMENT_PENDING = 'UPDATE_SENTIMENT_PENDING',
  UPDATE_SENTIMENT_SUCCESS = 'UPDATE_SENTIMENT_SUCCESS',
  UPDATE_SENTIMENT_ERROR = 'UPDATE_SENTIMENT_ERROR',

}
