export enum LoveDialogActions {
  BOOTSTRAP_LD_FLOWS = 'BOOTSTRAP_LD_FLOWS',

  GET_LD_FLOWS_PENDING = 'GET_LD_FLOWS_PENDING',
  GET_LD_FLOWS_SUCCESS = 'GET_LD_FLOWS_SUCCESS',
  GET_LD_FLOWS_ERROR = 'GET_LD_FLOWS_ERROR',

  ACTIVATE_LOVE_DIALOG_PENDING = 'ACTIVATE_LOVE_DIALOG_PENDING',
  ACTIVATE_LOVE_DIALOG_SUCCESS = 'ACTIVATE_LOVE_DIALOG_SUCCESS',
  ACTIVATE_LOVE_DIALOG_ERROR = 'ACTIVATE_LOVE_DIALOG_ERROR',

  SAVE_LOVE_DIALOG_PENDING = 'SAVE_LOVE_DIALOG_PENDING',
  SAVE_LOVE_DIALOG_SUCCESS = 'SAVE_LOVE_DIALOG_SUCCESS',
  SAVE_LOVE_DIALOG_ERROR = 'SAVE_LOVE_DIALOG_ERROR',

  SAVE_MESSAGE_CENTER_PENDING = 'SAVE_MESSAGE_CENTER_PENDING',
  SAVE_MESSAGE_CENTER_SUCCESS = 'SAVE_MESSAGE_CENTER_SUCCESS',
  SAVE_MESSAGE_CENTER_ERROR = 'SAVE_MESSAGE_CENTER_ERROR',

  FETCH_STATS_PENDING = 'FETCH_STATS_PENDING',
  FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS',
  FETCH_STATS_ERROR = 'FETCH_STATS_ERROR',

  FETCH_PREV_STATS_PENDING = 'FETCH_PREV_STATS_PENDING',
  FETCH_PREV_STATS_SUCCESS = 'FETCH_PREV_STATS_SUCCESS',
  FETCH_PREV_STATS_ERROR = 'FETCH_PREV_STATS_ERROR',
}
