/* eslint-disable no-case-declarations */

import { toString, tryCatch } from 'ramda';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WEBSOCKET } from 'src/api';
import { InsightsService, ExportInsightData } from '../insights-service';
import { IBaseWebSocket, BaseWebSocket, EventHandler } from '../base-ws-service';
import {
  ExportType,
  RespMessage,
  SendMessage,
  RespType,
  RespState,
  SendType,
  StatusType,
  ExcludeExportFields,
} from './insights-export-ws.types';

export { EventHandler };

export class InsightsExportWs extends BaseWebSocket implements IBaseWebSocket<RespType, RespMessage> {
  static RespType = RespType;
  static SendType = SendType;
  static ExportType = ExportType;
  RespType = RespType;
  SendType = SendType;
  ExportType = ExportType;

  private subject = new Subject<RespMessage>();

  constructor() {
    super(WEBSOCKET.INSIGHTS_EXPORT);
    this.init();
  }

  init() {
    this.onSocketEvent('message', this.onMessage.bind(this));
    this.onSocketEvent('error', this.onError.bind(this));
    this.onSocketEvent('close', this.onClose.bind(this));
  }

  startExport({ userId, ...params }: ExportInsightData & { userId: string } & ExcludeExportFields, type: ExportType) {
    this.send({
      $type: SendType.start,
      export_type: type,
      parameters: params,
      owner_id: userId,
    });
    return this;
  }

  onExportInProgress(handler: EventHandler) {
    this.on(RespType.state, (data) => isInProgress(data) && handler(data));
    return this;
  }

  onExportComplete(handler: EventHandler<RespState & { url: string }>) {
    this.on(RespType.state, (data: RespState & { url: string }) => isCompleted(data) && handler(data));
    return this;
  }

  onExportDownstreamFail(handler: EventHandler) {
    this.on(RespType.failure, handler);
    return this;
  }

  onExportError(handler: EventHandler) {
    this.on(RespType.error, handler);
    return this;
  }

  send(data: SendMessage) {
    if (!this.isOpen()) {
      return;
    }
    if (typeof data === 'object') {
      const payload = convertSendData(data);
      this.socket.send(toString(payload));
    }
  }

  on(type: RespType, handler: (data: RespMessage) => void): Subscription {
    const sub = this.subject.pipe(filter<RespMessage>((data: RespMessage) => data.$type === type)).subscribe(handler);
    this.subs.add(sub);
    return sub;
  }

  private onMessage(event: MessageEvent & { type: keyof WebSocketEventMap }) {
    if (BaseWebSocket.isMessageEvent(event)) {
      const data = invokeMessagePayload(event);
      if (data) {
        this.subject.next(data);
      }
    }
  }

  private onError(_event: Event) {
    //
  }

  private onClose(_event: CloseEvent) {
    //
  }
}

function invokeMessagePayload(event: MessageEvent): RespMessage | undefined {
  if (event.data) {
    return tryCatch((d) => JSON.parse(d), (d) => d)(event.data);
  }
  return undefined;
}

function isStatusMessage(data: RespMessage): data is RespState {
  return data.$type === RespType.state && !!data.status;
}

function isCompleted(data: RespMessage): data is RespState {
  return isStatusMessage(data) && data.status === StatusType.completed;
}

function isInProgress(data: RespMessage): data is RespState {
  return isStatusMessage(data) && data.status === StatusType.inProgress;
}

function convertSendData(data: SendMessage) {
  switch (data.$type) {
    case SendType.start:
      const params = InsightsService.aggsParamsToQueryRequest(data.parameters) as any;
      delete params.orgId;
      return {
        ...data,
        parameters: {
          ...params,
          app_id: data.parameters.appId,
          org_id: data.parameters.orgId,
        },
      };
    default:
      return data;
  }
}
