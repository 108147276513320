import * as d3 from 'd3';

const ONE_DAY = 1000 * 60 * 60 * 24;
// Finds the extents (min/max) of several data series for a given key.
// @param {array} series - 1 or more series of data
// @param {string} key - Which key you are looking for the extent of
// @param {maxReferenceLineValue} - If you have a reference on this key, this
//  ensures that the max is not lower than it.
// key = 'y'|'date'
const findDomain = (series, key, maxReferenceLineValue) => {
  const extents = series.map(s => (
    d3.extent(s.datums || [], (datum) => datum[key])
  )).filter(s => s[0] !== undefined || s[1] !== undefined);

  const result = extents.reduce((memo, extent) => (
    [
      Math.min(memo[0], extent[0]),
      Math.max(memo[memo.length - 1], extent[extent.length - 1], maxReferenceLineValue),
    ]
  ), [Infinity, 0]);

  return resolveSinglePoint(result);
};

export default findDomain;

/**
 * Show on the middle of the graph, of one point (same domain edges)
 * @param {*} domain = [ number|Infinity, number ]
 * @param {*} key = 'y'|'date'
 */
function resolveSinglePoint(domain, key) {
  if (domain[0] === domain[1] && Number.isFinite(domain[0])) {
    return key === 'date' ? [domain[0] - ONE_DAY, domain[0] + ONE_DAY] : [domain[0] / 2, domain[0] * 2];
  }
  return domain;
}
