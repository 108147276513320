import styled from 'styled-components';

/**
 * Normalized
 *
 * A button component with browser default styles stripped out. In most cases
 * you want to use a different button type, however this can be useful in cases
 * where you need an unstyled button element within a component.
 */
export default styled.button`
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0;
  height: auto;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
