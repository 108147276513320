import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import tagPropType from 'src/proptypes/tag';
import { Button } from 'src/components/molecules/button';
import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';
import { Icon, SimplePopover } from 'src/components/molecules';
import { RenameTagForm } from './rename_tag_form';

import './tag-item.scss';

const bem = bemPrefix('tag-item');

const isSameTagNames = (newName, oldName) => newName.toLowerCase() === oldName.toLowerCase();

export class Tag extends Component {
  state = {
    showRenameTagForm: false,
    newTagName: null,
  };

  tagRef = React.createRef();

  static propTypes = {
    tag: tagPropType,
    tags: PropTypes.arrayOf(tagPropType),
    gtmId: PropTypes.string,
    onRetry: PropTypes.func,
    updatable: PropTypes.bool,
    onRename: PropTypes.func,
    removable: PropTypes.bool,
    onRemove: PropTypes.func,
    selectable: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  handleRenameTag = (newTagName) => {
    if (!isSameTagNames(newTagName, this.props.tag.name)) {
      this.setState({ showRenameTagForm: false, newTagName: newTagName.toLowerCase() }, () => this.onRenameTag());
    }
  };

  onRenameTag = () => {
    const newName = this.state.newTagName && this.state.newTagName.toLowerCase();
    if (newName && !isSameTagNames(newName, this.props.tag.name)) {
      this.props.onRename(this.props.tag.name, newName);
    }
  };

  toggleRenameTagForm = () => {
    this.setState({ showRenameTagForm: !this.state.showRenameTagForm });
  };

  handleOnClick = handler => () => {
    handler(this.props.tag.name);
  };

  render() {
    const {
      tag,
      tags,
      onRetry,
      updatable,
      removable,
      onRemove,
      selectable,
      onSelect,
    } = this.props;
    const { showRenameTagForm } = this.state;

    const nameEl = <span className={bem('name')}>{tag.name}</span>;
    return (
      <>
        { showRenameTagForm ? (
          <SimplePopover className={bem('rename-popover')} isOpen={showRenameTagForm} targetEl={this.tagRef.current} onClose={this.toggleRenameTagForm}>
            <RenameTagForm tags={tags} onChange={this.handleRenameTag} />
          </SimplePopover>
        ) : null }
        <li className={classNames(bem(), 'fs-hide', { removableContainer: removable })} ref={this.tagRef} gtm-id={this.props.gtmId}>
          <div className={bem('container')}>
            {(updatable || selectable) ? (<Button className={classNames(bem('title'), { tag_in_list: !removable })} normalize onClick={updatable ? this.toggleRenameTagForm : this.handleOnClick(onSelect)}>{nameEl}</Button>) : nameEl}
            {tag.addError ? (<Button normalize error onClick={this.handleOnClick(onRetry)}>: Click to re-apply action</Button>) : ''}
            {removable ? <Icon className={bem('button-remove')} onClick={() => onRemove(this.props.tag.name)} name="close" gtmId={GtmID.DeleteCardTag} /> : ''}
          </div>
        </li>
      </>
    );
  }
}

export const Tags = ({ tags, ...rest }) => (
  <ul className="tags-list">
    {tags.map(tag => (
      <Tag
        key={tag.name}
        tag={tag}
        tags={tags}
        {...rest}
      />
    ))}
  </ul>
);

Tags.propTypes = {
  tags: PropTypes.arrayOf(tagPropType),
};

export default Tags;
