import React, { useCallback, useState } from 'react';

export function useFieldBlurHighlight() {
  const [focused, setFocused] = useState(false);
  const onBlurEvent = useCallback(() => setFocused(true), []);

  return {
    focused,
    onBlurEvent,
  };
}
