import { AppInteractionData, InteractionItem, InteractionType } from 'src/types/core';

export enum ImageLayoutTitle {
  FULL_WIDTH = 'Full Width', // default
  CENTER = 'Center',
  LEFT = 'Align Left',
  RIGHT = 'Align Right',
}

export enum ImageLayout {
  FULL_WIDTH = 'full_width', // default
  CENTER = 'center',
  LEFT = 'align_left',
  RIGHT = 'align_right',
}

export interface PromptHeaderFile {
  file: any;
  title: string;
  layout: ImageLayout;
  altText: string;
  scale?: number;
}

export interface PromptMultiAppsItem {
  id?: string;
  name: string;
  title?: string;
  body?: string;
  canLaunch: boolean;
  displayType?: null | 'notification';
  firstActiveAt?: string;
  updatedAt?: string;
  createdAt?: string;
  image?: PromptHeaderFile;
  maxHeight?: number;
  interactionData: AppInteractionData[];
  orgId: string;
  startTime?: InteractionItem['start_time'];
  endTime?: InteractionItem['end_time'];
  createdBy: string;
  type: InteractionType;
}

export type PromptPayload = {
  interaction_data: { actions: { append_variables: never[] }[] }[];
};
