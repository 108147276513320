import React, { useRef, useCallback } from 'react';
import Creatable from 'react-select/creatable';
import { GtmID } from 'src/types/google-tag-manager';
import { KeyboardKey } from 'src/components/molecules/util-handlers';

import '../../../styles/molecules/react-select.scss';

export interface SelectableInputProps<OptionType = any> {
  gtmId?: GtmID;
  options: OptionType[];
  onChange(value: string): void;
  onKeyDown?(e: React.KeyboardEvent): void;
}

export const SelectableInput: React.FC<SelectableInputProps> = function SelectableInput({
  gtmId,
  options,
  onChange,
  onKeyDown,
}) {
  const ref = useRef<any>(null);

  const onKeyDownHandler = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === KeyboardKey.ESC) {
        if (ref.current) {
          ref.current.onMenuClose();
          ref.current.blur();
        }
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [onKeyDown, ref.current],
  );

  return (
    <Creatable
      ref={ref as any}
      className="react-select"
      classNamePrefix="react-select"
      options={options}
      getOptionValue={({ name }) => name}
      getNewOptionData={(inputValue: string, optionLabel: string) => ({
        name: inputValue,
        label: optionLabel,
      })}
      formatOptionLabel={({ label, name }: { label: string; name: string }) => (
        <div gtm-id={gtmId}>{label || name}</div>
      )}
      onCreateOption={(name: string) => onChange(name.substring(0, 100) as string)}
      onChange={({ name }: { name: string }) => onChange(name.substring(0, 100))}
      onKeyDown={onKeyDownHandler}
      controlShouldRenderValue={false}
      autoFocus
    />
  );
};
