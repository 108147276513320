export const family = {
  standard: 'Lato, Lato-Regular, Tahoma, sans-serif',
};

export const weight = {
  light: 200,
  regular: 300,
  bold: 700,
};

export const colors = {
  primary: '#555',
};

export const lineHeight = {
  primary: '1.4em',
};

export const size = {
  primary: '16px',
};

export default {
  primary: `normal ${weight.regular} ${size.primary}/${lineHeight.primary} ${family.standard}`,
};
