import { EventsActions, EventsReducerAction } from 'src/actions/events';
import { EventsState, initialState } from './events.state';
import { AppEventItem, AppEventList } from './events.types';

export const eventsReducer = (
  state: EventsState = initialState,
  action: EventsReducerAction = {} as EventsReducerAction,
): EventsState => {
  switch (action.type) {
    case EventsActions.FETCH_MULTUPLE_APPS_EVENTS_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case EventsActions.FETCH_MULTUPLE_APPS_EVENTS_SUCCESS: {
      const { events } = action.payload as { events: AppEventList[] };
      return {
        ...state,
        isLoading: false,
        data: events,
      };
    }

    case EventsActions.CREATE_MULTUPLE_APPS_EVENTS_ERROR:
    case EventsActions.FETCH_MULTUPLE_APPS_EVENTS_ERROR: {
      const { message = 'An error occurred' } = action.error as Error;
      return {
        ...state,
        isLoading: false,
        error: message,
      };
    }

    case EventsActions.CREATE_MULTUPLE_APPS_EVENTS_PENDING: {
      return {
        ...state,
        isCreating: true,
      };
    }

    case EventsActions.CREATE_MULTUPLE_APPS_EVENTS_SUCCESS: {
      const { events } = action.payload as { events: AppEventItem[] };
      const appEventMap = new Map<string, AppEventItem>();
      events.forEach((event) => {
        appEventMap.set(event.app_id, event);
      });

      return {
        ...state,
        isCreating: false,
        data: state.data.map((eventData) => {
          if (!appEventMap.has(eventData.app_id)) {
            return eventData;
          }

          return {
            ...eventData,
            items: [...eventData.items, appEventMap.get(eventData.app_id) as AppEventItem],
          };
        }),
      };
    }

    default:
      return state;
  }
};
