import cookie from 'react-cookie';

const forceRemoveCookie = (name, host = window.location.hostname, cookieInterface = cookie) => {
  const subdomains = host.split('.');
  subdomains.forEach((subdomain, i) => {
    if (i >= subdomains.length - 1) return;
    const domain = subdomains.slice(i).join('.');
    cookieInterface.remove(name, { domain });
    cookieInterface.remove(name, { domain: `.${domain}` });
  });
};

export default forceRemoveCookie;
