/* eslint-disable arrow-body-style */
import { isEmpty } from 'ramda';
import { GetState, DispatchFunc } from 'src/reducers';
import { NotesReducerAction, NoteItem, NoteModel } from 'src/reducers/notes';
import { getCurrentAppId } from 'src/selectors/current_app';
import { NotesService } from 'src/services';
import { NoteActions } from 'src/actions/notes/notes.action-types';
import { getNoteById, getNotesList, getNotesListIsLoading } from 'src/selectors/notes.selectors';
import { surveys as surveysV12 } from 'src/actions/surveys-v12/survey.actions';

type NoteDispatch = DispatchFunc<NotesReducerAction>;

export const notes = {
  createNote: (note: NoteItem) => {
    return (dispatch: NoteDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());
      const notePayload = NoteModel.convertNoteToPayload(note);

      dispatch({ type: NoteActions.CREATE_NOTE_PENDING, payload: { note: notePayload } });

      return NotesService.save(appId, notePayload)
        .then((data) =>
          dispatch({
            type: NoteActions.CREATE_NOTE_SUCCESS,
            payload: { note: NoteModel.convertPayloadToNote(data) },
          }),
        )
        .catch((error) => {
          const errorMessage = Array.isArray(error.errors) ? error.errors : 'Failed to create a note';
          dispatch({ type: NoteActions.CREATE_NOTE_ERROR, payload: { note, errorMessage }, error });
        });
    };
  },
  saveNote: (note: NoteItem) => {
    return (dispatch: NoteDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());
      const notePayload = NoteModel.convertNoteToPayload(note);

      dispatch({ type: NoteActions.UPDATE_NOTE_PENDING, payload: { note: notePayload } });

      return NotesService.save(appId, notePayload)
        .then((data) =>
          dispatch({
            type: NoteActions.UPDATE_NOTE_SUCCESS,
            payload: { note: NoteModel.convertPayloadToNote(data) },
          }),
        )
        .catch((error) => {
          const errorMessage = Array.isArray(error.errors) ? error.errors : 'Failed to update a note';
          dispatch({ type: NoteActions.UPDATE_NOTE_ERROR, payload: { note, errorMessage }, error });
        });
    };
  },

  activateNote: (noteId: string, active: boolean, apiVersion: string) => {
    return (dispatch: NoteDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: NoteActions.ACTIVATE_NOTE_PENDING, payload: { noteId } });

      return NotesService.activate(appId, noteId, active, apiVersion)
        .then((data) =>
          dispatch({
            type: NoteActions.ACTIVATE_NOTE_SUCCESS,
            payload: { note: NoteModel.convertPayloadToNote(data) },
          }),
        )
        .catch((error) => dispatch({ type: NoteActions.ACTIVATE_NOTE_ERROR, payload: { noteId }, error }));
    };
  },

  bootstrapNotes: () => {
    return (dispatch: NoteDispatch, getState: GetState) => {
      const state = getState();
      const list = getNotesList(state);
      const isLoading = getNotesListIsLoading(state);

      dispatch({ type: NoteActions.BOOTSTRAP_NOTES, payload: {} });

      if (!isLoading && isEmpty(list)) {
        return dispatch(notes.fetchNotes());
      }

      return Promise.resolve();
    };
  },

  fetchNotes: () => {
    return (dispatch: NoteDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: NoteActions.GET_NOTES_PENDING, payload: {} });

      return NotesService.fetchNotes(appId)
        .then((data) =>
          dispatch({
            type: NoteActions.GET_NOTES_SUCCESS,
            payload: { notes: (data || []).map((note) => NoteModel.convertPayloadToNote(note)) },
          }),
        )
        .catch((error) => dispatch({ type: NoteActions.GET_NOTES_ERROR, error, payload: {} }));
    };
  },

  fetchNote: (noteId: string) => {
    return (dispatch: NoteDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: NoteActions.GET_NOTE_PENDING, payload: { noteId } });

      return NotesService.fetchNote(appId, noteId)
        .then((data) =>
          dispatch({
            type: NoteActions.GET_NOTE_SUCCESS,
            payload: { note: NoteModel.convertPayloadToNote(data) },
          }),
        )
        .catch((error) => dispatch({ type: NoteActions.GET_NOTE_ERROR, error, payload: { noteId } }));
    };
  },

  deleteNote: (noteId: string) => {
    return (dispatch: NoteDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);

      dispatch({ type: NoteActions.DELETE_NOTE_PENDING, payload: { noteId } });

      const removedNote = getNoteById(state, noteId) as NoteItem;
      const survIds = NoteModel.getActionsSurveyIds(removedNote);

      return NotesService.deleteNote(appId, noteId)
        .then((data) => {
          dispatch({ type: NoteActions.DELETE_NOTE_SUCCESS, payload: { noteId, note: data } });
          dispatch(surveysV12.fetchSurveysByIds(survIds));
        })
        .catch((error) => dispatch({ type: NoteActions.DELETE_NOTE_ERROR, payload: { noteId }, error }));
    };
  },

  cloneNoteToApps: (noteId: string, appIds: string[], options: { cloneTargeting: boolean }) => {
    return (dispatch: NoteDispatch) => {
      appIds.forEach((appId) => dispatch(notes.cloneNoteToApp(noteId, appId, options)));
    };
  },

  cloneNoteToApp: (noteId: string, appId: string, options: { cloneTargeting: boolean }) => {
    return (dispatch: NoteDispatch) => {
      dispatch({ type: NoteActions.CLONE_NOTE_TO_APP_PENDING, payload: { appId, noteId } });

      return NotesService.cloneNoteToApp(appId, noteId, options)
        .then((data) => {
          dispatch({
            type: NoteActions.CLONE_NOTE_TO_APP_SUCCESS,
            payload: { appId, noteId, note: data },
          });
        })
        .catch((error) => dispatch({ type: NoteActions.CLONE_NOTE_TO_APP_ERROR, payload: { appId, noteId }, error }));
    };
  },
};
