import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import numeral from 'numeral';
import GraphContainer from './container';
import HoverOverlay from './hover_overlay';

import '../styles/graphs/graph.scss';

export default class VerticalBarChart extends Component {
  state = {
    width: this.props.minWidth,
  };

  resizeDebounce = undefined;

  static propTypes = {
    className: PropTypes.string,
    subtitle: PropTypes.string,
    height: PropTypes.number,
    minWidth: PropTypes.number,
    xMargin: PropTypes.number,
    xOffset: PropTypes.number,
    yMargin: PropTypes.number,
    fetchDataCB: PropTypes.func,
    loading: PropTypes.bool,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        // eslint is unable to see the use of these props within util functions
        /* eslint-disable react/no-unused-prop-types */
        count: PropTypes.number,
        label: PropTypes.string,
        displayLabel: PropTypes.string,
        order: PropTypes.number,
        /* eslint-enable react/no-unused-prop-types */
      })
    ),
    summary: PropTypes.string,
    hoverValuesFormat: PropTypes.func,
    hoverDetailsFormat: PropTypes.func,
    numTicks: PropTypes.number,
    coloredHoverTitle: PropTypes.bool,
  };

  static defaultProps = {
    subtitle: '',
    summary: '',
    className: '',
    xMargin: 0,
    xOffset: -12,
    height: 154,
    minWidth: 0,
    yMargin: 0,
    loading: false,
    series: [],
    fetchDataCB: () => {},
    hoverValuesFormat: v => numeral(v).format('0.[0]a'),
    hoverDetailsFormat: _v => null,
    numTicks: 4,
  };

  componentDidMount() {
    this.resizeGraph();
    window.addEventListener('resize', this.resizeGraph);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeGraph);
  }

  resizeGraph = () => {
    this.setState({
      width: this.props.minWidth,
    });
    if (this.resizeDebounce) clearTimeout(this.resizeDebounce);
    this.resizeDebounce = setTimeout(() => {
      const clientWidth = this.inputNode ? this.inputNode.clientWidth : 0;
      this.setState({
        width: Math.max(this.props.minWidth, clientWidth),
      });
    }, 100);
  };

  render() {
    const { xMargin, xOffset, yMargin, height, series, className, summary, numTicks, ...rest } = this.props;

    // every element must have an 'order' property, if any fail that requirement then assign all elements an order equal to their index
    const hasOrder = (obj) => (Number.isFinite(obj.order));
    let orderedSeries;
    if (!series.every(hasOrder)) {
      orderedSeries = series.map((obj, idx) => ({ ...obj, order: idx }));
    } else {
      orderedSeries = series;
    }

    const sortedSeries = orderedSeries.sort((a, b) => (a.order - b.order));

    const xAxisFormatter = (idx) => {
      const seriesObj = sortedSeries.find(obj => obj.order === idx);
      return (seriesObj) ? seriesObj.displayLabel : idx;
    };

    // Dimensions
    const width = this.state.width;
    const graphWidth = width - (xMargin * 2);
    const graphHeight = height - (yMargin * 2);
    const groupWidth = ((graphWidth - 38) / (sortedSeries.length + 1));

    // Scales
    const xScale = d3.scaleBand()
      .domain(sortedSeries.map((s, i) => i))
      .range([xMargin + 38, graphWidth]);

    const xAxis = d3.axisBottom(xScale)
      .tickFormat((d) => sortedSeries[d].displayLabel)
      .tickSize(10)
      .tickSizeOuter(0);

    const yMax = Math.max(0, ...sortedSeries.map(obj => obj.count));
    const yScale = d3.scaleLinear()
      .domain([0, yMax])
      .range([graphHeight + yMargin, yMargin])
      .nice(5);

    const yAxis = d3.axisRight(yScale)
      .ticks(numTicks)
      .tickSize(graphWidth)
      .tickFormat(d => numeral(d).format('0.[0]a'));

    const totalNumberOfEvents = sortedSeries.reduce((tot, s) => tot + s.count, 0);

    return (
      <GraphContainer
        className={`${className} ratings-distribution`}
        inputRef={node => { this.inputNode = node; }}
        {...rest}
      >
        <div className="summary">
          {summary}
        </div>
        <div className="graph-wrapper" style={{ position: 'relative' }}>
          <svg
            width={width}
            height={height + 20}
            ref={(svg) => { this.svg = svg; }}
          >
            <g className="series">
              {sortedSeries.map((ratingGroup, i) => (
                <rect
                  x={xScale(i)}
                  y={yScale(ratingGroup.count)}
                  height={graphHeight - yScale(ratingGroup.count)}
                  width={groupWidth}
                  className={`key-color series-${i}-color`}
                  key={`histogram-bar-${ratingGroup.label}`}
                />
              ))}
            </g>
            <g
              className="x axis"
              transform={`translate(${xOffset}, ${height - yMargin})`}
              ref={axis => {
                d3.select(axis).call(xAxis);
                d3.select(axis).selectAll('text')
                  .attr('class', (i) => `display series-${i}-color`);
              }}
            />
            <g
              className="y axis"
              ref={axis => {
                d3.select(axis).call(yAxis);
                d3.select(axis).selectAll('line')
                  .attr('class', (_d, i) => {
                    if (i === 0) {
                      return 'origin';
                    }
                    return null;
                  });
                d3.select(axis).selectAll('text')
                  .attr('x', 2)
                  .attr('dy', 11)
                  .attr('font-weight', 'bold')
                  .attr('class', (_d, i) => {
                    if (i === 0) {
                      return 'origin';
                    }
                    return null;
                  });
              }}
            />
          </svg>
          {series.length > 0 && <HoverOverlay
            coloredTitle={this.props.coloredHoverTitle}
            className={`vertical-bar-chart-hover ${className}__hover`}
            forFormat={xAxisFormatter || d3.utcFormat('%b %e, %Y')}
            displayFormat={this.props.hoverValuesFormat}
            detailFormat={this.props.hoverDetailsFormat}
            summaryFormat={_v => null}
            mouseCoords={() => d3.mouse(this.svg)}
            coordinates={(xVal, yVal) => [xScale(xVal), yScale(yVal)]}
            coordsToPoint={(xCoord, yCoord) => {
              // returns the distance between steps
              const eachBand = xScale.step();
              // make sure we do not fall off the end
              const index = Math.min(Math.floor((xCoord - 30) / eachBand), xScale.domain().length - 1);
              const val = xScale.domain()[index];
              return [val, yScale.invert(yCoord)];
            }}
            closestPoints={(xPos, _yPos) => {
              if (sortedSeries[xPos]) {
                const denominator = totalNumberOfEvents;
                const percentOfTotal = series[xPos].count / denominator;
                const detail = denominator === 0 ? '0%' : `${Math.round(percentOfTotal * 100)}%`;
                const point = {
                  title: numeral(sortedSeries[xPos].count).format('0,0'),
                  label: sortedSeries[xPos].displayLabel,
                  value: parseInt(sortedSeries[xPos].count, 10),
                  detail: `(${detail})`,
                  y: sortedSeries[xPos].count,
                  x: xPos,
                };
                return [point];
              }

              return [{
                label: '',
                display: '',
                value: 1,
                y: 1,
                x: 1,
              }];
            }}
          />}
        </div>
      </GraphContainer>
    );
  }
}
