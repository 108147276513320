import { createSelector } from 'reselect';
import { State } from 'src/reducers';
import { NotesState } from 'src/reducers/notes';

const withNoteId = (_: State, noteId: string) => noteId;

export const getNotes = (state: State): NotesState => state.notes || [];

export const getNotesList = createSelector(
  getNotes,
  (notes) => notes.notes || []
);

export const getNotesListIsLoading = createSelector(
  getNotes,
  (notes) => notes.loading
);
export const getNoteById = createSelector(
  [getNotesList, withNoteId],
  (notes, noteId) => notes.find((item) => item.id === noteId)
);

export const getLoadingNoteById = createSelector(
  [getNotes, withNoteId],
  ({ loadingIds }, noteId) => loadingIds.includes(noteId)
);

export const getActivatingNoteById = createSelector(
  [getNotes, withNoteId],
  ({ activatingIds }, noteId) => activatingIds.includes(noteId)
);
