import { connect } from 'react-redux';
import { State } from '../../reducers';
import { DateRangeSelectorPicker, DateRangeSelectorPickerProps } from './date-range-selector-picker';
import { getDashboardStartDate, getDashboardEndDate } from '../../selectors/dashboard';
import { updateDashboardDateRange } from '../../actions/index';

export const DateRangeSelectorContainer = connect<
  Pick<DateRangeSelectorPickerProps, 'startDate' | 'endDate'>,
  Pick<DateRangeSelectorPickerProps, 'onUpdateDateRange'>,
  Pick<DateRangeSelectorPickerProps, 'analyticsEventKey'>
>(
  (state: State) => ({
    startDate: getDashboardStartDate(state),
    endDate: getDashboardEndDate(state),
  }),
  (dispatch) => ({
    onUpdateDateRange: (start, end, name) => {
      dispatch(updateDashboardDateRange(start, end, name));
    },
  })
)(DateRangeSelectorPicker);
