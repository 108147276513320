import React from 'react';
import { Modal, Button, Icon, IconName } from 'src/components/molecules';
import { bemPrefix } from 'src/utils';
import { DownloadFanSignalsInfoListContainer } from './download-fan-signals-info-list';

import './download-fan-signals-info-dialog.scss';

const bem = bemPrefix('download-fan-signals-info-dialog');

export interface DownloadFanSignalsInfoDialogProps {
  showModal: boolean;
  onCloseModal(): void;
}

interface Option {
  name: IconName;
  className: string;
}
const options: Option[] = [
  {
    name: 'note',
    className: 'icon-note',
  },
  {
    name: 'man',
    className: 'icon-man',
  },
  {
    name: 'disk',
    className: 'icon-disk',
  },
  {
    name: 'cross',
    className: 'icon-cross',
  },
  {
    name: 'union',
    className: 'icon-union',
  },
  {
    name: 'mark',
    className: 'icon-mark',
  },
  {
    name: 'mark',
    className: 'icon-big',
  },
  {
    name: 'mark',
    className: 'icon-bottom',
  },
  {
    name: 'mark',
    className: 'icon-left',
  },
];

export const DownloadFanSignalsInfoDialog: React.FC<DownloadFanSignalsInfoDialogProps> = ({
  showModal,
  onCloseModal,
}) => {
  const onCustomerClick = () => {
    if (ApptentiveSDK) {
      ApptentiveSDK.showMessageCenter();
    }
    onCloseModal();
  };

  return (
    <Modal className={bem()} isOpen={showModal} title="Fan Signals Exports" onCancel={onCloseModal} isCloseOnEsc>
      <p className={bem('info-text')}>
        Fan Signals helps you understand customer sentiment over time, from your most loyal customers to those customers
        that are at risk of churning. With Exports, you are able to download this information to deeply analyze your
        data—down to the individual customer. Share it with your teammates and take further action in personalization or
        marketing.
      </p>
      <DownloadFanSignalsInfoListContainer onCloseModal={onCloseModal} onCustomerClick={onCustomerClick} />
      <div className={bem('footer')}>
        <div className={bem('footer-icons')}>
          <div className={bem('icon-container')}>
            {options.map((option) => (
              <Icon key={option.className} name={option.name} className={bem(`${option.className}`)} />
            ))}
          </div>
        </div>
        <div className={bem('footer-content')}>
          <h4 className={bem('footer-title')}>Get More!</h4>
          <p className={bem('footer-descr')}>
            Request access to more customer data and Signals Over Time from your Account Manager.
          </p>
          <Button className={bem('footer-submit')} onClick={onCustomerClick}>
            Contact us
          </Button>
        </div>
      </div>
    </Modal>
  );
};
DownloadFanSignalsInfoDialog.displayName = 'DownloadFanSignalsInfoDialog';
