/* eslint-disable arrow-body-style */
import { isEmpty } from 'ramda';
import { GetState, DispatchFunc } from 'src/reducers';
import { InitiatorsReducerAction, InitiatorItem } from 'src/reducers/initiators';
import { getCurrentAppId } from 'src/selectors/current_app';
import { EventStateService, InitiatorsService } from 'src/services';
import { InitiatorActions } from 'src/actions/initiators/initiators.action-types';
import { getInitiatorsList, getInitiatorsListIsLoading } from 'src/selectors/initiators.selectors';
import { SimpleEventItem } from 'src/reducers/events';
import { daysAgo } from 'src/utils/time';

type InitiatorDispatch = DispatchFunc<InitiatorsReducerAction>;

export const initiators = {
  saveInitiator: (initiator: InitiatorItem) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.UPDATE_INITIATOR_PENDING, payload: { initiator } });

      return InitiatorsService.save(appId, initiator)
        .then((data) =>
          dispatch({
            type: InitiatorActions.UPDATE_INITIATOR_SUCCESS,
            payload: { initiator: data },
          }),
        )
        .catch((error) => {
          const errorMessage = Array.isArray(error.errors) ? error.errors : 'Failed to update a initiator';
          dispatch({ type: InitiatorActions.UPDATE_INITIATOR_ERROR, payload: { initiator, errorMessage }, error });
        });
    };
  },

  activateInitiator: (initiatorId: string, active: boolean) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.ACTIVATE_INITIATOR_PENDING, payload: { initiatorId } });

      return InitiatorsService.activate(appId, initiatorId, active)
        .then((initiator) =>
          dispatch({
            type: InitiatorActions.ACTIVATE_INITIATOR_SUCCESS,
            payload: { initiator },
          }),
        )
        .catch((error) =>
          dispatch({ type: InitiatorActions.ACTIVATE_INITIATOR_ERROR, payload: { initiatorId }, error }),
        );
    };
  },

  bootstrapInitiators: () => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const state = getState();
      const list = getInitiatorsList(state);
      const isLoading = getInitiatorsListIsLoading(state);

      dispatch({ type: InitiatorActions.BOOTSTRAP_INITIATORS, payload: {} });

      if (!isLoading && isEmpty(list)) {
        return dispatch(initiators.fetchInitiators());
      }

      return Promise.resolve();
    };
  },

  fetchInitiators: () => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.GET_INITIATORS_PENDING, payload: {} });

      return InitiatorsService.fetchInitiators(appId)
        .then((data) =>
          dispatch({
            type: InitiatorActions.GET_INITIATORS_SUCCESS,
            payload: {
              initiators: data || [],
            },
          }),
        )
        .catch((error) => dispatch({ type: InitiatorActions.GET_INITIATORS_ERROR, error, payload: {} }));
    };
  },

  fetchInitiatorsByIds: (initiatorIds: string[]) => (dispatch: Function, getState: GetState) => {
    const appId = getCurrentAppId(getState());
    dispatch({ type: InitiatorActions.GET_INITIATORS_BY_IDS_PENDING, payload: { initiatorIds } });

    const promises: Promise<InitiatorItem>[] = initiatorIds.map((id) => InitiatorsService.fetchInitiator(appId, id));

    return Promise.all(promises)
      .then((initiators) =>
        dispatch({
          type: InitiatorActions.GET_INITIATORS_BY_IDS_SUCCESS,
          payload: { initiators },
        }),
      )
      .catch((error) =>
        dispatch({ type: InitiatorActions.GET_INITIATORS_BY_IDS_ERROR, payload: { initiatorIds }, error }),
      );
  },

  fetchInitiator: (initiatorId: string) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.GET_INITIATOR_PENDING, payload: { initiatorId } });

      return InitiatorsService.fetchInitiator(appId, initiatorId)
        .then((initiator) =>
          dispatch({
            type: InitiatorActions.GET_INITIATOR_SUCCESS,
            payload: { initiator },
          }),
        )
        .catch((error) => dispatch({ type: InitiatorActions.GET_INITIATOR_ERROR, error, payload: { initiatorId } }));
    };
  },

  deleteInitiator: (initiatorId: string) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);

      dispatch({ type: InitiatorActions.DELETE_INITIATOR_PENDING, payload: { initiatorId } });

      return InitiatorsService.deleteInitiator(appId, initiatorId)
        .then((data) => {
          dispatch({ type: InitiatorActions.DELETE_INITIATOR_SUCCESS, payload: { initiatorId, initiator: data } });
        })
        .catch((error) => dispatch({ type: InitiatorActions.DELETE_INITIATOR_ERROR, payload: { initiatorId }, error }));
    };
  },

  fetchInitiatorEventsTimeSeries: (
    initiatorId: string,
    events: SimpleEventItem['label'][],
    startDate = daysAgo(30),
    endDate = new Date(),
  ) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.FETCH_INITIATOR_EVENTS_TIME_SERIES_PENDING, payload: { initiatorId } });

      return EventStateService.fetchEventsTimeseries(appId, events, startDate.valueOf(), endDate.valueOf())
        .then((eventsTimeSeries) =>
          dispatch({
            type: InitiatorActions.FETCH_INITIATOR_EVENTS_TIME_SERIES_SUCCESS,
            payload: { initiatorId, eventsTimeSeries },
          }),
        )
        .catch((error) =>
          dispatch({
            type: InitiatorActions.FETCH_INITIATOR_EVENTS_TIME_SERIES_ERROR,
            error,
            payload: { initiatorId },
          }),
        );
    };
  },
};
