import { append, merge, uniq } from 'ramda';
import * as actions from '../actions/group_messages';

const initialState = {
  loading: true,
  fetchError: false,
  groupMessages: [],
  pending: false,
};

const groupMessages = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_GROUP_MESSAGES:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.RECEIVE_GROUP_MESSAGES:
      return merge(state, {
        loading: false,
        fetchError: false,
        groupMessages: action.payload,
      });
    case actions.ERROR_REQUESTING_GROUP_MESSAGES:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    case actions.CREATING_GROUP_MESSAGE:
      return merge(state, {
        pending: true,
        fetchError: false,
      });
    case actions.CREATED_GROUP_MESSAGE:
      return merge(state, {
        pending: false,
        fetchError: false,
        groupMessages: uniq(append(action.payload, state.groupMessages)),
      });
    case actions.ERROR_CREATING_GROUP_MESSAGE:
      return merge(state, {
        pending: false,
        fetchError: true,
      });
    default:
      return state;
  }
};

export default groupMessages;
