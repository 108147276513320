import { isDevMode } from 'src/utils/environment';

const APP_KEY = 'app-local-state';

type AppLocalState = Record<string, any>;

const serialize = (): AppLocalState => {
  const data = localStorage.getItem(APP_KEY);
  try {
    return data ? JSON.parse(data) : {};
  } catch (e: any) {
    //
  }
  return {};
};

export class LocalStorage {
  static serialize = serialize;

  static setValue(key: string, value: any) {
    const data = {
      ...serialize(),
      [key]: value,
    };
    localStorage.setItem(APP_KEY, JSON.stringify(data));
  }

  static getValue<T = any>(key: string): T | undefined {
    return serialize()[key];
  }

  static removeValue(key: string) {
    const data = serialize();
    delete data[key];
    localStorage.setItem(APP_KEY, JSON.stringify(data));
  }

  static clear() {
    localStorage.removeItem(APP_KEY);
  }
}

if (isDevMode()) {
  Object.assign(window, { LocalStorage });
}
