import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Textarea from 'react-textarea-autosize';

const textareaField = ({ input, id, label, className, instructions, meta: { touched, error, warning }, ...rest }) => {
  let message;
  if (touched) {
    /* istanbul ignore else */
    if (error) {
      message = (<label htmlFor={id} className="error">{error}</label>);
    } else if (warning) {
      message = (<label htmlFor={id} className="warning">{warning}</label>);
    }
  }
  const classes = classnames({
    error: touched && error,
    warning: touched && warning,
  });
  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>
      {instructions ? <div className="instructions">{instructions}</div> : null}
      <Textarea {...input} className={classes} {...rest} />
      {message}
    </div>
  );
};

textareaField.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  instructions: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
};

textareaField.defaultProps = {
  label: '',
  className: '',
};

export default textareaField;
