import React, { useState, useCallback } from 'react';
import { Button, Loading, Icon } from 'src/components/molecules';

enum SavingState {
  Default = '',
  Saving = 'saving',
  Success = 'success',
  Fail = 'fail',
}

interface ButtonWithResultProps {
  state: SavingState;
  className: string;
  disabled: boolean;
  onClick(): void;
}

export const ButtonWithResult: React.FC<ButtonWithResultProps> = ({ className, state, disabled, onClick }) => {
  const buttonProps = { className };

  switch (state) {
    case SavingState.Saving:
      return (
        <Button {...buttonProps} color="primary" flat reverted>
          <Loading title="Creating..." isSmall />
        </Button>
      );
    case SavingState.Success:
      return (
        <Button {...buttonProps} color="success" flat reverted>
          <Icon name="success" />
          <span>Success!</span>
        </Button>
      );
    case SavingState.Fail:
      return (
        <Button {...buttonProps} color="danger" flat reverted>
          <Icon name="errorCircle" />
          <span>Error!</span>
        </Button>
      );
    default: {
      return (
        <Button {...buttonProps} disabled={disabled} onClick={onClick}>
          Enable
        </Button>
      );
    }
  }
};

ButtonWithResult.displayName = 'ButtonWithResult';

export function useResultsOnButton() {
  const [savingState, setSavingState] = useState<SavingState>(SavingState.Default);

  const setButtonSaving = useCallback(() => setSavingState(SavingState.Saving), []);
  const setButtonSuccess = useCallback(() => setSavingState(SavingState.Success), []);
  const setButtonFail = useCallback(() => setSavingState(SavingState.Fail), []);
  const setButtonReset = useCallback(() => setSavingState(SavingState.Default), []);

  return {
    savingState,
    isError: savingState === SavingState.Fail,
    setButtonSaving,
    setButtonSuccess,
    setButtonFail,
    setButtonReset,
  };
}
