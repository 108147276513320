import React, { Component } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { differenceWith, eqProps, take, isEmpty } from 'ramda';

import { GtmID } from 'src/types/google-tag-manager';
// actions
import { tags as tagsActions } from 'src/actions/tags';
import { Tag } from 'src/reducers/tags';
import { State } from 'src/reducers';

// selectors
import { getMostUsedTags } from 'src/selectors/tags';

// components
import { Tags } from './tags';
import { TagsSelectContainer, TagsSelectProps } from './tags-select';

interface CreateTagFormProps extends Pick<TagsSelectProps, 'onChange'> {
  mostUsedTags: Tag[];
  tags: Tag[];
  amount: number;
  fetchMostUsedTags(): void;
}

class CreateTagForm extends Component<CreateTagFormProps> {
  static defaultProps = {
    amount: 5,
  };

  componentDidMount() {
    if (isEmpty(this.props.mostUsedTags)) {
      this.props.fetchMostUsedTags();
    }
  }

  render() {
    const { mostUsedTags, tags, amount, onChange } = this.props;

    const unusedSuggestions = differenceWith(eqProps('name'), mostUsedTags, tags);
    const topSuggestions = take(amount, unusedSuggestions);

    const popularTagsSection = (
      <div className="popular-tags">
        <h3>Most Used</h3>
        <Tags tags={topSuggestions} selectable onSelect={onChange} gtmId={GtmID.AddCardTagMostUsed} />
      </div>
    );

    return (
      <div className="new-tag-form create-tag-form">
        <TagsSelectContainer onChange={onChange} skipTags={tags} gtmId={GtmID.AddCardTag} />
        {topSuggestions.length ? popularTagsSection : null}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  mostUsedTags: getMostUsedTags(state),
});

const mapDispatchToProps = (dispatch: ResolveThunks<any>) => ({
  fetchMostUsedTags: () => {
    dispatch(tagsActions.fetchMostUsedTags());
  },
});

export const CreateTagFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTagForm);
