import { fonts, colors as themeColors } from '../../../theme';

/** Apptentive Chart Theme */
const DEFAULT_COLORS = [
  themeColors.pine,
  themeColors.mint,
  themeColors.mango,
  themeColors.coral,
  themeColors.chinook,
];

// See https://formidable.com/open-source/victory/guides/themes/ for reference
export default {
  axis: {
    style: {
      axis: {
        stroke: themeColors.smoke,
        fontFamily: fonts.primary,
      },
      tickLabels: {
        fontSize: '11px',
        stroke: 'transparent',
        fill: themeColors.smoke,
        fontFamily: fonts.primary,
      },
      grid: {
        stroke: 'transparent',
        fill: 'transparent',
      },
    },
  },
  stack: {
    colorScale: DEFAULT_COLORS,
  },
  legend: {
    colorScale: DEFAULT_COLORS,
    fontFamily: fonts.primary,
  },
};
