import { Action } from 'redux';
import { NoteActions } from 'src/actions/notes/notes.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';

import { initialState, NotesState } from './notes.state';
import { NoteItem } from './notes.types';

export type NotesReducerAction = Action<
  NoteActions | typeof UPDATE_CURRENT_APP_PENDING | typeof UPDATE_CURRENT_APP_SUCCESS
> & {
  payload: {
    note?: NoteItem;
    notes?: NoteItem[];
    noteId?: string;
    appId?: string;
    errorMessage?: string;
  };
  error?: Error;
};

export const notesReducer = (
  state: NotesState = initialState,
  action: NotesReducerAction = {} as NotesReducerAction
): NotesState => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_PENDING:
    case UPDATE_CURRENT_APP_SUCCESS: {
      return {
        ...state,
        ...initialState,
      };
    }
    case NoteActions.GET_NOTES_PENDING: {
      return { ...state, loading: true };
    }
    case NoteActions.GET_NOTES_SUCCESS: {
      const { notes = [] } = action.payload;
      return {
        ...state,
        notes,
        loading: false,
      };
    }

    case NoteActions.GET_NOTES_ERROR: {
      return { ...state, loading: false };
    }

    case NoteActions.ACTIVATE_NOTE_PENDING: {
      const { noteId = '' } = action.payload;
      return {
        ...state,
        activatingIds: [...state.activatingIds, noteId],
      };
    }

    case NoteActions.GET_NOTE_PENDING: {
      const { noteId = '' } = action.payload;
      return {
        ...state,
        loadingIds: [...state.loadingIds, noteId],
      };
    }

    case NoteActions.GET_NOTE_SUCCESS:
    case NoteActions.CREATE_NOTE_SUCCESS:
    case NoteActions.UPDATE_NOTE_SUCCESS:
    case NoteActions.ACTIVATE_NOTE_SUCCESS: {
      const { note } = action.payload as { note: NoteItem };
      const data = [...state.notes];
      const idx = data.findIndex((item) => item.id === note.id);
      if (idx >= 0) {
        data.splice(idx, 1, note);
      } else {
        data.unshift(note);
      }
      return {
        ...state,
        notes: data,
        loadingIds: state.loadingIds.filter((id) => id !== note.id),
        activatingIds: state.activatingIds.filter((id) => id !== note.id),
      };
    }

    case NoteActions.GET_NOTE_ERROR: {
      const { noteId = '' } = action.payload;
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => id !== noteId),
        activatingIds: state.activatingIds.filter((id) => id !== noteId),
      };
    }

    case NoteActions.DELETE_NOTE_SUCCESS: {
      const { noteId = '' } = action.payload;
      return {
        ...state,
        notes: state.notes.filter((item) => item.id !== noteId),
      };
    }

    default:
      return state;
  }
};
