import { assoc } from 'ramda';

import { CONTACT_FORM_OPEN, CONTACT_FORM_CLOSE } from '../actions/contact_form';

const initialState = { open: false };
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CONTACT_FORM_OPEN:
      return assoc('open', true, state);
    case CONTACT_FORM_CLOSE:
      return assoc('open', false, state);
    default:
      return state;
  }
};
