/* eslint-disable import/extensions */
import React from 'react';
import classNames from 'classnames';

import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';

import './icons.scss';

// Interaction icons
import squareLove from '../../assets/interactions/icon-square-love-prompt.jsx.svg';
import squareMessages from '../../assets/interactions/icon-square-messages.jsx.svg';
import squareSurvey from '../../assets/interactions/icon-square-survey.jsx.svg';
import squareRating from '../../assets/interactions/icon-square-rating-prompt.jsx.svg';
import squareNote from '../../assets/interactions/icon-square-note.jsx.svg';
import squareInitiator from '../../assets/interactions/icon-square-initiator.jsx.svg';
import squareGroups from '../../assets/interactions/icon-square-groups-s.jsx.svg';
import noReportData from '../../assets/interactions/no-report-data.jsx.svg';
import noteToSurvey from '../../assets/interactions/note-to-survey.jsx.svg';
import ratingPromptSurvey from '../../assets/interactions/ratings-prompt-to-survey.jsx.svg';
import coloredAvatar from '../../assets/interactions/icon-avatar-colored.jsx.svg';
import googlePlay from '../../assets/interactions/icon-google-play.jsx.svg';
import iconWebNoReportData from '../../assets/interactions/icon-web-no-report-data.jsx.svg';
import warningNotFilled from '../../assets/interactions/icon-not-filled-warning.jsx.svg';
import stanchion from '../../assets/interactions/stanchion-icon.jsx.svg';
import lockNotFilled from '../../assets/interactions/icon-lock-not-filled.jsx.svg';

// Love-dialog Icons
import loveDialogPromptMessageCenter from '../../assets/interactions/love-dialog-prompt-message-center.jsx.svg';
import loveDialogPromptSurvey from '../../assets/interactions/love-dialog-prompt-survey.jsx.svg';
import loveDialogThankNote from '../../assets/interactions/love-dialog-thank-note.jsx.svg';
import loveDialogRequestRating from '../../assets/interactions/love-dialog-request-rating.jsx.svg';
import ratingPromptNote from '../../assets/interactions/icon-note-to-ratings-prompt.jsx.svg';
import webLdToMcActive from '../../assets/interactions/icon-web-ld-mc-active.jsx.svg';
import webLdToMcNotActive from '../../assets/interactions/icon-web-ld-mc-not-active.jsx.svg';
import webLdToSurveyActive from '../../assets/interactions/icon-web-ld-survey-active.jsx.svg';
import webLdToSurveyNotActive from '../../assets/interactions/icon-web-ld-survey-not-active.jsx.svg';
import iconWebNoteToSurvey from '../../assets/interactions/icon-web-note-survey.jsx.svg';

// Rating-dialog Icons
import sdk from '../../assets/interactions/icon-sdk.jsx.svg';
import phone from '../../assets/interactions/icon-phone.jsx.svg';

// Message Center Icons
import pushStateSuccess from '../../assets/interactions/push-state-success.jsx.svg';
import pushStateError from '../../assets/interactions/push-state-error.jsx.svg';
import mcFromCode from '../../assets/interactions/mc-from-code.jsx.svg';
import mcFromInteraction from '../../assets/interactions/mc-from-interaction.jsx.svg';
import mciOSAvatar from '../../assets/interactions/ios-profile.jsx.svg';
import mcAndroidHeader from '../../assets/interactions/icon-android-header.jsx.svg';
import simpleAvatar from '../../assets/interactions/icon-simple-avatar.jsx.svg';
import webMessageCenter from '../../assets/interactions/icon-web-message-center.jsx.svg';
import webNoteToMc from '../../assets/interactions/icon-web-note-mc.jsx.svg';

// Common Icons
import edit from '../../assets/icons/icon-edit.jsx.svg';
import editDark from '../../assets/icons/icon-edit-dark.jsx.svg';
import remove from '../../assets/icons/icon-remove.jsx.svg';
import dots from '../../assets/icons/icon-dots.jsx.svg';
import refresh from '../../assets/icons/icon-refresh.jsx.svg';
import chevron from '../../assets/icons/icon-chevron.jsx.svg';
import chevronDouble from '../../assets/icons/icon-chevron-double.jsx.svg';
import conversation from '../../assets/icons/icon-conversation.jsx.svg';
import survey from '../../assets/icons/icon-survey.jsx.svg';
import star from '../../assets/icons/icon-star.jsx.svg';
import close from '../../assets/icons/icon-close.jsx.svg';
import closeBold from '../../assets/icons/icon-close-bold.jsx.svg';
import success from '../../assets/icons/icon-success.jsx.svg';
import error from '../../assets/icons/icon-error.jsx.svg';
import errorCircle from '../../assets/icons/icon-error-circle.jsx.svg';
import info from '../../assets/icons/icon-info.jsx.svg';
import arrowDown from '../../assets/icons/icon-sort-down.jsx.svg';
import plus from '../../assets/icons/icon-plus.jsx.svg';
import newFan from '../../assets/icons/new-fan.jsx.svg';
import newRisk from '../../assets/icons/new-risk.jsx.svg';
import nonRespondent from '../../assets/icons/non-respondent.jsx.svg';
import repeatFan from '../../assets/icons/repeat-fan.jsx.svg';
import repeatRisk from '../../assets/icons/repeat-risk.jsx.svg';
import shiftedToFan from '../../assets/icons/shifted-to-fan.jsx.svg';
import shiftedToRisk from '../../assets/icons/shifted-to-risk.jsx.svg';
import star1 from '../../assets/icons/icon-star-1.jsx.svg';
import star2 from '../../assets/icons/icon-star-2.jsx.svg';
import star3 from '../../assets/icons/icon-star-3.jsx.svg';
import star4 from '../../assets/icons/icon-star-4.jsx.svg';
import star5 from '../../assets/icons/icon-star-5.jsx.svg';
import oldStar1 from '../../assets/icons/old-icon-star-1.jsx.svg';
import oldStar2 from '../../assets/icons/old-icon-star-2.jsx.svg';
import oldStar3 from '../../assets/icons/old-icon-star-3.jsx.svg';
import oldStar4 from '../../assets/icons/old-icon-star-4.jsx.svg';
import oldStar5 from '../../assets/icons/old-icon-star-5.jsx.svg';
import sentimentIcon from '../../assets/icons/sentiment-neutral.jsx.svg';
import negative from '../../assets/icons/sentiment-negative.jsx.svg';
import check from '../../assets/icons/icon-check.jsx.svg';
import warning from '../../assets/icons/icon-warning.jsx.svg';
import warningCircle from '../../assets/icons/icon-warning-circle.jsx.svg';
import magnifying from '../../assets/icons/icon-magnifying.jsx.svg';
import filter from '../../assets/icons/icon-filter.jsx.svg';
import pin from '../../assets/icons/icon-pin.jsx.svg';
import hide from '../../assets/icons/icon-hide.jsx.svg';
import show from '../../assets/icons/icon-show.jsx.svg';
import lock from '../../assets/icons/icon-lock.jsx.svg';
import animatedSpinner from '../../assets/icons/icon-spinner.jsx.svg';
import successGreen from '../../assets/icons/icon-success-green.jsx.svg';
import deletedImage from '../../assets/icons/icon-deleted-image.jsx.svg';
import warningRed from '../../assets/icons/icon-warning-red.jsx.svg';
import defaultAvatar from '../../assets/icons/icon-default-avatar.jsx.svg';
import avatarWithMicro from '../../assets/icons/icon-avatar-micro.jsx.svg';
import iphone11 from '../../assets/icons/icon-iphone-11.jsx.svg';
import user from '../../assets/icons/icon-user.jsx.svg';
import target from '../../assets/icons/icon-target.jsx.svg';
import gear from '../../assets/icons/icon-gear.jsx.svg';
import flag from '../../assets/icons/icon-flag.jsx.svg';
import globe from '../../assets/icons/icon-globe.jsx.svg';
import device from '../../assets/icons/icon-device.jsx.svg';
import appGear from '../../assets/icons/icon-appGear.jsx.svg';
import infoIcon from '../../assets/icons/icon-info-grey.jsx.svg';
import questionBlue from '../../assets/icons/question-blue.jsx.svg';
import questionCentered from '../../assets/icons/question-centered.jsx.svg';
import exportInfo from '../../assets/icons/icon-export.jsx.svg';
import externalLink from '../../assets/icons/icon-externalLink.jsx.svg';
import android12 from '../../assets/icons/icon-android12.jsx.svg';
import goBackArrow from '../../assets/icons/icon-go-back-arrow.jsx.svg';
import bold from '../../assets/icons/icon-bold.jsx.svg';
import italic from '../../assets/icons/icon-italic.jsx.svg';
import underline from '../../assets/icons/icon-underline.jsx.svg';
import strikeThrough from '../../assets/icons/icon-strike-through.jsx.svg';
import orderedList from '../../assets/icons/icon-ordered-list.jsx.svg';
import unorderedList from '../../assets/icons/icon-unordered-list.jsx.svg';
import insertLink from '../../assets/icons/icon-insert-link.jsx.svg';

// Alchemer icons
import alchemerLogo from '../../assets/alchemer-logo.jsx.svg';
import alchemerContactUs from '../../assets/alchemer-contact-us.jsx.svg';
import alchemerLoadingLogo from '../../assets/loading/alchemer-logo-loading.jsx.svg';
import alchemerLoadFactLogo from '../../assets/loading/alchemer-logo.jsx.svg';

// Grid navigation icons
import gridNav from '../../assets/burger-menu/grid-nav.jsx.svg';
import alchemerSurvey from '../../assets/burger-menu/alchemer-survey.jsx.svg';
import alchemerWorkflow from '../../assets/burger-menu/alchemer-workflow.jsx.svg';
import alchemerDigital from '../../assets/burger-menu/alchemer-digital.jsx.svg';
import alchemerPulse from '../../assets/burger-menu/alchemer-pulse.jsx.svg';

// Integration icons
import fsWebhook from '../../assets/icons/icon-webhook.jsx.svg';
import aws from '../../assets/icons/icon-aws.jsx.svg';
import apptentive from '../../assets/icons/icon-apptentive.jsx.svg';
import urban from '../../assets/icons/icon-urban.jsx.svg';
import slack from '../../assets/icons/icon-slack.jsx.svg';
import zendesk from '../../assets/icons/icon-zendesk.jsx.svg';
import userVoice from '../../assets/icons/icon-user-voice.jsx.svg';
import salesforce from '../../assets/icons/icon-salesforce.jsx.svg';
import apptimize from '../../assets/icons/icon-apptimize.jsx.svg';
import rightNow from '../../assets/icons/icon-rightnow.jsx.svg';
import NA from '../../assets/icons/icon-na.jsx.svg';
import addCase from '../../assets/integrations/add.jsx.svg';

// customerId page icons
import badge from '../../assets/icons/icon-badge.jsx.svg';
import disk from '../../assets/icons/icon-disk.jsx.svg';
import position from '../../assets/icons/icon-position.jsx.svg';
// multiple love dialogs icons
import calculator from '../../assets/interactions/icon-calculator.jsx.svg';
import lockIcon from '../../assets/interactions/icon-lock.jsx.svg';

// info modal about unlimited export
import shadow from '../../assets/icons/icon-shadow.jsx.svg';
import spot from '../../assets/icons/icon-spot.jsx.svg';
import top from '../../assets/icons/icon-top.jsx.svg';
import circle from '../../assets/icons/icon-circle.jsx.svg';
import bottom from '../../assets/icons/icon-bottom.jsx.svg';
import pulse from '../../assets/icons/icon-pulse.jsx.svg';
import unlocked from '../../assets/icons/icon-unlocked.jsx.svg';
import notebook from '../../assets/icons/icon-notebook.jsx.svg';
// fan signals info export modal
import graphs from '../../assets/icons/icon-graphs.jsx.svg';
import people from '../../assets/icons/icon-people.jsx.svg';
import arrows from '../../assets/icons/icon-arrows.jsx.svg';
import notebooks from '../../assets/icons/icon-notebooks.jsx.svg';
import man from '../../assets/icons/icon-man.jsx.svg';
import note from '../../assets/icons/icon-note.jsx.svg';
import cross from '../../assets/icons/icon-cross.jsx.svg';
import union from '../../assets/icons/icon-union.jsx.svg';
import mark from '../../assets/icons/icon-mark.jsx.svg';
// love score icons
import lovescore from '../../assets/icons/icon-lovescore.jsx.svg';
import scoreInfo from '../../assets/icons/icon-info-2.jsx.svg';
import arrowLeft from '../../assets/icons/icon-left-arrow.jsx.svg';
import arrowRight from '../../assets/icons/icon-right-arrow.jsx.svg';
// sentiment dropdown
import pen from '../../assets/icons/icon-pen.jsx.svg';
import penOutline from '../../assets/icons/icon-pen-outline.jsx.svg';
// api key icons
import cancel from '../../assets/icons/icon-cancel.jsx.svg';
import circlecheck from '../../assets/icons/icon-circlecheck.jsx.svg';
import copy from '../../assets/icons/icon-copy.jsx.svg';
import deleteicon from '../../assets/icons/icon-delete.jsx.svg';
import editoutline from '../../assets/icons/icon-editoutline.jsx.svg';
import key from '../../assets/icons/icon-key.jsx.svg';
import keyoff from '../../assets/icons/icon-key_off.jsx.svg';

import faCircleExclamation from '../../assets/fa-icons/circle-exclamation.jsx.svg';
import faCircleQuestion from '../../assets/fa-icons/circle-question.jsx.svg';
import faMessageQuestion from '../../assets/fa-icons/message-question.jsx.svg';
import trash from '../../assets/icons/trash.jsx.svg';
import image from '../../assets/icons/image.jsx.svg';

// Interactions with Multiple Targets
import leftArrow from '../../assets/interactions/icon-left-arrow.jsx.svg';
import rightArrow from '../../assets/interactions/icon-right-arrow.jsx.svg';
import checkMark from '../../assets/interactions/icon-check-mark.jsx.svg';
import crossMark from '../../assets/interactions/icon-cross-mark.jsx.svg';

const bem = bemPrefix('app-icon');

const iconsList = {
  // Interaction icons
  squareLove,
  squareMessages,
  squareSurvey,
  squareRating,
  squareNote,
  squareGroups,
  squareInitiator,
  noReportData,
  noteToSurvey,
  ratingPromptSurvey,
  coloredAvatar,
  googlePlay,
  iconWebNoReportData,
  warningNotFilled,
  iconWebNoteToSurvey,
  stanchion,
  lockNotFilled,

  // Love-dialog Icons
  loveDialogPromptMessageCenter,
  loveDialogPromptSurvey,
  loveDialogThankNote,
  loveDialogRequestRating,
  ratingPromptNote,
  webLdToMcActive,
  webLdToMcNotActive,
  webLdToSurveyActive,
  webLdToSurveyNotActive,

  // Message Center Icons
  pushStateSuccess,
  pushStateError,
  mcFromCode,
  mcFromInteraction,
  mciOSAvatar,
  mcAndroidHeader,
  simpleAvatar,
  webMessageCenter,
  webNoteToMc,

  // Rating-dialog Icons
  sdk,
  phone,

  // Common icons
  edit,
  editDark,
  remove,
  dots,
  refresh,
  chevron,
  chevronUp: chevron,
  chevronDouble,
  conversation,
  survey,
  star,
  close,
  closeBold,
  success,
  error,
  errorCircle,
  info,
  arrowDown,
  plus,
  newFan,
  newRisk,
  nonRespondent,
  repeatFan,
  repeatRisk,
  shiftedToFan,
  shiftedToRisk,
  star1,
  star2,
  star3,
  star4,
  star5,
  oldStar1,
  oldStar2,
  oldStar3,
  oldStar4,
  oldStar5,
  sentimentIcon,
  negative,
  check,
  warning,
  warningCircle,
  magnifying,
  filter,
  pin,
  hide,
  show,
  lock,
  animatedSpinner,
  successGreen,
  deletedImage,
  warningRed,
  defaultAvatar,
  avatarWithMicro,
  iphone11,
  user,
  target,
  gear,
  flag,
  globe,
  device,
  appGear,
  infoIcon,
  questionBlue,
  questionCentered,
  android12,
  goBackArrow,
  bold,
  italic,
  underline,
  strikeThrough,
  orderedList,
  unorderedList,
  insertLink,

  // Alchemer icons
  alchemerLogo,
  alchemerContactUs,
  alchemerLoadingLogo,
  alchemerLoadFactLogo,

  // Grid navigations icons
  gridNav,
  alchemerSurvey,
  alchemerWorkflow,
  alchemerDigital,
  alchemerPulse,

  // Integration icons
  fsWebhook,
  aws,
  apptentive,
  urban,
  slack,
  zendesk,
  userVoice,
  salesforce,
  apptimize,
  rightNow,
  NA,
  addCase,

  // customerId page icons
  badge,
  disk,
  position,
  // multiple love dialogs icons
  calculator,
  lockIcon,
  // export menus
  exportInfo,
  // info modal about unlimited export
  shadow,
  spot,
  top,
  circle,
  bottom,
  pulse,
  unlocked,
  notebook,
  // fan signals info export modal
  graphs,
  people,
  arrows,
  notebooks,
  man,
  note,
  cross,
  union,
  mark,
  // love score icons
  lovescore,
  scoreInfo,
  // journey survey
  arrowLeft,
  arrowRight,
  // sentiment dropdown
  pen,
  penOutline,
  // api keys
  cancel,
  circlecheck,
  copy,
  deleteicon,
  editoutline,
  key,
  keyoff,
  // link for Custom Data Managment
  externalLink,
  faCircleExclamation,
  faCircleQuestion,
  faMessageQuestion,
  trash,
  image,
  // Interactions with Multiple Targets
  leftArrow,
  rightArrow,
  checkMark,
  crossMark,
};

export type IconName = keyof typeof iconsList;

export interface IconProps {
  name: IconName;
  title?: string;
  className?: string;
  gtmId?: GtmID;
  onClick?(arg?: any): void;
  forwardRef?: React.RefObject<any>;
  customAttribute?: string;
  isInline?: boolean;
}

export const Icon: React.FC<IconProps> = function Icon({
  name,
  className = '',
  title,
  forwardRef,
  customAttribute = '',
  gtmId,
  isInline,
  ...props
}) {
  const SVG = iconsList[name] || iconsList.NA;
  return (
    <i
      className={classNames(bem('', { inline: isInline }), bem(name), className)}
      title={title}
      gtm-id={gtmId}
      data-custom={customAttribute}
      {...props}
      ref={forwardRef}
    >
      <SVG />
    </i>
  );
};

Icon.displayName = 'Icon';
