import { merge, update, findIndex, propEq, assocPath } from 'ramda';
import * as actions from '../actions/downloads';

const initialState = {
  downloads: [],
  fetchError: false,
  loading: true,
  retryError: false,
};

const downloads = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.FETCH_DOWNLOADS_PENDING:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.FETCH_DOWNLOADS_SUCCESS:
      return merge(state, {
        loading: false,
        fetchError: false,
        downloads: action.payload,
      });
    case actions.FETCH_DOWNLOADS_FAILURE:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    case actions.UPDATE_DOWNLOAD_PENDING:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.UPDATE_DOWNLOAD_SUCCESS:
      return merge(state, {
        loading: false,
        fetchError: false,
        downloads: action.payload,
      });
    case actions.UPDATE_DOWNLOAD_FAILURE:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    case actions.RETRY_DOWNLOAD_PENDING: {
      const index = findIndex(propEq('id', action.meta.data.id), state.downloads);
      if (index >= 0) {
        const download = state.downloads[index];
        const newDownload = assocPath(['status'], 'idle', download);
        const newDownloads = update(index, newDownload, state.downloads);
        const newState = assocPath(['downloads'], newDownloads, state);
        return assocPath(['retryError'], false, newState);
      }
      return state;
    }
    case actions.RETRY_DOWNLOAD_SUCCESS: {
      const index = findIndex(propEq('id', action.meta.data.id), state.downloads);
      if (index >= 0) {
        const newDownloads = update(index, action.payload, state.downloads);
        const newState = assocPath(['downloads'], newDownloads, state);
        return assocPath(['retryError'], false, newState);
      }
      return state;
    }
    case actions.RETRY_DOWNLOAD_FAILURE: {
      const index = findIndex(propEq('id', action.meta.data.id), state.downloads);
      if (index >= 0) {
        const download = state.downloads[index];
        const newDownload = assocPath(['status'], 'failed', download);
        const newDownloads = update(index, newDownload, state.downloads);
        const newState = assocPath(['downloads'], newDownloads, state);
        return assocPath(['retryError'], true, newState);
      }
      return state;
    }
    case actions.DELETE_DOWNLOAD_PENDING:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.DELETE_DOWNLOAD_SUCCESS: {
      return merge(state, {
        loading: false,
        fetchError: false,
        downloads: state.downloads.filter(download => download.id !== action.meta.data),
      });
    }
    case actions.DELETE_DOWNLOAD_FAILURE:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    default:
      return state;
  }
};

export default downloads;
