export enum TagsActions {
  FETCH_ALL_TAGS_PENDING = 'TAGS_V2_FETCH_ALL_TAGS_PENDING',
  FETCH_ALL_TAGS_SUCCESS = 'TAGS_V2_FETCH_ALL_TAGS_SUCCESS',
  FETCH_ALL_TAGS_ERROR = 'TAGS_V2_FETCH_ALL_TAGS_ERROR',

  FETCH_MOST_USER_TAGS_PENDING = 'TAGS_V2_FETCH_MOST_USER_TAGS_PENDING',
  FETCH_MOST_USER_TAGS_SUCCESS = 'TAGS_V2_FETCH_MOST_USER_TAGS_SUCCESS',
  FETCH_MOST_USER_TAGS_ERROR = 'TAGS_V2_FETCH_MOST_USER_TAGS_ERROR',

  FETCH_APP_TAGS_PENDING = 'TAGS_V2_FETCH_APP_TAGS_PENDING',
  FETCH_APP_TAGS_SUCCESS = 'TAGS_V2_FETCH_APP_TAGS_SUCCESS',
  FETCH_APP_TAGS_ERROR = 'TAGS_V2_FETCH_APP_TAGS_ERROR',

  FETCH_AGGREG_TAGS_PENDING = 'TAGS_V2_FETCH_AGGREG_TAGS_PENDING',
  FETCH_AGGREG_TAGS_SUCCESS = 'TAGS_V2_FETCH_AGGREG_TAGS_SUCCESS',
  FETCH_AGGREG_TAGS_ERROR = 'TAGS_V2_FETCH_AGGREG_TAGS_ERROR',

  RENAME_TAG_PENDING = 'TAGS_V2_RENAME_TAG_PENDING',
  RENAME_TAG_SUCCESS = 'TAGS_V2_RENAME_TAG_SUCCESS',
  RENAME_TAG_ERROR = 'TAGS_V2_RENAME_TAG_ERROR',

  REMOVE_TAG_PENDING = 'TAGS_V2_REMOVE_TAG_PENDING',
  REMOVE_TAG_SUCCESS = 'TAGS_V2_REMOVE_TAG_SUCCESS',
  REMOVE_TAG_ERROR = 'TAGS_V2_REMOVE_TAG_ERROR',
}
