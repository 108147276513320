import React, { useState, useCallback, useMemo } from 'react';
import { bemPrefix } from 'src/utils';
import { InputText, Button } from 'src/components/molecules';

interface ContactFormProps {
  className: string;
  onSubmit(): void;
}

export const ContactForm: React.FC<ContactFormProps> = ({ className, onSubmit }) => {
  const [feedbackText, setText] = useState('');
  const bem = useMemo(() => bemPrefix(className), [className]);

  const onClickSubmit = useCallback(() => {
    if (ApptentiveSDK) {
      ApptentiveSDK.createMessage({
        body: `Set Customer ID: ${feedbackText}`,
        custom_data: {},
      });
    }
    onSubmit();
  }, [feedbackText, onSubmit]);

  return (
    <>
      <div className={bem('text-desc')}>
        If your customer ID is not in the list, feel free to reach out to us. Or to get a better understanding of person
        custom data, visit{' '}
        <a
          className="link"
          href="https://help.alchemer.com/help/product-guides"
          target="_blank"
          rel="noreferrer"
        >
          Learn Alchemer Mobile
        </a>{' '}
        site.
      </div>
      <InputText className={bem('contact-input')} type="textarea" rows={3} onChange={setText} />
      <Button
        className={bem('contact-submit')}
        color="primary"
        disabled={!feedbackText}
        onClick={onClickSubmit}
        reverted
      >
        Submit
      </Button>
    </>
  );
};
ContactForm.displayName = 'ContactForm';
