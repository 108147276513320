import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import '../styles/molecules/alert_box.scss';

const AlertBox = ({ message, type = 'error' }) => (
  <div className="content">
    <div
      className={className({
        error: (type === 'error'),
        success: (type !== 'error' && type !== 'warning'),
        warning: (type === 'warning'),
        'alert-box': true,
        radius: true,
      })}
    >
      {message}
    </div>
  </div>
);

AlertBox.propTypes = {
  message: PropTypes.node,
  type: PropTypes.string,
};

export default AlertBox;
