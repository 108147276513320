export default {
  data: [
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.615,
        2: 0.797,
        3: 2.426,
        4: 12.758,
        5: 81.404,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {
        1: 5749,
        2: 1753,
        3: 5333,
        4: 28049,
        5: 178965,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.263,
        3: 15.79,
        4: 26.316,
        5: 52.632,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1722431944005,
      store_observed_time: 1722297600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219854,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1722345518485,
      store_observed_time: 1722211200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.593,
        2: 0.783,
        3: 2.441,
        4: 12.713,
        5: 81.471,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219647,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1722345518485,
      store_observed_time: 1722211200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 219647,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1722345518485,
      store_observed_time: 1722211200000,
      all_ratings: {
        1: 5695,
        2: 1719,
        3: 5361,
        4: 27923,
        5: 178944,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 219647,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1722345518485,
      store_observed_time: 1722211200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.263,
        3: 15.79,
        4: 26.316,
        5: 52.632,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219647,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1722345518485,
      store_observed_time: 1722211200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219647,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1722345518485,
      store_observed_time: 1722211200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219647,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1722259114500,
      store_observed_time: 1722124800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.576,
        2: 0.765,
        3: 2.439,
        4: 12.725,
        5: 81.495,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219494,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1722259114500,
      store_observed_time: 1722124800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 219494,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1722259114500,
      store_observed_time: 1722124800000,
      all_ratings: {
        1: 5655,
        2: 1680,
        3: 5353,
        4: 27929,
        5: 178871,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 219493,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1722259114500,
      store_observed_time: 1722124800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.263,
        3: 15.79,
        4: 26.316,
        5: 52.632,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219494,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1722259114500,
      store_observed_time: 1722124800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219494,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1722259114500,
      store_observed_time: 1722124800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219494,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1722172711121,
      store_observed_time: 1722038400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.576,
        2: 0.772,
        3: 2.442,
        4: 12.733,
        5: 81.477,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219301,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1722172711121,
      store_observed_time: 1722038400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 219301,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1722172711121,
      store_observed_time: 1722038400000,
      all_ratings: {
        1: 5643,
        2: 1691,
        3: 5360,
        4: 27908,
        5: 178722,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 219330,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1722172711121,
      store_observed_time: 1722038400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.263,
        3: 15.789,
        4: 26.316,
        5: 52.632,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219301,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1722172711121,
      store_observed_time: 1722038400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219301,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1722172711121,
      store_observed_time: 1722038400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219301,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1722086312737,
      store_observed_time: 1721952000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.557,
        2: 0.763,
        3: 2.405,
        4: 12.727,
        5: 81.548,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219158,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1722086312737,
      store_observed_time: 1721952000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 219158,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1722086312737,
      store_observed_time: 1721952000000,
      all_ratings: {
        1: 5604,
        2: 1672,
        3: 5271,
        4: 27892,
        5: 178719,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 219164,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1722086312737,
      store_observed_time: 1721952000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.263,
        3: 15.789,
        4: 26.316,
        5: 52.632,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219158,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1722086312737,
      store_observed_time: 1721952000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219158,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1722086312737,
      store_observed_time: 1721952000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219158,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721999912214,
      store_observed_time: 1721865600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.56,
        2: 0.768,
        3: 2.403,
        4: 12.716,
        5: 81.553,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219018,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721999912214,
      store_observed_time: 1721865600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 219018,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721999912214,
      store_observed_time: 1721865600000,
      all_ratings: {
        1: 5607,
        2: 1682,
        3: 5263,
        4: 27850,
        5: 178611,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 219018,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721999912214,
      store_observed_time: 1721865600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.263,
        3: 15.789,
        4: 26.316,
        5: 52.632,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219018,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721999912214,
      store_observed_time: 1721865600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 219018,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721999912214,
      store_observed_time: 1721865600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 219018,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721913511835,
      store_observed_time: 1721779200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.55,
        2: 0.768,
        3: 2.389,
        4: 12.723,
        5: 81.57,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218893,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721913511835,
      store_observed_time: 1721779200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 218893,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721913511835,
      store_observed_time: 1721779200000,
      all_ratings: {
        1: 5580,
        2: 1681,
        3: 5228,
        4: 27846,
        5: 178555,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 218896,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721913511835,
      store_observed_time: 1721779200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 218893,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721913511835,
      store_observed_time: 1721779200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218893,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721913511835,
      store_observed_time: 1721779200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 218893,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721827111588,
      store_observed_time: 1721692800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.553,
        2: 0.769,
        3: 2.391,
        4: 12.712,
        5: 81.575,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218746,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721827111588,
      store_observed_time: 1721692800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 218746,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721827111588,
      store_observed_time: 1721692800000,
      all_ratings: {
        1: 5584,
        2: 1682,
        3: 5230,
        4: 27806,
        5: 178438,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 218746,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721827111588,
      store_observed_time: 1721692800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 218746,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721827111588,
      store_observed_time: 1721692800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218746,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721827111588,
      store_observed_time: 1721692800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.882,
        2: 5.882,
        3: 0,
        4: 23.529,
        5: 64.706,
      },
      all_ratings_average: 4.2,
      all_ratings_count: 218746,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721740714244,
      store_observed_time: 1721606400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.528,
        2: 0.776,
        3: 2.38,
        4: 12.706,
        5: 81.61,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218473,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721740714244,
      store_observed_time: 1721606400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 218473,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721740714244,
      store_observed_time: 1721606400000,
      all_ratings: {
        1: 5516,
        2: 1693,
        3: 5204,
        4: 27767,
        5: 178345,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 218530,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721740714244,
      store_observed_time: 1721606400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 218472,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721740714244,
      store_observed_time: 1721606400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218473,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721740714244,
      store_observed_time: 1721606400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 218473,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721654310669,
      store_observed_time: 1721520000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.517,
        2: 0.774,
        3: 2.361,
        4: 12.665,
        5: 81.682,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218248,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721654310669,
      store_observed_time: 1721520000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 218248,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721654310669,
      store_observed_time: 1721520000000,
      all_ratings: {
        1: 5493,
        2: 1690,
        3: 5153,
        4: 27642,
        5: 178265,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 218248,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721654310669,
      store_observed_time: 1721520000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 218248,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721654310669,
      store_observed_time: 1721520000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218248,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721654310669,
      store_observed_time: 1721520000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 218248,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721567909873,
      store_observed_time: 1721433600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.517,
        2: 0.778,
        3: 2.374,
        4: 12.637,
        5: 81.694,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218008,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721567909873,
      store_observed_time: 1721433600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 218008,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721567909873,
      store_observed_time: 1721433600000,
      all_ratings: {
        1: 5488,
        2: 1697,
        3: 5175,
        4: 27548,
        5: 178094,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 218008,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721567909873,
      store_observed_time: 1721433600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 218008,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721567909873,
      store_observed_time: 1721433600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 218008,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721567909873,
      store_observed_time: 1721433600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 218008,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721481509004,
      store_observed_time: 1721347200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.524,
        2: 0.779,
        3: 2.384,
        4: 12.625,
        5: 81.688,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217874,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721481509004,
      store_observed_time: 1721347200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 217874,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721481509004,
      store_observed_time: 1721347200000,
      all_ratings: {
        1: 5500,
        2: 1697,
        3: 5195,
        4: 27505,
        5: 177971,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 217873,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721481509004,
      store_observed_time: 1721347200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50.001,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 217874,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721481509004,
      store_observed_time: 1721347200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217874,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721481509004,
      store_observed_time: 1721347200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 217874,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721395110015,
      store_observed_time: 1721260800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.488,
        2: 0.781,
        3: 2.395,
        4: 12.607,
        5: 81.729,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217705,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721395110015,
      store_observed_time: 1721260800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 217705,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721395110015,
      store_observed_time: 1721260800000,
      all_ratings: {
        1: 5420,
        2: 1698,
        3: 5209,
        4: 27424,
        5: 177965,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 217720,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721395110015,
      store_observed_time: 1721260800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 217705,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721395110015,
      store_observed_time: 1721260800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217705,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721395110015,
      store_observed_time: 1721260800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 217705,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721308709082,
      store_observed_time: 1721174400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.49,
        2: 0.786,
        3: 2.374,
        4: 12.594,
        5: 81.755,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217562,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721308709082,
      store_observed_time: 1721174400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 217562,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721308709082,
      store_observed_time: 1721174400000,
      all_ratings: {
        1: 5418,
        2: 1711,
        3: 5165,
        4: 27399,
        5: 177863,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 217562,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721308709082,
      store_observed_time: 1721174400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.556,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 217562,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721308709082,
      store_observed_time: 1721174400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217562,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721308709082,
      store_observed_time: 1721174400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 217562,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721222321667,
      store_observed_time: 1721088000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.468,
        2: 0.767,
        3: 2.353,
        4: 12.562,
        5: 81.85,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217418,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721222321667,
      store_observed_time: 1721088000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 217418,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721222321667,
      store_observed_time: 1721088000000,
      all_ratings: {
        1: 5365,
        2: 1668,
        3: 5116,
        4: 27311,
        5: 177953,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 217418,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721222321667,
      store_observed_time: 1721088000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50.001,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 217418,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721222321667,
      store_observed_time: 1721088000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217418,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721222321667,
      store_observed_time: 1721088000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 217418,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721135921333,
      store_observed_time: 1721001600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.484,
        2: 0.775,
        3: 2.359,
        4: 12.612,
        5: 81.77,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217198,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721135921333,
      store_observed_time: 1721001600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 217198,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721135921333,
      store_observed_time: 1721001600000,
      all_ratings: {
        1: 5388,
        2: 1681,
        3: 5116,
        4: 27397,
        5: 177643,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 217230,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721135921333,
      store_observed_time: 1721001600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50.001,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 217198,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721135921333,
      store_observed_time: 1721001600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217198,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721135921333,
      store_observed_time: 1721001600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 217198,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1721049509148,
      store_observed_time: 1720915200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.489,
        2: 0.776,
        3: 2.358,
        4: 12.637,
        5: 81.741,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217040,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1721049509148,
      store_observed_time: 1720915200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 217040,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1721049509148,
      store_observed_time: 1720915200000,
      all_ratings: {
        1: 5399,
        2: 1684,
        3: 5125,
        4: 27414,
        5: 177413,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 217040,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1721049509148,
      store_observed_time: 1720915200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.556,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 217040,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1721049509148,
      store_observed_time: 1720915200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 217040,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1721049509148,
      store_observed_time: 1720915200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 217040,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720963108007,
      store_observed_time: 1720828800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.467,
        2: 0.771,
        3: 2.359,
        4: 12.613,
        5: 81.79,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216789,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720963108007,
      store_observed_time: 1720828800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 216789,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720963108007,
      store_observed_time: 1720828800000,
      all_ratings: {
        1: 5347,
        2: 1671,
        3: 5115,
        4: 27343,
        5: 177308,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 216789,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720963108007,
      store_observed_time: 1720828800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50.001,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 216789,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720963108007,
      store_observed_time: 1720828800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216789,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720963108007,
      store_observed_time: 1720828800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 216789,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720876709396,
      store_observed_time: 1720742400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.417,
        2: 0.767,
        3: 2.357,
        4: 12.544,
        5: 81.916,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216509,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720876709396,
      store_observed_time: 1720742400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 216509,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720876709396,
      store_observed_time: 1720742400000,
      all_ratings: {
        1: 5232,
        2: 1660,
        3: 5103,
        4: 27158,
        5: 177350,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 216509,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720876709396,
      store_observed_time: 1720742400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50.001,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 216509,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720876709396,
      store_observed_time: 1720742400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216509,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720876709396,
      store_observed_time: 1720742400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 216509,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720790576704,
      store_observed_time: 1720656000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.408,
        2: 0.767,
        3: 2.362,
        4: 12.545,
        5: 81.918,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216252,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720790576704,
      store_observed_time: 1720656000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 216254,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720790576704,
      store_observed_time: 1720656000000,
      all_ratings: {
        1: 5208,
        2: 1658,
        3: 5107,
        4: 27128,
        5: 177145,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 216252,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720790576704,
      store_observed_time: 1720656000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 50,
      },
      all_ratings_average: 4.1,
      all_ratings_count: 216252,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720790576704,
      store_observed_time: 1720656000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216252,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720790576704,
      store_observed_time: 1720656000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 216254,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.383,
        2: 0.766,
        3: 2.35,
        4: 12.555,
        5: 81.946,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216020,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 216020,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {
        1: 5035,
        2: 1611,
        3: 5055,
        4: 27057,
        5: 177148,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 215911,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.383,
        2: 0.766,
        3: 2.35,
        4: 12.555,
        5: 81.946,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216020,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 216020,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {
        1: 5035,
        2: 1611,
        3: 5055,
        4: 27057,
        5: 177148,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 215911,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.556,
        2: 5.556,
        3: 22.222,
        4: 22.222,
        5: 44.444,
      },
      all_ratings_average: 3.9,
      all_ratings_count: 216020,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 216020,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720703909378,
      store_observed_time: 1720569600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 215981,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720617515966,
      store_observed_time: 1720483200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.328,
        2: 0.746,
        3: 2.351,
        4: 12.524,
        5: 82.051,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215774,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720617515966,
      store_observed_time: 1720483200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.571,
        2: 5.714,
        3: 0,
        4: 5.714,
        5: 80.001,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 215774,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720617515966,
      store_observed_time: 1720483200000,
      all_ratings: {
        1: 5023,
        2: 1610,
        3: 5073,
        4: 27023,
        5: 177039,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 215774,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720617515966,
      store_observed_time: 1720483200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.555,
        2: 5.555,
        3: 22.222,
        4: 22.222,
        5: 44.445,
      },
      all_ratings_average: 3.9,
      all_ratings_count: 215774,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720617515966,
      store_observed_time: 1720483200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215774,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720617515966,
      store_observed_time: 1720483200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 215774,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720531114726,
      store_observed_time: 1720396800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.341,
        2: 0.742,
        3: 2.322,
        4: 12.516,
        5: 82.079,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215489,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720531114726,
      store_observed_time: 1720396800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 215489,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720531114726,
      store_observed_time: 1720396800000,
      all_ratings: {
        1: 5035,
        2: 1606,
        3: 4994,
        4: 26996,
        5: 176884,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 215519,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720531114726,
      store_observed_time: 1720396800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.053,
        5: 42.105,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 215489,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720531114726,
      store_observed_time: 1720396800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215489,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720531114726,
      store_observed_time: 1720396800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 215489,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720444714254,
      store_observed_time: 1720310400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.294,
        2: 0.748,
        3: 2.308,
        4: 12.523,
        5: 82.126,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215310,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720444714254,
      store_observed_time: 1720310400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 215310,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720444714254,
      store_observed_time: 1720310400000,
      all_ratings: {
        1: 4940,
        2: 1610,
        3: 4970,
        4: 26963,
        5: 176824,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 215310,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720444714254,
      store_observed_time: 1720310400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.053,
        5: 42.106,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 215310,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720444714254,
      store_observed_time: 1720310400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215310,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720444714254,
      store_observed_time: 1720310400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 215310,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720358314173,
      store_observed_time: 1720224000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.301,
        2: 0.756,
        3: 2.296,
        4: 12.56,
        5: 82.087,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215073,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720358314173,
      store_observed_time: 1720224000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.824,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 215073,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720358314173,
      store_observed_time: 1720224000000,
      all_ratings: {
        1: 4943,
        2: 1623,
        3: 4933,
        4: 26989,
        5: 176580,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 215073,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720358314173,
      store_observed_time: 1720224000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.053,
        5: 42.106,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 215073,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720358314173,
      store_observed_time: 1720224000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 215073,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720358314173,
      store_observed_time: 1720224000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 215073,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720271913611,
      store_observed_time: 1720137600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.259,
        2: 0.756,
        3: 2.309,
        4: 12.533,
        5: 82.143,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214884,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720271913611,
      store_observed_time: 1720137600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 214884,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720271913611,
      store_observed_time: 1720137600000,
      all_ratings: {
        1: 4851,
        2: 1623,
        3: 4958,
        4: 26919,
        5: 176527,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 214884,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720271913611,
      store_observed_time: 1720137600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.052,
        5: 42.105,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 214884,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720271913611,
      store_observed_time: 1720137600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214884,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720271913611,
      store_observed_time: 1720137600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 214884,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720185514728,
      store_observed_time: 1720051200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.259,
        2: 0.759,
        3: 2.305,
        4: 12.477,
        5: 82.2,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214667,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720185514728,
      store_observed_time: 1720051200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.824,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 214667,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720185514728,
      store_observed_time: 1720051200000,
      all_ratings: {
        1: 4849,
        2: 1629,
        3: 4948,
        4: 26784,
        5: 176454,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 214667,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720185514728,
      store_observed_time: 1720051200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.053,
        5: 42.105,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 214667,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720185514728,
      store_observed_time: 1720051200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214667,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720185514728,
      store_observed_time: 1720051200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 214667,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720099116756,
      store_observed_time: 1719964800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.263,
        2: 0.76,
        3: 2.319,
        4: 12.497,
        5: 82.161,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214363,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720099116756,
      store_observed_time: 1719964800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 214363,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720099116756,
      store_observed_time: 1719964800000,
      all_ratings: {
        1: 4842,
        2: 1627,
        3: 4982,
        4: 26766,
        5: 176177,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 214400,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720099116756,
      store_observed_time: 1719964800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.053,
        5: 42.106,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 214363,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720099116756,
      store_observed_time: 1719964800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214363,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720099116756,
      store_observed_time: 1719964800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 214363,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.5',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1720012712700,
      store_observed_time: 1719878400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.245,
        2: 0.764,
        3: 2.33,
        4: 12.481,
        5: 82.18,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214132,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1720012712700,
      store_observed_time: 1719878400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.824,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 214132,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1720012712700,
      store_observed_time: 1719878400000,
      all_ratings: {
        1: 4839,
        2: 1642,
        3: 4981,
        4: 26693,
        5: 175996,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 214158,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1720012712700,
      store_observed_time: 1719878400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.053,
        5: 42.105,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 214132,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1720012712700,
      store_observed_time: 1719878400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 214132,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1720012712700,
      store_observed_time: 1719878400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 214132,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719926319628,
      store_observed_time: 1719792000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.129,
        2: 0.758,
        3: 2.308,
        4: 12.408,
        5: 82.397,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213913,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719926319628,
      store_observed_time: 1719792000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 213913,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719926319628,
      store_observed_time: 1719792000000,
      all_ratings: {
        1: 4556,
        2: 1623,
        3: 4937,
        4: 26544,
        5: 176272,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 213934,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719926319628,
      store_observed_time: 1719792000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 21.053,
        5: 42.106,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 213913,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719926319628,
      store_observed_time: 1719792000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213913,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719926319628,
      store_observed_time: 1719792000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 213913,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719839912459,
      store_observed_time: 1719705600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.113,
        2: 0.754,
        3: 2.302,
        4: 12.424,
        5: 82.407,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213663,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719839912459,
      store_observed_time: 1719705600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 213663,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719839912459,
      store_observed_time: 1719705600000,
      all_ratings: {
        1: 4509,
        2: 1617,
        3: 4923,
        4: 26516,
        5: 176117,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 213688,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719839912459,
      store_observed_time: 1719705600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 26.316,
        5: 36.842,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 213663,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719839912459,
      store_observed_time: 1719705600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213663,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719839912459,
      store_observed_time: 1719705600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 213663,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719753512085,
      store_observed_time: 1719619200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.11,
        2: 0.756,
        3: 2.301,
        4: 12.444,
        5: 82.39,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213461,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719753512085,
      store_observed_time: 1719619200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 213461,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719753512085,
      store_observed_time: 1719619200000,
      all_ratings: {
        1: 4502,
        2: 1613,
        3: 4911,
        4: 26562,
        5: 175867,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 213461,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719753512085,
      store_observed_time: 1719619200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 26.316,
        5: 36.842,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 213461,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719753512085,
      store_observed_time: 1719619200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213461,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719753512085,
      store_observed_time: 1719619200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 213461,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719667111962,
      store_observed_time: 1719532800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.123,
        2: 0.756,
        3: 2.293,
        4: 12.45,
        5: 82.379,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213187,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719667111962,
      store_observed_time: 1719532800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 213187,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719667111962,
      store_observed_time: 1719532800000,
      all_ratings: {
        1: 4526,
        2: 1612,
        3: 4889,
        4: 26542,
        5: 175630,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 213203,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719667111962,
      store_observed_time: 1719532800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 26.316,
        5: 36.842,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 213187,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719667111962,
      store_observed_time: 1719532800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 213187,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719667111962,
      store_observed_time: 1719532800000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 213187,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719580711823,
      store_observed_time: 1719446400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.086,
        2: 0.74,
        3: 2.258,
        4: 12.388,
        5: 82.528,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 212860,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719580711823,
      store_observed_time: 1719446400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 212860,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719580711823,
      store_observed_time: 1719446400000,
      all_ratings: {
        1: 4440,
        2: 1575,
        3: 4807,
        4: 26369,
        5: 175664,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 212860,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719580711823,
      store_observed_time: 1719446400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 26.316,
        5: 36.842,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 212860,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719580711823,
      store_observed_time: 1719446400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 212860,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719580711823,
      store_observed_time: 1719446400000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 212860,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719494310441,
      store_observed_time: 1719360000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.102,
        2: 0.741,
        3: 2.258,
        4: 12.348,
        5: 82.552,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 212563,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719494310441,
      store_observed_time: 1719360000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 212563,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719494310441,
      store_observed_time: 1719360000000,
      all_ratings: {
        1: 4474,
        2: 1574,
        3: 4795,
        4: 26239,
        5: 175490,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 212579,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719494310441,
      store_observed_time: 1719360000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 26.316,
        5: 36.842,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 212563,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719494310441,
      store_observed_time: 1719360000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.819,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 212563,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719494310441,
      store_observed_time: 1719360000000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 212563,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719407912577,
      store_observed_time: 1719273600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.066,
        2: 0.74,
        3: 2.247,
        4: 12.412,
        5: 82.535,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 212279,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719407912577,
      store_observed_time: 1719273600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.823,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 212279,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719407912577,
      store_observed_time: 1719273600000,
      all_ratings: {
        1: 4385,
        2: 1570,
        3: 4771,
        4: 26347,
        5: 175201,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 212279,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719407912577,
      store_observed_time: 1719273600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 26.316,
        5: 36.842,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 212268,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719407912577,
      store_observed_time: 1719273600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 212268,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719407912577,
      store_observed_time: 1719273600000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.75,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 212279,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'US',
      ingest_time: 1719321512641,
      store_observed_time: 1719187200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 2.07,
        2: 0.742,
        3: 2.207,
        4: 12.381,
        5: 82.6,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 211946,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'MX',
      ingest_time: 1719321512641,
      store_observed_time: 1719187200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 8.824,
        2: 5.882,
        3: 0,
        4: 5.882,
        5: 79.412,
      },
      all_ratings_average: 4.4,
      all_ratings_count: 211946,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'L:en',
      ingest_time: 1719321512641,
      store_observed_time: 1719187200000,
      all_ratings: {
        1: 4388,
        2: 1572,
        3: 4678,
        4: 26243,
        5: 175075,
      },
      all_ratings_percents: {},
      all_ratings_average: 4.7,
      all_ratings_count: 211961,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'GB',
      ingest_time: 1719321512641,
      store_observed_time: 1719187200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 5.263,
        2: 5.263,
        3: 26.316,
        4: 26.316,
        5: 36.842,
      },
      all_ratings_average: 3.8,
      all_ratings_count: 211946,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'ES',
      ingest_time: 1719321512641,
      store_observed_time: 1719187200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 0,
        2: 0,
        3: 9.091,
        4: 9.091,
        5: 81.818,
      },
      all_ratings_average: 4.7,
      all_ratings_count: 211946,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
    {
      store_id: 'demo-store',
      store: 'android',
      region: 'CA',
      ingest_time: 1719321512641,
      store_observed_time: 1719187200000,
      all_ratings: {},
      all_ratings_percents: {
        1: 6.25,
        2: 6.25,
        3: 0,
        4: 18.75,
        5: 68.751,
      },
      all_ratings_average: 4.3,
      all_ratings_count: 211946,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '8.2.3',
    },
  ],
};
