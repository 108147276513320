// Fetch Check Update
export const REQUEST_CHECK_UPDATE = 'REQUEST_CHECK_UPDATE';
export const RECEIVE_CHECK_UPDATE = 'RECEIVE_CHECK_UPDATE';
export const ERRORED_CHECK_UPDATE = 'ERRORED_CHECK_UPDATE';
export const requestCheckUpdate = (sha) => ({
  type: REQUEST_CHECK_UPDATE,
  payload: { sha },
  meta: { sha },
});
export const receiveCheckUpdate = (sha, payload) => ({
  type: RECEIVE_CHECK_UPDATE,
  payload,
  meta: { sha },
});
export const errorCheckUpdate = (sha, payload) => ({
  type: ERRORED_CHECK_UPDATE,
  payload,
  error: true,
  meta: { sha },
});
export const fetchCheckUpdate = (sha) => (dispatch, getState, { api }) => {
  dispatch({ type: REQUEST_CHECK_UPDATE, payload: { sha }, meta: { sha } });
  return api.fetchCheckUpdate(sha)
    .then(payload => dispatch({ type: RECEIVE_CHECK_UPDATE, payload, meta: { sha } }))
    .catch(error => dispatch({ type: ERRORED_CHECK_UPDATE, payload: error, error: true, meta: { sha } }));
};
