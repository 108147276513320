import { RatingPromptItem } from './rating-prompt.types';

export interface RatingPromptState {
  activating: boolean;
  loading: boolean;
  rating: RatingPromptItem;
}

export const initialState: RatingPromptState = {
  activating: false,
  loading: false,
  rating: {
    rating_dialog: {},
  } as RatingPromptItem,
};
