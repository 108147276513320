import { createLinkMatcherWithRegExp } from '@lexical/react/LexicalAutoLinkPlugin';
import { TextFormatType } from 'lexical';

export const editorConfig = {
  namespace: 'Alchemer Rich Text Editor',
  onError(error: any) {
    throw error;
  },
};

export type FormatType =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'insertLink'
  | 'orderedList'
  | 'unorderedList';

export const INLINE_FORMAT_TYPES: (FormatType & TextFormatType)[] = ['bold', 'italic', 'underline', 'strikethrough'];

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const PHONE_REGEX = /^(?:\+|tel:\+?)[\d\s\-()]{6,}$/;

export const URL_MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    return text;
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
    return `mailto:${text}`;
  }),
  createLinkMatcherWithRegExp(PHONE_REGEX, (text) => {
    return `tel:${text}`;
  }),
];

export const validateUrl = (url: string) =>
  url === 'https://' || URL_REGEX.test(url) || EMAIL_REGEX.test(url) || PHONE_REGEX.test(url);

/**
 * Add HTML needed for correct roundtripping in the editor.
 *
 * Currently, this only includes removing `<br>`s before block-level elements like lists.
 *
 * @param [string] html The HTML to process.
 * @returns [string] The transformed HTML.
 */
export const addEditorStyling = (html: string): string => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;

  // Remove `<br>` tags immediately before block-level elements.
  //
  // Lexical creates extra _empty_ lines for `<br>` tags, so these must be removed to then get them
  // back after addEditorStyling.
  Array.from(wrapper.children).forEach((child) => {
    if (child.tagName !== 'BR') return;
    if (!(child.nextSibling instanceof Element)) return;

    if (['UL', 'OL'].includes(child.nextSibling.tagName)) {
      child.remove();
    }
  });

  return wrapper.innerHTML;
};

/**
 * Remove all HTML from the given string only useful inside the rich text editor. This includes
 * style attributes, spans, certain class names, and `<p>` tags (which are turned into `<br>` where
 * needed).
 *
 * @param [string] html The HTML to process.
 * @returns [string] The HTML with all unneeded attributes and tags removed.
 */
export const removeEditorStyling = (html: string): string => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;

  const querySelectorAllAndSelf = (selector: string): Array<Element> => {
    return [...(wrapper.matches(selector) ? [wrapper] : []), ...Array.from(wrapper.querySelectorAll(selector))];
  };

  querySelectorAllAndSelf('[class]').forEach((child) => {
    // Remove any of the classes used to add styling inside the editor, as they're redundant with
    // the <s>/<u> elements in the export.
    Array.from(child.classList).forEach((className) => {
      if (className.search(/__text-/) !== -1) child.classList.remove(className);
    });

    if (child.classList.length === 0) {
      child.removeAttribute('class');
    }
  });

  // Remove the `white-space: pre-wrap` style used within Lexical. This ensures that any spans only
  // in place for that style will be attribute-free for the next step.
  querySelectorAllAndSelf('[style]').forEach((child: HTMLElement) => {
    if (child.style.whiteSpace === 'pre-wrap') child.style.removeProperty('white-space');

    if (child.getAttribute('style') === '') child.removeAttribute('style');
  });

  // Remove redundant spans with no attributes.
  Array.from(wrapper.getElementsByTagName('span')).forEach((span) => {
    if (span.tagName === 'SPAN' && span.attributes.length === 0) {
      // Remove span but keep its children
      span.replaceWith(...Array.from(span.childNodes));
    }
  });

  // Pre-process "empty" `<p>` tags containing only `<br>`.
  Array.from(wrapper.children).forEach((child) => {
    if (child.tagName === 'P' && child.childNodes.length === 1 && child.firstElementChild?.tagName === 'BR') {
      child.replaceWith(child.firstElementChild);
    }
  });

  // Transform `<p>` tags to `<br>`.
  //
  // This adds `<br>` tags that are removed above; `<br>` tags are required even before block-level
  // elements to allow multiple new lines before things like lists, even though they then have to be
  // removed by `addEditorStyling` before loading HTML into Lexical.
  Array.from(wrapper.children).forEach((child) => {
    if (child.tagName !== 'P') return;

    child.replaceWith(...Array.from(child.childNodes), document.createElement('br'));
  });

  // Remove leading/trailing `<br>`s.
  while (wrapper.firstChild instanceof Element && wrapper.firstChild.tagName === 'BR') {
    wrapper.firstChild.remove();
  }

  while (wrapper.lastChild instanceof Element && wrapper.lastChild.tagName === 'BR') {
    wrapper.lastChild.remove();
  }

  return wrapper.innerHTML;
};
