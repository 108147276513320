import { createSelector } from 'reselect';
import { TagsState, Tag } from '../reducers/tags';
import { State } from '../reducers';

export const getTags = (state: State) => state.tags;

export const getAllTags = createSelector<State, any, TagsState['allTags']>(
  getTags,
  ({ allTags }) => [...allTags]
);
export const getFetchAllTagsPending = createSelector<State, any, TagsState['fetchAllTagsPending']>(
  getTags,
  ({ fetchAllTagsPending }) => fetchAllTagsPending || false
);
export const getFetchAllTagsError = createSelector<State, any, TagsState['fetchAllTagsError']>(
  getTags,
  ({ fetchAllTagsError }) => fetchAllTagsError || false
);

export const getMostUsedTags = createSelector<State, any, TagsState['mostUsedTags']>(
  getTags,
  ({ mostUsedTags }) => [...mostUsedTags]
);

export const getRecentTags = createSelector<State, any, TagsState['recentTags']>(
  getTags,
  ({ recentTags }) => [...recentTags]
);

export const getAppTags = createSelector<State, any, TagsState['appTags']>(
  getTags,
  ({ appTags }) => [...appTags]
);

export const getAggregatedTags = createSelector<State, any, TagsState['aggregatedTags']>(
  getTags,
  ({ aggregatedTags }) => [...aggregatedTags]
);

export const getFetchOrgTagsPending = createSelector(
  getTags,
  ({ fetchMostUsedTagsPending }) => fetchMostUsedTagsPending || false
);
export const getFetchOrgTagsError = createSelector(
  getTags,
  ({ fetchOrgTagsError }) => fetchOrgTagsError || false
);

export const getAllTagName = (tagName: string) => createSelector<State, TagsState['allTags'], Tag | undefined>(
  getAllTags,
  (allTags) => allTags.find((item) => item.name === tagName)
);
