import React from 'react';

import { bemPrefix } from 'src/utils';
import LoadingIcon from '../../assets/loading/conversation-search.jsx.svg';
import { Icon } from '../icons';

import './loading-container.scss';

export interface LoadingProps {
  className?: string;
  title?: string;
  isSmall?: boolean;
}

const bem = bemPrefix('loading-container');

const Loading: React.FC<LoadingProps> = ({ className = '', title = 'Loading...', isSmall = false }) => (
  <div className={`${bem('', { small: isSmall })} ${className}`}>
    {isSmall ? (
      <>
        <Icon name="animatedSpinner" className={bem('spinner')} />
        {title && <span className={bem('inline-title')}>{title}</span>}
      </>
    ) : (
      <LoadingIcon />
    )}
    {!isSmall && <h2>{title}</h2>}
  </div>
);

export default Loading;
