import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { any } from 'ramda';
import { fetchAppStoreIdsV2 } from '../../../api';

import './appStoreIdTypeahead.scss';

const renderMenuItemChildren = (option) => {
  if (!option.app_name) {
    return null;
  }
  return (
    <div className="dropdown-content" key={option.store_app_id}>
      {
        option.icon_url
          ? <img
              alt={`${option.app_name} App Logo`}
              src={option.icon_url}
              className="dropdown-thumbnail"
          />
          : null
      }
      <div className="text-container">
        <div className="app-name">{option.app_name}</div>
        <div className="store-id">{option.store_app_id}</div>
      </div>
    </div>
  );
};

class AppStoreIdTypeahead extends Component {
  platformToStore = {
    iOS: 'itunes',
    Android: 'android',
  };

  static propTypes = {
    handleInputChange: PropTypes.func,
    handleOnOptionSelected: PropTypes.func,
    handleOnStoreAppIdSelected: PropTypes.func,
    defaultInputValue: PropTypes.string,
    labelKey: PropTypes.func,
    placeholder: PropTypes.string,
    platform: PropTypes.string,
    storeId: PropTypes.string,
  };

  static defaultProps = {
    handleInputChange: () => { },
    handleOnOptionSelected: () => { },
    handleOnStoreAppIdSelected: () => { },
    defaultInputValue: '',
    labelKey: (option) => (option.store_app_id || ''),
    placeholder: 'App Store ID',
    platform: '',
    storeId: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      isLoading: false,
      selected: [],
    };
    if (props.storeId) {
      this.state.selected = [{ store_app_id: props.storeId }];
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.storeId) {
      this.setState({ selected: [{ store_app_id: nextProps.storeId }] });
    }
  }

  handleSearch = query => {
    if (!query) {
      return undefined;
    }

    this.setState({ isLoading: true });
    return fetchAppStoreIdsV2(query, this.platformToStore[this.props.platform])
      .then(res => {
        this.setState({
          options: res.results || [],
          isLoading: false,
        });
      });
  };

  handleSelect = option => {
    if (Array.isArray(option) && option.length > 0) {
      this.props.handleOnStoreAppIdSelected(option[0].store_app_id);
      this.props.handleOnOptionSelected(option[0]);
      this.setState({ selected: option });
    }
  };

  filterOption = (option, { text }) => {
    if (this.props.platform && option.store !== this.platformToStore[this.props.platform]) {
      return false;
    }
    const fields = ['app_name', 'store_app_id'];
    const condition = (field) => {
      let value = option[field];
      text = text.toLowerCase();
      value = value.toLowerCase();
      return value.indexOf(text) !== -1;
    };
    return any(condition, fields);
  };

  render() {
    return (
      <div id="app-store-id-typeahead">
        <AsyncTypeahead
          {...this.state}
          id="app-store-typeahead"
          filterBy={this.filterOption}
          labelKey={this.props.labelKey}
          onChange={this.handleSelect}
          onInputChange={this.props.handleInputChange}
          onSearch={this.handleSearch}
          placeholder={this.props.placeholder}
          renderMenuItemChildren={renderMenuItemChildren}
          defaultInputValue={this.props.defaultInputValue}
        />
      </div>
    );
  }
}

export default AppStoreIdTypeahead;
