import { any, intersection, is, isEmpty, isNil, omit, pick, values } from 'ramda';
import ObjectID from 'bson-objectid';
import { camelCaseKeys } from 'src/api/camelcase-keys';
import {
  InteractionType,
  InteractionItem,
  ISurveyQuestionSet,
  ActionAttribute,
  CriteriaKey,
  ISurveyLogicQuestion,
  API_VERSION_WITH_NO_RICH_TEXT_SURVEYS,
  API_VERSION_WITH_RICH_TEXT,
} from 'src/types/core';
import { isType } from 'src/reducers/surveys-v12/surveys.utils';
import { LocalStorage } from 'src/services';
import { sortBy, SortOrder } from 'src/utils';
import {
  getIsSurveyV12WithSkipLogic,
  getIsSurveyV12WithSkipToEnd,
  isDefaultInvoke,
  isNotDefaultInvoke,
} from 'src/interactions/components/survey-form/survey-questions/skip-logic-helpers';
import { getTextFromHtml, getIsStringContainHtml } from 'src/components/molecules/util-handlers';
import {
  DefaultQuestions,
  DefaultQuestionAnswerChoice,
  DefaultQuestionOtherChoice,
  DEFAULT_QUESTION_SET_DATA,
} from './surveys.state';
import { QuestionAnswerChoice, SurveyQuestionType, SurveyRenderAs, SurveyV12Item } from './surveys.types';
import { templates, FanSignalTemplateName, TemplateName, Templates } from './survey-templates';

export { TemplateName };

const invalidSchemes = ['javascript'];

export const defaultModel: SurveyV12Item = {
  id: '',
  appId: '',
  active: false,
  required: false,
  renderAs: SurveyRenderAs.LIST,
  name: '',
  title: '',
  description: '',
  questionSets: [],
  responseCount: 0,
  showSuccessMessage: true,
  showTermsAndConditions: false,
  termsAndConditionsLabel: 'Terms & Conditions',
  showDisclaimer: false,
  disclaimerText: '',
  successMessage: 'Thank you!',
  submitButtonText: 'Submit',
  nextButtonText: 'Next',
};

const getTemplates = (): Partial<Templates> => templates;

type GetFlagFn<T extends { _destroy?: number }> = (item: T) => boolean;
type GetIsSelectedSurveyWithSLFn<T extends { id: string }> = (surveys: T[], itemId: string) => boolean;
const isActiveEntity: GetFlagFn<ISurveyQuestionSet | QuestionAnswerChoice | ActionAttribute> = (el) =>
  el._destroy !== 1;
const isNotActiveEntity: GetFlagFn<ISurveyQuestionSet | QuestionAnswerChoice | ActionAttribute> = (el) =>
  el._destroy === 1;

const getIsSelectedSurveyWithSL: GetIsSelectedSurveyWithSLFn<SurveyV12Item> = (surveys, item) => {
  const selectedItem = surveys.find((s) => s.id === item);
  return selectedItem ? getIsSurveyV12WithSkipLogic(selectedItem as SurveyV12Item) : false;
};

export class SurveyV12Model {
  static Defaults = defaultModel;
  static DefaultQuestions = DefaultQuestions;
  static DefaultAnswerChoice = DefaultQuestionAnswerChoice;
  static DefaultOtherChoiceData = DefaultQuestionOtherChoice;
  static getTemplates = getTemplates;
  static toLegacy = toLegacy;
  static oldSurveyToNew = oldSurveyToNew;
  static enrichQSetsWithDefaultSkipLogic = enrichQSetsWithDefaultSkipLogic;
  static updQSetsWithDefaultSkipLogic = updQSetsWithDefaultSkipLogic;
  static getIsSelectedSurveyWithSL = getIsSelectedSurveyWithSL;

  static isActiveEntity = isActiveEntity;
  static isNotActiveEntity = isNotActiveEntity;
  static isSelectOtherType = isSelectOtherType;
  static filterOtherType = filterOtherType;
  static sortQuestionChoices = sortQuestionChoices;
  static isQuestionWithOtherChoice = isQuestionWithOtherChoice;

  static getIsContainHtmlFields(model: SurveyV12Item): boolean {
    const hasHtmlInQuestionSets = model.questionSets.some((qSet) => {
      const hasHtmlInQuestion = qSet.questions.some((q) => getIsStringContainHtml(q.value));
      const hasHtmlInAnswerChoices = qSet.questions.some(
        (q) => q.answer_choices && q.answer_choices.some((ans) => getIsStringContainHtml(ans.value)),
      );
      return hasHtmlInQuestion || hasHtmlInAnswerChoices;
    });
    return (
      hasHtmlInQuestionSets ||
      getIsStringContainHtml(model.description) ||
      getIsStringContainHtml(model.disclaimerText) ||
      getIsStringContainHtml(model.successMessage)
    );
  }

  static getParsedHtmlFieldsBeforeSave(model: SurveyV12Item): SurveyV12Item {
    return {
      ...model,
      description: getIsStringContainHtml(model.description)
        ? (model.description as string)
        : getTextFromHtml(model.description as string | undefined),
      disclaimerText: getIsStringContainHtml(model.disclaimerText)
        ? (model.disclaimerText as string)
        : getTextFromHtml(model.disclaimerText),
      successMessage: getIsStringContainHtml(model.successMessage)
        ? (model.successMessage as string)
        : getTextFromHtml(model.successMessage),
      questionSets: model.questionSets.map((qSet) => ({
        ...qSet,
        questions: qSet.questions.map((q) => ({
          ...q,
          value: getIsStringContainHtml(q.value) ? q.value : (getTextFromHtml(q.value) as string),
          answer_choices: q.answer_choices
            ? q.answer_choices.map((ans) => ({
                ...ans,
                value: getIsStringContainHtml(ans.value) ? ans.value : (getTextFromHtml(ans.value) as string),
              }))
            : q.answer_choices,
        })),
      })),
    };
  }

  static getApiVersion(model: SurveyV12Item): string {
    return this.getIsContainHtmlFields(model) ? API_VERSION_WITH_RICH_TEXT : API_VERSION_WITH_NO_RICH_TEXT_SURVEYS;
  }

  static setData(model: SurveyV12Item, data: Partial<SurveyV12Item>): SurveyV12Item {
    if (data.selfTargeting === false) {
      data.active = true;
      data.codePoints = [];
      if (data.criteria) {
        data.criteria = omit(
          Object.keys(data.criteria).filter((key) => key !== '_version'),
          data.criteria,
        );
      }
    }
    return { ...model, ...data };
  }

  static addQuestionSet(model: SurveyV12Item, type: SurveyQuestionType): SurveyV12Item {
    const question = { ...DefaultQuestions[type] };
    const answer_choices = question.answer_choices
      ? question.answer_choices.map((ans) => ({ ...ans, logical_id: ObjectID().toHexString() }))
      : undefined;

    return {
      ...model,
      questionSets: [
        ...model.questionSets.filter(isActiveEntity),
        {
          ...DEFAULT_QUESTION_SET_DATA,
          logical_id: ObjectID().toHexString(),
          order: model.questionSets.filter(isActiveEntity).length,
          questions: [{ ...question, logical_id: ObjectID().toHexString(), answer_choices }],
        },
        ...model.questionSets.filter(isNotActiveEntity),
      ],
    };
  }

  static updateQuestionSet(model: SurveyV12Item, pos: number, data: ISurveyQuestionSet): SurveyV12Item {
    const updated = [...model.questionSets];
    updated.splice(pos, 1, sortQuestionChoices(data));
    const hasSL = getIsSurveyV12WithSkipLogic({ ...model, questionSets: updated });
    const isShowSuccessRequired = hasSL ? getIsSurveyV12WithSkipToEnd({ ...model, questionSets: updated }) : false;

    return {
      ...model,
      questionSets: updated,
      renderAs: hasSL ? SurveyRenderAs.PAGED : SurveyRenderAs.LIST,
      showSuccessMessage: isShowSuccessRequired || model.showSuccessMessage,
    };
  }

  static setQuestionSetPosition(model: SurveyV12Item, prevPos: number, newPos: number): SurveyV12Item {
    const updated = [...model.questionSets];
    const firstMovedQuestionSet = updated[prevPos];
    const secondMovedQuestionSet = updated[newPos];
    updated[newPos] = { ...firstMovedQuestionSet, order: newPos };
    updated[prevPos] = { ...secondMovedQuestionSet, order: prevPos };
    return {
      ...model,
      questionSets: updated,
    };
  }

  static removeQuestionSet(model: SurveyV12Item, id: string): SurveyV12Item {
    const targetQuestionSet: ISurveyQuestionSet | undefined = model.questionSets.find((item) => item.logical_id === id);
    if (!targetQuestionSet) {
      return model;
    }

    const updated: ISurveyQuestionSet[] = targetQuestionSet.id
      ? [
          ...model.questionSets
            .filter((qSet) => qSet.logical_id !== targetQuestionSet.logical_id)
            .map((el, order) => ({ ...el, order })),
          { ...targetQuestionSet, order: model.questionSets.length - 1, _destroy: 1 },
        ]
      : [
          ...model.questionSets
            .filter((qSet) => qSet.logical_id !== targetQuestionSet.logical_id)
            .map((el, order) => ({ ...el, order })),
        ];

    const hasSL = getIsSurveyV12WithSkipLogic({ ...model, questionSets: updated });
    const isShowSuccessRequired = hasSL ? getIsSurveyV12WithSkipToEnd({ ...model, questionSets: updated }) : false;

    return {
      ...model,
      questionSets: updated,
      renderAs: hasSL ? SurveyRenderAs.PAGED : SurveyRenderAs.LIST,
      showSuccessMessage: isShowSuccessRequired || model.showSuccessMessage,
    };
  }

  static parseSurveyBeforeSave(model: SurveyV12Item, forceActive: boolean): SurveyV12Item {
    return {
      ...model,
      active: forceActive,
      description: model.description === '' ? null : model.description,
      questionSets: model.questionSets.map(handleQSetOtherAnsChoices),
    };
  }

  static serialize(model: SurveyV12Item): SurveyV12Item {
    return model;
  }

  static getTemplate(name: TemplateName) {
    let item = getTemplates()[name];
    if (name === FanSignalTemplateName.LostFan) {
      item = LocalStorage.getValue(name);
    }

    return enrichLegacyTemplate(item);
  }

  static isTemplateExist(name: TemplateName | string) {
    return Object.keys(getTemplates()).includes(name) || !!LocalStorage.getValue(name);
  }

  static isCommunitySurvey(name: SurveyV12Item['fromTemplate']) {
    return name ? ['covid_19_customer_survey'].includes(name) : false;
  }

  static isValid(model: SurveyV12Item): boolean {
    const questions = model.questionSets.filter(isActiveEntity);
    const requiredProps = pick(['name', 'title'], model);
    return !any(isEmpty)(values({ ...requiredProps, questions }));
  }

  /**
   * Ensure that the url parameter is valid.
   * @param {SurveyV12Item} model The survey model
   * @returns True if the url is empty or begins with 'http'. False otherwise.
   */
  static isValidUrl(url: string): boolean {
    const isUrl = !!url;
    if (!isUrl) {
      return true;
    }
    const scheme = url.split(':')[0];
    return invalidSchemes.indexOf(scheme.toLowerCase()) < 0;
  }

  static isValidQuestionSets(model: SurveyV12Item): boolean {
    const questions = model.questionSets.filter(isActiveEntity).map((q) => q.questions[0]);
    const validity = questions.map((q) => {
      if (isType.Singleline(q) || isType.Nps(q)) {
        return withRequiredProps(['value'], q);
      }
      if (isType.Multichoice(q) || isType.Multiselect(q)) {
        const answerValues = (q.answer_choices || []).find((a) => !a.value);
        return withRequiredProps(['value', 'other_choice_text'], q) && !answerValues;
      }
      if (isType.Range(q)) {
        return withRequiredProps(['value', 'min', 'max_label', 'min_label'], q);
      }
      return withRequiredProps(['value'], q);
    });

    return validity.find((valid) => !valid) === undefined;

    function withRequiredProps(props: (keyof ISurveyLogicQuestion)[], q: ISurveyLogicQuestion): boolean {
      return !any(isEmpty)(values(pick(props, q)));
    }
  }

  static isValidTargeting(model: SurveyV12Item): boolean {
    return is(Boolean, model.selfTargeting);
  }

  static isTriggeredBy({ triggeredBy = [] }: SurveyV12Item, interactionId: string) {
    return !!triggeredBy.find((trigger) => trigger.id === interactionId);
  }

  static isFromLoveDialog(codePoints?: InteractionItem['code_points']): boolean {
    if (!codePoints) {
      return false;
    }
    // https://github.com/apptentive/pupum/blob/33c8241050c5a097075e4facdb2a669bbec28045/legacy/assets/models/survey.js#L105
    const points = Array.isArray(codePoints) ? codePoints : [codePoints];
    return intersection(loveDialogCodePoints(), points).length > 0;
  }

  static invokeInteractions(triggeredBy: SurveyV12Item['triggeredBy']) {
    return {
      loveDialogs: triggeredBy ? triggeredBy.filter((item) => item.type === InteractionType.EnjoymentDialog) : [],
      notes: triggeredBy ? triggeredBy.filter((item) => item.type === InteractionType.TextModal) : [],
    };
  }

  static getIsSelectedSurveyWithRichText(surveys: SurveyV12Item[], itemId: string): boolean {
    const selectedItem = surveys.find((s) => s.id === itemId);
    return selectedItem ? SurveyV12Model.getIsContainHtmlFields(selectedItem as SurveyV12Item) : false;
  }
}

function isSelectOtherType(el: QuestionAnswerChoice) {
  return el.type && el.type === 'select_other';
}

function filterOtherType(el: QuestionAnswerChoice) {
  return el.type && el.type !== 'select_other';
}

function sortQuestionChoices(data: ISurveyQuestionSet): ISurveyQuestionSet {
  const question = data.questions[0];
  if (!question.answer_choices || isEmpty(question.answer_choices)) {
    return data;
  }

  const hasOtherChoice = question.answer_choices.some(isSelectOtherType);
  if (hasOtherChoice && question.answer_choices.findIndex(isSelectOtherType) < question.answer_choices.length - 1) {
    const otherChoice = question.answer_choices.find(isSelectOtherType) as QuestionAnswerChoice;
    const answer_choices = [...question.answer_choices.filter(filterOtherType), otherChoice];
    return {
      ...data,
      questions: [{ ...question, answer_choices }],
    };
  }

  return data;
}

function handleQSetOtherAnsChoices(qSet: ISurveyQuestionSet): ISurveyQuestionSet {
  const question = qSet.questions[0];
  if (!question.answer_choices || isEmpty(question.answer_choices)) {
    return qSet;
  }

  const otherChoiceOption = question.answer_choices.find(isSelectOtherType) as QuestionAnswerChoice;
  const isActiveOtherChoice = otherChoiceOption && isActiveEntity(otherChoiceOption);

  return {
    ...qSet,
    questions: [
      {
        ...question,
        other_choice_text: otherChoiceOption && otherChoiceOption.value ? otherChoiceOption.value : 'Other',
        other_choice: !!isActiveOtherChoice,
      },
    ],
  };
}

function loveDialogCodePoints() {
  return [
    'enjoyment_dialog.cancel',
    'enjoyment_dialog.launch',
    'enjoyment_dialog.no',
    'enjoyment_dialog.yes',
    'feedback_dialog.cancel',
    'feedback_dialog.decline',
    'feedback_dialog.dismiss',
    'feedback_dialog.launch',
    'feedback_dialog.skip_view_messages',
    'feedback_dialog.submit',
    'feedback_dialog.view_messages',
    'rating_dialog.cancel',
    'rating_dialog.decline',
    'rating_dialog.launch',
    'rating_dialog.rate',
    'rating_dialog.remind',
    'rating_dialog.yes',
  ];
}

function oldSurveyToNew(item: any): SurveyV12Item {
  // README: See src/api/surveys.ts#L64
  const json = camelCaseKeys(item) as SurveyV12Item;
  return {
    ...json,
    questionSets: sortBy(item.question_sets.map(sortQuestionChoices), 'order', SortOrder.asc),
    criteria: item.criteria,
  };
}

function enrichLegacyTemplate(item: any) {
  return {
    toNew: () => (item ? oldSurveyToNew(item) : item),
    toPure: () => item,
  };
}

// README: Temp, all props should be via underscore, and avoid oldSurveyToNew
// TODO: Avoid converting, and clean up codes and forms to native props
function toLegacy(model: SurveyV12Item) {
  const unknowProps = {
    display_response_rate: '', // ?
    response_count: model.responseCount,
    response_rate: model.responseRate,
    response_rate_cached_time: model.responseRateCachedTime,
    display_type: model.displayType || null,
    triggered_by: model.triggeredBy,
    show_terms_and_conditions: model.showTermsAndConditions,
    terms_and_conditions_label: model.termsAndConditionsLabel || null,
    terms_and_conditions_link: model.termsAndConditionsLink || null,
    style_name: model.styleName || null,
    show_disclaimer: model.showDisclaimer,
    disclaimer_text: model.disclaimerText || null,
  };
  return {
    ...omit(
      [
        'appId',
        'criteria',
        'codePoints',
        'displayType',
        'fromTemplate',
        'multipleResponses',
        'showSuccessMessage',
        'successMessage',
        'contactUrl',
        'contactUrlText',
        'selfTargeting',
        'questions',
        'templateLocked',
        'createdAt',
        'updatedAt',
        'endTime',
        'startTime',
        'respMax',
        'responseCount',
        'responseRate',
        'responseRateCachedTime',
        'triggeredBy',
        'viewLimit',
        'viewCount',
        'viewPeriod',
        'showTermsAndConditions',
        'termsAndConditionsLabel',
        'termsAndConditionsLink',
        'styleName',
        'renderAs',
        'questionSets',
        'showDisclaimer',
        'disclaimerText',
        'submitButtonText',
        'nextButtonText',
      ],
      model,
    ),
    app_id: model.appId,
    created_at: model.createdAt,
    updated_at: model.updatedAt,
    multiple_responses: model.multipleResponses,
    show_success_message: model.showSuccessMessage,
    success_message: model.successMessage,
    code_points: model.codePoints,
    render_as: model.renderAs,
    contact_url: model.contactUrl || null,
    contact_url_text: model.contactUrlText || null,
    question_sets_attributes: model.questionSets.map((qSet) => ({
      id: qSet.id,
      logical_id: qSet.logical_id,
      order: qSet.order,
      actions_attributes: qSet.invokes,
      questions_attributes: qSet.questions.map(({ answer_choices, ...q }) => ({
        ...q,
        answer_choices_attributes: (answer_choices || []).map((answer) => ({ ...answer })),
      })),
      _destroy: qSet._destroy,
    })),
    from_template: model.fromTemplate || '', // TemplateName
    criteria: model.criteria,
    template_locked: model.templateLocked || false,
    self_targeting: model.selfTargeting,
    resp_max: model.respMax || null,
    end_time: model.endTime || null,
    start_time: model.startTime || null,
    view_count: model.viewCount,
    view_limit: model.viewLimit || null,
    view_period: model.viewPeriod || 86400, // day
    submit_button_text: model.submitButtonText,
    next_button_text: model.nextButtonText,
    ...unknowProps,
  };
}

function isQuestionWithOtherChoice(question: ISurveyLogicQuestion): boolean {
  if (!question.answer_choices || isEmpty(question.answer_choices)) {
    return false;
  }

  return question.answer_choices.some(isSelectOtherType);
}

const getIsEmptyAnsValue = (value: any) =>
  value === '' || (typeof value === 'number' && value < -1) || isNil(value) || Number.isNaN(value);

const hasEmptyAnswer = (answers: Record<CriteriaKey, any>[]) => {
  let res = false;
  answers.forEach((ans) => {
    const criteria = Object.values(ans)[0];
    const value = Object.values(criteria)[0];

    if (getIsEmptyAnsValue(value)) {
      res = true;
    }
  });

  return res;
};

const isEmptyAnswer = (ans: Record<CriteriaKey, any>) => getIsEmptyAnsValue(Object.values(ans)[0]);

const isCompletedInvoke = (inv: ActionAttribute) => {
  let hasEmptyCriteria = false;
  if (inv.criteria && !isEmpty(Object.values(inv.criteria))) {
    const objAnswrs = Object.values(inv.criteria)[0];
    const isArr = Array.isArray(objAnswrs);

    if (isArr) {
      hasEmptyCriteria = hasEmptyAnswer(objAnswrs);
    } else {
      hasEmptyCriteria = !!(inv.criteria && !isEmpty(objAnswrs) && isEmptyAnswer(objAnswrs));
    }
  }

  const hasCriteriaAndNoNextLogID = !!(inv.behavior === 'continue' && isNotDefaultInvoke(inv) && !inv.next_logical_id);
  const hasNextLogIDAndNoCriteria = !!(inv.behavior === 'continue' && isDefaultInvoke(inv) && inv.next_logical_id);
  const hasEndOfSurveyAndEmptyCriteria = !!(inv.behavior === 'end' && isDefaultInvoke(inv) && !inv.next_logical_id);

  return !(
    hasNextLogIDAndNoCriteria ||
    hasCriteriaAndNoNextLogID ||
    hasEmptyCriteria ||
    hasEndOfSurveyAndEmptyCriteria
  );
};

function enrichQSetsWithDefaultSkipLogic(questionSets: ISurveyQuestionSet[]): ISurveyQuestionSet[] {
  return questionSets.map((qSet, index) => {
    // no modification for the last question set in survey-v12
    if (questionSets.length === index + 1) {
      return {
        ...qSet,
        invokes: [{ behavior: 'end', order: 0 }],
      };
    }

    const next_logical_id = questionSets[index + 1].logical_id || '';
    // check if question set is already contains skip logic, so default skip logic rules will be added as well
    if (qSet.invokes) {
      const filteredInvokes = qSet.invokes.filter(isCompletedInvoke);
      return {
        ...qSet,
        invokes: [
          ...filteredInvokes,
          {
            behavior: 'continue',
            next_logical_id,
            criteria: {},
            order: filteredInvokes.length,
          },
        ],
      };
    }

    // there is no skip logic in question set, so there will be only default skip logic rules
    return {
      ...qSet,
      invokes: [
        {
          behavior: 'continue',
          next_logical_id,
          criteria: {},
          order: 0,
        },
      ],
    };
  });
}

function updQSetsWithDefaultSkipLogic(questionSets: ISurveyQuestionSet[]): ISurveyQuestionSet[] {
  const activeQSets = [...questionSets.filter(SurveyV12Model.isActiveEntity)];
  return questionSets.map((qSet, index) => {
    // return if question removed
    if (!SurveyV12Model.isActiveEntity(qSet)) {
      return qSet;
    }

    const invokes: ActionAttribute[] = qSet.invokes && !isEmpty(qSet.invokes) ? qSet.invokes : [];
    const defaultInvoke = invokes.find(isDefaultInvoke) as ActionAttribute;

    // modification for the last question set in survey-v12
    if (activeQSets.length === index + 1) {
      return {
        ...qSet,
        invokes: [
          ...invokes.filter(isNotDefaultInvoke),
          { ...defaultInvoke, behavior: 'end', next_logical_id: '', criteria: {}, order: 0 },
        ],
      };
    }

    // question set is already contains skip logic, so default skip logic rules will be changed
    const nextQSetId = questionSets[index + 1].logical_id || '';
    let notDefaultInvokes: ActionAttribute[] = [];
    invokes.forEach((inv) => {
      if (isDefaultInvoke(inv)) {
        return;
      }

      if (!isCompletedInvoke(inv)) {
        notDefaultInvokes = inv.id ? [...notDefaultInvokes, { ...inv, _destroy: 1 }] : notDefaultInvokes;
      } else {
        notDefaultInvokes = [...notDefaultInvokes, inv];
      }
    });

    return {
      ...qSet,
      invokes: [
        ...notDefaultInvokes,
        {
          ...defaultInvoke,
          behavior: 'continue',
          next_logical_id: nextQSetId,
          criteria: {},
          order: notDefaultInvokes.length,
        },
      ],
    };
  });
}
