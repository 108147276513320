import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, IconName } from 'src/components/molecules';
import { bemPrefix } from 'src/utils';
import { DateLastPeriod, getStartEndOptionByPeriod } from 'src/utils/time';
import { FanSignalsExportType } from 'src/reducers/insights';
import { fanSignals, FsExportConfig } from 'src/actions/fan-signals';
import { eventMetrics, Events } from 'src/actions/event-metrics';
import { DEFAULT_STATE, invokeFsValues } from './download-fan-signals-dialog-options';

import './download-fan-signals-info-dialog.scss';

const bem = bemPrefix('download-fan-signals-info-dialog');

const listOptions = [
  {
    type: FanSignalsExportType.CURRENT_SIGNAL,
    name: 'people',
    title: 'Fan Signals by Customer',
    text:
      'Identify all of your customers along with their current Fan Signal. Pinpoint the customers who are shifting away from you and take action because you know who they are.',
    descr: 'Export 30 days',
  },
  {
    type: FanSignalsExportType.SIGNAL_SUMMARY,
    list: ['arrows', 'graphs'],
    title: 'Fan Signal Buckets by Day',
    text:
      ' Get an aggregate view of all your Fan Signals on a daily basis. If your organization uses Net Promoter Score, we think that you’ll find this daily aggregation incredibly powerful and help you identify your true velocity of promotion.',
    descr: 'Export 30 days',
  },
  {
    type: FanSignalsExportType.SIGNALS_OVER_TIME,
    name: 'notebooks',
    title: 'Signals Over Time',
    text:
      'Signals Over Time is the emotional journey that your customers are taking with you. It can help you identify when new features lead to more love, when outages really break trust, or when heavy investments are harming  your relationships. Export to see your customer’s sentiment over a specific time during their journey.',
    descr: 'Contact us',
  },
];

export interface DownloadFanSignalsInfoListProps {
  onCloseModal(): void;
  onCustomerClick(): void;
  onExport(config: FsExportConfig): void;
  sendEvent(event: Events): void;
}

export const DownloadFanSignalsInfoList: React.FC<DownloadFanSignalsInfoListProps> = ({
  onCloseModal,
  onCustomerClick,
  onExport,
  sendEvent,
}) => {
  const dateRange = getStartEndOptionByPeriod(DateLastPeriod.DAYS_30);

  const onSubmit = useCallback(
    (type: FanSignalsExportType) => {
      onExport({
        exportType: type,
        statuses: invokeFsValues(DEFAULT_STATE),
        isUnlimitedExport: false,
        ...dateRange,
      });
      sendEvent(
        type === FanSignalsExportType.CURRENT_SIGNAL
          ? Events.MODAL_EXPORT_SIGNALS_BY_CUSTOMER
          : Events.MODAL_EXPORT_SIGNALS_BY_DAY
      );
      onCloseModal();
    },
    [onExport, onCloseModal, dateRange]
  );

  return (
    <ul className={bem('main-content')}>
      {listOptions.map((option) => (option.list ? (
        <li key={option.title}>
          {option.list.map((item) => (
            <Icon key={item} name={item as IconName} className={bem(`${item}`)} />
          ))}
          <h4 className={bem('title')}>{option.title}</h4>
          <p>{option.text}</p>
          <Button className={bem('submit')} onClick={() => onSubmit(option.type)}>
            {option.descr}
          </Button>
        </li>
      ) : (
        <li key={option.title}>
          <Icon name={option.name as IconName} className={bem(`${option.name}`)} />
          <h4 className={bem('title')}>{option.title}</h4>
          <p>{option.text}</p>
          {option.type === FanSignalsExportType.SIGNALS_OVER_TIME ? (
            <Button key={option.name} className={bem('submit')} onClick={onCustomerClick}>
              {option.descr}
            </Button>
          ) : (
            <Button key={option.name} className={bem('submit')} onClick={() => onSubmit(option.type)}>
              {option.descr}
            </Button>
          )}
        </li>
      ))
      )}
    </ul>
  );
};

DownloadFanSignalsInfoList.displayName = 'DownloadFanSignalsInfoList';

export const DownloadFanSignalsInfoListContainer = connect(
  null,
  (dispatch: Function) => ({
    onExport: (config: FsExportConfig) => dispatch(fanSignals.runExport(config)),
    sendEvent: (event: Events) => dispatch(eventMetrics.sendEvent(event)),
  })
)(DownloadFanSignalsInfoList);
