export enum PromptActions {
  CREATE_PROMPT_MULTI_APPS_PENDING = 'CREATE_PROMPT_MULTI_APPS_PENDING',
  CREATE_PROMPT_MULTI_APPS_SUCCESS = 'CREATE_PROMPT_MULTI_APPS_SUCCESS',
  CREATE_PROMPT_MULTI_APPS_ERROR = 'CREATE_PROMPT_MULTI_APPS_ERROR',

  UPDATE_PROMPT_MULTI_APPS_PENDING = 'UPDATE_PROMPT_MULTI_APPS_PENDING',
  UPDATE_PROMPT_MULTI_APPS_SUCCESS = 'UPDATE_PROMPT_MULTI_APPS_SUCCESS',
  UPDATE_PROMPT_MULTI_APPS_ERROR = 'UPDATE_PROMPT_MULTI_APPS_ERROR',

  ACTIVATE_PROMPT_MULTI_APPS_PENDING = 'ACTIVATE_PROMPT_MULTI_APPS_PENDING',
  ACTIVATE_PROMPT_MULTI_APPS_SUCCESS = 'ACTIVATE_PROMPT_MULTI_APPS_SUCCESS',
  ACTIVATE_PROMPT_MULTI_APPS_ERROR = 'ACTIVATE_PROMPT_MULTI_APPS_ERROR',

  BOOTSTRAP_PROMPT_MULTI_APPSS = 'BOOTSTRAP_PROMPT_MULTI_APPSS',

  GET_PROMPT_MULTI_APPSS_PENDING = 'GET_PROMPT_MULTI_APPSS_PENDING',
  GET_PROMPT_MULTI_APPSS_SUCCESS = 'GET_PROMPT_MULTI_APPSS_SUCCESS',
  GET_PROMPT_MULTI_APPSS_ERROR = 'GET_PROMPT_MULTI_APPSS_ERROR',

  GET_PROMPT_MULTI_APPS_PENDING = 'GET_PROMPT_MULTI_APPS_PENDING',
  GET_PROMPT_MULTI_APPS_SUCCESS = 'GET_PROMPT_MULTI_APPS_SUCCESS',
  GET_PROMPT_MULTI_APPS_ERROR = 'GET_PROMPT_MULTI_APPS_ERROR',

  DELETE_PROMPT_MULTI_APPS_PENDING = 'DELETE_PROMPT_MULTI_APPS_PENDING',
  DELETE_PROMPT_MULTI_APPS_SUCCESS = 'DELETE_PROMPT_MULTI_APPS_SUCCESS',
  DELETE_PROMPT_MULTI_APPS_ERROR = 'DELETE_PROMPT_MULTI_APPS_ERROR',

  CLONE_PROMPT_MULTI_APPS_TO_APP_PENDING = 'CLONE_PROMPT_MULTI_APPS_TO_APP_PENDING',
  CLONE_PROMPT_MULTI_APPS_TO_APP_SUCCESS = 'CLONE_PROMPT_MULTI_APPS_TO_APP_SUCCESS',
  CLONE_PROMPT_MULTI_APPS_TO_APP_ERROR = 'CLONE_PROMPT_MULTI_APPS_TO_APP_ERROR',
}
