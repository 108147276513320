import classNames from 'classnames';
import React, { DetailedReactHTMLElement, ReactHTML } from 'react';
import { bemPrefix } from 'src/utils';

import './rich-text-content.scss';

const bem = bemPrefix('rich-text-content');

const removeBrs = (html: string): string => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;

  for (const el of Array.from(wrapper.getElementsByTagName('BR'))) {
    el.replaceWith(' ');
  }

  return wrapper.innerHTML;
};

type RichTextContentType = <T extends HTMLElement>(
  props: {
    type?: keyof ReactHTML;
    html: string | undefined;
    isInline?: boolean;
    className?: string;
  } & React.DetailedHTMLProps<React.HTMLAttributes<T>, T>,
) => DetailedReactHTMLElement<React.HTMLAttributes<T>, T>;

export const RichTextContent: RichTextContentType = ({ type = 'div', html, isInline, className, ...props }) => {
  // eslint-disable-next-line  react/no-danger
  return React.createElement(type, {
    dangerouslySetInnerHTML: html ? { __html: isInline ? removeBrs(html) : html } : undefined,
    className: classNames(className, bem('', { inline: isInline })),
    ...props,
  });
};
