import { __, allPass, anyPass, ap, compose, filter, map, of, path, pathEq, pluck, prop, propEq, sortBy, split, toPairs, useWith, values } from 'ramda';
import { SortOrder } from 'src/components/molecules';
import { conversationsInitialState, ConversationsSortBy } from 'src/reducers/conversations/conversations.state';

const stateWithSortedConversations = (sort, conversationList) => {
  const dotPath = useWith(path, [split('.')]); // eslint-disable-line
  const propsDotPath = useWith(ap, [map(dotPath), of]); // eslint-disable-line

  const pairs = toPairs(conversationList);
  const sorted = sortBy(compose(propsDotPath([sort.sortBy]), prop(1)))(pairs);
  const isDescOrder = sort.order === SortOrder.desc;
  const isTimeBased = [ConversationsSortBy.MessageCreated, ConversationsSortBy.LastMessage].includes(sort.sortBy);
  if (isTimeBased && isDescOrder) {
    sorted.reverse();
  }
  return pluck(1)(sorted);
};

/**
 *
 * @param {sortBy: {sortBy: string, order: SortOrder}, *} options
 * @param {*} conversationList
 */
export default function filterConversations(options, conversationList) {
  const conversationFilter = options.filter || 'inbox';
  const currentUserId = options.currentUserId || ''; // NOTE: We want a blank string and not null/undefined due to those matching on id: null.
  const sortConversationsBy = options.sortBy || { ...conversationsInitialState.sortBy };

  // Sort Conversations
  const sortedConversationList = stateWithSortedConversations(sortConversationsBy, conversationList);

  // Available Filter Predicates
  const assignedToUser = pathEq(['assignee', 'id'], currentUserId);
  const unassigned = propEq('assignee', null);
  const unrepliable = propEq('can_reply', false);
  const open = propEq('state', 'open');
  const waiting = propEq('state', 'waiting');
  const archived = propEq('state', 'archived');
  const starred = propEq('starred', true);
  const ongoing = anyPass([open, waiting]);
  const upForGrabs = anyPass([unassigned, assignedToUser]);

  const filterPredicates = [];
  switch (true) {
    case /^inbox/.test(conversationFilter): // NOTE: Inbox is state: open, assigned to user or unassigned.
      filterPredicates.push(open, upForGrabs);
      break;
    case /^assignee_id:.*ongoing$/.test(conversationFilter):
      filterPredicates.push(assignedToUser, ongoing);
      break;
    case /^assignee_id:.*starred_by/.test(conversationFilter):
      filterPredicates.push(assignedToUser, starred);
      break;
    case /^assignee_id:.*state:archived/.test(conversationFilter):
      filterPredicates.push(assignedToUser, archived);
      break;
    case /^assignee_id:[a-f0-9]{24}$/.test(conversationFilter):
      filterPredicates.push(assignedToUser);
      break;
    case /^ongoing$/.test(conversationFilter):
      filterPredicates.push(ongoing);
      break;
    case /^starred_by/.test(conversationFilter):
      filterPredicates.push(starred);
      break;
    case /^state:archived$/.test(conversationFilter):
      filterPredicates.push(archived);
      break;
    case /^open$/.test(conversationFilter):
      filterPredicates.push(open);
      break;
    case /^waiting$/.test(conversationFilter):
      filterPredicates.push(waiting);
      break;
    case /^unassigned$/.test(conversationFilter):
      filterPredicates.push(unassigned);
      break;
    case /^unrepliable$/.test(conversationFilter):
      filterPredicates.push(unrepliable);
      break;
    case /^all$/.test(conversationFilter):
    default:
      break;
  }

  return values(map(
    prop('id'),
    filter(allPass(filterPredicates))(sortedConversationList)
  ));
}
