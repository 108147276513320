const loadingFacts = [
  {
    fact: 'Only 6% of apps have 50 reviews or more.',
    source: 'Alchemer study',
  }, {
    fact: '70% of companies say it is cheaper to retain a customer than it is to acquire one.',
    source: 'Econsultancy',
  }, {
    fact: 'It costs 6x more to acquire a customer than it does to retain one.',
    source: 'Bain & Company',
  }, {
    fact: '40% of customers try an app for at least a week before leaving a review.',
    source: 'Alchemer study',
  }, {
    fact: 'A 5% increase in customer retention can increase profits by anywhere between 25% and 125%.',
    source: 'Gartner',
  }, {
    fact: '79% of customers will only retry an app once or twice if it failed the first time.',
    source: 'Compuware',
  }, {
    fact: 'Poor customer service outranks price as the leading cause of customer churn.',
    source: 'Alchemer study',
  }, {
    fact: '96% of unhappy customers never complain, 91% will simply leave and never come back.',
    source: '1st Financial Training Services',
  }, {
    fact: 'News of poor customer experiences travels twice as far as news of positive experiences.',
    source: 'White House Office of Consumer Affairs',
  }, {
    fact: '70% of buying experiences are based on how the customer feels they are being treated.',
    source: 'McKinsey',
  }, {
    fact: 'A loyal customer is worth up to 10x the transaction value of their first purchase.',
    source: 'White House Office of Consumer Affairs',
  }, {
    fact: 'Prospects are twice as likely to download a 4-star app than a 3-star app.',
    source: 'Alchemer study',
  }, {
    fact: '92% of individuals report app store ratings as a key driver of app discovery.',
    source: 'Alchemer study',
  }, {
    fact: '33% of prospective customers read at least seven reviews before downloading a paid app.',
    source: 'Alchemer study',
  }, {
    fact: 'A jump from two stars to three stars can increase app store conversion by 540%.',
    source: 'Alchemer study',
  }, {
    fact: '96 of the 100 top grossing Android apps have at least a 4-star rating.',
    source: 'Alchemer study',
  }, {
    fact: 'Only 50% of individuals would consider downloading an app with a 3-star rating.',
    source: 'Alchemer study',
  }, {
    fact: 'Unprompted reviews are 56% more likely to be based on negative experiences than positive ones.',
    source: 'Alchemer study',
  }, {
    fact: 'There are now more users of the mobile web than the desktop web.',
    source: 'comScore',
  }, {
    fact: 'Intelligent and respectful messaging is a leading driver of retention and engagement.',
    source: 'Appirate',
  }, {
    fact: 'Annoying push notifications are the leading cause of app uninstalls.',
    source: 'Appirate',
  }, {
    fact: '70% of unhappy customers can be won back if their complaint is resolved.',
    source: 'Lee Resources',
  },
];
export default loadingFacts;
