import React from 'react';
import numeral from 'numeral';
import { clone } from 'ramda';

import HoverCard from 'src/apptentive_ui/styled/charts/HoverCard';
import { HistogramDataPoint, HistogramTimeSeries } from 'src/types/core';

interface HistogramHoverData extends HistogramDataPoint {
  color: string;
  label: string;
}

interface HoverCardData {
  ratiogram: boolean;
  title: string;
  series: HistogramHoverData[];
}

const VOLUME_FORMAT = '0.[00]a';

export const renderPercentHovercard = (props: { summaryTitle: string; hiddenSeries: HistogramTimeSeries[] }) => (
  data: HoverCardData
) => {
  // Don't show the hovercard for zero values.
  if (data.series.every((s) => s.y === 0 || Number.isNaN(s.y))) {
    return null;
  }

  // Get corresponding datapoint for 'hidden' series
  const dataPointPosition = data.series[0].meta.positionInSeries;
  const { hiddenSeries } = props;
  const elements = hiddenSeries.map((series) => ({
    color: series.color,
    label: series.label,
    ...series.data[dataPointPosition],
  }));
  const existingSeries = clone(data.series).concat(elements);
  const series = existingSeries.map((s) => ({ ...s, y: s.meta.originalDatum.y }));
  const originalTotal = series[0].meta.originalTotal || 1;
  const formatters = {
    detailFormatter: (y: number) => {
      // PBI-94: Data is inconsistent, we need to normalize it.
      const value = y / originalTotal;
      if (value > 1) {
        return '100%';
      }
      if (value < 0) {
        return '0%';
      }
      return numeral(value).format('0%');
    },
    subDetailFormatter: (y: number) => {
      // PBI-94: Data is inconsistent, we need to normalize it.
      if (y < 0) {
        return '(0)';
      }
      return `(${numeral(y).format(VOLUME_FORMAT)})`;
    },
    summaryInfoFormatter: () => `Total: ${numeral(originalTotal).format(VOLUME_FORMAT)}`,
  };
  return <HoverCard {...props} {...formatters} {...data} series={series as any} />;
};

export const renderVolumeHovercard = (formatterProps: { summaryTitle: string }) => (data: HoverCardData) => {
  // Don't show the hovercard for zero values.
  if (data.series.every((s) => s.y === 0 || Number.isNaN(s.y))) {
    return null;
  }

  // Override the series with the originalDatum in metadata. This will show the raw count of yes/no
  // love dialog clicks in the hover card and the hover display will be consistent b/t the ratio
  // and the volume charts. Without this the count in the volume chart of "don't love" will display as
  // a negative number. And on the ratio chart, the hover value will display as a 1 or 0 since the actual
  // plotted values are decimal values between 0 and 1.
  const series = data.series.map((s) => ({
    ...s,
    y: s.meta.originalDatum.y,
  }));
  const formatters = {
    detailFormatter: (y: number) => {
      // PBI-94: Data is inconsistent, we need to normalize it.
      if (y < 0) {
        return 0;
      }
      return y;
    },
    subDetailFormatter: (y: number, total: number) => {
      // PBI-94: Data is inconsistent, we need to normalize it.
      const value = y / total;
      if (value > 1) {
        return '100%';
      }
      if (value < 0) {
        return '0%';
      }
      return numeral(value).format('0%');
    },
    summaryInfoFormatter: (tooltipData: any) => {
      const total = tooltipData.reduce((sum: number, { y }: { y: number }) => sum + Math.abs(y), 0);
      return `Total: ${numeral(total).format(VOLUME_FORMAT)}`;
    },
  };
  return <HoverCard {...formatterProps} {...formatters} {...data} series={series as any} />;
};
