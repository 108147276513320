import { isDevMode } from 'src/utils/environment';
import { InsightsExportWs, EventHandler } from './insights-export-ws';
import { ExportInsightData } from '../insights-service';
import { ExportType, RespState, ExcludeExportFields } from './insights-export-ws.types';

let instance: InsightsExportManager | null = null;

interface InitExportOptions {
  params: ExportInsightData & { userId: string };
  type: ExportType;
  onComplete: EventHandler<RespState & { url: string }>;
  onError: EventHandler;
}

export class InsightsExportManager {
  static getInstance = getInstance;

  sessions: InsightsExportWs[] = [];

  async createSession(): Promise<InsightsExportWs> {
    const client = new InsightsExportWs();
    this.sessions.push(client);

    await new Promise((resolve) => client.onSocketOpen(resolve));
    client.onSocketClose(() => this.destroyClient(client));

    return client;
  }

  initExportSession({ params, type, ...options }: InitExportOptions) {
    const retry = () => this.initExportSession({ params, type, ...options });

    const fields_to_exclude: ExcludeExportFields['fields_to_exclude'] = [];

    this.createSession().then((client: InsightsExportWs) => {
      client
        .startExport({ ...params, fields_to_exclude }, type)
        .onExportComplete((data) => {
          options.onComplete(data);
          this.destroyClient(client);
        })
        .onExportDownstreamFail(() => {
          setTimeout(retry, 1000);
          this.destroyClient(client);
        })
        .onExportError((err) => {
          options.onError(err);
          this.destroyClient(client);
        });
    });
  }

  destroyClient(client: InsightsExportWs) {
    this.sessions.splice(this.sessions.indexOf(client), 1);
    client.destroy();
  }

  destroy() {
    this.sessions.forEach((client) => client.destroy());
  }
}

function getInstance() {
  if (!instance) {
    instance = new InsightsExportManager();
    if (isDevMode()) {
      Object.assign(window, { InsightsExportManager: instance });
    }
  }
  return instance;
}
