import { FC, useEffect } from 'react';
import { $createTextNode, $isParagraphNode, LineBreakNode, ParagraphNode, RootNode, TextNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type voidFn = () => void;

const handleRootExtraParagraphs = (rootNode: RootNode) => {
  if (rootNode.getChildrenSize() <= 1) {
    return;
  }

  const paragraphs = rootNode.getChildren().filter((n) => $isParagraphNode(n)) as ParagraphNode[];

  if (paragraphs.length <= 1) {
    return;
  }

  paragraphs.slice(1).forEach((extraParagraph) => {
    if (extraParagraph.getTextContent()) {
      if (paragraphs[0].getTextContent()) {
        paragraphs[0].append($createTextNode(' '));
      }

      extraParagraph.getChildren().forEach((child) => {
        paragraphs[0].append(child);
      });
    }

    extraParagraph.remove();
  });

  if (!paragraphs[0].getTextContent()) {
    paragraphs[0].remove();
  }
};

const handleTextNode = (node: TextNode) => {
  // Single-line editors have no shortcut to add/remove strikethrough, so we remove it from pasted
  // text for consistency's sake.
  if (node.hasFormat('strikethrough')) {
    node.toggleFormat('strikethrough');
  }
};

const handleLineBreak = (node: LineBreakNode) => {
  node.remove();
};

export const TransformPlugin: FC<{ isDisabled?: boolean; isSingleLine?: boolean }> = ({
  isDisabled = false,
  isSingleLine = false,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (isDisabled) {
      editor.setEditable(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    // Set the editor to not editable - it won't take focus while in that state
    editor.setEditable(false);
    const removeListeners: voidFn[] = [];

    if (isSingleLine) {
      removeListeners.push(
        editor.registerRootListener((root) => {
          if (!root) return;

          root.style.whiteSpace = 'pre';
        }),
      );

      const removeListenerRoot = editor.registerNodeTransform(RootNode, handleRootExtraParagraphs);
      const removeListenerTextNode = editor.registerNodeTransform(TextNode, handleTextNode);
      const removeListenerLineBreak = editor.registerNodeTransform(LineBreakNode, handleLineBreak);

      removeListeners.push(removeListenerRoot, removeListenerTextNode, removeListenerLineBreak);
    }

    // Set the editor back to editable a frame later
    requestAnimationFrame(() => editor.setEditable(true));

    // eslint-disable-next-line consistent-return
    return () => {
      removeListeners.forEach((removeListener) => removeListener());
    };
  }, [editor, isDisabled]);

  return null;
};
