import { atriumCall, report } from './util';

export const fetchOrganization = orgId => atriumCall(`/organizations/${orgId}`, {
  error: e => report('[API] Error fetching organization', orgId, e),
});

export const fetchOrganizations = userId => atriumCall(`/users/${userId}/organizations`, {
  error: e => report('[API] Error fetching organizations for', userId, e),
});

export const fetchOrganizationApps = (userId, orgId) => atriumCall(`/users/${userId}/organizations/${orgId}/apps`, {
  error: e => report('[API] Error fetching organization apps for', orgId, 'with user', userId, e),
});

export const fetchOrganizationMembers = (userId, orgId) => atriumCall(`/users/${userId}/organizations/${orgId}/members`, {
  error: e => report('[API] Error fetching organization members for', orgId, 'with user', userId, e),
});

export const createApp = (orgId, body) => atriumCall(`/organizations/${orgId}/apps`, {
  method: 'POST',
  body: JSON.stringify(body),
  error: e => report('[API] Error creating App for org', orgId, 'with body', body, e),
});

export const createOrganization = (body) => atriumCall('/organizations', {
  method: 'POST',
  body: JSON.stringify(body),
  error: e => report('[API] Error creating Organization with body', body, e),
});

export const updateOrganization = (orgId, body) => atriumCall(`/organizations/${orgId}`, {
  method: 'PUT',
  body: JSON.stringify(body),
  error: e => report('[API] Error updating Organization with body', body, e),
});

export const deleteOrganization = (orgId, confirmation) => atriumCall(`/organizations/${orgId}?confirmation=${confirmation}`, {
  method: 'DELETE',
  error: e => report('[API] Error deleting Organization with confirmation', confirmation, e),
});
