// bd859a
const randomPair = () => Math.random()
  .toString(16)
  .substring(2, 8);

// bd859a-c73868-2676a3-dsa312
export const generateUUID = (n = 4) => {
  const ids = [];
  // eslint-disable-next-line
  while (n--) ids.push(randomPair());
  return ids.join('-');
};
