import React, { useRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { bemPrefix } from 'src/utils';
import { InputText, InputTextProps } from '../input';
import { IconName, Icon } from '../icons';
import './search-input.scss';

export interface SearchRef {
  getValue(): string;
}

export interface SearchInputProps extends Pick<InputTextProps, 'placeholder'> {
  className?: string;
  iconName?: IconName;
  submitSearch?: boolean;
  text?: string;
  onSearch?(text: string): void;
  searchRef?: React.RefObject<SearchRef>;
  onChange?: InputTextProps['onChange'];
}

const bem = bemPrefix('search-input');

export const SearchInput: React.FC<SearchInputProps> = ({
  className = '',
  iconName = 'magnifying',
  submitSearch = true,
  text,
  placeholder,
  onChange,
  searchRef,
  onSearch = () => null,
}) => {
  const inputEl = useRef<HTMLInputElement | null>(null);

  const onSubmit = useCallback(() => {
    if (inputEl.current) {
      const value = inputEl.current.value.trim();
      onSearch(value);
    }
  }, [inputEl.current, onSearch]);

  const onEnter = useCallback(
    (_e: React.KeyboardEvent) => {
      if (submitSearch) {
        onSubmit();
      }
    },
    [submitSearch, onSubmit]
  );

  useImperativeHandle(
    searchRef,
    () => ({
      getValue: () => (inputEl.current ? inputEl.current.value : ''),
    }),
    [searchRef, inputEl.current]
  );

  useEffect(() => {
    if (text !== undefined && inputEl.current) {
      inputEl.current.value = text;
    }
  }, [inputEl.current && inputEl.current.value, text]);

  return (
    <div className={`${bem()} ${className}`}>
      <Icon className={bem('icon')} name={iconName} />
      <InputText
        inputClassName={bem('input')}
        type="search"
        placeholder={placeholder}
        onSubmit={onSubmit}
        onChange={onChange}
        onEnter={onEnter}
        innerRef={inputEl}
        preventDefault
      />
    </div>
  );
};

SearchInput.displayName = 'SearchInput';
