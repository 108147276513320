import React, { Component } from 'react';
import PropTypes from 'prop-types';

import tagPropType from 'src/proptypes/tag';
import { bemPrefix } from 'src/utils';
import { SimplePopover, Icon } from 'src/components/molecules';
import { CreateTagFormContainer } from './create_tag_form';
import { Tags } from './tags';

// styles
import '../styles/index.scss';
import './tag-editor.scss';

const bem = bemPrefix('tag-editor');

export class TagsEditor extends Component {
  state = {
    showCreateTagForm: false,
  };

  addRef = React.createRef();

  static propTypes = {
    tags: PropTypes.arrayOf(tagPropType),
    addTag: PropTypes.func,
    renameTag: PropTypes.func,
    removeTag: PropTypes.func,
  };

  toggleCreateTagForm = () => {
    this.setState({ showCreateTagForm: !this.state.showCreateTagForm });
  };

  render() {
    const {
      tags,
      addTag,
      renameTag,
      removeTag,
    } = this.props;

    return (
      <div className={bem()}>
        <Icon className={bem('icon')} name="plus" forwardRef={this.addRef} onClick={this.toggleCreateTagForm} />
        {this.state.showCreateTagForm && (
          <SimplePopover
            className={bem('add-popover')}
            isOpen={this.state.showCreateTagForm}
            targetEl={this.addRef.current}
            onClose={this.toggleCreateTagForm}
          >
            <CreateTagFormContainer tags={tags} onChange={addTag} />
          </SimplePopover>
        )}
        {tags.length ? (
          <Tags
            tags={tags}
            onRetry={addTag}
            updatable
            onRename={renameTag}
            removable
            onRemove={removeTag}
          />
        ) : (
          <span className="no-tags">no tags...</span>
        )}
      </div>
    );
  }
}

export default TagsEditor;
