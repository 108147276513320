import styled from 'styled-components';
import { colors } from '../../theme';

import Normalized from './Normalized';

const Button = styled(Normalized)`
  border: 1px solid ${colors.smoke};
  background-color: ${colors.snow};
  cursor: pointer;
  padding: 0 0.8em;
  box-shadow: 0 1px 0 ${colors.nimbus} inset, 0 1px 0 ${colors.smoke};
  height: 2.35714em;
  border-radius: 0.24em;
  transition: 0.15s background-color, 0.15s border-color, 0.15s box-shadow, 0.15s -webkit-box-shadow;
  margin: 0 0.2em 0.5em 0;
  outline: 0;
  vertical-align: baseline;
  box-sizing: border-box;
`;

export default Button;
