import { AppEventList } from './events.types';

export interface EventsState {
  isLoading: boolean;
  isCreating: boolean;
  data: AppEventList[];
  error: string;
}

export const initialState: EventsState = {
  isLoading: false,
  isCreating: false,
  data: [],
  error: '',
};
