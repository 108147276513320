import { RECEIVED_NOTICES } from '../actions';

export default (state = false, action = {}) => {
  switch (action.type) {
    case RECEIVED_NOTICES:
      return true;
    default:
      return state;
  }
};
