import React from 'react';
import PropTypes from 'prop-types';
import { VictoryChart, VictoryAxis, VictoryLabel } from 'victory';
import { SizeMe } from 'react-sizeme';

import { UIError } from '../../overlays';
import { colors as themeColors } from '../../../theme';
import chartTheme from './theme';
import boundsProp from '../utils/rangeBoundsProp';

const safeFormat = formatter => x => {
  try {
    return formatter(x);
  } catch (e) {
    return x;
  }
};
const HistogramError = function HistogramError({
  xFormat, yFormat, xMinBounds, xMaxBounds, yMinBounds, yMaxBounds,
}) {
  return (
    <>
      <SizeMe>
        {({ size }) => (
          <div>
            <VictoryChart
              height={154}
              width={size.width}
              padding={{ bottom: 24 }}
              domainPadding={{ x: [70, 20], y: 0 }}
              theme={chartTheme}
              domain={{ x: [xMinBounds[0], xMaxBounds[0]], y: [yMinBounds[0], yMaxBounds[0]] }}
            >
              <VictoryAxis
                data-test="y-axis"
                dependentAxis
                crossAxis={false}
                tickFormat={safeFormat(yFormat)}
                tickLabelComponent={<VictoryLabel data-test="y-axis-label" dx={20} dy={11} />}
                tickCount={5}
                style={{
                  axis: { strokeWidth: 0 },
                  grid: { stroke: themeColors.smoke, size: 5, strokeDasharray: 5 },
                  tickLabels: { padding: 0 },
                }}
              />
              <VictoryAxis
                data-test="x-axis"
                className="axis x-axis"
                tickLabelComponent={<VictoryLabel data-test="x-axis-label" />}
                tickFormat={safeFormat(xFormat)}
                style={{
                  ticks: { stroke: themeColors.smoke, size: 10 },
                  tickLabels: { padding: 5 },
                }}
                orientation="bottom"
                offsetY={24}
                tickCount={5}
                fixLabelOverlap
              />
            </VictoryChart>
          </div>
        )}
      </SizeMe>
      <div className="overlay">
        <UIError />
      </div>
    </>
  );
};

HistogramError.propTypes = {
  /** formats the tick labels for the x-axis */
  xFormat: PropTypes.func,
  /** formats the tick labels for the y-axis */
  yFormat: PropTypes.func,
  /** The inner & outer bounds of the X Axis's maximum */
  xMaxBounds: boundsProp,
  /** The inner & outer bounds of the X Axis's minimum */
  xMinBounds: boundsProp,
  /** The inner & outer bounds of the Y Axis's maximum */
  yMaxBounds: boundsProp,
  /** The inner & outer bounds of the Y Axis's minimum */
  yMinBounds: boundsProp,
};

HistogramError.defaultProps = {
  xFormat: null,
  yFormat: null,
  xMaxBounds: [0, Infinity],
  xMinBounds: [0, -Infinity],
  yMaxBounds: [0, Infinity],
  yMinBounds: [0, -Infinity],
};

export default HistogramError;
