import { OrganizationApp } from 'src/reducers/organizations';
import { Platform, SdkVersion } from 'src/types/core';

export type ExtendedOrganizationApp = OrganizationApp & { isWarningVersion?: boolean };

const MAU_FOR_WEB_PLATFORM = -1

export const getAppLatestSDKVersion = (app: OrganizationApp) =>
  (app.latestSDKVersion && Number(app.latestSDKVersion.split('.').slice(0, 2).join('.'))) || 0;

export const getImtSDKVersionNumber = (imtSdkVersion: SdkVersion) => Number(imtSdkVersion.replace('v', ''));

export const getFormattedMau = (app: OrganizationApp, isWeb: boolean) => {
  if (isWeb) {
    return MAU_FOR_WEB_PLATFORM;
  }

  return app.mau ? Math.round(app.mau) : 0
};

export const getExtendedOrgApp = (app: OrganizationApp, imtSdkVersion?: SdkVersion): ExtendedOrganizationApp => {
  const isWeb = app.platform === Platform.Web;
  const isWarningVersion = !isWeb && imtSdkVersion && getAppLatestSDKVersion(app) < getImtSDKVersionNumber(imtSdkVersion);

  return {
    ...app,
    mau: getFormattedMau(app, isWeb),
    isWarningVersion,
  };
};
