import { OrganizationApp } from "src/reducers/organizations";
import { eventNameToHuman } from "src/utils/events";
import { AppEventList, SimpleEventItem } from "src/reducers/events";
import { SearchableSelectOption } from "../select";

const idToItem = (id: string): SearchableSelectOption => ({
  id,
  label: id,
  title: id,
  name: id,
});

export const getSelectedItems = (
  selectedIds: string[] | [string, string],
  isMultiAppInteraction: boolean,
  eventsTitleList: SimpleEventItem[],
  options: SearchableSelectOption[],
  multipleEvents: AppEventList[],
  apps: OrganizationApp[],
): SearchableSelectOption[] => {
  const labelToAppIdMap = new Map<string, OrganizationApp[]>();
  const appIdToDetailsMap = new Map<string, OrganizationApp>();
  apps.forEach((app) => {
    appIdToDetailsMap.set(app.id, app);
  });

  multipleEvents.forEach(({ app_id, items }) => {
    if (!appIdToDetailsMap.has(app_id)) {
      return;
    }
    const appDetails = appIdToDetailsMap.get(app_id) as OrganizationApp;
    items.forEach(({ label }) => {
      const humanLabel = eventNameToHuman(label);

      if (!labelToAppIdMap.has(humanLabel)) {
        labelToAppIdMap.set(humanLabel, []);
      }
      labelToAppIdMap.get(humanLabel)?.push(appDetails);
    });
  });

  const multiAppsEvents = eventsTitleList?.map(({ label, ...e }) => ({
    ...e,
    id: label,
    label: eventNameToHuman(label),
    title: eventNameToHuman(label),
    apps: labelToAppIdMap.get(eventNameToHuman(label)) || [],
  }));

  const eventOptions: SearchableSelectOption[] | undefined = isMultiAppInteraction ? multiAppsEvents : options;
  const existedOptions = eventOptions?.filter(({ id }) => selectedIds.includes(id));
  return selectedIds.map((id: string) => existedOptions?.find((item) => item.id === id) || idToItem(id));
};
