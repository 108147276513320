import { __, merge, without, ifElse, append, contains } from 'ramda';
import * as actions from '../actions/groups';

const initialState = {
  loading: true,
  fetchError: false,
  groups: [],
  groupSelected: [],
  id: '',
  conversations: [],
};

let group;
const groups = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_FETCH_GROUP:
      return merge(state, {
        loading: true,
        fetchError: false,
        id: action.payload.groupId,
      });
    case actions.REQUEST_FETCH_GROUP_CONVERSATIONS:
    case actions.REQUEST_FETCH_GROUPS:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.RECEIVE_FETCH_GROUPS:
      return merge(state, {
        loading: false,
        fetchError: false,
        groups: action.payload,
      });
    case actions.RECEIVE_FETCH_GROUP:
    case actions.RECEIVE_ADD_CONVERSATION_TO_GROUP:
      group = state.groups.concat(action.payload);
      return merge(state, {
        loading: false,
        fetchError: false,
        groups: group,
      });
    case actions.RECEIVE_REMOVE_CONVERSATION_FROM_GROUP:
      group = state.groups.find(g => g.id === action.payload.id);
      group.conversations = action.payload.conversations;
      state.conversations = state.conversations.filter(c => c.id !== action.meta.conversationId);
      return merge(state, {
        loading: false,
        fetchError: false,
      });
    case actions.RECEIVE_FETCH_GROUP_CONVERSATIONS:
      return merge(state, {
        loading: false,
        fetchError: false,
        conversations: action.payload,
      });
    case actions.ERRORED_FETCH_GROUP:
      return merge(state, {
        loading: false,
        fetchError: true,
        id: '',
      });
    case actions.ERRORED_ADD_CONVERSATION_TO_GROUP:
    case actions.ERRORED_DELETE_GROUP:
    case actions.ERRORED_FETCH_GROUP_CONVERSATIONS:
    case actions.ERRORED_FETCH_GROUPS:
    case actions.ERRORED_REMOVE_CONVERSATION_FROM_GROUP:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    case actions.RECEIVE_DELETE_GROUP: {
      const filteredGroups = state.groups.filter((g) => g.id !== action.payload.groupId);
      return {
        ...state,
        groups: filteredGroups,
        showGroupActions: false,
        loading: false,
        fetchError: false,
      };
    }
    case actions.TOGGLE_GROUP_SELECTED: {
      const toggleSelected = ifElse(
        contains(action.payload.groupId, __),
        without(action.payload.groupId, __),
        append(action.payload.groupId, __)
      );

      const groupSelected = toggleSelected(state.groupSelected);

      return {
        ...state,
        groupSelected,
        loading: false,
        showGroupActions: groupSelected.length > 0,
      };
    }
    default:
      return state;
  }
};

export default groups;
