import React, { useEffect, useState } from 'react';
import { bemPrefix } from 'src/utils';
import { AppEventList, SimpleEventItem } from 'src/reducers/events';
import { eventNameToHuman } from 'src/utils/events';
import { InteractionType } from 'src/types/core';
import { OrganizationApp } from 'src/reducers/organizations';
import { Icon } from '../icons';

import './table-option.scss';

const bem = bemPrefix('table-option');
const noop = () => null;

export interface TableOptionProps {
  apps?: OrganizationApp[];
  multipleEvents?: AppEventList[];
  eventsTitleList?: SimpleEventItem[];
  searchQuery?: string;
  tableOptionRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  type: InteractionType;
  onMultipleSelectItem?: (selected: SimpleEventItem) => void;
  isMultipleOptionDisabled?: (multipleOptions: SimpleEventItem) => boolean;
}

const APPS_PER_PAGE = 3;
const ITEMS_PER_LOAD = 5;

export const TableOption: React.FC<TableOptionProps> = ({
  apps = [],
  multipleEvents = [],
  eventsTitleList = [],
  searchQuery,
  tableOptionRef,
  isOpen,
  type,
  onMultipleSelectItem = noop,
  isMultipleOptionDisabled = noop,
}) => {
  const [page, setPage] = useState(0);
  const [checkAppEvent, setCheckAppEvent] = useState(0);
  const [loadedCount, setLoadedCount] = useState(ITEMS_PER_LOAD);
  const [filteredEvents, setFilteredEvents] = useState<SimpleEventItem[] | undefined>(eventsTitleList);

  const sortCheckedEventsForApp = (checkedEventsForApp: AppEventList[], apps: OrganizationApp[]) => {
    const appIdToIndexMap = new Map(apps.map((app, index) => [app.id, index]));
    return checkedEventsForApp.sort((a, b) => {
      const indexA = appIdToIndexMap.get(a.app_id);
      const indexB = appIdToIndexMap.get(b.app_id);
      if (indexA === undefined || indexB === undefined) {
        return 0;
      }
      return indexA - indexB;
    });
  };

  const sortedCheckedEventsForApp = sortCheckedEventsForApp(multipleEvents, apps);
  const checkedEventsForApp = sortedCheckedEventsForApp?.slice(checkAppEvent, checkAppEvent + APPS_PER_PAGE);

  useEffect(() => {
    if (searchQuery && eventsTitleList) {
      const filtered = eventsTitleList.filter((item) =>
        (item.label as string).toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(eventsTitleList);
    }
  }, [searchQuery, eventsTitleList]);

  const visibleEvents = filteredEvents?.slice(0, loadedCount);

  const handlePrev = () => {
    if (APPS_PER_PAGE > apps.length) {
      return;
    }
    setPage((prev) => (prev - APPS_PER_PAGE < 0 ? 0 : prev - APPS_PER_PAGE));
    setCheckAppEvent((prev) => (prev - APPS_PER_PAGE < 0 ? 0 : prev - APPS_PER_PAGE));
  };

  const handleNext = () => {
    if (APPS_PER_PAGE > apps.length) {
      return;
    }
    setPage((prev) =>
      prev + APPS_PER_PAGE > apps.length - APPS_PER_PAGE ? apps.length - APPS_PER_PAGE : prev + APPS_PER_PAGE,
    );
    setCheckAppEvent((prev) =>
      prev + APPS_PER_PAGE > multipleEvents.length - APPS_PER_PAGE
        ? multipleEvents.length - APPS_PER_PAGE
        : prev + APPS_PER_PAGE,
    );
  };

  const handleLoadMore = () => {
    setLoadedCount(eventsTitleList.length);
  };

  const handleSelect = (option: SimpleEventItem) => {
    onMultipleSelectItem(option);
  };

  const disabledPrevArrow = page === 0;
  const disabledNextArrow = page === apps.length - APPS_PER_PAGE || apps.length < APPS_PER_PAGE;

  const showLoadMoreBtn =
    filteredEvents && filteredEvents.length > ITEMS_PER_LOAD && visibleEvents && visibleEvents.length <= ITEMS_PER_LOAD;

  const renderIcons = (title: string) => {
    return checkedEventsForApp?.map((app) => {
      const hasTitle = app.items.some((item) => eventNameToHuman(item.label) === title);
      return (
        <td key={app.app_id} className={bem('app-name')}>
          <Icon name={hasTitle ? 'checkMark' : 'crossMark'} className="icon" />
        </td>
      );
    });
  };

  const visibleApps = apps?.slice(page, page + APPS_PER_PAGE);
  const hasAppsOptions =
    visibleApps?.length > 0 &&
    type &&
    type !== InteractionType.EnjoymentDialog &&
    type !== InteractionType.RatingDialog &&
    type !== InteractionType.Initiator;

  return (
    <div ref={tableOptionRef} className={bem('', { visible: isOpen })}>
      <table className={bem('table')}>
        <thead className={bem('header')}>
          <tr>
            <th className={bem('title')}>Event Name</th>
            {hasAppsOptions && (
              <>
                <th className={bem('control')}>
                  <Icon
                    name="leftArrow"
                    className={bem('arrow', disabledPrevArrow ? 'disabled' : '')}
                    onClick={handlePrev}
                  />
                </th>
                {visibleApps?.map((app, index) => (
                  <th key={index} className={bem('app-name')}>
                    {app.title} <br /> {app.platform}
                  </th>
                ))}
                <th className={bem('control')}>
                  <Icon
                    name="rightArrow"
                    className={bem('arrow', disabledNextArrow ? 'disabled' : '')}
                    onClick={handleNext}
                  />
                </th>
              </>
            )}
          </tr>
        </thead>
      </table>
      {filteredEvents && filteredEvents.length > 0 ? (
        <div className={bem('scrollable-table')}>
          <table className={bem('table')}>
            <tbody>
              {visibleEvents?.map((item, index) => (
                <tr
                  key={index}
                  className={`${bem('event-row', isMultipleOptionDisabled(item) ? 'disabled' : '')}`}
                  onClick={() => !isMultipleOptionDisabled(item) && handleSelect(item)}
                >
                  <td className={bem('event')}>{item.label.replace('local#app#', '')}</td>
                  {hasAppsOptions && (
                    <>
                      <td className={bem('control')} />
                      {renderIcons(item.label.replace('local#app#', ''))}
                      <td className={bem('control')} />
                    </>
                  )}
                </tr>
              ))}
            </tbody>
            {showLoadMoreBtn && (
              <tfoot>
                <tr>
                  <td colSpan={6} className={bem('load-more')} onClick={handleLoadMore}>
                    Load More...
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      ) : (
        <tbody>
          <tr>
            <td colSpan={6} className="no-option">
              No Options
            </td>
          </tr>
        </tbody>
      )}
    </div>
  );
};
