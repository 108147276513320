import React from 'react';
import { Icon } from 'src/components/molecules';

import './not-configured-warning.scss';

interface NotConfiguredLoveDialogProps {
  ratingFlowIntegrated: boolean;
  isReporter: boolean;
  currentAppId: string;
}

export const NotConfiguredLoveDialogWarning: React.FC<NotConfiguredLoveDialogProps> = ({
  ratingFlowIntegrated,
  isReporter,
  currentAppId,
}) => {
  if (ratingFlowIntegrated) {
    return null;
  }
  if (isReporter) {
    return null;
  }

  return (
    <div className="not-configured-warning">
      <Icon name="warningCircle" />
      <p>
        <span className="text">The Love Dialog hasn&#39;t been displayed yet to customers in your app. On the </span>
        <a href={`/apps/${currentAppId}/love-prompt`}>Interactions</a>{' '}
        <span className="text">Page you can adjust your targeting and ensure the Love Dialog is active.</span>
      </p>
    </div>
  );
};
