import { FC, useEffect } from 'react';
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR, FOCUS_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

export const FocusEventPlugin: FC<{ onBlur: () => void; onFocus: () => void }> & { EVENT_SYNTHETIC_BLUR: string } = ({
  onBlur,
  onFocus,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      BLUR_COMMAND,
      () => {
        onBlur();

        return false;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [onBlur]);

  useEffect(() => {
    return editor.registerCommand(
      FOCUS_COMMAND,
      () => {
        onFocus();

        return false;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [onBlur]);

  useEffect(() => {
    return editor.registerRootListener((rootElement, prevRootElement) => {
      if (rootElement) {
        rootElement.addEventListener(FocusEventPlugin.EVENT_SYNTHETIC_BLUR, onBlur);
      }

      if (prevRootElement) {
        prevRootElement.removeEventListener(FocusEventPlugin.EVENT_SYNTHETIC_BLUR, onBlur);
      }
    });
  });

  return null;
};

FocusEventPlugin.EVENT_SYNTHETIC_BLUR = 'richtextsyntheticblur';
