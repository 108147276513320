import React from 'react';
import { bemPrefix } from 'src/utils';

import './info-text.scss';

interface InfoTextProps {
  className?: string;
  text: React.ReactNode;
  children?: React.ReactNode;
}

type Props =
  | InfoTextProps
  | {
      className?: string;
      text?: string;
      children: React.ReactNode;
    };

const bem = bemPrefix('info-text');

export const InfoText: React.FC<Props> = ({ className = '', text, children }) => (
  <div className={`${bem()} ${className}`}>{children || text}</div>
);
