import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { equals } from 'ramda';
import { bemPrefix } from 'src/utils';
import { daysAgo, Time, TimeMoment } from 'src/utils/time';
import { Icon } from 'src/components/molecules/icons';

import '../../styles/molecules/react-datepicker.css';
import './date-range-custom-selector.scss';

export interface DateRangeCustomSelectorProps {
  startDate: number;
  endDate: number;
  onRangeChange(startDate: number, endDate: number): void;
  minDate?: Date;
}

enum PickerName {
  START = 'start',
  END = 'end',
}

interface DateRangeCustomSelectorState {
  isPickerOpen: PickerName | null;
  startDate: TimeMoment;
  endDate: TimeMoment;
}

const bem = bemPrefix('date-range-custom-selector');

const momentifyDates = (startDate: number, endDate: number | undefined = undefined) => ({
  startDate: Time.create(startDate).startOf('day'),
  endDate: Time.create(endDate).endOf('day'),
});

export class DateRangeCustomSelector extends React.PureComponent<
  DateRangeCustomSelectorProps,
  DateRangeCustomSelectorState,
  ReactDatePickerProps
> {
  static momentifyDatesRanges = momentifyDates;

  static getDerivedStateFromProps(nextProps: DateRangeCustomSelectorProps, prevState: DateRangeCustomSelectorState) {
    const newValues = [nextProps.startDate, nextProps.endDate];
    const oldValues = [prevState.startDate.valueOf(), prevState.endDate.valueOf()];
    if (!equals(newValues, oldValues)) {
      return {
        ...prevState,
        ...momentifyDates(nextProps.startDate, nextProps.endDate),
      };
    }
    return null;
  }

  constructor(props: DateRangeCustomSelectorProps) {
    super(props);
    this.state = {
      ...momentifyDates(this.props.startDate || daysAgo(30).valueOf(), this.props.endDate),
      isPickerOpen: null,
    };
  }

  onOpenClick = (date: PickerName) => () => this.setState({ isPickerOpen: date });
  onCloseClick = () => this.setState({ isPickerOpen: null });

  handleChangeStart = (startDate: Date) => {
    this.handleChange({ startDate: Time.create(startDate.valueOf()), endDate: this.state.endDate });
  };

  handleChangeEnd = (endDate: Date) => {
    this.handleChange({ startDate: this.state.startDate, endDate: Time.create(endDate.valueOf()) });
  };

  handleChange = ({ startDate, endDate }: { startDate: TimeMoment; endDate: TimeMoment }) => {
    if (startDate.isAfter(endDate)) {
      const temp = startDate.clone().endOf('day');
      startDate = endDate.clone().startOf('day');
      endDate = temp;
    }
    startDate.startOf('day');
    this.setState({ startDate, endDate, isPickerOpen: null });

    this.props.onRangeChange(startDate.valueOf(), endDate.valueOf());
  };

  render() {
    const { isPickerOpen } = this.state;
    const getDateClassMods = (value: PickerName) => (isPickerOpen === value ? ['selected-picker'] : undefined);
    const getIconClassMods = (value: PickerName) => (isPickerOpen === value ? 'reversed-icon' : undefined);

    const dateProps = {
      startDate: this.state.startDate.toDate(),
      endDate: this.state.endDate.toDate(),
      maxDate: Time.create().toDate(),
      minDate: this.props.minDate,
      nextMonthButtonLabel: '',
      previousMonthButtonLabel: '',
      onClickOutside: this.onCloseClick,
    };

    return (
      <div className={`${bem()} custom-range`}>
        <div className={bem('input-start', getDateClassMods(PickerName.START))}>
          <DatePicker
            selected={dateProps.startDate}
            selectsStart
            onChange={this.handleChangeStart}
            open={this.state.isPickerOpen === PickerName.START}
            onInputClick={this.onOpenClick(PickerName.START)}
            {...dateProps}
          />
          <div className={bem('open-picker')} onClick={this.onOpenClick(PickerName.START)}>
            <Icon name="arrowDown" className={getIconClassMods(PickerName.START)} />
          </div>
        </div>
        <div className={bem('input-end', getDateClassMods(PickerName.END))}>
          <DatePicker
            selected={dateProps.endDate}
            selectsEnd
            onChange={this.handleChangeEnd}
            popperPlacement="bottom-end"
            calendarClassName="custom-range-end"
            open={this.state.isPickerOpen === PickerName.END}
            onInputClick={this.onOpenClick(PickerName.END)}
            {...dateProps}
          />
          <div className={bem('open-picker')} onClick={this.onOpenClick(PickerName.END)}>
            <Icon name="arrowDown" className={getIconClassMods(PickerName.END)} />
          </div>
        </div>
      </div>
    );
  }
}
