import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

const classes = (selected) => className({
  'range-option': true,
  selected,
});

const toId = (value) => value.toLowerCase().replace(/ |\(|\)/g, '_');

const RangeOption = ({ label, name, selected, onSelect, ...props }) => {
  const id = props.id || toId(label);

  return (
    <li className={classes(selected)}>
      <button type="button" onClick={onSelect}>
        <input name={name} type="radio" id={`option-${id}`} checked={selected} onChange={(e) => { e.preventDefault(); }} />
        <div className="check" />
        <label htmlFor={`option-${id}`}>{label}</label>
      </button>
    </li>
  );
};

RangeOption.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  id: PropTypes.string,
};

RangeOption.defaultProps = {
  label: '',
  name: '',
  selected: false,
  onSelect: () => {},
  id: '',
};

export default RangeOption;
