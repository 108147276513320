import { Action } from 'redux';
import { indexBy, uniq } from 'ramda';
import { UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import { UPDATE_DASHBOARD_DATE_RANGE } from 'src/actions';

import { InsightActions } from 'src/actions/insights/insights.action-types';
import { TagsActions } from 'src/actions/tags/tags.action-types';
import { Time } from 'src/utils/time';
import { isAbortErrorAction } from 'src/actions/utils';
import { TagModel } from 'src/services/tags-service';

import { initialState, InsightsState } from './insights.state';
import {
  resetStateDataPagination,
  invokeCurrentSearch,
  getRedistributedPhrases,
  getFlattenPhrases,
  getPhrase,
  updatePhraseStatus,
  getUpdatedInsightTags,
  getSentiment,
  getDestribution,
} from './insights.helpers';
import { IPhraseReduxState, SavedInsight, SentimentItem } from './insights.types';

type InsightReducerActions = Action<
  InsightActions | TagsActions | typeof UPDATE_CURRENT_APP_SUCCESS | typeof UPDATE_DASHBOARD_DATE_RANGE
> & {
  payload: any;
  meta?: any;
  error?: Error;
};

interface ISentimentDistribution {
  sentiment: string;
  count: number;
}

export const insightsReducers = (state: InsightsState = initialState, action: InsightReducerActions): InsightsState => {
  if (!action || isAbortErrorAction(action)) {
    return state;
  }
  switch (action.type) {
    case UPDATE_CURRENT_APP_SUCCESS:
    case InsightActions.INIT:
    case InsightActions.CLEANUP:
      return { ...initialState, savedInsights: state.savedInsights };
    case UPDATE_DASHBOARD_DATE_RANGE:
      return resetStateDataPagination(state);

    // aggs
    case InsightActions.FETCH_AGGS_PENDING:
      return {
        ...state,
        fetchAggsPending: true,
        fetchAggsError: false,
      };
    case InsightActions.FETCH_AGGS_SUCCESS: {
      const { count_histogram = [], ratings_distribution = [] } = action.payload.aggs;
      return {
        ...state,
        aggs: {
          ...state.aggs,
          histogram: count_histogram.map(({ date, count: y }: any) => ({
            date: Time.create.utc(date, Time.FORMAT.YYYY_MM_DD).unix() * 1000,
            y,
          })),
          ratingDistribution: ratings_distribution,
        },
        fetchAggsPending: false,
        fetchAggsError: false,
      };
    }
    case InsightActions.FETCH_AGGS_ERROR:
      return {
        ...state,
        fetchAggsPending: false,
        fetchAggsError: true,
      };

    // sentiment
    case InsightActions.FETCH_SENTIMENT_AGG_PENDING:
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchSentimentDistributionPending: true,
          fetchSentimentDistributionError: false,
        },
      };
    case InsightActions.FETCH_SENTIMENT_AGG_SUCCESS: {
      const { sentiment_distribution = [] } = action.payload.aggs;

      return {
        ...state,
        aggs: {
          ...state.aggs,
          sentimentDistribution: sentiment_distribution.map(
            ({ sentiment, count }: ISentimentDistribution): ISentimentDistribution => ({
              sentiment,
              count,
            })
          ),
          fetchSentimentDistributionPending: false,
          fetchSentimentDistributionError: false,
        },
      };
    }
    case InsightActions.FETCH_SENTIMENT_AGG_ERROR: {
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchSentimentDistributionPending: false,
          fetchSentimentDistributionError: true,
        },
      };
    }

    // fan signals
    case InsightActions.FETCH_FAN_SIGNALS_AGG_PENDING:
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchFanSignalsDistributionPending: true,
          fetchFanSignalsDistributionError: false,
        },
      };
    case InsightActions.FETCH_FAN_SIGNALS_AGG_SUCCESS: {
      const { fs_distribution = [] } = action.payload.aggs;
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fanSignalsDistribution: fs_distribution,
          fetchFanSignalsDistributionPending: false,
          fetchFanSignalsDistributionError: false,
        },
      };
    }
    case InsightActions.FETCH_FAN_SIGNALS_AGG_ERROR: {
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchFanSignalsDistributionPending: false,
          fetchFanSignalsDistributionError: true,
        },
      };
    }

    // survey names
    case InsightActions.FETCH_SURVEY_NAMES_PENDING:
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchSurveyNamesDistributionPending: true,
          fetchSurveyNamesDistributionError: false,
        },
      };
    case InsightActions.FETCH_SURVEY_NAMES_SUCCESS: {
      const { survey_title_distribution = [] } = action.payload.aggs;
      return {
        ...state,
        aggs: {
          ...state.aggs,
          surveyNamesDistribution: survey_title_distribution,
          fetchSurveyNamesDistributionPending: false,
          fetchSurveyNamesDistributionError: false,
        },
      };
    }
    case InsightActions.FETCH_SURVEY_NAMES_ERROR: {
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchSurveyNamesDistributionPending: false,
          fetchSurveyNamesDistributionError: true,
        },
      };
    }

    // types
    case InsightActions.FETCH_TYPES_AGG_PENDING:
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchTypesDistributionPending: true,
          fetchTypesDistributionError: false,
        },
      };
    case InsightActions.FETCH_TYPES_AGG_SUCCESS: {
      const { type_distribution = [] } = action.payload.aggs;
      const { review = 0, message = 0, survey_answer: survey = 0 } = type_distribution.reduce(
        (acc: any, { type, count }: any): any => ({ ...acc, [type]: count }),
        {}
      );
      return {
        ...state,
        aggs: {
          ...state.aggs,
          typesDistribution: {
            review,
            message,
            survey,
          },
          fetchTypesDistributionPending: false,
          fetchTypesDistributionError: false,
        },
      };
    }
    case InsightActions.FETCH_TYPES_AGG_ERROR: {
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchTypesDistributionPending: false,
          fetchTypesDistributionError: true,
        },
      };
    }

    // regions
    case InsightActions.FETCH_REGIONS_PENDING:
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchRegionsPending: true,
          fetchRegionsError: false,
        },
      };
    case InsightActions.FETCH_REGIONS_SUCCESS: {
      const { region_distribution = [] } = action.payload.aggs;
      return {
        ...state,
        aggs: {
          ...state.aggs,
          regions: region_distribution.map(({ region }: any) => region),
          fetchRegionsPending: false,
          fetchRegionsError: false,
        },
      };
    }
    case InsightActions.FETCH_REGIONS_ERROR: {
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchRegionsPending: false,
          fetchRegionsError: true,
        },
      };
    }

    case InsightActions.FETCH_REGIONS_AGG_PENDING:
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchRegionsDistributionPending: true,
          fetchRegionsDistributionError: false,
        },
      };
    case InsightActions.FETCH_REGIONS_AGG_SUCCESS: {
      const { region_distribution = [] } = action.payload.aggs;
      const regionsDistribution = region_distribution.reduce(
        (acc: Record<string, number>, item: { region: string; count: number }) => ({
          ...acc,
          [item.region]: item.count,
        }),
        {}
      );
      const regions = region_distribution.map(({ region }: any) => region);
      return {
        ...state,
        aggs: {
          ...state.aggs,
          regions,
          regionsDistribution,
          fetchRegionsDistributionPending: true,
          fetchRegionsDistributionError: false,
        },
      };
    }
    case InsightActions.FETCH_REGIONS_AGG_ERROR: {
      return {
        ...state,
        aggs: {
          ...state.aggs,
          fetchRegionsDistributionPending: false,
          fetchRegionsDistributionError: true,
        },
      };
    }

    // data
    case InsightActions.FETCH_DATA_PENDING:
      return {
        ...state,
        currentSearch: invokeCurrentSearch(action.payload),
        fetchDataPending: true,
        fetchDataError: false,
      };
    case InsightActions.FETCH_DATA_SUCCESS: {
      const { dataPagination: initialDataPagination } = initialState;
      const { dataPagination: currentDataPagination } = state;
      const { data, per, total } = action.payload.page;
      const { resetPagination } = action.meta;
      return {
        ...state,
        data,
        dataPagination: {
          ...currentDataPagination,
          ...(resetPagination && initialDataPagination),
          per,
          total,
        },
        fetchDataPending: false,
        fetchDataError: false,
      };
    }
    case InsightActions.FETCH_DATA_ERROR:
      return {
        ...state,
        data: [],
        fetchDataPending: false,
        fetchDataError: true,
      };

    case InsightActions.UPDATE_DATA_PAGE: {
      const { dataPagination: currentDataPagination } = state;
      const { page } = action.payload;
      return {
        ...state,
        dataPagination: {
          ...currentDataPagination,
          page,
          $pristine: false,
        },
      };
    }

    // phrases
    case InsightActions.FETCH_PHRASES_PENDING:
      return {
        ...state,
        fetchPhrasesPending: true,
        fetchPhrasesError: false,
      };
    case InsightActions.FETCH_PHRASES_SUCCESS: {
      const { significant_phrases: phrases } = action.payload;
      return {
        ...state,
        phrases: getRedistributedPhrases(phrases),
        fetchPhrasesPending: false,
      };
    }
    case InsightActions.FETCH_PHRASES_ERROR:
      return {
        ...state,
        phrases: getRedistributedPhrases([]),
        fetchPhrasesPending: false,
        fetchPhrasesError: true,
      };

    case InsightActions.UPDATE_PHRASE_PENDING: {
      const { phrases } = state;
      const flattenPhrases = getFlattenPhrases(phrases);
      const phrase = getPhrase(flattenPhrases, action.meta.phrase);

      // TODO: Why there this props?
      (phrase as any).updatePending = true;
      (phrase as any).updateError = false;

      return {
        ...state,
        phrases,
        updatePhrasePending: true,
        updatePhraseError: false,
      };
    }
    case InsightActions.UPDATE_PHRASE_SUCCESS: {
      const { phrases } = state;
      const flattenPhrases = getFlattenPhrases(phrases);
      const phrase = getPhrase(flattenPhrases, action.meta.phrase);

      (phrase as any).updatePending = false;
      updatePhraseStatus(phrase, action.payload.status);

      return {
        ...state,
        phrases: getRedistributedPhrases(flattenPhrases),
        updatePhrasePending: false,
      };
    }
    case InsightActions.UPDATE_PHRASE_ERROR: {
      const { phrases } = state;
      const flattenPhrases = getFlattenPhrases(phrases);
      const phrase = getPhrase(flattenPhrases, action.meta.phrase);

      (phrase as any).updatePending = false;
      (phrase as any).updateError = true;

      return {
        ...state,
        phrases,
        updatePhrasePending: false,
        updatePhraseError: true,
      };
    }

    case InsightActions.TAG_ADD_SUCCESS: {
      const { elasticsearch_id: elasticsearchId, tag_name: tagName } = action.payload;
      const data = state.data.map((item) => {
        if (item.elasticsearch_id === elasticsearchId) {
          return {
            ...item,
            customer_tags: uniq([...(item.customer_tags || []), tagName]),
          };
        }
        return item;
      });

      return {
        ...state,
        data,
      };
    }

    case InsightActions.TAG_RENAME_SUCCESS:
    case TagsActions.RENAME_TAG_SUCCESS: {
      const { tagName, newTagName } = action.meta;
      const tagsData = action.payload.tags as TagModel[];

      const tagsHash = indexBy((tag) => tag.elasticsearch_id, tagsData);
      const data = state.data.map((item) => (tagsHash[item.elasticsearch_id] ? getUpdatedInsightTags(item, tagName, newTagName) : item)
      );

      return {
        ...state,
        data,
      };
    }

    case InsightActions.TAG_REMOVE_SUCCESS: {
      const { elasticsearchId, tagName } = action.meta;
      const data = state.data.map((item) => {
        if (item.elasticsearch_id === elasticsearchId) {
          return getUpdatedInsightTags(item, tagName, null);
        }
        return item;
      });

      return {
        ...state,
        data,
      };
    }

    case TagsActions.REMOVE_TAG_SUCCESS: {
      const tagsData = action.payload.tags as TagModel[];
      const tagsHash = indexBy((tag) => tag.elasticsearch_id, tagsData);
      const data = state.data.map((item) => (tagsHash[item.elasticsearch_id]
        ? getUpdatedInsightTags(item, tagsHash[item.elasticsearch_id].tag_name, null)
        : item)
      );

      return {
        ...state,
        data,
      };
    }

    case InsightActions.EXPORT_PENDING: {
      return {
        ...state,
        exportPending: true,
      };
    }

    case InsightActions.EXPORT_SUCCESS:
    case InsightActions.EXPORT_ERROR: {
      return {
        ...state,
        exportPending: false,
      };
    }

    case InsightActions.SAVE_INSIGHTS_SUCCESS: {
      const item = action.payload;
      return {
        ...state,
        savedInsights: [item, ...state.savedInsights],
      };
    }

    case InsightActions.FETCH_SAVED_INSIGHTS_PENDING: {
      return {
        ...state,
        savedInsightsPending: true,
      };
    }

    case InsightActions.FETCH_SAVED_INSIGHTS_SUCCESS: {
      const data: SavedInsight[] = action.payload;
      return {
        ...state,
        savedInsights: [...data],
        savedInsightsPending: false,
      };
    }

    case InsightActions.FETCH_SAVED_INSIGHTS_ERROR: {
      return {
        ...state,
        savedInsightsPending: false,
      };
    }

    case InsightActions.DELETE_SAVED_INSIGHT_SUCCESS: {
      return {
        ...state,
        savedInsights: state.savedInsights.filter((i) => i.id !== action.payload.id),
      };
    }

    case InsightActions.UPDATE_SAVED_INSIGHTS_SUCCESS: {
      const insight = action.payload;
      return {
        ...state,
        savedInsights: [insight, ...state.savedInsights.filter((i) => i.id !== insight.id)],
      };
    }

    case InsightActions.DELETE_SAVED_INSIGHT_ERROR: {
      return state;
    }

    case InsightActions.UPDATE_SAVED_INSIGHTS_ERROR: {
      return state;
    }

    case InsightActions.UPDATE_SENTIMENT_SUCCESS: {
      const { id, sentiment_compound } = action.payload;
      const { phrases, currentSearch, data, aggs } = state;

      const itemToChangeSentiment = data.find(item => item.elasticsearch_id === id);
      if (!itemToChangeSentiment) {
        return state
      }
      const existingSentiment = getSentiment(itemToChangeSentiment.sentiment_compound)
      if (existingSentiment === sentiment_compound) {
        return state;
      }
      // changing All Sentiment dropdown and Sentiment destribution chart if the sentiment in the card has been changed
      const sentimentDistribution = getDestribution(aggs.sentimentDistribution, sentiment_compound, existingSentiment)

      // changing phrases chart if the sentiment in the card has been changed
      const existingPhrases = new Map()
      itemToChangeSentiment.phrases.forEach(key => existingPhrases.set(key, true))

      // if phrases are selected in the phrase filter, the selected phrases of the itemToChangeSentiment phrases will be changed
      const selectedPhrases = new Map();
      currentSearch.phrases.forEach(key => selectedPhrases.set(key, true));

      const newPhrases = Object.entries(phrases).map(phrase => {
        const phrasesType = phrase[0]
        const phrasesTypeValue: IPhraseReduxState[] = phrase[1]
        // if no phrase is selected in the phrase filter, sentiment_compound will be changed for all itemToChangeSentiment phrases

        // find all phrases to change sentiment_compound from state phrases array ( phrasesTypeValue )
        const arrayToChangeSentiment = currentSearch.phrases.length ? selectedPhrases : existingPhrases;
        const phrasesArray = phrasesTypeValue.filter(arr => arrayToChangeSentiment.has(arr.phrase));

        // change sentiment in all phrases of the phrasesArray
        const modifiedItems = phrasesArray.map(item => {
          const newDestributionForItem = getDestribution(item.phrase_sentiment_distribution as unknown as SentimentItem[], sentiment_compound, existingSentiment)
          return {
            ...item,
            phrase_sentiment_distribution: newDestributionForItem,
          }
        });

        // filter state phrases that haven't been changed
        const filtered = phrasesTypeValue.filter(item => !arrayToChangeSentiment.has(item.phrase))
        return [phrasesType, [...filtered, ...modifiedItems]];
      })
      return {
        ...state,
        data: data.map(item => item.elasticsearch_id === id ? { ...item, sentiment_compound } : item),
        aggs: {
          ...state.aggs,
          sentimentDistribution,
        },
        phrases: Object.fromEntries(newPhrases),
      };
    }

    default:
      return state;
  }
};
