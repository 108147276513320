import {
  mix, darken, lighten, rgba, saturate, desaturate,
} from 'polished';

const baseTheme = {
  white: '#FFF',
  snow: '#FAFAFA',
  nimbus: '#E2E2E2',
  smoke: '#B5B5B5',
  silver: '#ACACAC',
  shadow: '#575757',
  onyx: '#333333',

  amethyst: '#BB65CA',
  orchid: '#9D1BB2',
  eggplant: '#6B0F9C',

  malibu: '#70B7EA',
  sky: '#64B5F7',
  royal: '#1194F6',
  lapis: '#1565C0',

  mint: '#80CAC3',
  pine: '#159688',
  forest: '#0B695C',

  mango: '#FBA900',

  coral: '#FD8980',
  chinook: '#EA4755',
  cayenne: '#DE2230',
  mandy: '#E84959',
};

const derivedTheme = {
  formBorder: darken(0.01, mix(0.07, baseTheme.royal, baseTheme.smoke)),

  formBoxShadow: darken(0.04, mix(0.075, baseTheme.royal, baseTheme.nimbus)),

  buttonGrey: darken(0.055, mix(0.01, baseTheme.royal, baseTheme.snow)),
  buttonGreyOutline: darken(0.045, mix(0.05, baseTheme.royal, baseTheme.nimbus)),
  disabledButtonGrey: lighten(0.085, mix(0.1, baseTheme.royal, baseTheme.smoke)),
  disabledButtonGreyOutline: mix(0.04, baseTheme.royal, baseTheme.nimbus),
  buttonHoverBorder: lighten(0.011, mix(0.10, baseTheme.royal, baseTheme.smoke)),
  buttonFocusBorder: darken(0.1, mix(0.53, baseTheme.royal, baseTheme.smoke)),

  errorBackground: lighten(0.015, mix(0.22, baseTheme.coral, baseTheme.snow)),
  errorBorder: darken(0.09, saturate(1.5, baseTheme.chinook)),
  errorWarningBorder: darken(0.05, mix(0.22, baseTheme.coral, baseTheme.snow)),

  boxShadow: `0 1px 0 ${rgba(baseTheme.white, 0.75)} inset, 0 0 0.5em ${rgba(darken(0.015, desaturate(0.3, baseTheme.royal)), 0.5)}`,
};

export default Object.assign(baseTheme, derivedTheme);
