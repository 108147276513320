import { Action } from 'redux';
import { isAbortErrorAction } from '../../actions/utils';
import { TagsActions } from '../../actions/tags';
import { InsightActions } from '../../actions/insights/insights.action-types';
import { ADD_CONVERSATION_TAG, RENAME_CONVERSATION_TAG } from '../../actions/conversations/tags';
import { initialState, TagsState, Tag } from './tags.state';

type TagsReducerActions = Action<
  TagsActions | InsightActions | typeof RENAME_CONVERSATION_TAG.SUCCESS | typeof ADD_CONVERSATION_TAG.SUCCESS
> & {
  payload: any;
  meta?: any;
  error?: Error;
};

export const tagsReducer = (state: TagsState = initialState, action: TagsReducerActions): TagsState => {
  if (!action || isAbortErrorAction(action)) {
    return state;
  }
  switch (action.type) {
    case TagsActions.FETCH_ALL_TAGS_PENDING:
      return {
        ...state,
        fetchAllTagsPending: true,
        fetchAllTagsError: false,
      };
    case TagsActions.FETCH_ALL_TAGS_SUCCESS: {
      const { tags: allTags } = action.payload as { tags: Tag[] };
      const tagNames = allTags.map((item) => item.name);
      const recentTags = state.recentTags.filter(({ name }) => tagNames.includes(name));

      return {
        ...state,
        allTags,
        recentTags,
        fetchAllTagsPending: false,
      };
    }
    case TagsActions.FETCH_ALL_TAGS_ERROR:
      return {
        ...state,
        allTags: [],
        fetchAllTagsPending: false,
        fetchAllTagsError: true,
      };

    case TagsActions.FETCH_MOST_USER_TAGS_PENDING:
      return {
        ...state,
        fetchMostUsedTagsPending: true,
        fetchOrgTagsError: false,
      };
    case TagsActions.FETCH_MOST_USER_TAGS_SUCCESS: {
      const rawOrgTags = action.payload;
      // now we store mostUsedTags as string[]
      // in future each tag will contain displayName and tagId as separate props
      // TODO: map should be handled within an request response (service), and payload must have proper ready data.
      const mostUsedTags = rawOrgTags.map((name: string) => ({ name }));
      return {
        ...state,
        mostUsedTags,
        fetchMostUsedTagsPending: false,
      };
    }
    case TagsActions.FETCH_MOST_USER_TAGS_ERROR:
      return {
        ...state,
        mostUsedTags: [],
        fetchMostUsedTagsPending: false,
        fetchOrgTagsError: true,
      };
    case TagsActions.FETCH_APP_TAGS_SUCCESS: {
      // TODO: map should be handled within an request response (service), and payload must have proper ready data.
      const { tags } = action.payload;
      const appTags = tags.map((name: string) => ({ name }));
      return {
        ...state,
        appTags,
      };
    }
    case TagsActions.FETCH_AGGREG_TAGS_PENDING: {
      return {
        ...state,
        aggregatedTagsPending: true,
      };
    }
    case TagsActions.FETCH_AGGREG_TAGS_SUCCESS: {
      const { tags: aggTags } = action.payload;
      return {
        ...state,
        aggregatedTags: aggTags,
        aggregatedTagsPending: false,
      };
    }
    case TagsActions.FETCH_AGGREG_TAGS_ERROR: {
      return {
        ...state,
        aggregatedTagsPending: false,
      };
    }
    case InsightActions.TAG_ADD_SUCCESS:
    case ADD_CONVERSATION_TAG.SUCCESS:
      const { tag_name: name } = action.payload; // eslint-disable-line
      return {
        ...state,
        recentTags: [{ name }, ...state.recentTags.filter((tag) => tag.name !== name)],
      };
    case InsightActions.TAG_RENAME_SUCCESS:
    case RENAME_CONVERSATION_TAG.SUCCESS: {
      const { newTagName: name, tagName } = action.meta; // eslint-disable-line
      return {
        ...state,
        recentTags: [{ name }, ...state.recentTags.filter((tag) => ![tagName, name].includes(tag.name))],
      };
    }
    default:
      return state;
  }
};
