export enum SurveyMultiAppsActions {
  CREATE_SURVEY_MULTI_APPS_PENDING = 'SURVEY_MULTI_APPS_CREATE_PENDING',
  CREATE_SURVEY_MULTI_APPS_SUCCESS = 'SURVEY_MULTI_APPS_CREATE_SUCCESS',
  CREATE_SURVEY_MULTI_APPS_ERROR = 'SURVEY_MULTI_APPS_CREATE_ERROR',

  FETCH_SURVEY_MULTI_APPS_PENDING = 'FETCH_SURVEY_MULTI_APPS_PENDING',
  FETCH_SURVEY_MULTI_APPS_SUCCESS = 'FETCH_SURVEY_MULTI_APPS_SUCCESS',
  FETCH_SURVEY_MULTI_APPS_ERROR = 'FETCH_SURVEY_MULTI_APPS_ERROR',

  FETCH_SURVEYS_MULTI_APPS_BY_ID_PENDING = 'FETCH_SURVEYS_MULTI_APPS_BY_ID_PENDING',
  FETCH_SURVEYS_MULTI_APPS_BY_ID_SUCCESS = 'FETCH_SURVEYS_MULTI_APPS_BY_ID_SUCCESS',
  FETCH_SURVEYS_MULTI_APPS_BY_ID_ERROR = 'FETCH_SURVEYS_MULTI_APPS_BY_ID_ERROR',

  GET_SURVEY_MULTI_APPS_STATS_PENDING = 'GET_SURVEY_MULTI_APPS_STATS_PENDING',
  GET_SURVEY_MULTI_APPS_STATS_SUCCESS = 'GET_SURVEY_MULTI_APPS_STATS_SUCCESS',
  GET_SURVEY_MULTI_APPS_STATS_ERROR = 'GET_SURVEY_MULTI_APPS_STATS_ERROR',

  GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_PENDING = 'GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_PENDING',
  GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_SUCCESS = 'GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_SUCCESS',
  GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_ERROR = 'GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_ERROR',

  GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_PENDING = 'GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_PENDING',
  GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_SUCCESS = 'GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_SUCCESS',
  GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_ERROR = 'GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_ERROR',

  UPDATE_SURVEY_MULTI_APPS_PENDING = 'UPDATE_SURVEY_MULTI_APPS_PENDING',
  UPDATE_SURVEY_MULTI_APPS_SUCCESS = 'UPDATE_SURVEY_MULTI_APPS_SUCCESS',
  UPDATE_SURVEY_MULTI_APPS_ERROR = 'UPDATE_SURVEY_MULTI_APPS_ERROR',

  BOOTSTRAP_SURVEYS_MULTI_APPS = 'BOOTSTRAP_SURVEYS_MULTI_APPS',

  REQUEST_FETCH_ACTIVE_SURVEYS_MULTI_APPS = 'REQUEST_FETCH_ACTIVE_SURVEYS_MULTI_APPS',
  RECEIVE_FETCH_ACTIVE_SURVEYS_MULTI_APPS = 'RECEIVE_FETCH_ACTIVE_SURVEYS_MULTI_APPS',
  ERRORED_FETCH_ACTIVE_SURVEYS_MULTI_APPS = 'ERRORED_FETCH_ACTIVE_SURVEYS_MULTI_APPS',

  REQUEST_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS = 'REQUEST_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS',
  RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS = 'RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS',
  ERRORED_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS = 'ERRORED_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS',

  REQUEST_UPDATE_SURVEY_MULTI_APPS_ACTIVE = 'REQUEST_UPDATE_SURVEY_MULTI_APPS_ACTIVE',
  RECEIVE_UPDATE_SURVEY_MULTI_APPS_ACTIVE = 'RECEIVE_UPDATE_SURVEY_MULTI_APPS_ACTIVE',
  ERRORED_UPDATE_SURVEY_MULTI_APPS_ACTIVE = 'ERRORED_UPDATE_SURVEY_MULTI_APPS_ACTIVE',

  REQUEST_DELETE_SURVEY_MULTI_APPS = 'REQUEST_DELETE_SURVEY_MULTI_APPS',
  RECEIVE_DELETE_SURVEY_MULTI_APPS = 'RECEIVE_DELETE_SURVEY_MULTI_APPS',
  ERRORED_DELETE_SURVEY_MULTI_APPS = 'ERRORED_DELETE_SURVEY_MULTI_APPS',

  RECEIVE_SURVEY_MULTI_APPS_EMAIL_SUMMARY_APP_IDS = 'RECEIVE_SURVEY_MULTI_APPS_EMAIL_SUMMARY_APP_IDS',

  CLONE_SURVEY_MULTI_APPS_TO_APP_PENDING = 'CLONE_SURVEY_MULTI_APPS_TO_APP_PENDING',
  CLONE_SURVEY_MULTI_APPS_TO_APP_SUCCESS = 'CLONE_SURVEY_MULTI_APPS_TO_APP_SUCCESS',
  CLONE_SURVEY_MULTI_APPS_TO_APP_ERROR = 'CLONE_SURVEY_MULTI_APPS_TO_APP_ERROR',
}
