export enum EventSDK {
  CUSTOMER_ID_SET_FROM_PROMT = 'customer_id_from_prompt',
  CUSTOMER_ID_SET_FROM_PAGE = 'customer_id_from_page',
  PERSON_CUSTOM_DATA_ADDED = 'person_custom_data_added',
  CUSTOMER_ID_UPDATE = 'customer_id_update',
  NFS_FAN_SIGNALS_LINK = 'nfs_fan_signals_link',
  NFS_INFO_ICON = 'nfs_info_icon',
  FAN_SIGNALS_PAGE = 'fan_signals_page',
  NEW_SDK_LINK_SURVEY_LOGIC = 'new_sdk_link_survey_logic',
  SURVEY_LOGIC_ICON_OPENED ='survey_logic_icon_opened',
  QM_SESSION = 'qm_session',
  STAR_RATINGS_ANDROID_FILTER = 'star_ratings_android_filter',
  CDM_PAGE_ENTER = 'cdm_page_enter',
  CDM_ARCHIEVE_CLICK = 'cdm_archieve_click',
  CDM_SHOW_CLICK = 'cdm_show_click'
}

export class EventMetricsSDK {
  static Metrics = EventSDK;
  static send = (event: EventSDK) => ApptentiveSDK && ApptentiveSDK.engage(event);
}
