import { RatingActions, RatingReducerAction } from 'src/actions/star-ratings';

export interface StarRating {
  all_ratings: Record<number, number>,
  all_ratings_percents?: Record<number, number>,
  all_ratings_average: number,
  all_ratings_count: number,
  current_ratings: Record<number, number>,
  current_ratings_average: number | null,
  current_ratings_count: number | null,
  ingest_time: number,
  region: string,
  store: string,
  store_id: string,
  store_observed_time: number,
  version: string,
}

export interface StarRatingsState {
  loading: boolean;
  fetchError: boolean;
  data: StarRating[];
}

export const DEFAULT_STAR_RATING_STATE: StarRatingsState = {
  loading: false,
  fetchError: false,
  data: [],
}

export const starRatings = (state: StarRatingsState = DEFAULT_STAR_RATING_STATE, action: RatingReducerAction = {} as RatingReducerAction) => {
  switch (action.type) {
    case RatingActions.FETCH_RATINGS_PENDING: {
      return {
        ...state,
        loading: true,
        fetchError: false,
      };
    }
    case RatingActions.FETCH_RATINGS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case RatingActions.FETCH_RATINGS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        loading: false,
        fetchError: false,
        data: payload.json?.data,
      };
    }
    default:
      return state;
  }
};
