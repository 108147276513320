/* eslint-disable arrow-body-style */

import { isTestMode } from '../utils/environment';

const setupTimer = (target: Record<string, any>, name: string, timeout: NodeJS.Timeout | number) => {
  if (target[name]) {
    window.clearTimeout(target[name]);
    delete target[name];
  }
  target[name] = timeout;
};

export const debounce = (interval: number = 300) => {
  return (target: Record<string, any>, name: string, descriptor: PropertyDescriptor) => {
    if (isTestMode()) {
      return descriptor;
    }

    const TIMER_FN = `__timeoutFnIndex_${name}`;
    Object.defineProperty(target, TIMER_FN, {
      value: 0,
      writable: true,
    });

    const originalMethod: Function = descriptor.value;
    return {
      ...descriptor,
      value(...args: any[]) {
        setupTimer(
          this,
          TIMER_FN,
          window.setTimeout(() => {
            originalMethod.apply(this, args);
          }, interval)
        );
      },
    };
  };
};
