import classnames from 'classnames';
import React, { Component, MouseEvent, ReactNode } from 'react';
import { SortOrder } from '../utils/sort';

import './styles/molecules/interactive-table.scss';

export interface IGridColumn<S> {
  text: string;
  sortField?: S;
}

export interface IGridState<S> {
  sortBy: S;
  sortOrder: SortOrder;
}

export interface IGridProps<T, S> {
  rowObjects: T[];
  gridState: IGridState<S>;
  renderRow: (object: T, index: number) => ReactNode;
  columns: IGridColumn<S>[];
  onSortClick: (sortBy: S, sortOrder: SortOrder) => void;
  className?: string;
}

/**
 * Grid
 * @template T - the type of the object that populates each row of the grid
 * @template S - the type that defines the allowed sort field names
 */
class Grid<T, S extends string> extends Component<IGridProps<T, S>> {
  handleSortClick = (event: MouseEvent<HTMLButtonElement>) => {
    const sortField = event.currentTarget.getAttribute('data-sort-field');
    if (!sortField) {
      return;
    }

    const { gridState } = this.props;

    if (sortField === gridState.sortBy) {
      this.props.onSortClick(gridState.sortBy, gridState.sortOrder === SortOrder.desc ? SortOrder.asc : SortOrder.desc);
    } else {
      this.props.onSortClick(sortField as S, SortOrder.desc);
    }
  };

  renderColHeader(col: IGridColumn<S>, index: number) {
    const { gridState } = this.props;
    let Child;

    let isActiveSortCol = false;

    if (col.sortField) {
      let sortType: string;

      if (col.sortField === gridState.sortBy) {
        sortType = gridState.sortOrder.toString();
        isActiveSortCol = true;
      } else {
        sortType = 'none';
      }

      Child = (
        <button
          className="button-normalize icon icon__button"
          data-sort-field={col.sortField}
          onClick={this.handleSortClick}
        >
          {col.text}
          <i className={`icon icon--sort icon--sort-${sortType}`} />
        </button>
      );
    } else {
      Child = <span>{col.text}</span>;
    }

    return (
      <th className={classnames('col col--header', { active: isActiveSortCol })} data-field={col.text} key={index}>
        {Child}
      </th>
    );
  }

  render() {
    return (
      <table className={classnames('table table--interactive', this.props.className)}>
        <thead>
          <tr className="row row--header">
            {this.props.columns.map((col, index) => this.renderColHeader(col, index))}
          </tr>
        </thead>

        <tbody>{this.props.rowObjects.map(this.props.renderRow)}</tbody>
      </table>
    );
  }
}

export default Grid;
