import React, { useEffect } from 'react';
import { Modal, Button, Icon, IconName } from 'src/components/molecules';
import { bemPrefix } from 'src/utils';
import { Events } from 'src/actions/event-metrics';

import './download-fan-signals-export-dialog.scss';

const bem = bemPrefix('download-fan-signals-export-dialog');

export interface DownloadFanSignalsExportDialogProps {
  showModal: boolean;
  onCloseModal(): void;
  sendEvent?(event: Events): void;
}

const options: IconName[] = ['circle', 'notebook', 'unlocked', 'spot', 'top', 'bottom', 'shadow', 'pulse'];

export const DownloadFanSignalsExportDialog: React.FC<DownloadFanSignalsExportDialogProps> = ({
  showModal,
  onCloseModal,
  sendEvent,
}) => {
  useEffect(() => {
    if (!showModal || !sendEvent) {
      return;
    }
    sendEvent(Events.EXPORT_LEARN_MORE);
  }, [showModal]);

  const onCustomerClick = () => {
    if (ApptentiveSDK) {
      ApptentiveSDK.showMessageCenter();
    }
    onCloseModal();
  };
  return (
    <Modal
      className={bem()}
      isOpen={showModal}
      title="Explore the full Customer Journey"
      onCancel={onCloseModal}
      isCloseOnEsc
    >
      <div className={bem('icon')}>
        {options.map((option) => (
          <Icon key={option} name={option} className={bem(`icon-${option}`)} />
        ))}
      </div>
      <p className={bem('info-text')}>
        30 days of Fan Signals data are available for you to download and analyze immediately. With this data you can:
      </p>
      <ul>
        <li>
          <span className={bem('info-span')}>
            Understand customer emotion and exactly who has expressed a shift in their
          </span>{' '}
          emotions. Share this with your marketing teams for upsell and win-back campaigns
        </li>
        <li>
          <span className={bem('info-span')}>
            Calculate your Net Fan number on a daily basis, so you can pinpoint the most
          </span>{' '}
          painful CX days
        </li>
      </ul>
      <p className={bem('descr')}>
        Accessing more than 30 days of data will enable you and your teammates to identify much larger customers lists.
        In addition, with more data you can identify customer shifts in emotion over time, providing a unique piece of
        1st party data.
      </p>
      <p>Contact your Account Manager to enable unlimited exports.</p>
      <Button className={bem('contact-button')} onClick={onCustomerClick}>
        Contact my account manager
      </Button>
    </Modal>
  );
};
DownloadFanSignalsExportDialog.displayName = 'DownloadFanSignalsExportDialog';
