import { useState, useCallback, useRef } from 'react';

export function usePopoverProps<T = HTMLElement>(opened: boolean = false) {
  const targetRef = useRef<T | null>(null);
  const [isOpenPopover, setIsOpen] = useState(opened);
  const showPopover = useCallback(() => setIsOpen(true), []);
  const closePopover = useCallback(() => setIsOpen(false), []);
  const togglePopover = useCallback(() => setIsOpen(!isOpenPopover), [isOpenPopover]);

  return { isOpenPopover, targetRef, togglePopover, showPopover, closePopover };
}
