// GET /insights/apps/52fbe2e775bf3c760b0000bc/user/5cebf34f7ceb81156b0000e2/significant-phrases?store_app_id=922103212&types=review%2Cmessage%2Csurvey_answer&rating=1..5&page=0&start_date=2020-10-28&end_date=2020-11-26&exclude_outbound=true&exclude_automated=true&per=200
export default {
  significant_phrases: [
    {
      display_key: "don't change",
      phrase: "don't$DELIM$change",
      score: 34.10567990166083,
      count: 46,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 594.240104611755,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 23.0 },
        { sentiment: '600.0-*', count: 23.0 },
      ],
      combined_score: 15.959693046175236,
    },
    {
      display_key: 'connecting with',
      phrase: 'connecting$DELIM$with',
      score: 3.6339475556249723,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -0.4593086155081494,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.923704243924842,
    },
    {
      display_key: 'promotion period',
      phrase: 'promotion$DELIM$period',
      score: 4.264895043504038,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -899.9749906521961,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.315914518405084,
    },
    {
      display_key: 'new content and',
      phrase: 'new$DELIM$content$DELIM$and',
      score: 4.341546230248303,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -986.2477987420202,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.543448058551206,
    },
    {
      display_key: 'it took forever',
      phrase: 'it$DELIM$took$DELIM$forever',
      score: 2.8994819036328674,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9953145195522,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.595036807701995,
    },
    {
      display_key: 'that helps me plan',
      phrase: 'that$DELIM$helps$DELIM$me$DELIM$plan',
      score: 4.315691663290911,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 997.9379878846262,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.704565965923562,
    },
    {
      display_key: 'i love this app and',
      phrase: 'i$DELIM$love$DELIM$this$DELIM$app$DELIM$and',
      score: 3.775977578055377,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 999.6834776632696,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.561352212045495,
    },
    {
      display_key: 'i really like how',
      phrase: 'i$DELIM$really$DELIM$like$DELIM$how',
      score: 4.039069402334537,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.767034801091036,
    },
    {
      display_key: "it's horrible",
      phrase: "it's$DELIM$horrible",
      score: 2.669712795611268,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.477556493048155,
    },
    {
      display_key: 'i really wish',
      phrase: 'i$DELIM$really$DELIM$wish',
      score: 2.8425896894715685,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 427.5352530258093,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.582790086447107,
    },
    {
      display_key: 'the pictures take',
      phrase: 'the$DELIM$pictures$DELIM$take',
      score: 4.315691663290911,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -969.0771543386213,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.422164244862069,
    },
    {
      display_key: 'that space',
      phrase: 'that$DELIM$space',
      score: 4.203055680285234,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -969.0771543386213,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.39571844408821,
    },
    {
      display_key: 'reach customer support',
      phrase: 'reach$DELIM$customer$DELIM$support',
      score: 3.6718854899039535,
      count: 29,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -976.4494383996902,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 29.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.524357208755042,
    },
    {
      display_key: 'why do you make',
      phrase: 'why$DELIM$do$DELIM$you$DELIM$make',
      score: 4.789009372192273,
      count: 23,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -922.0533899126523,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 23.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 17.50901895892397,
    },
    {
      display_key: 'tap through',
      phrase: 'tap$DELIM$through',
      score: 4.203055680285234,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -997.813327778886,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.748159127872398,
    },
    {
      display_key: 'the search function',
      phrase: 'the$DELIM$search$DELIM$function',
      score: 2.9830642923389723,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.8883563915878,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.839336829153542,
    },
    {
      display_key: 'in new areas',
      phrase: 'in$DELIM$new$DELIM$areas',
      score: 4.302879459487102,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 573.4142645817425,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.916317806976647,
    },
    {
      display_key: 'no results',
      phrase: 'no$DELIM$results',
      score: 3.1864437311057925,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9969003454007,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.738200388107318,
    },
    {
      display_key: 'what gives',
      phrase: 'what$DELIM$gives',
      score: 3.4357786522907974,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9969003454007,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.813538492892828,
    },
    {
      display_key: 'that match',
      phrase: 'that$DELIM$match',
      score: 3.7957576754200297,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -6.008238957568935,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.981657486066196,
    },
    {
      display_key: 'search through',
      phrase: 'search$DELIM$through',
      score: 3.4521491269140547,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -994.9760344990658,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.470218740274982,
    },
    {
      display_key: 'after beating',
      phrase: 'after$DELIM$beating',
      score: 3.81574598433589,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9101154773485,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.250601659836688,
    },
    {
      display_key: "i don't need to see",
      phrase: "i$DELIM$don't$DELIM$need$DELIM$to$DELIM$see",
      score: 3.5978585375185874,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -969.0771543386213,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.240245458183807,
    },
    {
      display_key: 'just send me',
      phrase: 'just$DELIM$send$DELIM$me',
      score: 4.050357364404168,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -987.5682333736038,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.496495473495944,
    },
    {
      display_key: 'the app crashes every time',
      phrase: 'the$DELIM$app$DELIM$crashes$DELIM$every$DELIM$time',
      score: 3.876993583313365,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9998806448475,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.36513780040436,
    },
    {
      display_key: 'liked the',
      phrase: 'liked$DELIM$the',
      score: 3.80879639673297,
      count: 42,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -991.4189158779168,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 42.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.442326932418478,
    },
    {
      display_key: 'new restaurant',
      phrase: 'new$DELIM$restaurant',
      score: 4.107756491642093,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 344.3887448300571,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.970624299651002,
    },
    {
      display_key: 'me know',
      phrase: 'me$DELIM$know',
      score: 4.154859958808117,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 445.1956652074654,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.930401706659113,
    },
    {
      display_key: 'app needs some serious',
      phrase: 'app$DELIM$needs$DELIM$some$DELIM$serious',
      score: 5.821820398414961,
      count: 25,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.8883563915878,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 25.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 17.9824340883985,
    },
    {
      display_key: 'let me know',
      phrase: 'let$DELIM$me$DELIM$know',
      score: 11.292803646079246,
      count: 120,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 715.2387331439918,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 90.0 },
      ],
      combined_score: 14.746864242498996,
    },
    {
      display_key: 'chatting with',
      phrase: 'chatting$DELIM$with',
      score: 4.549760318029206,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 931.3021705513605,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.832606344859176,
    },
    {
      display_key: 'just tell me',
      phrase: 'just$DELIM$tell$DELIM$me',
      score: 3.34072428351059,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -113.14988228359466,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.875939987421166,
    },
    {
      display_key: 'pictures are blurry',
      phrase: 'pictures$DELIM$are$DELIM$blurry',
      score: 4.302879459487102,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.95487003393927,
    },
    {
      display_key: 'looking for a new place',
      phrase: 'looking$DELIM$for$DELIM$a$DELIM$new$DELIM$place',
      score: 4.3545899757403195,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 996.8424528073709,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.742952705345173,
    },
    {
      display_key: 'i found this',
      phrase: 'i$DELIM$found$DELIM$this',
      score: 2.8425896894715685,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -678.2498104173666,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.832250984765468,
    },
    {
      display_key: 'existing ones',
      phrase: 'existing$DELIM$ones',
      score: 4.2275750779463355,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 679.5092277853645,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.874992180667197,
    },
    {
      display_key: 'sort your',
      phrase: 'sort$DELIM$your',
      score: 3.3718195886464413,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -511.1459571655874,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.97829531918932,
    },
    {
      display_key: 'its hard to',
      phrase: 'its$DELIM$hard$DELIM$to',
      score: 2.521025000733793,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -967.3363390712801,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.874719908088732,
    },
    {
      display_key: 'if you let',
      phrase: 'if$DELIM$you$DELIM$let',
      score: 4.203055680285234,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 445.1956652074654,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.941934786861,
    },
    {
      display_key: 'by city',
      phrase: 'by$DELIM$city',
      score: 4.215279740456394,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 888.2538532259038,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.76671904875428,
    },
    {
      display_key: 'vacation rental',
      phrase: 'vacation$DELIM$rental',
      score: 4.341546230248303,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -480.27248748087123,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.22297613124545,
    },
    {
      display_key: 'more active',
      phrase: 'more$DELIM$active',
      score: 8.38382557743622,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 997.9379878846262,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 14.36861268461065,
    },
    {
      display_key: "didn't include",
      phrase: "didn't$DELIM$include",
      score: 3.356199956205516,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9140525867125,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.148250400297975,
    },
    {
      display_key: 'to try out',
      phrase: 'to$DELIM$try$DELIM$out',
      score: 4.738996828181528,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 965.8129822370145,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.86299447615945,
    },
    {
      display_key: 'let me know about',
      phrase: 'let$DELIM$me$DELIM$know$DELIM$about',
      score: 4.215279740456394,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 886.6590236201524,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.777301159262164,
    },
    {
      display_key: "i'm done with this",
      phrase: "i'm$DELIM$done$DELIM$with$DELIM$this",
      score: 3.9726405187028457,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999994338713,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.556562891492884,
    },
    {
      display_key: 'drives me nuts',
      phrase: 'drives$DELIM$me$DELIM$nuts',
      score: 3.9726405187028457,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.216004772635,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.734950494254521,
    },
    {
      display_key: 'me know when',
      phrase: 'me$DELIM$know$DELIM$when',
      score: 4.328580357863701,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 445.1956652074654,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.971362609508361,
    },
    {
      display_key: 'you wanted',
      phrase: 'you$DELIM$wanted',
      score: 3.316425143543785,
      count: 23,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -334.12859233490695,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 23.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.948516786437747,
    },
    {
      display_key: 'check all',
      phrase: 'check$DELIM$all',
      score: 3.091265678875966,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 344.3887448300571,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.686327897821066,
    },
    {
      display_key: 'never heard back',
      phrase: 'never$DELIM$heard$DELIM$back',
      score: 3.31777637525816,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.95924205246,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.218973858804668,
    },
    {
      display_key: 'i contacted customer service',
      phrase: 'i$DELIM$contacted$DELIM$customer$DELIM$service',
      score: 3.8873930862675383,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.95924205246,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.377417841960972,
    },
    {
      display_key: "i couldn't change",
      phrase: "i$DELIM$couldn't$DELIM$change",
      score: 3.5022095638054673,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.8883563915878,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.999779844191641,
    },
    {
      display_key: 'order things',
      phrase: 'order$DELIM$things',
      score: 3.065106806170095,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 193.90938277119173,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.725296242404495,
    },
    {
      display_key: 'am trying to',
      phrase: 'am$DELIM$trying$DELIM$to',
      score: 3.3330398115517306,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -361.49954704365604,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.926881615283023,
    },
    {
      display_key: 'apple maps',
      phrase: 'apple$DELIM$maps',
      score: 4.302879459487102,
      count: 60,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -993.5423111866784,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 60.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.544375019741,
    },
    {
      display_key: 'ordering pizza',
      phrase: 'ordering$DELIM$pizza',
      score: 4.328580357863701,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -829.0152033123176,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.30406841553718,
    },
    {
      display_key: 'my ipad to',
      phrase: 'my$DELIM$ipad$DELIM$to',
      score: 3.9945394803408414,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.95924205246,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.404607365324933,
    },
    {
      display_key: 'without worrying',
      phrase: 'without$DELIM$worrying',
      score: 4.061708564356543,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -922.0533899126524,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.303802030696422,
    },
    {
      display_key: 'it tells me the',
      phrase: 'it$DELIM$tells$DELIM$me$DELIM$the',
      score: 3.5022095638054673,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.62441455485976,
    },
    {
      display_key: 'my coupon code',
      phrase: 'my$DELIM$coupon$DELIM$code',
      score: 3.9945394803408414,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9708666273223,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.627750935413607,
    },
    {
      display_key: 'free coins for',
      phrase: 'free$DELIM$coins$DELIM$for',
      score: 4.328580357863701,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -305.9701598325856,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.157464916192911,
    },
    {
      display_key: 'i want to visit',
      phrase: 'i$DELIM$want$DELIM$to$DELIM$visit',
      score: 4.277481816548573,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.824384957430663,
    },
    {
      display_key: 'buggy sometimes',
      phrase: 'buggy$DELIM$sometimes',
      score: 4.119431995932535,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -994.1282880962664,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.584023222893965,
    },
    {
      display_key: 'another level',
      phrase: 'another$DELIM$level',
      score: 4.2523820931753065,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -541.8093983285174,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.218485413025624,
    },
    {
      display_key: 'its really',
      phrase: 'its$DELIM$really',
      score: 2.8537889442277296,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -903.9291780653458,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.932326929286795,
    },
    {
      display_key: 'all pretty much',
      phrase: 'all$DELIM$pretty$DELIM$much',
      score: 4.302879459487102,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -969.0771543386213,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.419191080735605,
    },
    {
      display_key: "it won't work",
      phrase: "it$DELIM$won't$DELIM$work",
      score: 2.384083358237417,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.95924205246,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.888493781053985,
    },
    {
      display_key: 'to turn on',
      phrase: 'to$DELIM$turn$DELIM$on',
      score: 2.9586962489844235,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.3066997772838,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.470121185304537,
    },
    {
      display_key: 'gives me a',
      phrase: 'gives$DELIM$me$DELIM$a',
      score: 6.401041676336962,
      count: 60,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 486.3549259228251,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 14.278987563238353,
    },
    {
      display_key: 'dinner tonight',
      phrase: 'dinner$DELIM$tonight',
      score: 4.154859958808117,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 996.8424528073709,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.696000973186399,
    },
    {
      display_key: 'trips in advance',
      phrase: 'trips$DELIM$in$DELIM$advance',
      score: 4.3545899757403195,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 997.9379878846262,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.713538817106592,
    },
    {
      display_key: 'need more filters',
      phrase: 'need$DELIM$more$DELIM$filters',
      score: 4.142983156015541,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 997.8557605184616,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.683237275129414,
    },
    {
      display_key: 'little things that',
      phrase: 'little$DELIM$things$DELIM$that',
      score: 4.2523820931753065,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -994.1282880962664,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.615787252532613,
    },
    {
      display_key: 'too often and',
      phrase: 'too$DELIM$often$DELIM$and',
      score: 3.6614927720963233,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.5163889997528,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.762024200643662,
    },
    {
      display_key: 'to decide if',
      phrase: 'to$DELIM$decide$DELIM$if',
      score: 4.027844151165294,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.764251764347172,
    },
    {
      display_key: 'routes based',
      phrase: 'routes$DELIM$based',
      score: 4.380913909240826,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 878.1081177381658,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.821175906442237,
    },
    {
      display_key: 'reach customer',
      phrase: 'reach$DELIM$customer',
      score: 3.4740633823002907,
      count: 29,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -976.4494383996902,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 29.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.468976833563374,
    },
    {
      display_key: 'what am i',
      phrase: 'what$DELIM$am$DELIM$i',
      score: 2.8481783156170786,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9829515042966,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.363610218632253,
    },
    {
      display_key: 'too many connection',
      phrase: 'too$DELIM$many$DELIM$connection',
      score: 4.215279740456394,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.934301550702685,
    },
    {
      display_key: 'let me search',
      phrase: 'let$DELIM$me$DELIM$search',
      score: 4.073123535095165,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 888.2538532259038,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.73241325011326,
    },
    {
      display_key: "i'm hungry",
      phrase: "i'm$DELIM$hungry",
      score: 3.052192583750038,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -829.0152033123176,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.954689001473463,
    },
    {
      display_key: 'i need to find',
      phrase: 'i$DELIM$need$DELIM$to$DELIM$find',
      score: 3.2951413930273534,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 573.4142645817425,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.649482445068916,
    },
    {
      display_key: 'very user friendly interface',
      phrase: 'very$DELIM$user$DELIM$friendly$DELIM$interface',
      score: 4.239942317994696,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9994990977881,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.186363528611924,
    },
    {
      display_key: 'the keyboard covers',
      phrase: 'the$DELIM$keyboard$DELIM$covers',
      score: 4.1311740273129525,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.7440930103929,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.023296873474768,
    },
    {
      display_key: "i couldn't figure out",
      phrase: "i$DELIM$couldn't$DELIM$figure$DELIM$out",
      score: 3.1864437311057925,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.827679931613,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.862731628849877,
    },
    {
      display_key: 'to the cloud',
      phrase: 'to$DELIM$the$DELIM$cloud',
      score: 27.938646960879147,
      count: 26,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.7651554889414,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 26.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 19.387023221607443,
    },
    {
      display_key: 'canceling my',
      phrase: 'canceling$DELIM$my',
      score: 2.3646208429288462,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -572.1311481549594,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.639854370310138,
    },
    {
      display_key: 'i set up',
      phrase: 'i$DELIM$set$DELIM$up',
      score: 2.4527191863357647,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -6.008238957568935,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.544970725256993,
    },
    {
      display_key: 'i care about',
      phrase: 'i$DELIM$care$DELIM$about',
      score: 3.624857634189427,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 445.1956652074654,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.793937997386044,
    },
    {
      display_key: "don't work well",
      phrase: "don't$DELIM$work$DELIM$well",
      score: 3.9083602782074895,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -987.5682333736038,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.460808277997106,
    },
    {
      display_key: "so i don't have",
      phrase: "so$DELIM$i$DELIM$don't$DELIM$have",
      score: 3.205585089169716,
      count: 60,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -835.7961239497724,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 60.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.9503269752832,
    },
    {
      display_key: 'my security',
      phrase: 'my$DELIM$security',
      score: 7.1628574005087104,
      count: 20,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -393.3275800744964,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 20.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 18.337335209599,
    },
    {
      display_key: 'my pictures',
      phrase: 'my$DELIM$pictures',
      score: 3.8258192481958613,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.7651554889414,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.984974150380832,
    },
    {
      display_key: 'many connection',
      phrase: 'many$DELIM$connection',
      score: 4.154859958808117,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.91986431708999,
    },
    {
      display_key: 'new wave',
      phrase: 'new$DELIM$wave',
      score: 13.327984400023015,
      count: 95,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 914.8292626343002,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 95.0 },
      ],
      combined_score: 14.82210563303383,
    },
    {
      display_key: "can't scroll",
      phrase: "can't$DELIM$scroll",
      score: 3.0586360723625763,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.5473208783708,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.63255322185099,
    },
    {
      display_key: 'concert tickets',
      phrase: 'concert$DELIM$tickets',
      score: 7.532329189374916,
      count: 60,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -152.37779851015628,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.625295717660839,
    },
    {
      display_key: 'up too much space',
      phrase: 'up$DELIM$too$DELIM$much$DELIM$space',
      score: 4.215279740456394,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -969.0771543386213,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.39862259749902,
    },
    {
      display_key: 'do my banking',
      phrase: 'do$DELIM$my$DELIM$banking',
      score: 4.1311740273129525,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 759.1668017708078,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.784102649903447,
    },
    {
      display_key: 'for different',
      phrase: 'for$DELIM$different',
      score: 2.956351861433408,
      count: 20,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -399.817981078103,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 20.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 17.46825569350745,
    },
    {
      display_key: 'usually run',
      phrase: 'usually$DELIM$run',
      score: 4.016681089753219,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 878.1081177381658,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.734374510813549,
    },
    {
      display_key: 'app needs some',
      phrase: 'app$DELIM$needs$DELIM$some',
      score: 4.728204514738697,
      count: 25,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.8883563915878,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 25.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 17.774366627575503,
    },
    {
      display_key: 'looking for',
      phrase: 'looking$DELIM$for',
      score: 7.3873475614441615,
      count: 120,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 990.2340726210118,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 120.0 },
      ],
      combined_score: 14.186758106678637,
    },
    {
      display_key: 'let me save',
      phrase: 'let$DELIM$me$DELIM$save',
      score: 3.8461260931125154,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -997.3013790317741,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.645427439295688,
    },
    {
      display_key: 'if you could add',
      phrase: 'if$DELIM$you$DELIM$could$DELIM$add',
      score: 3.624857634189427,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.658835495816845,
    },
    {
      display_key: 'to get directions',
      phrase: 'to$DELIM$get$DELIM$directions',
      score: 4.302879459487102,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.830304921939195,
    },
    {
      display_key: 'my security settings',
      phrase: 'my$DELIM$security$DELIM$settings',
      score: 8.831233848262679,
      count: 20,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -393.3275800744964,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 20.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 18.546720968472776,
    },
    {
      display_key: 'looking for cute',
      phrase: 'looking$DELIM$for$DELIM$cute',
      score: 4.801791766549408,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 999.7400500068626,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.796908755521354,
    },
    {
      display_key: 'without worrying about',
      phrase: 'without$DELIM$worrying$DELIM$about',
      score: 4.178818926977577,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -922.0533899126524,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.33222697020338,
    },
    {
      display_key: 'my route',
      phrase: 'my$DELIM$route',
      score: 3.5363971792435045,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.5163889997528,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.727261738068837,
    },
    {
      display_key: "you'd think if",
      phrase: "you'd$DELIM$think$DELIM$if",
      score: 4.3677122980124095,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -334.12859233490695,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.17406926089717,
    },
    {
      display_key: 'looking for a new',
      phrase: 'looking$DELIM$for$DELIM$a$DELIM$new',
      score: 12.139763130283207,
      count: 90,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 987.065413492395,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 90.0 },
      ],
      combined_score: 14.707704044940682,
    },
    {
      display_key: 'i liked the',
      phrase: 'i$DELIM$liked$DELIM$the',
      score: 4.1972782014164185,
      count: 42,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -991.4189158779168,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 42.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.53944996749221,
    },
    {
      display_key: 'concept but',
      phrase: 'concept$DELIM$but',
      score: 4.016681089753219,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9998806448475,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.400533758853875,
    },
    {
      display_key: 'let me know when',
      phrase: 'let$DELIM$me$DELIM$know$DELIM$when',
      score: 11.589641340328296,
      count: 90,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 658.098636318605,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 60.0 },
      ],
      combined_score: 14.80954544371412,
    },
    {
      display_key: 'way too many issues',
      phrase: 'way$DELIM$too$DELIM$many$DELIM$issues',
      score: 26.52640990262233,
      count: 24,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999202608,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 24.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 21.418060806860314,
    },
    {
      display_key: 'it has encountered',
      phrase: 'it$DELIM$has$DELIM$encountered',
      score: 7.215647942168416,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.6272035481328,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.543945956564693,
    },
    {
      display_key: "couldn't change",
      phrase: "couldn't$DELIM$change",
      score: 2.7198391335888417,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.8883563915878,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.74695850743638,
    },
    {
      display_key: 'many buttons',
      phrase: 'many$DELIM$buttons',
      score: 3.4521491269140547,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -997.813327778886,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.551344296626073,
    },
    {
      display_key: 'way down to',
      phrase: 'way$DELIM$down$DELIM$to',
      score: 4.574482013309213,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.5473208783708,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.035077604421923,
    },
    {
      display_key: 'when things',
      phrase: 'when$DELIM$things',
      score: 14.973882138551046,
      count: 24,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 427.53525302580925,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 24.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 18.01957158456605,
    },
    {
      display_key: 'outside of my',
      phrase: 'outside$DELIM$of$DELIM$my',
      score: 2.7932611800831593,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -678.2498104173666,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.814745280569513,
    },
    {
      display_key: "i couldn't see",
      phrase: "i$DELIM$couldn't$DELIM$see",
      score: 3.0078367845458804,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.7440930103929,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.705956382942455,
    },
    {
      display_key: 'get lost',
      phrase: 'get$DELIM$lost',
      score: 2.4158980832618275,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -994.1282880962664,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.050379029204136,
    },
    {
      display_key: 'in another language',
      phrase: 'in$DELIM$another$DELIM$language',
      score: 2.649542903206399,
      count: 25,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -769.9776825309583,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 25.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.278917564786337,
    },
    {
      display_key: 'send me',
      phrase: 'send$DELIM$me',
      score: 3.308175910560105,
      count: 60,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -280.0164901738973,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.809979027763175,
    },
    {
      display_key: 'why did you change',
      phrase: 'why$DELIM$did$DELIM$you$DELIM$change',
      score: 3.16555859082392,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -995.5483489993392,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.396623381217312,
    },
    {
      display_key: 'connecting with friends',
      phrase: 'connecting$DELIM$with$DELIM$friends',
      score: 4.341546230248303,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -0.4593086155081494,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.101615264551135,
    },
    {
      display_key: "i couldn't figure",
      phrase: "i$DELIM$couldn't$DELIM$figure",
      score: 3.0846842178175202,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.827679931613,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.830275443365124,
    },
    {
      display_key: 'i contacted customer',
      phrase: 'i$DELIM$contacted$DELIM$customer',
      score: 3.81574598433589,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.95924205246,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.358815252179268,
    },
    {
      display_key: "app doesn't work properly",
      phrase: "app$DELIM$doesn't$DELIM$work$DELIM$properly",
      score: 3.6614927720963233,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.79345651909755,
    },
    {
      display_key: 'apple maps instead',
      phrase: 'apple$DELIM$maps$DELIM$instead',
      score: 4.3545899757403195,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.5163889997528,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.935383730794875,
    },
    {
      display_key: 'awesome game',
      phrase: 'awesome$DELIM$game',
      score: 15.724955755768308,
      count: 20,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 549.8671843994498,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 20.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 18.87411215078182,
    },
    {
      display_key: 'search for items',
      phrase: 'search$DELIM$for$DELIM$items',
      score: 3.020377858725627,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -978.1761506296746,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.169549604511818,
    },
    {
      display_key: 'i wanted to use',
      phrase: 'i$DELIM$wanted$DELIM$to$DELIM$use',
      score: 2.4778960089504225,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -899.9749906521961,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.772906763164007,
    },
    {
      display_key: 'they said they would',
      phrase: 'they$DELIM$said$DELIM$they$DELIM$would',
      score: 3.6339475556249723,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.95924205246,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.309998605044733,
    },
    {
      display_key: 'nothing happens when',
      phrase: 'nothing$DELIM$happens$DELIM$when',
      score: 3.670767490553766,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9864101187999,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.784385484959937,
    },
    {
      display_key: 'app in general',
      phrase: 'app$DELIM$in$DELIM$general',
      score: 3.468676256796866,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -994.1282880962664,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.412080973086532,
    },
    {
      display_key: 'the next level',
      phrase: 'the$DELIM$next$DELIM$level',
      score: 4.315691663290911,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.678035977381,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.04830070839209,
    },
    {
      display_key: 'too many buttons',
      phrase: 'too$DELIM$many$DELIM$buttons',
      score: 3.775977578055377,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -997.813327778886,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.641006634439705,
    },
    {
      display_key: 'are blurry',
      phrase: 'are$DELIM$blurry',
      score: 4.107756491642093,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.908462607297217,
    },
    {
      display_key: 'to search',
      phrase: 'to$DELIM$search',
      score: 15.553614382256898,
      count: 90,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -752.0605812753026,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 90.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.471451525793414,
    },
    {
      display_key: 'so much easier to',
      phrase: 'so$DELIM$much$DELIM$easier$DELIM$to',
      score: 3.16555859082392,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.523350011623853,
    },
    {
      display_key: 'was too small',
      phrase: 'was$DELIM$too$DELIM$small',
      score: 4.166805019088005,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9995224535458,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.229587972718615,
    },
    {
      display_key: 'way too hard',
      phrase: 'way$DELIM$too$DELIM$hard',
      score: 3.756402455792948,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -334.12859233490695,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.023291596173939,
    },
    {
      display_key: 'what am',
      phrase: 'what$DELIM$am',
      score: 2.6945430355202333,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9829515042966,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.308159246102107,
    },
    {
      display_key: 'make a purchase',
      phrase: 'make$DELIM$a$DELIM$purchase',
      score: 2.607821922592098,
      count: 22,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9454922310717,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 22.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 18.22373854940833,
    },
    {
      display_key: "i don't need to",
      phrase: "i$DELIM$don't$DELIM$need$DELIM$to",
      score: 5.470491778334463,
      count: 60,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -755.1214848108768,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 60.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.44970954042456,
    },
    {
      display_key: 'if you could',
      phrase: 'if$DELIM$you$DELIM$could',
      score: 4.253482426828281,
      count: 60,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 509.1624946113055,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.864072960401439,
    },
    {
      display_key: 'call them',
      phrase: 'call$DELIM$them',
      score: 2.7404203067622412,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9994039506911,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.692766673530222,
    },
    {
      display_key: 'doing wrong',
      phrase: 'doing$DELIM$wrong',
      score: 3.775977578055377,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9829515042966,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.645589926472525,
    },
    {
      display_key: 'make some',
      phrase: 'make$DELIM$some',
      score: 2.922881120747595,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 679.5092277853645,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.505933434628552,
    },
    {
      display_key: 'be scanned',
      phrase: 'be$DELIM$scanned',
      score: 2.8370229366182125,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.5473208783708,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.55733937384738,
    },
    {
      display_key: 'the color of',
      phrase: 'the$DELIM$color$DELIM$of',
      score: 27.22713453242442,
      count: 25,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.8883563915878,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 25.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 19.525035161469294,
    },
    {
      display_key: 'does anyone actually',
      phrase: 'does$DELIM$anyone$DELIM$actually',
      score: 3.6158130490203924,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.4075184383723,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.717209233790847,
    },
    {
      display_key: "it's so annoying",
      phrase: "it's$DELIM$so$DELIM$annoying",
      score: 3.258094624357458,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -996.9737115858732,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.438681563157962,
    },
    {
      display_key: 'you log',
      phrase: 'you$DELIM$log',
      score: 3.7370291389146675,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -996.9737115858732,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.575829957586338,
    },
    {
      display_key: 'with everyone',
      phrase: 'with$DELIM$everyone',
      score: 3.8258192481958613,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 997.1616772504597,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.608532086724503,
    },
    {
      display_key: 'up too much',
      phrase: 'up$DELIM$too$DELIM$much',
      score: 3.940238386605322,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -969.0771543386213,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.331147866098974,
    },
    {
      display_key: 'could tap on',
      phrase: 'could$DELIM$tap$DELIM$on',
      score: 7.179916907709198,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 14.342308291859196,
    },
    {
      display_key: 'every time the',
      phrase: 'every$DELIM$time$DELIM$the',
      score: 2.9708303361466273,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.3066997772838,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.474213958623267,
    },
    {
      display_key: 'my old one',
      phrase: 'my$DELIM$old$DELIM$one',
      score: 2.701506260161173,
      count: 21,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -987.5640682296116,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 21.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 17.535464894919173,
    },
    {
      display_key: 'will not open',
      phrase: 'will$DELIM$not$DELIM$open',
      score: 3.4115118310845562,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9997615783489,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.094132572544314,
    },
    {
      display_key: 'cross streets',
      phrase: 'cross$DELIM$streets',
      score: 4.178818926977577,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 282.39646606718065,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.001109471022692,
    },
    {
      display_key: 'if you',
      phrase: 'if$DELIM$you',
      score: 4.574467741686243,
      count: 210,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 442.44877622662614,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 120.0 },
        { sentiment: '600.0-*', count: 60.0 },
      ],
      combined_score: 13.937824249906269,
    },
    {
      display_key: "what i'm searching",
      phrase: "what$DELIM$i'm$DELIM$searching",
      score: 4.005579702713588,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.7883087252454,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.070895177115409,
    },
    {
      display_key: 'if you had',
      phrase: 'if$DELIM$you$DELIM$had',
      score: 3.0078367845458804,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 193.90938277119173,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.706434970744533,
    },
    {
      display_key: 'shopping centers',
      phrase: 'shopping$DELIM$centers',
      score: 4.239942317994696,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9829515042966,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.76148028112669,
    },
    {
      display_key: 'my birth month',
      phrase: 'my$DELIM$birth$DELIM$month',
      score: 4.315691663290911,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.8883563915878,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.208643375397541,
    },
    {
      display_key: 'to keep playing',
      phrase: 'to$DELIM$keep$DELIM$playing',
      score: 3.2507849480279725,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 549.8671843994499,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.647978196338252,
    },
    {
      display_key: "i'm having trouble getting",
      phrase: "i'm$DELIM$having$DELIM$trouble$DELIM$getting",
      score: 2.809512820773139,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9708666273223,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.275833731771634,
    },
    {
      display_key: 'never connects',
      phrase: 'never$DELIM$connects',
      score: 2.9830642923389723,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.588536651821734,
    },
    {
      display_key: 'every game and',
      phrase: 'every$DELIM$game$DELIM$and',
      score: 3.574950420215012,
      count: 29,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -976.4494383996902,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 29.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.497603228728206,
    },
    {
      display_key: 'notifications off',
      phrase: 'notifications$DELIM$off',
      score: 3.2218711172135603,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -960.7906600940787,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.100598538191916,
    },
    {
      display_key: 'like listening to',
      phrase: 'like$DELIM$listening$DELIM$to',
      score: 4.2275750779463355,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 999.6074292706375,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.679117635594556,
    },
    {
      display_key: 'look at pictures',
      phrase: 'look$DELIM$at$DELIM$pictures',
      score: 4.302879459487102,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 990.7015348038005,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.74100677442166,
    },
    {
      display_key: 'hip hop',
      phrase: 'hip$DELIM$hop',
      score: 18.801991120458613,
      count: 112,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 0.6795948469354618,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 112.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.464919896927615,
    },
    {
      display_key: 'search bar at',
      phrase: 'search$DELIM$bar$DELIM$at',
      score: 4.277481816548573,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.216004772635,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.808883978409424,
    },
    {
      display_key: "every time i'm",
      phrase: "every$DELIM$time$DELIM$i'm",
      score: 3.443944447233515,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -996.9737115858732,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.494156465836662,
    },
    {
      display_key: 'its just',
      phrase: 'its$DELIM$just',
      score: 3.1864437311057925,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -994.1282880962664,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.32721341119166,
    },
    {
      display_key: "don't change anything",
      phrase: "don't$DELIM$change$DELIM$anything",
      score: 3.756402455792948,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 188.7967315602404,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.942661849339606,
    },
    {
      display_key: "it'd be great",
      phrase: "it'd$DELIM$be$DELIM$great",
      score: 3.54504856848027,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.636572342057327,
    },
    {
      display_key: 'its too slow',
      phrase: 'its$DELIM$too$DELIM$slow',
      score: 3.8258192481958613,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999999965552,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.837358220488113,
    },
    {
      display_key: "i don't think i",
      phrase: "i$DELIM$don't$DELIM$think$DELIM$i",
      score: 2.4485726657193303,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -978.0723212382242,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.948743859747545,
    },
    {
      display_key: 'ever shows up',
      phrase: 'ever$DELIM$shows$DELIM$up',
      score: 4.119431995932535,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9998805304043,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.351685106199426,
    },
    {
      display_key: 'adding to',
      phrase: 'adding$DELIM$to',
      score: 9.622211268620132,
      count: 20,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -945.8076871771322,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 20.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 18.82272708346658,
    },
    {
      display_key: 'there is no one',
      phrase: 'there$DELIM$is$DELIM$no$DELIM$one',
      score: 2.887922131034356,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.4075184383723,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.492429751371537,
    },
    {
      display_key: 'i liked the old',
      phrase: 'i$DELIM$liked$DELIM$the$DELIM$old',
      score: 5.139074486775271,
      count: 42,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -991.4189158779168,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 42.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.74188670187359,
    },
    {
      display_key: 'this bug',
      phrase: 'this$DELIM$bug',
      score: 2.329784780991175,
      count: 28,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -966.2921964706513,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 28.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 16.142726338238877,
    },
    {
      display_key: 'outside of my house',
      phrase: 'outside$DELIM$of$DELIM$my$DELIM$house',
      score: 3.8563605269202434,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -678.2498104173666,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.137259355619358,
    },
    {
      display_key: 'contacted customer',
      phrase: 'contacted$DELIM$customer',
      score: 3.280222106096944,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.95924205246,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.207590202135489,
    },
    {
      display_key: 'sent them',
      phrase: 'sent$DELIM$them',
      score: 3.9945394803408414,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -6.008238957568935,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.032701742745516,
    },
    {
      display_key: 'for such a',
      phrase: 'for$DELIM$such$DELIM$a',
      score: 4.594823457958946,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9570250097174,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 15.516438522309416,
    },
    {
      display_key: "i'm done with this app",
      phrase: "i'm$DELIM$done$DELIM$with$DELIM$this$DELIM$app",
      score: 7.109505751568978,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.9999994338713,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 17.138564626677244,
    },
    {
      display_key: 'to turn on location',
      phrase: 'to$DELIM$turn$DELIM$on$DELIM$location',
      score: 4.239942317994696,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: -999.3066997772838,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 30.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 14.829922135326967,
    },
    {
      display_key: 'from my friends',
      phrase: 'from$DELIM$my$DELIM$friends',
      score: 4.277481816548573,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 990.7015348038005,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 13.735086809913128,
    },
    {
      display_key: 'if you could put',
      phrase: 'if$DELIM$you$DELIM$could$DELIM$put',
      score: 4.096146950652729,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 282.39646606718065,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 30.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 13.981127581484252,
    },
    {
      display_key: 'more than',
      phrase: 'more$DELIM$than',
      score: 0.0,
      count: 0,
      total_doc_count: 0,
      avg_rating: 0.0,
      avg_sentiment_compound: 0.0,
      phrase_sentiment_distribution: [],
      combined_score: 0.0,
      phrase_state: { state: 'MUTED', changed_on: 1584374795295 },
    },
    {
      display_key: 'have done a',
      phrase: 'have$DELIM$done$DELIM$a',
      score: 6.939373532187102,
      count: 30,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 735.9285231554303,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 0.0 },
        { sentiment: '600.0-*', count: 30.0 },
      ],
      combined_score: 14.308231982915384,
    },
    {
      display_key: "know if it's",
      phrase: "know$DELIM$if$DELIM$it's",
      score: 26.52640990262233,
      count: 24,
      total_doc_count: 10000,
      avg_rating: null,
      avg_sentiment_compound: 282.39646606718065,
      phrase_sentiment_distribution: [
        { sentiment: '-1000.0-0.0', count: 0.0 },
        { sentiment: '0.0-600.0', count: 24.0 },
        { sentiment: '600.0-*', count: 0.0 },
      ],
      combined_score: 18.611339075801013,
    },
  ],
};
