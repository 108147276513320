import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FloatingArrow, arrow, useFloating, useMergeRefs, offset, flip, shift, autoUpdate } from '@floating-ui/react';
import { hasPath } from 'ramda';
import Mousetrap from 'mousetrap';

import { bemPrefix } from 'src/utils';
import { useOnOutsideClick } from 'src/hooks/useOnOutsideClick';
import { KeyboardKey, isKeyboardEvent } from '../util-handlers';

import './simple-popover.scss';

export enum PopoverPlacement {
  top = 'top',
  topStart = 'top-start',
  topEnd = 'top-end',
  bottom = 'bottom',
  bottomStart = 'bottom-start',
  bottomEnd = 'bottom-end',
}

export interface SimplePopoverProps {
  className?: string;
  targetEl: HTMLElement | null;
  isOpen: boolean;
  placement?: PopoverPlacement;
  withArrow?: boolean;
  onClose?(event?: React.SyntheticEvent): void;
  children?: React.ReactNode;
  innerRef?: React.Ref<HTMLElement>;
}

const bem = bemPrefix('simple-popover');
const PADDING = 5;
const ARROW_WIDTH = 8;
const ARROW_HEIGHT = 4;
const ARROW_GAP = 4;

export const SimplePopover: React.FC<SimplePopoverProps> & { PopoverPlacement: typeof PopoverPlacement } = ({
  className = '',
  placement = PopoverPlacement.bottomEnd,
  targetEl,
  withArrow = true,
  innerRef,
  children,
  onClose = () => null,
  isOpen,
}: SimplePopoverProps) => {
  const mousetrap = useMemo(() => new Mousetrap(), []);

  const handleClickOutside = useCallback(
    (event: React.SyntheticEvent | React.KeyboardEvent) => {
      if (!isOpen) return;

      if (isKeyboardEvent(event) && event.key === KeyboardKey.ESC) {
        onClose(event);
        return;
      }
      if (targetEl && hasPath(['contains'], targetEl)) {
        if ((targetEl as HTMLElement).contains(event.target as Node)) {
          return;
        }
      }
      onClose(event);
    },
    [targetEl, onClose],
  );

  useEffect(() => {
    mousetrap.bind(['esc'], handleClickOutside as any);

    return () => {
      mousetrap.unbind(['esc']);
    };
  });

  const arrowRef = useRef(null);

  const {
    refs,
    floatingStyles,
    context,
    placement: realPlacement,
  } = useFloating({
    placement,
    elements: { reference: targetEl },
    whileElementsMounted: autoUpdate,
    // The ordering and options of these middleware are crucial:
    middleware: [
      // This must occur first, so it does flipping top/bottom, but is configured not to flip
      // horizontally, so that...
      flip({ crossAxis: false }),
      // this can handle all horizontal shifting.
      shift({ padding: PADDING }),
      // This must come after the positioning middleware.
      arrow({ element: arrowRef }),
      offset(ARROW_HEIGHT + ARROW_GAP),
    ],
  });

  const floatingRef = useMergeRefs([refs.setFloating, innerRef]);

  useOnOutsideClick([{ current: targetEl }, refs.floating], handleClickOutside as () => void);

  if (!isOpen) return null;

  return (
    <div ref={floatingRef} style={floatingStyles} className={`${className} ${bem('body')}`}>
      {children}
      {withArrow && (
        <FloatingArrow
          fill="white"
          height={ARROW_HEIGHT}
          width={ARROW_WIDTH}
          ref={arrowRef}
          className={bem('arrow', realPlacement)}
          context={context}
        />
      )}
    </div>
  );
};
SimplePopover.PopoverPlacement = PopoverPlacement;
