import React, { useEffect, useRef } from 'react';
import { bemPrefix } from 'src/utils';
import { SimplePopover, usePopoverProps, SimplePopoverProps } from '../popover';
import { Button } from '../button';
import { Icon } from '../icons';

import './dots-menu.scss';

export interface DotsMenuProps {
  className?: string;
  isOpen?: SimplePopoverProps['isOpen'];
  placement?: SimplePopoverProps['placement'];
  children: React.ReactNode;
  onMenuToggle?(show: boolean): void;
}

const bem = bemPrefix('app-dots-menu');

export const DotsMenu: React.FC<DotsMenuProps> = ({
  className = '',
  isOpen,
  children,
  placement,
  onMenuToggle = null,
}) => {
  const { isOpenPopover, closePopover, togglePopover } = usePopoverProps(isOpen);

  const targetRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!isOpen) {
      closePopover();
    }
  }, [isOpen]);

  useEffect(() => {
    if (onMenuToggle) {
      onMenuToggle(isOpenPopover);
    }
  }, [isOpenPopover]);

  return (
    <div className={`${bem()} ${className}`}>
      <Button className={bem('menu-dots')} onClick={togglePopover} forwardRef={targetRef} normalize>
        <Icon className={bem('icon')} name="dots" />
      </Button>
      {isOpenPopover && (
        <SimplePopover
          targetEl={targetRef.current}
          className={bem('container')}
          placement={placement}
          isOpen={isOpenPopover}
          onClose={closePopover}
        >
          {children}
        </SimplePopover>
      )}
    </div>
  );
};

DotsMenu.displayName = 'DotsMenu';
