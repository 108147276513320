import React, { useRef, useCallback } from 'react';
import { bemPrefix } from 'src/utils';
import { SimplePopover, usePopoverProps } from 'src/components/molecules/popover';
import { Icon } from 'src/components/molecules';
import { EventMetricsSDK } from 'src/utils/event-metrics';
import { Events } from 'src/actions/event-metrics';

import './info-notification.scss';

const bem = bemPrefix('info-notification');

const noop = () => null;
interface InfoNotificationProps {
  sendEvent(event: Events): void;
}

export const InfoNotification: React.FC<InfoNotificationProps> = ({ sendEvent = noop }) => {
  const { isOpenPopover, closePopover, togglePopover } = usePopoverProps();
  const targetRef = useRef<HTMLElement | null>(null);

  const handleToggle = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    togglePopover();
    EventMetricsSDK.send(EventMetricsSDK.Metrics.NFS_INFO_ICON);
    sendEvent(Events.NFS_INFO_ICON);
  }, []);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <span onClick={handleToggle} ref={targetRef}>
        <Icon name="scoreInfo" className={bem('info-icon')} />
      </span>
      {isOpenPopover && (
        <SimplePopover
          isOpen={isOpenPopover}
          targetEl={targetRef.current}
          onClose={closePopover}
          className={bem('tooltip')}
          placement={SimplePopover.PopoverPlacement.topStart}
          withArrow={false}
        >
          <div className={bem('tooltip-container')}>
            <h3 className={bem('tooltip__title')}>What does NFS measure?</h3>
            <div className={bem('tooltip__body')}>
              <p>
                Net Fan Score incorporates the totality of your Fan Signals® responses to generate a score for a
                selected date range, from -100 to 100. This score is similar to the Love Score, but more heavily weights
                customers who shift from Fan to Risk, or vice versa. Your Net Fan Score highlights changes and
                prevailing trends in sentiment across your entire customer base.
              </p>
            </div>
          </div>
        </SimplePopover>
      )}
    </div>
  );
};
