// GET /app-store/store/itunes/apps/331177714/ratings-histograms?start_date=2020-10-11&end_date=2020-11-18&page_size=2000
export default {
  data: [
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1605618850069,
      store_observed_time: 1605484800000,
      all_ratings: { 5: 2408, 1: 92, 2: 26, 3: 111, 4: 459 },
      all_ratings_average: 4.5,
      all_ratings_count: 3096,
      current_ratings: { 5: 2266, 1: 24, 2: 16, 3: 82, 4: 420 },
      current_ratings_average: 4.5,
      current_ratings_count: 2808,
      version: '15.7.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1605618850069,
      store_observed_time: 1605484800000,
      all_ratings: { 5: 813, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 853,
      current_ratings: { 5: 813, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 853,
      version: '15.7.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605618850069,
      store_observed_time: 1605484800000,
      all_ratings: { 5: 682069, 1: 4711, 2: 3951, 3: 30861, 4: 132942 },
      all_ratings_average: 4.5,
      all_ratings_count: 854534,
      current_ratings: { 5: 658261, 1: 2942, 2: 3204, 3: 29418, 4: 127738 },
      current_ratings_average: 4.5,
      current_ratings_count: 821563,
      version: '15.7.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SG',
      ingest_time: 1605532451637,
      store_observed_time: 1605398400000,
      all_ratings: { 5: 21, 1: 0, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 24,
      current_ratings: { 5: 21, 1: 0, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 24,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605532451637,
      store_observed_time: 1605398400000,
      all_ratings: { 5: 681914, 1: 4712, 2: 3950, 3: 30860, 4: 132902 },
      all_ratings_average: 4.5,
      all_ratings_count: 854338,
      current_ratings: { 5: 658119, 1: 2942, 2: 3203, 3: 29417, 4: 127699 },
      current_ratings_average: 4.5,
      current_ratings_count: 821380,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'JP',
      ingest_time: 1605446053218,
      store_observed_time: 1605312000000,
      all_ratings: { 5: 54, 1: 0, 2: 1, 3: 2, 4: 6 },
      all_ratings_average: 5.0,
      all_ratings_count: 63,
      current_ratings: { 5: 54, 1: 0, 2: 1, 3: 2, 4: 6 },
      current_ratings_average: 5.0,
      current_ratings_count: 63,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1605446053218,
      store_observed_time: 1605312000000,
      all_ratings: { 5: 193, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 237,
      current_ratings: { 5: 193, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 237,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605446053218,
      store_observed_time: 1605312000000,
      all_ratings: { 5: 681755, 1: 4712, 2: 3949, 3: 30866, 4: 132874 },
      all_ratings_average: 4.5,
      all_ratings_count: 854156,
      current_ratings: { 5: 657959, 1: 2942, 2: 3202, 3: 29423, 4: 127672 },
      current_ratings_average: 4.5,
      current_ratings_count: 821198,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1605359652565,
      store_observed_time: 1605225600000,
      all_ratings: { 5: 2407, 1: 92, 2: 26, 3: 111, 4: 458 },
      all_ratings_average: 4.5,
      all_ratings_count: 3094,
      current_ratings: { 5: 2265, 1: 24, 2: 16, 3: 82, 4: 419 },
      current_ratings_average: 4.5,
      current_ratings_count: 2806,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KR',
      ingest_time: 1605359652565,
      store_observed_time: 1605225600000,
      all_ratings: { 5: 95, 1: 0, 2: 1, 3: 4, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 103,
      current_ratings: { 5: 95, 1: 0, 2: 1, 3: 4, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 103,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605359652565,
      store_observed_time: 1605225600000,
      all_ratings: { 5: 681588, 1: 4710, 2: 3949, 3: 30862, 4: 132844 },
      all_ratings_average: 4.5,
      all_ratings_count: 853953,
      current_ratings: { 5: 657792, 1: 2940, 2: 3202, 3: 29419, 4: 127642 },
      current_ratings_average: 4.5,
      current_ratings_count: 820995,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1605273256331,
      store_observed_time: 1605139200000,
      all_ratings: { 5: 2405, 1: 92, 2: 26, 3: 111, 4: 458 },
      all_ratings_average: 4.5,
      all_ratings_count: 3092,
      current_ratings: { 5: 2263, 1: 24, 2: 16, 3: 82, 4: 419 },
      current_ratings_average: 4.5,
      current_ratings_count: 2804,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605273256331,
      store_observed_time: 1605139200000,
      all_ratings: { 5: 681309, 1: 4709, 2: 3949, 3: 30853, 4: 132804 },
      all_ratings_average: 4.5,
      all_ratings_count: 853624,
      current_ratings: { 5: 657514, 1: 2939, 2: 3202, 3: 29409, 4: 127602 },
      current_ratings_average: 4.5,
      current_ratings_count: 820666,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1605186852562,
      store_observed_time: 1605052800000,
      all_ratings: { 5: 2404, 1: 92, 2: 26, 3: 111, 4: 458 },
      all_ratings_average: 4.5,
      all_ratings_count: 3091,
      current_ratings: { 5: 2262, 1: 24, 2: 16, 3: 82, 4: 419 },
      current_ratings_average: 4.5,
      current_ratings_count: 2803,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1605186852562,
      store_observed_time: 1605052800000,
      all_ratings: { 5: 812, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 852,
      current_ratings: { 5: 812, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 852,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605186852562,
      store_observed_time: 1605052800000,
      all_ratings: { 5: 681005, 1: 4707, 2: 3949, 3: 30833, 4: 132750 },
      all_ratings_average: 4.5,
      all_ratings_count: 853244,
      current_ratings: { 5: 657210, 1: 2937, 2: 3202, 3: 29389, 4: 127548 },
      current_ratings_average: 4.5,
      current_ratings_count: 820286,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1605100452115,
      store_observed_time: 1604966400000,
      all_ratings: { 5: 2404, 1: 92, 2: 26, 3: 111, 4: 457 },
      all_ratings_average: 4.5,
      all_ratings_count: 3090,
      current_ratings: { 5: 2262, 1: 24, 2: 16, 3: 82, 4: 418 },
      current_ratings_average: 4.5,
      current_ratings_count: 2802,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NL',
      ingest_time: 1605100452115,
      store_observed_time: 1604966400000,
      all_ratings: { 5: 281, 1: 4, 2: 5, 3: 19, 4: 81 },
      all_ratings_average: 4.5,
      all_ratings_count: 390,
      current_ratings: { 5: 268, 1: 3, 2: 2, 3: 18, 4: 81 },
      current_ratings_average: 4.5,
      current_ratings_count: 372,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605100452115,
      store_observed_time: 1604966400000,
      all_ratings: { 5: 680644, 1: 4701, 2: 3947, 3: 30826, 4: 132716 },
      all_ratings_average: 4.5,
      all_ratings_count: 852834,
      current_ratings: { 5: 656850, 1: 2931, 2: 3200, 3: 29382, 4: 127512 },
      current_ratings_average: 4.5,
      current_ratings_count: 819875,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1605014053206,
      store_observed_time: 1604880000000,
      all_ratings: { 5: 2403, 1: 92, 2: 26, 3: 111, 4: 457 },
      all_ratings_average: 4.5,
      all_ratings_count: 3089,
      current_ratings: { 5: 2261, 1: 24, 2: 16, 3: 82, 4: 418 },
      current_ratings_average: 4.5,
      current_ratings_count: 2801,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1605014053206,
      store_observed_time: 1604880000000,
      all_ratings: { 5: 192, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 236,
      current_ratings: { 5: 192, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 236,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ID',
      ingest_time: 1605014053206,
      store_observed_time: 1604880000000,
      all_ratings: { 5: 39, 1: 0, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 42,
      current_ratings: { 5: 39, 1: 0, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 42,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605014053206,
      store_observed_time: 1604880000000,
      all_ratings: { 5: 680315, 1: 4700, 2: 3948, 3: 30823, 4: 132653 },
      all_ratings_average: 4.5,
      all_ratings_count: 852439,
      current_ratings: { 5: 656520, 1: 2930, 2: 3201, 3: 29379, 4: 127450 },
      current_ratings_average: 4.5,
      current_ratings_count: 819480,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1604927651281,
      store_observed_time: 1604793600000,
      all_ratings: { 5: 2402, 1: 92, 2: 26, 3: 111, 4: 457 },
      all_ratings_average: 4.5,
      all_ratings_count: 3088,
      current_ratings: { 5: 2260, 1: 24, 2: 16, 3: 82, 4: 418 },
      current_ratings_average: 4.5,
      current_ratings_count: 2800,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1604927651281,
      store_observed_time: 1604793600000,
      all_ratings: { 5: 191, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 235,
      current_ratings: { 5: 191, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 235,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'HK',
      ingest_time: 1604927651281,
      store_observed_time: 1604793600000,
      all_ratings: { 5: 65, 1: 0, 2: 0, 3: 1, 4: 8 },
      all_ratings_average: 5.0,
      all_ratings_count: 74,
      current_ratings: { 5: 65, 1: 0, 2: 0, 3: 1, 4: 8 },
      current_ratings_average: 5.0,
      current_ratings_count: 74,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1604927651281,
      store_observed_time: 1604793600000,
      all_ratings: { 5: 811, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 851,
      current_ratings: { 5: 811, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 851,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604927651281,
      store_observed_time: 1604793600000,
      all_ratings: { 5: 679893, 1: 4695, 2: 3945, 3: 30799, 4: 132559 },
      all_ratings_average: 4.5,
      all_ratings_count: 851891,
      current_ratings: { 5: 656099, 1: 2925, 2: 3197, 3: 29354, 4: 127357 },
      current_ratings_average: 4.5,
      current_ratings_count: 818932,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1604841253590,
      store_observed_time: 1604707200000,
      all_ratings: { 5: 2402, 1: 92, 2: 26, 3: 111, 4: 456 },
      all_ratings_average: 4.5,
      all_ratings_count: 3087,
      current_ratings: { 5: 2260, 1: 24, 2: 16, 3: 82, 4: 417 },
      current_ratings_average: 4.5,
      current_ratings_count: 2799,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MY',
      ingest_time: 1604841253590,
      store_observed_time: 1604707200000,
      all_ratings: { 5: 41, 1: 0, 2: 0, 3: 0, 4: 10 },
      all_ratings_average: 5.0,
      all_ratings_count: 51,
      current_ratings: { 5: 41, 1: 0, 2: 0, 3: 0, 4: 10 },
      current_ratings_average: 5.0,
      current_ratings_count: 51,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'JP',
      ingest_time: 1604841253590,
      store_observed_time: 1604707200000,
      all_ratings: { 5: 53, 1: 0, 2: 1, 3: 2, 4: 6 },
      all_ratings_average: 5.0,
      all_ratings_count: 62,
      current_ratings: { 5: 53, 1: 0, 2: 1, 3: 2, 4: 6 },
      current_ratings_average: 5.0,
      current_ratings_count: 62,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1604841253590,
      store_observed_time: 1604707200000,
      all_ratings: { 5: 810, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 850,
      current_ratings: { 5: 810, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 850,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604841253590,
      store_observed_time: 1604707200000,
      all_ratings: { 5: 679504, 1: 4692, 2: 3942, 3: 30784, 4: 132516 },
      all_ratings_average: 4.5,
      all_ratings_count: 851438,
      current_ratings: { 5: 655711, 1: 2922, 2: 3194, 3: 29339, 4: 127313 },
      current_ratings_average: 4.5,
      current_ratings_count: 818479,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1604754850715,
      store_observed_time: 1604620800000,
      all_ratings: { 5: 2402, 1: 92, 2: 26, 3: 111, 4: 455 },
      all_ratings_average: 4.5,
      all_ratings_count: 3086,
      current_ratings: { 5: 2260, 1: 24, 2: 16, 3: 82, 4: 416 },
      current_ratings_average: 4.5,
      current_ratings_count: 2798,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1604754850715,
      store_observed_time: 1604620800000,
      all_ratings: { 5: 809, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 849,
      current_ratings: { 5: 809, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 849,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604754850715,
      store_observed_time: 1604620800000,
      all_ratings: { 5: 679235, 1: 4694, 2: 3940, 3: 30774, 4: 132469 },
      all_ratings_average: 4.5,
      all_ratings_count: 851112,
      current_ratings: { 5: 655442, 1: 2924, 2: 3192, 3: 29329, 4: 127266 },
      current_ratings_average: 4.5,
      current_ratings_count: 818153,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KR',
      ingest_time: 1604668451351,
      store_observed_time: 1604534400000,
      all_ratings: { 5: 94, 1: 0, 2: 1, 3: 4, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 102,
      current_ratings: { 5: 94, 1: 0, 2: 1, 3: 4, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 102,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'DE',
      ingest_time: 1604668451351,
      store_observed_time: 1604534400000,
      all_ratings: { 5: 35, 1: 0, 2: 0, 3: 2, 4: 5 },
      all_ratings_average: 5.0,
      all_ratings_count: 42,
      current_ratings: { 5: 35, 1: 0, 2: 0, 3: 2, 4: 5 },
      current_ratings_average: 5.0,
      current_ratings_count: 42,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1604668451351,
      store_observed_time: 1604534400000,
      all_ratings: { 5: 808, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 848,
      current_ratings: { 5: 808, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 848,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604668451351,
      store_observed_time: 1604534400000,
      all_ratings: { 5: 679028, 1: 4694, 2: 3937, 3: 30765, 4: 132438 },
      all_ratings_average: 4.5,
      all_ratings_count: 850862,
      current_ratings: { 5: 655234, 1: 2924, 2: 3189, 3: 29321, 4: 127235 },
      current_ratings_average: 4.5,
      current_ratings_count: 817903,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1604582051436,
      store_observed_time: 1604448000000,
      all_ratings: { 5: 2402, 1: 92, 2: 26, 3: 111, 4: 454 },
      all_ratings_average: 4.5,
      all_ratings_count: 3085,
      current_ratings: { 5: 2260, 1: 24, 2: 16, 3: 82, 4: 415 },
      current_ratings_average: 4.5,
      current_ratings_count: 2797,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604582051436,
      store_observed_time: 1604448000000,
      all_ratings: { 5: 678911, 1: 4692, 2: 3935, 3: 30759, 4: 132432 },
      all_ratings_average: 4.5,
      all_ratings_count: 850729,
      current_ratings: { 5: 655116, 1: 2922, 2: 3187, 3: 29315, 4: 127230 },
      current_ratings_average: 4.5,
      current_ratings_count: 817770,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1604495652037,
      store_observed_time: 1604361600000,
      all_ratings: { 5: 190, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 234,
      current_ratings: { 5: 190, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 234,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604495652037,
      store_observed_time: 1604361600000,
      all_ratings: { 5: 678801, 1: 4692, 2: 3934, 3: 30759, 4: 132413 },
      all_ratings_average: 4.5,
      all_ratings_count: 850599,
      current_ratings: { 5: 655005, 1: 2922, 2: 3186, 3: 29315, 4: 127212 },
      current_ratings_average: 4.5,
      current_ratings_count: 817640,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604409268658,
      store_observed_time: 1604275200000,
      all_ratings: { 5: 678696, 1: 4692, 2: 3934, 3: 30747, 4: 132403 },
      all_ratings_average: 4.5,
      all_ratings_count: 850472,
      current_ratings: { 5: 654900, 1: 2922, 2: 3186, 3: 29303, 4: 127202 },
      current_ratings_average: 4.5,
      current_ratings_count: 817513,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1604322851174,
      store_observed_time: 1604188800000,
      all_ratings: { 5: 807, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 847,
      current_ratings: { 5: 807, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 847,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604322851174,
      store_observed_time: 1604188800000,
      all_ratings: { 5: 678580, 1: 4690, 2: 3934, 3: 30748, 4: 132386 },
      all_ratings_average: 4.5,
      all_ratings_count: 850338,
      current_ratings: { 5: 654784, 1: 2920, 2: 3186, 3: 29304, 4: 127185 },
      current_ratings_average: 4.5,
      current_ratings_count: 817379,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1604236453244,
      store_observed_time: 1604102400000,
      all_ratings: { 5: 2401, 1: 92, 2: 26, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3083,
      current_ratings: { 5: 2259, 1: 24, 2: 16, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2795,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TR',
      ingest_time: 1604236453244,
      store_observed_time: 1604102400000,
      all_ratings: { 5: 17, 1: 0, 2: 0, 3: 0, 4: 2 },
      all_ratings_average: 5.0,
      all_ratings_count: 19,
      current_ratings: { 5: 17, 1: 0, 2: 0, 3: 0, 4: 2 },
      current_ratings_average: 5.0,
      current_ratings_count: 19,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604236453244,
      store_observed_time: 1604102400000,
      all_ratings: { 5: 678451, 1: 4689, 2: 3933, 3: 30741, 4: 132345 },
      all_ratings_average: 4.5,
      all_ratings_count: 850159,
      current_ratings: { 5: 654655, 1: 2919, 2: 3185, 3: 29297, 4: 127144 },
      current_ratings_average: 4.5,
      current_ratings_count: 817200,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604150067111,
      store_observed_time: 1604016000000,
      all_ratings: { 5: 678260, 1: 4688, 2: 3933, 3: 30733, 4: 132327 },
      all_ratings_average: 4.5,
      all_ratings_count: 849941,
      current_ratings: { 5: 654465, 1: 2918, 2: 3185, 3: 29288, 4: 127126 },
      current_ratings_average: 4.5,
      current_ratings_count: 816982,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1604063666663,
      store_observed_time: 1603929600000,
      all_ratings: { 5: 2400, 1: 92, 2: 26, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3082,
      current_ratings: { 5: 2258, 1: 24, 2: 16, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2794,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1604063666663,
      store_observed_time: 1603929600000,
      all_ratings: { 5: 678057, 1: 4686, 2: 3933, 3: 30731, 4: 132307 },
      all_ratings_average: 4.5,
      all_ratings_count: 849714,
      current_ratings: { 5: 654261, 1: 2916, 2: 3185, 3: 29286, 4: 127107 },
      current_ratings_average: 4.5,
      current_ratings_count: 816755,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1603977250358,
      store_observed_time: 1603843200000,
      all_ratings: { 5: 805, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 845,
      current_ratings: { 5: 805, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 845,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BR',
      ingest_time: 1603977250358,
      store_observed_time: 1603843200000,
      all_ratings: { 5: 36, 1: 0, 2: 0, 3: 0, 4: 1 },
      all_ratings_average: 5.0,
      all_ratings_count: 37,
      current_ratings: { 5: 36, 1: 0, 2: 0, 3: 0, 4: 1 },
      current_ratings_average: 5.0,
      current_ratings_count: 37,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603977250358,
      store_observed_time: 1603843200000,
      all_ratings: { 5: 677966, 1: 4686, 2: 3931, 3: 30730, 4: 132296 },
      all_ratings_average: 4.5,
      all_ratings_count: 849609,
      current_ratings: { 5: 654169, 1: 2916, 2: 3183, 3: 29285, 4: 127097 },
      current_ratings_average: 4.5,
      current_ratings_count: 816650,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603890850761,
      store_observed_time: 1603756800000,
      all_ratings: { 5: 2399, 1: 92, 2: 26, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3081,
      current_ratings: { 5: 2257, 1: 24, 2: 16, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2793,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603890850761,
      store_observed_time: 1603756800000,
      all_ratings: { 5: 677853, 1: 4687, 2: 3929, 3: 30734, 4: 132271 },
      all_ratings_average: 4.5,
      all_ratings_count: 849474,
      current_ratings: { 5: 654057, 1: 2916, 2: 3181, 3: 29288, 4: 127072 },
      current_ratings_average: 4.5,
      current_ratings_count: 816514,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603804459063,
      store_observed_time: 1603670400000,
      all_ratings: { 5: 2398, 1: 92, 2: 26, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3080,
      current_ratings: { 5: 2256, 1: 24, 2: 16, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2792,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TH',
      ingest_time: 1603804459063,
      store_observed_time: 1603670400000,
      all_ratings: { 5: 65, 1: 0, 2: 0, 3: 4, 4: 9 },
      all_ratings_average: 5.0,
      all_ratings_count: 78,
      current_ratings: { 5: 65, 1: 0, 2: 0, 3: 4, 4: 9 },
      current_ratings_average: 5.0,
      current_ratings_count: 78,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1603804459063,
      store_observed_time: 1603670400000,
      all_ratings: { 5: 803, 1: 0, 2: 0, 3: 6, 4: 35 },
      all_ratings_average: 5.0,
      all_ratings_count: 844,
      current_ratings: { 5: 803, 1: 0, 2: 0, 3: 6, 4: 35 },
      current_ratings_average: 5.0,
      current_ratings_count: 844,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603804459063,
      store_observed_time: 1603670400000,
      all_ratings: { 5: 677734, 1: 4687, 2: 3928, 3: 30727, 4: 132261 },
      all_ratings_average: 4.5,
      all_ratings_count: 849337,
      current_ratings: { 5: 653938, 1: 2916, 2: 3180, 3: 29281, 4: 127062 },
      current_ratings_average: 4.5,
      current_ratings_count: 816377,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603718050513,
      store_observed_time: 1603584000000,
      all_ratings: { 5: 2397, 1: 92, 2: 26, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3079,
      current_ratings: { 5: 2255, 1: 24, 2: 16, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2791,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ID',
      ingest_time: 1603718050513,
      store_observed_time: 1603584000000,
      all_ratings: { 5: 38, 1: 0, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 41,
      current_ratings: { 5: 38, 1: 0, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 41,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603718050513,
      store_observed_time: 1603584000000,
      all_ratings: { 5: 677483, 1: 4684, 2: 3928, 3: 30714, 4: 132206 },
      all_ratings_average: 4.5,
      all_ratings_count: 849015,
      current_ratings: { 5: 653687, 1: 2913, 2: 3180, 3: 29268, 4: 127007 },
      current_ratings_average: 4.5,
      current_ratings_count: 816055,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603631663089,
      store_observed_time: 1603497600000,
      all_ratings: { 5: 2396, 1: 92, 2: 26, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3078,
      current_ratings: { 5: 2254, 1: 24, 2: 16, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2790,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NL',
      ingest_time: 1603631663089,
      store_observed_time: 1603497600000,
      all_ratings: { 5: 281, 1: 4, 2: 5, 3: 19, 4: 80 },
      all_ratings_average: 4.5,
      all_ratings_count: 389,
      current_ratings: { 5: 268, 1: 3, 2: 2, 3: 18, 4: 80 },
      current_ratings_average: 4.5,
      current_ratings_count: 371,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1603631663089,
      store_observed_time: 1603497600000,
      all_ratings: { 5: 801, 1: 0, 2: 0, 3: 6, 4: 35 },
      all_ratings_average: 5.0,
      all_ratings_count: 842,
      current_ratings: { 5: 801, 1: 0, 2: 0, 3: 6, 4: 35 },
      current_ratings_average: 5.0,
      current_ratings_count: 842,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603631663089,
      store_observed_time: 1603497600000,
      all_ratings: { 5: 677132, 1: 4683, 2: 3926, 3: 30688, 4: 132154 },
      all_ratings_average: 4.5,
      all_ratings_count: 848583,
      current_ratings: { 5: 653336, 1: 2912, 2: 3178, 3: 29242, 4: 126955 },
      current_ratings_average: 4.5,
      current_ratings_count: 815623,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1603545250073,
      store_observed_time: 1603411200000,
      all_ratings: { 5: 189, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 233,
      current_ratings: { 5: 189, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 233,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1603545250073,
      store_observed_time: 1603411200000,
      all_ratings: { 5: 800, 1: 0, 2: 0, 3: 6, 4: 35 },
      all_ratings_average: 5.0,
      all_ratings_count: 841,
      current_ratings: { 5: 800, 1: 0, 2: 0, 3: 6, 4: 35 },
      current_ratings_average: 5.0,
      current_ratings_count: 841,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603545250073,
      store_observed_time: 1603411200000,
      all_ratings: { 5: 676840, 1: 4681, 2: 3923, 3: 30673, 4: 132094 },
      all_ratings_average: 4.5,
      all_ratings_count: 848211,
      current_ratings: { 5: 653043, 1: 2910, 2: 3175, 3: 29228, 4: 126895 },
      current_ratings_average: 4.5,
      current_ratings_count: 815251,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GB',
      ingest_time: 1603458851635,
      store_observed_time: 1603324800000,
      all_ratings: { 5: 105, 1: 2, 2: 0, 3: 1, 4: 21 },
      all_ratings_average: 5.0,
      all_ratings_count: 129,
      current_ratings: { 5: 105, 1: 2, 2: 0, 3: 1, 4: 21 },
      current_ratings_average: 5.0,
      current_ratings_count: 129,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603458851635,
      store_observed_time: 1603324800000,
      all_ratings: { 5: 676545, 1: 4678, 2: 3923, 3: 30655, 4: 132060 },
      all_ratings_average: 4.5,
      all_ratings_count: 847861,
      current_ratings: { 5: 652747, 1: 2907, 2: 3175, 3: 29210, 4: 126861 },
      current_ratings_average: 4.5,
      current_ratings_count: 814900,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603372450315,
      store_observed_time: 1603238400000,
      all_ratings: { 5: 2394, 1: 92, 2: 26, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3076,
      current_ratings: { 5: 2252, 1: 24, 2: 16, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2788,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1603372450315,
      store_observed_time: 1603238400000,
      all_ratings: { 5: 799, 1: 0, 2: 0, 3: 6, 4: 35 },
      all_ratings_average: 5.0,
      all_ratings_count: 840,
      current_ratings: { 5: 799, 1: 0, 2: 0, 3: 6, 4: 35 },
      current_ratings_average: 5.0,
      current_ratings_count: 840,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603372450315,
      store_observed_time: 1603238400000,
      all_ratings: { 5: 676236, 1: 4678, 2: 3921, 3: 30638, 4: 132031 },
      all_ratings_average: 4.5,
      all_ratings_count: 847504,
      current_ratings: { 5: 652437, 1: 2907, 2: 3173, 3: 29193, 4: 126832 },
      current_ratings_average: 4.5,
      current_ratings_count: 814542,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603286050340,
      store_observed_time: 1603152000000,
      all_ratings: { 5: 2393, 1: 92, 2: 25, 3: 111, 4: 452 },
      all_ratings_average: 4.5,
      all_ratings_count: 3073,
      current_ratings: { 5: 2251, 1: 24, 2: 15, 3: 82, 4: 413 },
      current_ratings_average: 4.5,
      current_ratings_count: 2785,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603286050340,
      store_observed_time: 1603152000000,
      all_ratings: { 5: 675955, 1: 4677, 2: 3920, 3: 30635, 4: 131982 },
      all_ratings_average: 4.5,
      all_ratings_count: 847169,
      current_ratings: { 5: 652155, 1: 2906, 2: 3172, 3: 29190, 4: 126783 },
      current_ratings_average: 4.5,
      current_ratings_count: 814206,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603199649964,
      store_observed_time: 1603065600000,
      all_ratings: { 5: 675767, 1: 4678, 2: 3919, 3: 30631, 4: 131967 },
      all_ratings_average: 4.5,
      all_ratings_count: 846962,
      current_ratings: { 5: 651968, 1: 2907, 2: 3171, 3: 29186, 4: 126766 },
      current_ratings_average: 4.5,
      current_ratings_count: 813998,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603113249792,
      store_observed_time: 1602979200000,
      all_ratings: { 5: 2392, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3072,
      current_ratings: { 5: 2250, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2784,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603113249792,
      store_observed_time: 1602979200000,
      all_ratings: { 5: 675662, 1: 4677, 2: 3920, 3: 30629, 4: 131941 },
      all_ratings_average: 4.5,
      all_ratings_count: 846829,
      current_ratings: { 5: 651863, 1: 2906, 2: 3172, 3: 29184, 4: 126740 },
      current_ratings_average: 4.5,
      current_ratings_count: 813865,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1603026851799,
      store_observed_time: 1602892800000,
      all_ratings: { 5: 2391, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3071,
      current_ratings: { 5: 2249, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2783,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MY',
      ingest_time: 1603026851799,
      store_observed_time: 1602892800000,
      all_ratings: { 5: 40, 1: 0, 2: 0, 3: 0, 4: 10 },
      all_ratings_average: 5.0,
      all_ratings_count: 50,
      current_ratings: { 5: 40, 1: 0, 2: 0, 3: 0, 4: 10 },
      current_ratings_average: 5.0,
      current_ratings_count: 50,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1603026851799,
      store_observed_time: 1602892800000,
      all_ratings: { 5: 188, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 232,
      current_ratings: { 5: 188, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 232,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CA',
      ingest_time: 1603026851799,
      store_observed_time: 1602892800000,
      all_ratings: { 5: 45, 1: 0, 2: 0, 3: 0, 4: 8 },
      all_ratings_average: 5.0,
      all_ratings_count: 53,
      current_ratings: { 5: 45, 1: 0, 2: 0, 3: 0, 4: 8 },
      current_ratings_average: 5.0,
      current_ratings_count: 53,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1603026851799,
      store_observed_time: 1602892800000,
      all_ratings: { 5: 675557, 1: 4676, 2: 3920, 3: 30624, 4: 131923 },
      all_ratings_average: 4.5,
      all_ratings_count: 846700,
      current_ratings: { 5: 651756, 1: 2905, 2: 3172, 3: 29179, 4: 126723 },
      current_ratings_average: 4.5,
      current_ratings_count: 813735,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602940450487,
      store_observed_time: 1602806400000,
      all_ratings: { 5: 675493, 1: 4674, 2: 3917, 3: 30613, 4: 131906 },
      all_ratings_average: 4.5,
      all_ratings_count: 846603,
      current_ratings: { 5: 651691, 1: 2903, 2: 3169, 3: 29168, 4: 126706 },
      current_ratings_average: 4.5,
      current_ratings_count: 813637,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'VN',
      ingest_time: 1602854051095,
      store_observed_time: 1602720000000,
      all_ratings: { 5: 48, 1: 0, 2: 0, 3: 1, 4: 6 },
      all_ratings_average: 5.0,
      all_ratings_count: 55,
      current_ratings: { 5: 48, 1: 0, 2: 0, 3: 1, 4: 6 },
      current_ratings_average: 5.0,
      current_ratings_count: 55,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602854051095,
      store_observed_time: 1602720000000,
      all_ratings: { 5: 2390, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3070,
      current_ratings: { 5: 2248, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2782,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1602854051095,
      store_observed_time: 1602720000000,
      all_ratings: { 5: 798, 1: 0, 2: 0, 3: 6, 4: 35 },
      all_ratings_average: 5.0,
      all_ratings_count: 839,
      current_ratings: { 5: 798, 1: 0, 2: 0, 3: 6, 4: 35 },
      current_ratings_average: 5.0,
      current_ratings_count: 839,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602854051095,
      store_observed_time: 1602720000000,
      all_ratings: { 5: 675386, 1: 4674, 2: 3916, 3: 30606, 4: 131900 },
      all_ratings_average: 4.5,
      all_ratings_count: 846482,
      current_ratings: { 5: 651583, 1: 2903, 2: 3168, 3: 29161, 4: 126701 },
      current_ratings_average: 4.5,
      current_ratings_count: 813516,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602767684108,
      store_observed_time: 1602633600000,
      all_ratings: { 5: 2389, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3069,
      current_ratings: { 5: 2247, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2781,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602767684108,
      store_observed_time: 1602633600000,
      all_ratings: { 5: 675197, 1: 4673, 2: 3916, 3: 30598, 4: 131859 },
      all_ratings_average: 4.5,
      all_ratings_count: 846243,
      current_ratings: { 5: 651396, 1: 2902, 2: 3168, 3: 29153, 4: 126658 },
      current_ratings_average: 4.5,
      current_ratings_count: 813277,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602681273718,
      store_observed_time: 1602547200000,
      all_ratings: { 5: 2388, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3068,
      current_ratings: { 5: 2246, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2780,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KR',
      ingest_time: 1602681273718,
      store_observed_time: 1602547200000,
      all_ratings: { 5: 93, 1: 0, 2: 1, 3: 4, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 101,
      current_ratings: { 5: 93, 1: 0, 2: 1, 3: 4, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 101,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1602681273718,
      store_observed_time: 1602547200000,
      all_ratings: { 5: 797, 1: 0, 2: 0, 3: 6, 4: 35 },
      all_ratings_average: 5.0,
      all_ratings_count: 838,
      current_ratings: { 5: 797, 1: 0, 2: 0, 3: 6, 4: 35 },
      current_ratings_average: 5.0,
      current_ratings_count: 838,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602681273718,
      store_observed_time: 1602547200000,
      all_ratings: { 5: 674822, 1: 4670, 2: 3915, 3: 30587, 4: 131790 },
      all_ratings_average: 4.5,
      all_ratings_count: 845784,
      current_ratings: { 5: 651022, 1: 2899, 2: 3167, 3: 29142, 4: 126588 },
      current_ratings_average: 4.5,
      current_ratings_count: 812818,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AR',
      ingest_time: 1602681273718,
      store_observed_time: 1602547200000,
      all_ratings: { 5: 8, 1: 0, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 4.5,
      all_ratings_count: 11,
      current_ratings: { 5: 8, 1: 0, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 4.5,
      current_ratings_count: 11,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602594854570,
      store_observed_time: 1602460800000,
      all_ratings: { 5: 2387, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3067,
      current_ratings: { 5: 2245, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2779,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602594854570,
      store_observed_time: 1602460800000,
      all_ratings: { 5: 674454, 1: 4669, 2: 3915, 3: 30572, 4: 131731 },
      all_ratings_average: 4.5,
      all_ratings_count: 845341,
      current_ratings: { 5: 650653, 1: 2898, 2: 3167, 3: 29127, 4: 126530 },
      current_ratings_average: 4.5,
      current_ratings_count: 812375,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602508451125,
      store_observed_time: 1602374400000,
      all_ratings: { 5: 2381, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3061,
      current_ratings: { 5: 2239, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2773,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MY',
      ingest_time: 1602508451125,
      store_observed_time: 1602374400000,
      all_ratings: { 5: 39, 1: 0, 2: 0, 3: 0, 4: 9 },
      all_ratings_average: 5.0,
      all_ratings_count: 48,
      current_ratings: { 5: 39, 1: 0, 2: 0, 3: 0, 4: 9 },
      current_ratings_average: 5.0,
      current_ratings_count: 48,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1602508451125,
      store_observed_time: 1602374400000,
      all_ratings: { 5: 797, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 837,
      current_ratings: { 5: 797, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 837,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602508451125,
      store_observed_time: 1602374400000,
      all_ratings: { 5: 673984, 1: 4669, 2: 3913, 3: 30568, 4: 131645 },
      all_ratings_average: 4.5,
      all_ratings_count: 844779,
      current_ratings: { 5: 650183, 1: 2898, 2: 3165, 3: 29123, 4: 126444 },
      current_ratings_average: 4.5,
      current_ratings_count: 811813,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602422050874,
      store_observed_time: 1602288000000,
      all_ratings: { 5: 2380, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3060,
      current_ratings: { 5: 2238, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2772,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'FR',
      ingest_time: 1602422050874,
      store_observed_time: 1602288000000,
      all_ratings: { 5: 28, 1: 0, 2: 0, 3: 2, 4: 6 },
      all_ratings_average: 4.5,
      all_ratings_count: 36,
      current_ratings: { 5: 28, 1: 0, 2: 0, 3: 2, 4: 6 },
      current_ratings_average: 4.5,
      current_ratings_count: 36,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1602422050874,
      store_observed_time: 1602288000000,
      all_ratings: { 5: 796, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 836,
      current_ratings: { 5: 796, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 836,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602422050874,
      store_observed_time: 1602288000000,
      all_ratings: { 5: 673583, 1: 4668, 2: 3911, 3: 30538, 4: 131584 },
      all_ratings_average: 4.5,
      all_ratings_count: 844284,
      current_ratings: { 5: 649780, 1: 2897, 2: 3162, 3: 29095, 4: 126384 },
      current_ratings_average: 4.5,
      current_ratings_count: 811318,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602335650285,
      store_observed_time: 1602201600000,
      all_ratings: { 5: 2379, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3059,
      current_ratings: { 5: 2237, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2771,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602335650285,
      store_observed_time: 1602201600000,
      all_ratings: { 5: 673229, 1: 4665, 2: 3908, 3: 30532, 4: 131542 },
      all_ratings_average: 4.5,
      all_ratings_count: 843876,
      current_ratings: { 5: 649426, 1: 2894, 2: 3159, 3: 29089, 4: 126342 },
      current_ratings_average: 4.5,
      current_ratings_count: 810910,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602249255151,
      store_observed_time: 1602115200000,
      all_ratings: { 5: 2378, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3058,
      current_ratings: { 5: 2236, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2770,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602249255151,
      store_observed_time: 1602115200000,
      all_ratings: { 5: 672919, 1: 4662, 2: 3906, 3: 30520, 4: 131492 },
      all_ratings_average: 4.5,
      all_ratings_count: 843499,
      current_ratings: { 5: 649116, 1: 2891, 2: 3157, 3: 29076, 4: 126292 },
      current_ratings_average: 4.5,
      current_ratings_count: 810532,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1602162852179,
      store_observed_time: 1602028800000,
      all_ratings: { 5: 2373, 1: 91, 2: 25, 3: 111, 4: 454 },
      all_ratings_average: 4.5,
      all_ratings_count: 3054,
      current_ratings: { 5: 2231, 1: 23, 2: 15, 3: 82, 4: 415 },
      current_ratings_average: 4.5,
      current_ratings_count: 2766,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1602162852179,
      store_observed_time: 1602028800000,
      all_ratings: { 5: 187, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 231,
      current_ratings: { 5: 187, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 231,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1602162852179,
      store_observed_time: 1602028800000,
      all_ratings: { 5: 795, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 835,
      current_ratings: { 5: 795, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 835,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602162852179,
      store_observed_time: 1602028800000,
      all_ratings: { 5: 672615, 1: 4660, 2: 3903, 3: 30506, 4: 131453 },
      all_ratings_average: 4.5,
      all_ratings_count: 843137,
      current_ratings: { 5: 648813, 1: 2889, 2: 3154, 3: 29062, 4: 126252 },
      current_ratings_average: 4.5,
      current_ratings_count: 810170,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1602076451719,
      store_observed_time: 1601942400000,
      all_ratings: { 5: 672448, 1: 4655, 2: 3904, 3: 30496, 4: 131411 },
      all_ratings_average: 4.5,
      all_ratings_count: 842914,
      current_ratings: { 5: 648645, 1: 2884, 2: 3155, 3: 29052, 4: 126211 },
      current_ratings_average: 4.5,
      current_ratings_count: 809947,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601990050830,
      store_observed_time: 1601856000000,
      all_ratings: { 5: 794, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 834,
      current_ratings: { 5: 794, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 834,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601990050830,
      store_observed_time: 1601856000000,
      all_ratings: { 5: 672344, 1: 4655, 2: 3906, 3: 30490, 4: 131394 },
      all_ratings_average: 4.5,
      all_ratings_count: 842789,
      current_ratings: { 5: 648541, 1: 2882, 2: 3157, 3: 29047, 4: 126195 },
      current_ratings_average: 4.5,
      current_ratings_count: 809822,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TH',
      ingest_time: 1601910590215,
      store_observed_time: 1601769600000,
      all_ratings: { 5: 64, 1: 0, 2: 0, 3: 4, 4: 9 },
      all_ratings_average: 5.0,
      all_ratings_count: 77,
      current_ratings: { 5: 64, 1: 0, 2: 0, 3: 4, 4: 9 },
      current_ratings_average: 5.0,
      current_ratings_count: 77,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601910590215,
      store_observed_time: 1601769600000,
      all_ratings: { 5: 793, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 833,
      current_ratings: { 5: 793, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 833,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CL',
      ingest_time: 1601910590215,
      store_observed_time: 1601769600000,
      all_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 5.0,
      all_ratings_count: 7,
      current_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 5.0,
      current_ratings_count: 7,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601910590215,
      store_observed_time: 1601769600000,
      all_ratings: { 5: 672235, 1: 4654, 2: 3906, 3: 30486, 4: 131381 },
      all_ratings_average: 4.5,
      all_ratings_count: 842662,
      current_ratings: { 5: 648431, 1: 2881, 2: 3157, 3: 29044, 4: 126182 },
      current_ratings_average: 4.5,
      current_ratings_count: 809695,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601817250649,
      store_observed_time: 1601683200000,
      all_ratings: { 5: 792, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 832,
      current_ratings: { 5: 792, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 832,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601817250649,
      store_observed_time: 1601683200000,
      all_ratings: { 5: 672148, 1: 4654, 2: 3905, 3: 30486, 4: 131372 },
      all_ratings_average: 4.5,
      all_ratings_count: 842565,
      current_ratings: { 5: 648345, 1: 2880, 2: 3156, 3: 29044, 4: 126172 },
      current_ratings_average: 4.5,
      current_ratings_count: 809597,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601730850508,
      store_observed_time: 1601596800000,
      all_ratings: { 5: 790, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 830,
      current_ratings: { 5: 790, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 830,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601730850508,
      store_observed_time: 1601596800000,
      all_ratings: { 5: 672074, 1: 4653, 2: 3903, 3: 30479, 4: 131368 },
      all_ratings_average: 4.5,
      all_ratings_count: 842477,
      current_ratings: { 5: 648270, 1: 2879, 2: 3154, 3: 29037, 4: 126168 },
      current_ratings_average: 4.5,
      current_ratings_count: 809508,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1601644450760,
      store_observed_time: 1601510400000,
      all_ratings: { 5: 2372, 1: 91, 2: 25, 3: 111, 4: 454 },
      all_ratings_average: 4.5,
      all_ratings_count: 3053,
      current_ratings: { 5: 2230, 1: 23, 2: 15, 3: 82, 4: 415 },
      current_ratings_average: 4.5,
      current_ratings_count: 2765,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NZ',
      ingest_time: 1601644450760,
      store_observed_time: 1601510400000,
      all_ratings: { 5: 53, 1: 0, 2: 1, 3: 0, 4: 5 },
      all_ratings_average: 5.0,
      all_ratings_count: 59,
      current_ratings: { 5: 53, 1: 0, 2: 1, 3: 0, 4: 5 },
      current_ratings_average: 5.0,
      current_ratings_count: 59,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601644450760,
      store_observed_time: 1601510400000,
      all_ratings: { 5: 671964, 1: 4653, 2: 3903, 3: 30475, 4: 131346 },
      all_ratings_average: 4.5,
      all_ratings_count: 842341,
      current_ratings: { 5: 648160, 1: 2879, 2: 3154, 3: 29033, 4: 126146 },
      current_ratings_average: 4.5,
      current_ratings_count: 809372,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AE',
      ingest_time: 1601644450760,
      store_observed_time: 1601510400000,
      all_ratings: { 5: 9, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 5.0,
      all_ratings_count: 9,
      current_ratings: { 5: 9, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 5.0,
      current_ratings_count: 9,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1601558053136,
      store_observed_time: 1601424000000,
      all_ratings: { 5: 2371, 1: 91, 2: 25, 3: 111, 4: 454 },
      all_ratings_average: 4.5,
      all_ratings_count: 3052,
      current_ratings: { 5: 2229, 1: 23, 2: 15, 3: 82, 4: 415 },
      current_ratings_average: 4.5,
      current_ratings_count: 2764,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601558053136,
      store_observed_time: 1601424000000,
      all_ratings: { 5: 671834, 1: 4654, 2: 3903, 3: 30465, 4: 131314 },
      all_ratings_average: 4.5,
      all_ratings_count: 842170,
      current_ratings: { 5: 648031, 1: 2879, 2: 3154, 3: 29023, 4: 126114 },
      current_ratings_average: 4.5,
      current_ratings_count: 809201,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'JP',
      ingest_time: 1601471651203,
      store_observed_time: 1601337600000,
      all_ratings: { 5: 52, 1: 0, 2: 1, 3: 2, 4: 6 },
      all_ratings_average: 5.0,
      all_ratings_count: 61,
      current_ratings: { 5: 52, 1: 0, 2: 1, 3: 2, 4: 6 },
      current_ratings_average: 5.0,
      current_ratings_count: 61,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601471651203,
      store_observed_time: 1601337600000,
      all_ratings: { 5: 671694, 1: 4653, 2: 3901, 3: 30459, 4: 131298 },
      all_ratings_average: 4.5,
      all_ratings_count: 842005,
      current_ratings: { 5: 647891, 1: 2878, 2: 3151, 3: 29017, 4: 126099 },
      current_ratings_average: 4.5,
      current_ratings_count: 809036,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1601385251111,
      store_observed_time: 1601251200000,
      all_ratings: { 5: 2370, 1: 91, 2: 25, 3: 111, 4: 454 },
      all_ratings_average: 4.5,
      all_ratings_count: 3051,
      current_ratings: { 5: 2228, 1: 23, 2: 15, 3: 82, 4: 415 },
      current_ratings_average: 4.5,
      current_ratings_count: 2763,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1601385251111,
      store_observed_time: 1601251200000,
      all_ratings: { 5: 186, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 230,
      current_ratings: { 5: 186, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 230,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601385251111,
      store_observed_time: 1601251200000,
      all_ratings: { 5: 789, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 829,
      current_ratings: { 5: 789, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 829,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601385251111,
      store_observed_time: 1601251200000,
      all_ratings: { 5: 671533, 1: 4651, 2: 3900, 3: 30459, 4: 131258 },
      all_ratings_average: 4.5,
      all_ratings_count: 841801,
      current_ratings: { 5: 647731, 1: 2876, 2: 3150, 3: 29016, 4: 126059 },
      current_ratings_average: 4.5,
      current_ratings_count: 808832,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1601298851146,
      store_observed_time: 1601164800000,
      all_ratings: { 5: 2369, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3049,
      current_ratings: { 5: 2227, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2761,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601298851146,
      store_observed_time: 1601164800000,
      all_ratings: { 5: 788, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 828,
      current_ratings: { 5: 788, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 828,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601298851146,
      store_observed_time: 1601164800000,
      all_ratings: { 5: 671228, 1: 4645, 2: 3900, 3: 30447, 4: 131195 },
      all_ratings_average: 4.5,
      all_ratings_count: 841415,
      current_ratings: { 5: 647426, 1: 2870, 2: 3150, 3: 29004, 4: 125996 },
      current_ratings_average: 4.5,
      current_ratings_count: 808446,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1601212450926,
      store_observed_time: 1601078400000,
      all_ratings: { 5: 2366, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3046,
      current_ratings: { 5: 2224, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2758,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601212450926,
      store_observed_time: 1601078400000,
      all_ratings: { 5: 787, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 827,
      current_ratings: { 5: 787, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 827,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601212450926,
      store_observed_time: 1601078400000,
      all_ratings: { 5: 670894, 1: 4645, 2: 3900, 3: 30434, 4: 131144 },
      all_ratings_average: 4.5,
      all_ratings_count: 841017,
      current_ratings: { 5: 647090, 1: 2870, 2: 3150, 3: 28991, 4: 125945 },
      current_ratings_average: 4.5,
      current_ratings_count: 808046,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601126050279,
      store_observed_time: 1600992000000,
      all_ratings: { 5: 670576, 1: 4643, 2: 3900, 3: 30420, 4: 131101 },
      all_ratings_average: 4.5,
      all_ratings_count: 840640,
      current_ratings: { 5: 646769, 1: 2868, 2: 3150, 3: 28977, 4: 125904 },
      current_ratings_average: 4.5,
      current_ratings_count: 807668,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1601039651271,
      store_observed_time: 1600905600000,
      all_ratings: { 5: 786, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 826,
      current_ratings: { 5: 786, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 826,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1601039651271,
      store_observed_time: 1600905600000,
      all_ratings: { 5: 670247, 1: 4642, 2: 3899, 3: 30403, 4: 131055 },
      all_ratings_average: 4.5,
      all_ratings_count: 840246,
      current_ratings: { 5: 646441, 1: 2867, 2: 3149, 3: 28960, 4: 125857 },
      current_ratings_average: 4.5,
      current_ratings_count: 807274,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TH',
      ingest_time: 1600953250356,
      store_observed_time: 1600819200000,
      all_ratings: { 5: 63, 1: 0, 2: 0, 3: 4, 4: 9 },
      all_ratings_average: 5.0,
      all_ratings_count: 76,
      current_ratings: { 5: 63, 1: 0, 2: 0, 3: 4, 4: 9 },
      current_ratings_average: 5.0,
      current_ratings_count: 76,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600953250356,
      store_observed_time: 1600819200000,
      all_ratings: { 5: 669942, 1: 4640, 2: 3897, 3: 30387, 4: 131030 },
      all_ratings_average: 4.5,
      all_ratings_count: 839896,
      current_ratings: { 5: 646137, 1: 2865, 2: 3147, 3: 28944, 4: 125831 },
      current_ratings_average: 4.5,
      current_ratings_count: 806924,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1600866854847,
      store_observed_time: 1600732800000,
      all_ratings: { 5: 2364, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3044,
      current_ratings: { 5: 2222, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2756,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1600866854847,
      store_observed_time: 1600732800000,
      all_ratings: { 5: 786, 1: 0, 2: 0, 3: 6, 4: 33 },
      all_ratings_average: 5.0,
      all_ratings_count: 825,
      current_ratings: { 5: 786, 1: 0, 2: 0, 3: 6, 4: 33 },
      current_ratings_average: 5.0,
      current_ratings_count: 825,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600866854847,
      store_observed_time: 1600732800000,
      all_ratings: { 5: 669599, 1: 4638, 2: 3896, 3: 30374, 4: 130964 },
      all_ratings_average: 4.5,
      all_ratings_count: 839471,
      current_ratings: { 5: 645794, 1: 2863, 2: 3146, 3: 28932, 4: 125764 },
      current_ratings_average: 4.5,
      current_ratings_count: 806499,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600780451752,
      store_observed_time: 1600646400000,
      all_ratings: { 5: 669276, 1: 4638, 2: 3897, 3: 30360, 4: 130933 },
      all_ratings_average: 4.5,
      all_ratings_count: 839104,
      current_ratings: { 5: 645471, 1: 2863, 2: 3147, 3: 28918, 4: 125732 },
      current_ratings_average: 4.5,
      current_ratings_count: 806131,
      version: '15.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1600694051574,
      store_observed_time: 1600560000000,
      all_ratings: { 5: 2360, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3040,
      current_ratings: { 5: 2218, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2752,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600694051574,
      store_observed_time: 1600560000000,
      all_ratings: { 5: 668874, 1: 4635, 2: 3900, 3: 30343, 4: 130881 },
      all_ratings_average: 4.5,
      all_ratings_count: 838633,
      current_ratings: { 5: 645070, 1: 2860, 2: 3149, 3: 28900, 4: 125681 },
      current_ratings_average: 4.5,
      current_ratings_count: 805660,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1600607651103,
      store_observed_time: 1600473600000,
      all_ratings: { 5: 2358, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3038,
      current_ratings: { 5: 2216, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2750,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'FR',
      ingest_time: 1600607651103,
      store_observed_time: 1600473600000,
      all_ratings: { 5: 27, 1: 0, 2: 0, 3: 2, 4: 6 },
      all_ratings_average: 4.5,
      all_ratings_count: 35,
      current_ratings: { 5: 27, 1: 0, 2: 0, 3: 2, 4: 6 },
      current_ratings_average: 4.5,
      current_ratings_count: 35,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1600607651103,
      store_observed_time: 1600473600000,
      all_ratings: { 5: 785, 1: 0, 2: 0, 3: 6, 4: 33 },
      all_ratings_average: 5.0,
      all_ratings_count: 824,
      current_ratings: { 5: 785, 1: 0, 2: 0, 3: 6, 4: 33 },
      current_ratings_average: 5.0,
      current_ratings_count: 824,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600607651103,
      store_observed_time: 1600473600000,
      all_ratings: { 5: 668538, 1: 4636, 2: 3897, 3: 30325, 4: 130841 },
      all_ratings_average: 4.5,
      all_ratings_count: 838237,
      current_ratings: { 5: 644737, 1: 2860, 2: 3146, 3: 28882, 4: 125639 },
      current_ratings_average: 4.5,
      current_ratings_count: 805264,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1600521250419,
      store_observed_time: 1600387200000,
      all_ratings: { 5: 2357, 1: 91, 2: 25, 3: 111, 4: 453 },
      all_ratings_average: 4.5,
      all_ratings_count: 3037,
      current_ratings: { 5: 2215, 1: 23, 2: 15, 3: 82, 4: 414 },
      current_ratings_average: 4.5,
      current_ratings_count: 2749,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600521250419,
      store_observed_time: 1600387200000,
      all_ratings: { 5: 668344, 1: 4635, 2: 3897, 3: 30321, 4: 130812 },
      all_ratings_average: 4.5,
      all_ratings_count: 838009,
      current_ratings: { 5: 644543, 1: 2859, 2: 3146, 3: 28878, 4: 125610 },
      current_ratings_average: 4.5,
      current_ratings_count: 805036,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'HK',
      ingest_time: 1600434850767,
      store_observed_time: 1600300800000,
      all_ratings: { 5: 65, 1: 0, 2: 0, 3: 1, 4: 7 },
      all_ratings_average: 5.0,
      all_ratings_count: 73,
      current_ratings: { 5: 65, 1: 0, 2: 0, 3: 1, 4: 7 },
      current_ratings_average: 5.0,
      current_ratings_count: 73,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1600434850767,
      store_observed_time: 1600300800000,
      all_ratings: { 5: 784, 1: 0, 2: 0, 3: 6, 4: 33 },
      all_ratings_average: 5.0,
      all_ratings_count: 823,
      current_ratings: { 5: 784, 1: 0, 2: 0, 3: 6, 4: 33 },
      current_ratings_average: 5.0,
      current_ratings_count: 823,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600434850767,
      store_observed_time: 1600300800000,
      all_ratings: { 5: 668172, 1: 4633, 2: 3896, 3: 30315, 4: 130789 },
      all_ratings_average: 4.5,
      all_ratings_count: 837805,
      current_ratings: { 5: 644370, 1: 2857, 2: 3145, 3: 28872, 4: 125587 },
      current_ratings_average: 4.5,
      current_ratings_count: 804831,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'HK',
      ingest_time: 1600348451334,
      store_observed_time: 1600214400000,
      all_ratings: { 5: 64, 1: 0, 2: 0, 3: 1, 4: 7 },
      all_ratings_average: 5.0,
      all_ratings_count: 72,
      current_ratings: { 5: 64, 1: 0, 2: 0, 3: 1, 4: 7 },
      current_ratings_average: 5.0,
      current_ratings_count: 72,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600348451334,
      store_observed_time: 1600214400000,
      all_ratings: { 5: 668006, 1: 4633, 2: 3894, 3: 30310, 4: 130771 },
      all_ratings_average: 4.5,
      all_ratings_count: 837614,
      current_ratings: { 5: 644201, 1: 2857, 2: 3143, 3: 28867, 4: 125571 },
      current_ratings_average: 4.5,
      current_ratings_count: 804639,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600262050518,
      store_observed_time: 1600128000000,
      all_ratings: { 5: 667861, 1: 4634, 2: 3893, 3: 30302, 4: 130745 },
      all_ratings_average: 4.5,
      all_ratings_count: 837435,
      current_ratings: { 5: 644055, 1: 2858, 2: 3142, 3: 28860, 4: 125545 },
      current_ratings_average: 4.5,
      current_ratings_count: 804460,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1600175656082,
      store_observed_time: 1600041600000,
      all_ratings: { 5: 2357, 1: 90, 2: 25, 3: 111, 4: 452 },
      all_ratings_average: 4.5,
      all_ratings_count: 3035,
      current_ratings: { 5: 2215, 1: 22, 2: 15, 3: 82, 4: 413 },
      current_ratings_average: 4.5,
      current_ratings_count: 2747,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600175656082,
      store_observed_time: 1600041600000,
      all_ratings: { 5: 667719, 1: 4633, 2: 3892, 3: 30291, 4: 130717 },
      all_ratings_average: 4.5,
      all_ratings_count: 837252,
      current_ratings: { 5: 643912, 1: 2857, 2: 3141, 3: 28850, 4: 125516 },
      current_ratings_average: 4.5,
      current_ratings_count: 804276,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1600089249704,
      store_observed_time: 1599955200000,
      all_ratings: { 5: 2355, 1: 90, 2: 25, 3: 111, 4: 452 },
      all_ratings_average: 4.5,
      all_ratings_count: 3033,
      current_ratings: { 5: 2213, 1: 22, 2: 15, 3: 82, 4: 413 },
      current_ratings_average: 4.5,
      current_ratings_count: 2745,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600089249704,
      store_observed_time: 1599955200000,
      all_ratings: { 5: 667438, 1: 4634, 2: 3891, 3: 30280, 4: 130657 },
      all_ratings_average: 4.5,
      all_ratings_count: 836900,
      current_ratings: { 5: 643621, 1: 2858, 2: 3140, 3: 28839, 4: 125455 },
      current_ratings_average: 4.5,
      current_ratings_count: 803913,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1600002855422,
      store_observed_time: 1599868800000,
      all_ratings: { 5: 2353, 1: 90, 2: 25, 3: 111, 4: 452 },
      all_ratings_average: 4.5,
      all_ratings_count: 3031,
      current_ratings: { 5: 2211, 1: 22, 2: 15, 3: 82, 4: 413 },
      current_ratings_average: 4.5,
      current_ratings_count: 2743,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KR',
      ingest_time: 1600002855422,
      store_observed_time: 1599868800000,
      all_ratings: { 5: 92, 1: 0, 2: 1, 3: 4, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 100,
      current_ratings: { 5: 92, 1: 0, 2: 1, 3: 4, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 100,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1600002855422,
      store_observed_time: 1599868800000,
      all_ratings: { 5: 667023, 1: 4636, 2: 3888, 3: 30261, 4: 130588 },
      all_ratings_average: 4.5,
      all_ratings_count: 836396,
      current_ratings: { 5: 643215, 1: 2860, 2: 3137, 3: 28820, 4: 125388 },
      current_ratings_average: 4.5,
      current_ratings_count: 803420,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1599916450369,
      store_observed_time: 1599782400000,
      all_ratings: { 5: 2353, 1: 90, 2: 25, 3: 111, 4: 451 },
      all_ratings_average: 4.5,
      all_ratings_count: 3030,
      current_ratings: { 5: 2211, 1: 22, 2: 15, 3: 82, 4: 412 },
      current_ratings_average: 4.5,
      current_ratings_count: 2742,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NL',
      ingest_time: 1599916450369,
      store_observed_time: 1599782400000,
      all_ratings: { 5: 280, 1: 4, 2: 5, 3: 19, 4: 80 },
      all_ratings_average: 4.5,
      all_ratings_count: 388,
      current_ratings: { 5: 267, 1: 3, 2: 2, 3: 18, 4: 80 },
      current_ratings_average: 4.5,
      current_ratings_count: 370,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1599916450369,
      store_observed_time: 1599782400000,
      all_ratings: { 5: 783, 1: 0, 2: 0, 3: 6, 4: 33 },
      all_ratings_average: 5.0,
      all_ratings_count: 822,
      current_ratings: { 5: 783, 1: 0, 2: 0, 3: 6, 4: 33 },
      current_ratings_average: 5.0,
      current_ratings_count: 822,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1599916450369,
      store_observed_time: 1599782400000,
      all_ratings: { 5: 666752, 1: 4634, 2: 3880, 3: 30251, 4: 130520 },
      all_ratings_average: 4.5,
      all_ratings_count: 836037,
      current_ratings: { 5: 642944, 1: 2858, 2: 3129, 3: 28810, 4: 125320 },
      current_ratings_average: 4.5,
      current_ratings_count: 803061,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1599830051212,
      store_observed_time: 1599696000000,
      all_ratings: { 5: 2352, 1: 90, 2: 25, 3: 111, 4: 451 },
      all_ratings_average: 4.5,
      all_ratings_count: 3029,
      current_ratings: { 5: 2210, 1: 22, 2: 15, 3: 82, 4: 412 },
      current_ratings_average: 4.5,
      current_ratings_count: 2741,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1599830051212,
      store_observed_time: 1599696000000,
      all_ratings: { 5: 666399, 1: 4633, 2: 3877, 3: 30245, 4: 130464 },
      all_ratings_average: 4.5,
      all_ratings_count: 835618,
      current_ratings: { 5: 642592, 1: 2857, 2: 3126, 3: 28804, 4: 125263 },
      current_ratings_average: 4.5,
      current_ratings_count: 802642,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1599745436659,
      store_observed_time: 1599609600000,
      all_ratings: { 5: 2351, 1: 90, 2: 25, 3: 111, 4: 451 },
      all_ratings_average: 4.5,
      all_ratings_count: 3028,
      current_ratings: { 5: 2209, 1: 22, 2: 15, 3: 82, 4: 412 },
      current_ratings_average: 4.5,
      current_ratings_count: 2740,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1599745436659,
      store_observed_time: 1599609600000,
      all_ratings: { 5: 665992, 1: 4631, 2: 3873, 3: 30224, 4: 130404 },
      all_ratings_average: 4.5,
      all_ratings_count: 835124,
      current_ratings: { 5: 642185, 1: 2855, 2: 3122, 3: 28784, 4: 125202 },
      current_ratings_average: 4.5,
      current_ratings_count: 802148,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1599657250800,
      store_observed_time: 1599523200000,
      all_ratings: { 5: 2351, 1: 90, 2: 25, 3: 111, 4: 451 },
      all_ratings_average: 4.5,
      all_ratings_count: 3028,
      current_ratings: { 5: 2209, 1: 22, 2: 15, 3: 82, 4: 412 },
      current_ratings_average: 4.5,
      current_ratings_count: 2740,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1599657250800,
      store_observed_time: 1599523200000,
      all_ratings: { 5: 185, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 229,
      current_ratings: { 5: 185, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 229,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1599657250800,
      store_observed_time: 1599523200000,
      all_ratings: { 5: 782, 1: 0, 2: 0, 3: 6, 4: 33 },
      all_ratings_average: 5.0,
      all_ratings_count: 821,
      current_ratings: { 5: 782, 1: 0, 2: 0, 3: 6, 4: 33 },
      current_ratings_average: 5.0,
      current_ratings_count: 821,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1599657250800,
      store_observed_time: 1599523200000,
      all_ratings: { 5: 665589, 1: 4628, 2: 3870, 3: 30200, 4: 130322 },
      all_ratings_average: 4.5,
      all_ratings_count: 834609,
      current_ratings: { 5: 641782, 1: 2852, 2: 3120, 3: 28759, 4: 125120 },
      current_ratings_average: 4.5,
      current_ratings_count: 801633,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1599570851015,
      store_observed_time: 1599436800000,
      all_ratings: { 5: 2350, 1: 90, 2: 25, 3: 111, 4: 451 },
      all_ratings_average: 4.5,
      all_ratings_count: 3027,
      current_ratings: { 5: 2208, 1: 22, 2: 15, 3: 82, 4: 412 },
      current_ratings_average: 4.5,
      current_ratings_count: 2739,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NL',
      ingest_time: 1599570851015,
      store_observed_time: 1599436800000,
      all_ratings: { 5: 279, 1: 4, 2: 5, 3: 19, 4: 80 },
      all_ratings_average: 4.5,
      all_ratings_count: 387,
      current_ratings: { 5: 266, 1: 3, 2: 2, 3: 18, 4: 80 },
      current_ratings_average: 4.5,
      current_ratings_count: 369,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1599570851015,
      store_observed_time: 1599436800000,
      all_ratings: { 5: 781, 1: 0, 2: 0, 3: 6, 4: 33 },
      all_ratings_average: 5.0,
      all_ratings_count: 820,
      current_ratings: { 5: 781, 1: 0, 2: 0, 3: 6, 4: 33 },
      current_ratings_average: 5.0,
      current_ratings_count: 820,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1599570851015,
      store_observed_time: 1599436800000,
      all_ratings: { 5: 665177, 1: 4627, 2: 3868, 3: 30186, 4: 130237 },
      all_ratings_average: 4.5,
      all_ratings_count: 834095,
      current_ratings: { 5: 641372, 1: 2851, 2: 3118, 3: 28745, 4: 125033 },
      current_ratings_average: 4.5,
      current_ratings_count: 801119,
      version: '15.2.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1599484451329,
      store_observed_time: 1599350400000,
      all_ratings: { 5: 2347, 1: 90, 2: 25, 3: 111, 4: 451 },
      all_ratings_average: 4.5,
      all_ratings_count: 3024,
      current_ratings: { 5: 2205, 1: 22, 2: 15, 3: 82, 4: 412 },
      current_ratings_average: 4.5,
      current_ratings_count: 2736,
      version: '15.1.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GB',
      ingest_time: 1599484451329,
      store_observed_time: 1599350400000,
      all_ratings: { 5: 104, 1: 2, 2: 0, 3: 1, 4: 21 },
      all_ratings_average: 5.0,
      all_ratings_count: 128,
      current_ratings: { 5: 104, 1: 2, 2: 0, 3: 1, 4: 21 },
      current_ratings_average: 5.0,
      current_ratings_count: 128,
      version: '15.1.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1599484451329,
      store_observed_time: 1599350400000,
      all_ratings: { 5: 664713, 1: 4623, 2: 3864, 3: 30162, 4: 130165 },
      all_ratings_average: 4.5,
      all_ratings_count: 833527,
      current_ratings: { 5: 640908, 1: 2846, 2: 3114, 3: 28720, 4: 124962 },
      current_ratings_average: 4.5,
      current_ratings_count: 800550,
      version: '15.1.1',
    },
  ],
  type: 'RATINGS_HISTOGRAMS',
  page_size: 2000,
  has_more: false,
  min_key: 0,
};
