import { merge } from 'ramda';
import moment from 'moment';
import { isDemoEnv } from 'src/utils/environment';
import * as actions from '../actions/retention';

const initialState = {
  loading: true,
  fetchError: false,
  data: [[]],
  retentionAverages: [],
};

const retention = (state = initialState, action = {}) => {
  const transposeMatrix = (matrix) => {
    const newMatrix = [];
    const matrixHeight = matrix.length;
    const matrixWidth = matrix[0].length;
    let i;
    let j;
    for (i = 0; i < matrixWidth; i++) {
      newMatrix.push([]);
    }
    for (i = 0; i < matrixWidth; i++) {
      for (j = 0; j < matrixHeight; j++) {
        newMatrix[i].push(matrix[j][i]);
      }
    }
    return newMatrix;
  };

  const getColumnHeight = (col) => {
    let height = 0;
    while (col[height] !== 'invalid' && height < col.length) {
      height += 1;
    }
    return height;
  };

  switch (action.type) {
    case actions.FETCH_RETENTION_PENDING:
      return merge(state, {
        loading: true,
        fetchError: false,
      });

    case actions.FETCH_RETENTION_SUCCESS: {
      const retentionArray = [];
      const retainedArray = [];
      let wentLiveOn = moment('2017-05-01', 'YYYY-MM-DD').utc();
      if (isDemoEnv()) {
        wentLiveOn = moment('2012-01-01', 'YYYY-MM-DD').utc();
      } else if (action.meta.wentLiveOn) {
        wentLiveOn = moment(action.meta.wentLiveOn, 'YYYY-MM-DD').utc();
      }
      const dataStartDate = moment('2017-05-01', 'YYYY-MM-DD').utc();
      const currentMonthStart = moment().utc().startOf('month');
      const currentMonthEnd = moment().utc().endOf('month');
      let i;
      let j;
      let retentionRow;
      let retainedRow;
      let cohortOriginEndDate;
      let cohortOriginString;
      let timestamp;

      // create retentionArray (2D array of retention rates, this is for presentation)
      // and retainedArray (2D array of absolute retention values, this is to calculate averages)
      if (action.payload.retention) {
        for (i = 0; i < action.payload.retention.length; i++) {
          retentionRow = [];
          retainedRow = [];
          cohortOriginString = action.payload.retention[i][0].origin || '';
          cohortOriginEndDate = moment(cohortOriginString.split('/')[1]).utc();

          for (j = 0; j < action.payload.retention[i].length; j++) {
            timestamp = moment(action.payload.retention[i][j].timestamp).utc();
            //  if this cohort is invalid, it's left blank and the cell is shaded
            if (cohortOriginEndDate.isBefore(wentLiveOn) || cohortOriginEndDate.isBefore(dataStartDate)) {
              retentionRow.push({ percentage: 'empty', shaded: true });
            } else if (timestamp.isAfter(currentMonthStart, 'day')) {
              // if this bucket is in the future, it's left blank
              retentionRow.push({ percentage: 'empty' });
            } else if (timestamp.isSameOrAfter(currentMonthStart, 'day') && timestamp.isSameOrBefore(currentMonthEnd, 'day')) {
              // if this bucket is in the current month, i.e. it's still changing as the month elapses, mark the cell as such
              retentionRow.push({ percentage: action.payload.retention[i][j].percentage, isCurrent: true });
            } else {
              // if this bucket is in the past, and the cohort is valid, just use the datum value
              retentionRow.push({ percentage: action.payload.retention[i][j].percentage });
            }

            if (timestamp.isAfter(currentMonthEnd, 'month')) {
              retainedRow.push('invalid');
            } else {
              retainedRow.push(action.payload.retention[i][j].retained);
            }
          }
          retentionArray.push(retentionRow);

          if (cohortOriginEndDate.isAfter(wentLiveOn) && cohortOriginEndDate.isAfter(dataStartDate)) {
            retainedArray.push(retainedRow);
          }
        }
      }

      // transpose retainedArray to make it easier to get sums of columns
      const columns = transposeMatrix(retainedArray);

      // take sums of cohort retention for each month, take average rate of retention
      // for original population
      const averages = [];
      let columnSum;
      let originalPopSum;
      let colHeight;
      for (i = 0; i < retainedArray[0].length; i++) {
        columnSum = columns[i].reduce((a, b, l) => { // eslint-disable-line no-loop-func
          if (l === columns[i].length - i - 1) {
            return a;
          }
          if (b !== 'invalid') {
            return (a + b);
          }
          return a;
        }, 0);

        colHeight = getColumnHeight(columns[i]);
        originalPopSum = columns[0].map((el, idx) => ((idx < colHeight) ? el : 0)).reduce((a, b, k) => { // eslint-disable-line no-loop-func
          if (k === columns[i].length - i - 1) {
            return a;
          }
          return (a + b);
        }, 0);

        // avoid divide by zero
        if (originalPopSum === 0) {
          averages.push(0);
        } else {
          averages.push(columnSum / originalPopSum);
        }
      }

      return merge(state, {
        loading: false,
        fetchError: false,
        data: retentionArray,
        retentionAverages: averages,
      });
    }

    case actions.FETCH_RETENTION_FAILURE:
      return merge(state, {
        loading: false,
        fetchError: true,
      });

    default:
      return state;
  }
};

export default retention;
