import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { State } from 'src/reducers';
import {
  getCurrentApp,
  getCurrentAppId,
  getCurrentAppLoveScore,
  getCurrentAppMembershipRole,
  getCurrentAppPlatform,
  getCurrentAppPrimaryCIDKey,
  getCurrentAppTitle,
  getCurrentStoreAppId,
  integratedRatingFlow,
} from 'src/selectors/current_app';
import { getFeatures, getDisplayFsExportInfoModal } from 'src/selectors/user';
import { FanSignalDayCount, FanSignalLoveScore } from 'src/services/fan-signals-service';
import { eventMetrics, Events } from 'src/actions/event-metrics';
import { NotConfiguredLoveDialogWarning } from 'src/dashboard/components/love-percent-panel/not-configured-love-dialog';
import { DashboardLoveScore } from 'src/dashboard/components/dashboard-love-score/dashboard-love-score';
import demoAppIds from 'src/utils/demo_app_ids';
import { AppMemberRole } from 'src/reducers/apps.types';
import { fanSignals } from 'src/actions/fan-signals';
import { getDashboardEndDate, getDashboardStartDate } from 'src/selectors/dashboard';
import { acknowledgeFsExportInfoModal } from '../../actions/session';
import { SubHeaderNav } from '../sub-header-nav';
import { SubHeaderTabActions, SubHeaderTabActionsProps } from './sub-nav-tab-actions';

import '../styles/dashboard_sub_nav.scss';

interface DashboardSubNavProps
  extends Pick<
    SubHeaderTabActionsProps,
    'appStoreId' | 'live' | 'features' | 'withPrimaryCid' | 'hasSeenExportInfoModal'
  > {
  currentAppId: string;
  fanSignalCounts: FanSignalDayCount[];
  hasSeenExportInfoModal: boolean;
  acknowledgeFsExportInfoModal(): void;
  sendEvent(event: Events): void;
  ratingFlowIntegrated: boolean;
  fetchLoveScore(): Promise<FanSignalLoveScore>;
  loveScore: FanSignalLoveScore;
  startDate: number;
  endDate: number;
  appTitle: string;
  platform: string;
  role: AppMemberRole;
}

export class DashboardSubNav extends React.PureComponent<DashboardSubNavProps & RouteComponentProps> {
  static defaultProps = {
    appStoreId: '',
    currentAppId: 'current',
    features: [],
    live: false,
    withPrimaryCid: false,
    fanSignalCounts: [],
    hasSeenExportInfoModal: false,
    acknowledgeFsExportInfoModal,
    appTitle: '',
    platform: '',
  };

  componentDidMount() {
    this.props.fetchLoveScore();
  }

  componentDidUpdate(prevProps: { startDate: number; endDate: number }) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.props.fetchLoveScore();
    }
  }

  render() {
    const {
      currentAppId,
      features,
      appStoreId,
      live,
      withPrimaryCid,
      fanSignalCounts,
      hasSeenExportInfoModal,
      loveScore,
      appTitle,
      platform,
      role,
      sendEvent,
      acknowledgeFsExportInfoModal,
    } = this.props;

    const tabs = [
      { label: 'Dashboard', link: `/apps/${currentAppId}/dashboard` },
      { label: 'Fan Signals', link: `/apps/${currentAppId}/fan_signals` },
    ];

    const isDashboard = () => {
      const locationPath = this.props.location.pathname.split('/');
      return locationPath[locationPath.length - 1] === 'dashboard';
    };

    const isReporter = role === AppMemberRole.Reporter;

    return (
      <>
        <SubHeaderNav
          title="Summary"
          tabs={tabs}
          sendEvent={sendEvent}
          tabActions={
            <SubHeaderTabActions
              pathname={this.props.location.pathname}
              appStoreId={appStoreId}
              live={live}
              features={features}
              withPrimaryCid={withPrimaryCid}
              fanSignalCounts={fanSignalCounts}
              hasSeenExportInfoModal={hasSeenExportInfoModal}
              isReporter={isReporter}
              acknowledgeFsExportInfoModal={acknowledgeFsExportInfoModal}
              sendEvent={sendEvent}
            />
          }
        />
        {isDashboard() && (
          <DashboardLoveScore
            currentAppId={currentAppId}
            loveScore={loveScore}
            appTitle={appTitle}
            platform={platform}
            sendEvent={sendEvent}
          />
        )}
        {isDashboard() && (
          <div className="no-config-warnings sub-nav">
            <NotConfiguredLoveDialogWarning
              ratingFlowIntegrated={this.props.ratingFlowIntegrated}
              currentAppId={currentAppId}
              isReporter={isReporter}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: State) => ({
  appStoreId: getCurrentStoreAppId(state),
  currentAppId: getCurrentAppId(state),
  features: getFeatures(state),
  live: getCurrentApp(state).live,
  withPrimaryCid: !!getCurrentAppPrimaryCIDKey(state),
  hasSeenExportInfoModal: getDisplayFsExportInfoModal(state),
  ratingFlowIntegrated: demoAppIds.includes(getCurrentAppId(state)) || integratedRatingFlow(state),
  loveScore: getCurrentAppLoveScore(state),
  endDate: getDashboardEndDate(state),
  startDate: getDashboardStartDate(state),
  appTitle: getCurrentAppTitle(state),
  platform: getCurrentAppPlatform(state),
  role: getCurrentAppMembershipRole(state),
});

const mapDispatcheToProps = (dispatch: Function) => ({
  acknowledgeFsExportInfoModal: () => dispatch(acknowledgeFsExportInfoModal()),
  sendEvent: (event: Events) => dispatch(eventMetrics.sendEvent(event)),
  fetchLoveScore: () => dispatch(fanSignals.fetchLoveScore()),
});

export const DashboardSubNavContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatcheToProps
  )(DashboardSubNav)
);
