import { apply, compose, concat, converge, equals, identity, ifElse, is, isEmpty, isNil, join, lensIndex, lift, map, or, over, pick, reject, split, splitAt, toPairs, values, view, zipObj } from 'ramda';

import { InsightsBaseParams } from 'src/services/insights-service';
import { resolveQuery, LocationHistory } from 'src/store/history';
import {
  AggsSentiment,
  ICurrentSearch,
  PhraseItem,
  AggsType,
  AggsRating,
  DEFAULT_INSIGHTS_FILTERS,
  FanSignalStatus,
} from 'src/reducers/insights';
import { InsightsContextData } from './insights-context.types';

type InsightsUrlParams = Omit<InsightsBaseParams, 'phrases' | 'types'> & {
  phrases: InsightsContextData['phrases'];
  types: AggsType[];
};

/**
 * @param params.regions
 * @param params.types
 * @param {string} params.sentiment
 * @param {string} params.rating
 * @param params.phrases Array<{ display_key: string; phrase: string }>
 * @param {string} params.text
 * @param {number} params.startDate
 * @param {number} params.endDate
 * @param {number} params.page
 */
export const generateInsightsUrlQuery = (params: InsightsUrlParams, pathname = window.location.pathname): string => {
  const queryObj = reject(converge(or, [isEmpty, isNil]) as any)(params) as Partial<InsightsUrlParams>;
  const keyLens = lensIndex(0);
  const valueLens = lensIndex(1);
  const serializeValue = ifElse(is(Array), join(','), identity);
  const serializePhrase = (p: ['phrase', PhraseItem[]]) => {
    try {
      return [
        'phrases',
        serializeValue(
          map(
            join('::'),
            map(
              compose(
                values,
                pick(['display_key', 'phrase'])
              ),
              p[1]
            )
          )
        ),
      ];
    } catch (e: any) {
      // if we can't parse the phrase param we just toss away the values
      return ['phrase', ''];
    }
  };
  const isPhraseParam = compose(
    equals('phrases'),
    view(keyLens)
  );
  const processor = ifElse(isPhraseParam, serializePhrase, over(valueLens, serializeValue));
  const query = join('&', map(join('='), map(processor, toPairs(encodeQueryParams(queryObj)))));

  return `${pathname}?${query}`;
};

const splitQueryElems = (elems: string) => elems.replace(/, /g, '__').split(',').map(el => el.replace(/__/g, ', '))

export const getInsightsParamsFromUrlQuery = (
  location: LocationHistory,
  isNewApp: boolean
): Omit<ICurrentSearch, 'phrases'> & { phrases: PhraseItem[] } => {
  const {
    query: { regions, sentiment, rating, phrases, text, tags, types, fs_state, survey_title },
  } = resolveQuery(location);

  return {
    regions: regions ? regions.split(',') : DEFAULT_INSIGHTS_FILTERS.regions,
    sentiment: (sentiment as AggsSentiment) || DEFAULT_INSIGHTS_FILTERS.sentiment,
    rating: (rating as AggsRating) || DEFAULT_INSIGHTS_FILTERS.rating,
    // if we are fetching for a new app, we don't persist phrases
    phrases: isNewApp ? DEFAULT_INSIGHTS_FILTERS.phrases : deserializePhrases(phrases),
    types: types ? (types.split(',') as AggsType[]) : DEFAULT_INSIGHTS_FILTERS.types,
    tags: tags ? tags.split(',') : [],
    text: text || '',
    fs_state: fs_state ? (fs_state.split(',') as FanSignalStatus[]) : [],
    survey_title: survey_title ? (splitQueryElems(survey_title) as string[]) : [],
  };
};

export const getDateRangeFromUrlQuery = (location: LocationHistory) => {
  const { query } = resolveQuery(location);

  return {
    startDate: Number(query.startDate),
    endDate: Number(query.endDate),
  };
};

export const resolveTypesState = (
  data: Pick<Partial<InsightsContextData>, 'fs_state' | 'survey_title' | 'types'>,
  state: Pick<InsightsContextData, 'fs_state' | 'survey_title' | 'types'>
): Partial<InsightsContextData> => {
  const fsState = data.fs_state || state.fs_state;
  const surveyNamesState = data.survey_title || state.survey_title;
  const types = data.types || state.types;

  if (!data.fs_state && !data.types && !data.survey_title) {
    return data;
  }

  if (!isEmpty(fsState)) {
    data.types = types.filter((type) => type !== AggsType.REVIEW);
  }

  if (!isEmpty(surveyNamesState)) {
    data.types = types.filter((type) => type === AggsType.SURVEY);
  }

  if (isEmpty(data.types) && !isEmpty(fsState)) {
    data.types = DEFAULT_INSIGHTS_FILTERS.types.filter((type) => type !== AggsType.REVIEW);
  }

  if (isEmpty(data.types) && !isEmpty(surveyNamesState)) {
    data.types = [AggsType.SURVEY];
  }

  return data;
};

/**
 * @returns
 * text=% => text=%25
 * tags=["±","�","some"] => tags=["%C2%B1", "%EF%BF%BD", "some"]
 */
function encodeQueryParams({ tags, text, ...params }: Partial<InsightsUrlParams>) {
  return {
    ...params,
    tags: Array.isArray(tags) ? tags.map(encodeURI) : tags,
    text: text ? encodeURI(text) : text,
  };
}

/**
 * @param queryString "tell me::tell$DELIM$me"
 * @returns Array<{display_key: "tell me", phrase: "tell$DELIM$me"}>
 */
function deserializePhrases(queryString: string) {
  if (!queryString) return [];
  const tokenized = map(split('::'), split(',', queryString));
  return compose<any, any, any, PhraseItem[]>(
    apply(lift(zipObj)) as any,
    splitAt(1),
    concat([['display_key', 'phrase']])
  )(tokenized);
}

/**
 * @param phrases ["free$DELIM$stuff"]
 * @returns Array<{display_key: "free stuff", phrase: "tell$DELIM$me"}>
 */
export function prepareSerializedPhrases(phrases: string[]): InsightsContextData['phrases'] {
  return phrases.map(
    (phrase) =>
      // eslint-disable-next-line
      ({
        phrase,
        display_key: phrase.replace(/\$DELIM\$/g, ' '),
      } as PhraseItem)
  );
}
