import React, { useMemo } from 'react';
import { ChartDataSets } from 'chart.js';
import { ChartWrapper, ChartProps } from './chart-wrapper';

import './score-chart.scss';

export interface ScoreChartProps extends Omit<ChartProps, 'data'> {
  score: number;
  min: number;
  max: number;
  simpleColors?: boolean;
}

// README: For improvements
// https://github.com/kluverua/Chartjs-tsgauge
// https://github.com/fiefdx/gauge-chart-js/blob/master/gauge.js
export const ScoreChart: React.FC<ScoreChartProps> = ({
  options = {},
  score,
  min,
  max,
  simpleColors = true,
  ...props
}) => {
  const data = useMemo<ChartProps['data']>(() => {
    const dataValues = getScoreData(min, max, score);
    const dataColors = simpleColors ? getScoreSimpleColors(min, max, score) : getScoreColors(min, max, score);
    const overlapColors: string[] = dataValues.map((c, i) => {
      if (c === 1) {
        dataColors[i] = 'black';
        dataColors.splice(i + 1);
        return 'black';
      }
      return 'white';
    });

    const valueData: ChartDataSets = {
      data: dataValues,
      backgroundColor: dataColors,
      hoverBackgroundColor: dataColors,
      borderWidth: 0,
      hoverBorderWidth: 0,
    };

    const overlayData = {
      ...valueData,
      backgroundColor: overlapColors,
      hoverBackgroundColor: overlapColors,
    };

    return {
      datasets: [valueData, overlayData],
    };
  }, [score, min, max]);

  return (
    <div className="score-chart">
      <div className="score-middle">{(min + max) / 2}</div>
      <ChartWrapper
        type="doughnut"
        {...props}
        data={data}
        options={{
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          cutoutPercentage: 30,
          layout: {
            padding: {
              top: 10,
              right: 15,
              left: 15,
            },
          },
          title: {
            display: true,
            text: String(score),
            position: 'bottom',
            padding: -38,
            fontSize: 32,
            fontStyle: '400',
          },
          ...options,
        }}
      />
      <div className="score-ranges">
        <span>{min}</span>
        <span>{max}</span>
      </div>
      <div className="score-label">NPS</div>
    </div>
  );
};

const NUMBER_OF_STEPS = 4;
const inRange = (value: number, [from, to]: number[]) => value >= from && value < to;

// https://github.com/apptentive/dokidoki/blob/d25bbf02038001aa8ce3c052fb725e219ca1dc64/javascripts/views/surveys/show_response_row.coffee#L366
function getScoreColors(min: number, max: number, score: number) {
  const { range } = getRanges(min, max, score);
  if (score < range[0]) return ['#EF424A', '#aaeab9', '#82df98', '#59D576'];
  if (score >= range[0] && score < range[1]) return ['#FD7A63', '#FCCA61', '#82df98', '#59D576'];
  return ['#d3f4db', '#aaeab9', '#82df98', '#59D576'];
}

function getScoreSimpleColors(min: number, max: number, score: number) {
  const { range } = getRanges(min, max, score);
  const colors = new Array(NUMBER_OF_STEPS).fill('#21C822');
  if (score < range[0]) return colors.fill('#FF7F00');
  if (score >= range[0] && score < range[1]) return colors.fill('#FFBB73');
  return colors;
}

function getRanges(min: number, max: number, score: number) {
  const step = (Math.abs(min) + Math.abs(max)) / NUMBER_OF_STEPS;
  const diff = Math.abs(step - Math.abs(score));
  const range = [min + step, (min + max) / 2, max - step];
  return { step, diff, range };
}

function getScoreData(min: number, max: number, score: number) {
  const { step, diff, range } = getRanges(min, max, score);
  let data = new Array(NUMBER_OF_STEPS).fill(step);

  if (score < range[0]) {
    data = [step - diff, 1, step * 4 - diff, 0, 0];
  } else if (inRange(score, [range[0], range[1]])) {
    data = [step, diff, 1, step * 3 - diff, 0];
  } else if (inRange(score, [range[1], range[2]])) {
    data = [step, step, step - diff, 1, step + diff];
  } else {
    data = [step, step, step, diff, 1, step - diff];
  }

  return data;
}
