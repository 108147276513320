import { CLOSE_ATTACHMENT, OPEN_ATTACHMENT } from '../actions/messages';

const initialState = {
  open: false,
  url: null,
  originalName: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_ATTACHMENT:
      return { open: true, url: action.payload.url, originalName: action.payload.originalName };
    case CLOSE_ATTACHMENT:
      return initialState;
    default:
      return state;
  }
};
