import { createBrowserHistory, Location } from 'history';
import analytics from '../data/analytics';

const history = createBrowserHistory();

export interface LocationHistory extends Location {
  query: Record<string, string>;
}

// compatibility with old borwserHistory
Object.assign(history, {
  getCurrentLocation: () => resolveQuery(history.location),
});

history.listen((location) => {
  let path;
  const tokens = location.pathname.split('/');
  const appsIndex = tokens.indexOf('apps');
  if (appsIndex !== -1) {
    tokens.splice(appsIndex + 1, 1);
    path = tokens.join('/');
  } else {
    path = location.pathname;
  }
  analytics.trackPageView(path);
});

export { history, resolveQuery };

function resolveQuery(location: Location & { query?: LocationHistory['query'] }): LocationHistory {
  const query = location.query ? { ...location.query } : {};
  if (!location.query) {
    const params = new URLSearchParams(location.search.substring(1));
    params.forEach((value, key) => (query[key] = value));
  }
  return {
    ...location,
    query,
  };
}
