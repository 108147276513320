import { AggsType, AggsRating, AggsSentiment } from './insights.enum';
import { SavedInsight } from './insights.types';

export const SAVED_INSIGHT: SavedInsight = {
  count: 51000,
  created_date: 1575471032091,
  description: 'some description',
  filters: {
    storeAppId: 'store-app-id',
    tags: [],
    text: '',
    types: [AggsType.REVIEW],
    rating: AggsRating.ALL,
    regions: [],
    sentiment: AggsSentiment.ALL,
    fs_state: [],
    survey_title: [],
    phrases: [],
    startDate: 1570233600000,
    endDate: 1575503999999,
  },
  id: 'item-id',
  modified_date: 1575471032091,
  name: 'SavedInsight-Name',
  app_id: 'appId-1',
  org_id: 'orgId-1',
  user_id: 'userId-2',
};
