import { shadow } from './box';

// Collection of theme properties
export { default as colors } from './colors';
export { default as breakpoints } from './breakpoints';
export {
  default as fonts,
  weight as fontWeights,
  family as fontFamilies,
  colors as fontColors,
  lineHeight,
  size as fontSize,
} from './fonts';
export const box = { shadow };
