import React from 'react';

import { bemPrefix } from 'src/utils';
import { OrganizationApp } from 'src/reducers/organizations';

import './app-info.scss';

const bem = bemPrefix('imt-app-info');

export const AppInfo: React.FC<{ app: OrganizationApp }> = ({ app }) => {
  return (
    <div key={app.id} className={bem()}>
      <img className={bem('icon')} src={app.icon_url} alt={app.title} />
      <span className={bem('name')}>{app.title}</span>
      <span className={bem('platform')}>{app.platform}</span>
    </div>
  );
};
