import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { colors, fontWeights, box } from '../../../theme';

const Tooltip = styled.aside`
  padding: 20px 30px 27px;
  display: inline-block;
  color: ${colors.onyx};
  background: ${colors.white};
  position: relative;
  z-index: 2;
  min-width: 300px;
  max-width: 500px;
  box-shadow: ${box.shadow.material};

  h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 23px;
    font-weight: ${fontWeights.bold};
  }

  .summary {
    margin: 0;
    height: 36px;
    display: flex;
    justify-content: space-between;
    color: ${colors.onyx};
    > * {
      white-space: nowrap;
      margin: 0 0 17px;
      font-size: 14px;
      font-weight: ${fontWeights.regular};
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
  .series-details {
    display: flex;
    flex-wrap: wrap;
    > .series-detail {
      flex: 1 1 auto;
      margin: 12px 20px 0 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

const Detail = styled(({
  className, label, y, total, color, detailFormatter, subDetailFormatter,
}) => (
  <div className={className}>
    <h5 style={{ color }}>{label}</h5>
    <h2>{detailFormatter(y)}</h2>
    <h3>{subDetailFormatter(y, total)}</h3>
  </div>
))`
  border-left: solid 1px ${colors.nimbus};
  padding: 0 0 0 9px;

  h5 {
    font-size: 10px;
    font-weight: ${fontWeights.bold};
    margin: 0px;
    text-transform: uppercase;
    whitespace: nowrap;
  }
  h2 {
    font-size: 26px;
    line-height: 32px;
    font-weight: ${fontWeights.regular};
    margin: -2px 0 0;
    color: ${colors.shadow};
  }
  h3 {
    font-size: 14px;
    font-weight: ${fontWeights.regular};
    margin: 0;
    color: ${colors.shadow};
  }
`;

const Ratiogram = styled.div.attrs({ 'data-test': 'ratiogram' })`
  display: flex;
  width: 260px;
  height: 12px;
  margin-bottom: 20px;
  &:empty {
    display: none;
  }
  > div {
    height: 12px;
    &:last-of-type {
      border-right: none;
    }
  }
`;

const HoverCard = ({
  /* eslint-disable max-len */
  title, summaryTitle, summaryInfoFormatter, renderDetail, series, ratiogram, detailFormatter, subDetailFormatter,
}) => {
  const total = series.reduce((sum, { y }) => sum + Math.abs(y), 0);
  const summaryInfo = summaryInfoFormatter(series);
  const barPercentage = (point) => (point.y ? (
    <div
      key={point.label}
      data-test="horizontal-bar"
      style={{ width: `${(Math.abs(point.y) / total) * 100}%`, background: point.color }}
    />
  ) : null);

  return (
    <Tooltip>
      <h3 data-test="title">{title}</h3>
      <div className="summary">
        <h4 data-test="summary-title">{summaryTitle || title}</h4>
        <h4 data-test="summary-info">{summaryInfo}</h4>
      </div>
      {ratiogram && <Ratiogram>{series.map(barPercentage)}</Ratiogram>}
      <div className="series-details">
        {renderDetail
          && series.map((s) => (
            <div key={s.label} className="series-detail" data-test="series-detail">
              {renderDetail(s, series, detailFormatter, subDetailFormatter)}
            </div>
          ))}
      </div>
    </Tooltip>
  );
};
HoverCard.propTypes = {
  title: PropTypes.string,
  summaryTitle: PropTypes.string,
  series: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, x: PropTypes.number, y: PropTypes.number }),
  ),
  renderDetail: PropTypes.func,
  ratiogram: PropTypes.bool,
  detailFormatter: PropTypes.func,
  subDetailFormatter: PropTypes.func,
  summaryInfoFormatter: PropTypes.func,
};

const defaultRenderDetail = (data, series, detailFormatter, subDetailFormatter) => {
  const total = series.reduce((sum, { y }) => sum + Math.abs(y), 0);
  return <Detail y={data.y} label={data.label} total={total} color={data.color} detailFormatter={detailFormatter} subDetailFormatter={subDetailFormatter} />;
};

HoverCard.defaultProps = {
  title: '',
  summaryTitle: '',
  series: [],
  renderDetail: defaultRenderDetail,
  ratiogram: false,
  detailFormatter: y => numeral(y).format('0a'),
  subDetailFormatter: (y, total) => numeral(y / total).format('0.[00]%'),
  summaryInfoFormatter: (tooltipData) => numeral(tooltipData.reduce((sum, { y }) => sum + y, 0)).format('0'),
};

export default HoverCard;
