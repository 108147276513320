import React, { Component } from 'react';
import { connect } from 'react-redux';
import colors from 'src/apptentive_ui/theme/colors';
import { isEmpty } from 'ramda';

import { FanSignalResponseStatus, HistogramDataPoint, IAppStoreVersion, IDateAnnotation } from 'src/types/core';
import { State } from 'src/reducers';

import { addUtcOffsetToEpoch, DateLastPeriod, CustomPeriodName } from 'src/utils/time';
import collapseAndFormatAppReleases from 'src/utils/reference_lines';

import { getCurrentApp, getCurrentAppId, getCurrentStoreAppVersions } from 'src/selectors/current_app';
import {
  getDashboardEndDate,
  getDashboardNamedDate,
  getDashboardStartDate,
  getDateAnnotations,
} from 'src/selectors/dashboard';
import { getFanSignalsCountsByDashboard } from 'src/selectors/fan-signals.selectors';

import InfoTooltip from 'src/components/molecules/info_tooltip';

import { DashboardSubNavContainer } from 'src/components/dashboard_sub_nav';
import { fanSignals } from 'src/actions/fan-signals';
import { FanSignalDayCount } from 'src/services/fan-signals-service';

import FanSignalsTimeSeriesGraph from './fan_signals_graph';
import FanSignalsAggregateSection from './fan_signals_aggregate';
import { FanSignalsShiftedActionsContainer } from './fan-signals-graph-actions';

import '../../components/styles/base.scss';
import '../../components/styles/dashboard.scss';

export const REPEAT_FAN_VOLUME_COLORS = [colors.eggplant, colors.cayenne];
export const REPEAT_FAN_PERCENT_COLORS = [colors.eggplant, colors.nimbus];
export const SHIFTED_FAN_VOLUME_COLORS = [colors.orchid, colors.coral];
export const SHIFTED_FAN_PERCENT_COLORS = [colors.orchid, colors.nimbus];
export const NEW_FAN_VOLUME_COLORS = [colors.amethyst, colors.mango];
export const NEW_FAN_PERCENT_COLORS = [colors.amethyst, colors.nimbus];

interface IState {
  isLoading: boolean;
  repeatFanData: GraphConfig;
  shiftedFanData: GraphConfig;
  newFanData: GraphConfig;
  fetchError: boolean;
}

export interface IFanSignalsPanelProps {
  appStoreVersions: IAppStoreVersion[];
  currentApp: any;
  currentAppId: string;
  dateAnnotations?: IDateAnnotation[];
  endDate: number;
  startDate: number;
  dateRangeName: DateLastPeriod | CustomPeriodName;
  fanSignalCounts: FanSignalDayCount[];
  fetchFanSignalsCounts(
    startDate: number,
    endDate: number,
    lastPeriod?: DateLastPeriod | CustomPeriodName
  ): Promise<FanSignalDayCount[]>;
}

export interface SwitchersTimeseries {
  label: string;
  data: HistogramDataPoint[];
}

export const SHIFTED_GRAPH_ID = 'shifted-graph';

export class FanSignalsPanel extends Component<IFanSignalsPanelProps, IState> {
  static defaultProps = {
    dateAnnotations: [],
    fanSignalCounts: [],
  };

  state: IState = {
    fetchError: false,
    isLoading: true,
    newFanData: generateNewFanConfig(),
    repeatFanData: generateRepeatFanConfig(),
    shiftedFanData: generateShiftedFanConfig(),
  };

  componentDidMount() {
    this.fetchFanSignalsData(this.props.startDate, this.props.endDate);
    const params = new URLSearchParams(window.location.search);
    if (params.get('scrollTo')) {
      const target = document.getElementById(params.get('scrollTo') as string);
      if (target) {
        target.scrollIntoView();
      }
    }
  }

  componentDidUpdate(prevProps: IFanSignalsPanelProps) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.fetchFanSignalsData(this.props.startDate, this.props.endDate);
    }
  }

  invokeFsDataPairs = (counts: FanSignalDayCount[]) => {
    const { shiftedFanData, repeatFanData, newFanData } = transformFanSignalsResponse({ data: counts });

    this.setState({
      isLoading: false,
      fetchError: false,
      shiftedFanData,
      repeatFanData,
      newFanData,
    });
  };

  async fetchFanSignalsData(startDate: number, endDate: number) {
    try {
      this.setState({ isLoading: true });
      const fanSignals = await this.props.fetchFanSignalsCounts(startDate, endDate, this.props.dateRangeName);
      // Perform all calculations for graphs/callouts in one loop for performance
      this.invokeFsDataPairs(fanSignals);
    } catch (err: any) {
      this.setState({
        fetchError: true,
        isLoading: false,
      });
    }
  }

  render() {
    const { appStoreVersions, dateAnnotations, startDate, endDate, fanSignalCounts } = this.props;
    const appReleaseAnnotations: IDateAnnotation[] = collapseAndFormatAppReleases(appStoreVersions, startDate, endDate);
    const graphAnnotations = [...(dateAnnotations || []), ...appReleaseAnnotations];

    return (
      <div id="fan-signals-panel" className="main-content">
        <DashboardSubNavContainer fanSignalCounts={fanSignalCounts} />
        <div className="graph-grid full-width">
          <header>
            <div className="title-container">
              <h2>Repeat Fans Percent</h2>
              <InfoTooltip>
                <p>
                  <strong>Repeat Fans Percent</strong> - This reflects customers who repeatedly responded “Yes” to Love
                  Dialogs presented, vs customers who repeatedly responded “No” to Love Dialogs. The higher the
                  percentage, the more repeat fans you have, as opposed to repeat customers at risk. 50% means that
                  there is the same number of people repeatedly responding “Yes” as those repeatedly responding “No”
                </p>

                <p>
                  You can use this percent to determine how changes to your application or customer experience affect
                  loyal consumers. A low percentage indicates that you’re losing Fans who used to always be loyal, or
                  that you’re gaining a lot of customers who are repeatedly at risk.
                </p>
                <p>
                  <strong>Repeat Fans</strong> - A customer who responded “Yes” to the last two Love Dialogs seen.
                </p>
                <p>
                  <strong>Repeat Risks</strong> - A customer who responded “No” to the last two Love Dialogs seen.
                </p>
              </InfoTooltip>
            </div>
          </header>
          <div className="graph-grid-body">
            <FanSignalsAggregateSection
              percentSection
              startDate={startDate}
              endDate={endDate}
              fetchError={this.state.fetchError}
              isLoading={this.state.isLoading}
              graphConfig={this.state.repeatFanData}
            />
            <section className="graph fan-signals-volume-over-time-graph two-third-width histogram-timeseries-graph">
              <FanSignalsTimeSeriesGraph
                colors={REPEAT_FAN_PERCENT_COLORS}
                percentSection
                graphConfig={this.state.repeatFanData}
                graphAnnotations={graphAnnotations}
                fetchError={this.state.fetchError}
                isLoading={this.state.isLoading}
                startDate={startDate}
                endDate={endDate}
              />
            </section>
            <FanSignalsAggregateSection
              startDate={startDate}
              endDate={endDate}
              fetchError={this.state.fetchError}
              isLoading={this.state.isLoading}
              graphConfig={this.state.repeatFanData}
            />
            <section className="graph fan-signals-volume-over-time-graph two-third-width histogram-timeseries-graph">
              <FanSignalsTimeSeriesGraph
                colors={REPEAT_FAN_VOLUME_COLORS}
                graphConfig={this.state.repeatFanData}
                graphAnnotations={graphAnnotations}
                fetchError={this.state.fetchError}
                isLoading={this.state.isLoading}
                startDate={startDate}
                endDate={endDate}
              />
            </section>
          </div>
        </div>

        <div className="graph-grid full-width" id={SHIFTED_GRAPH_ID}>
          <header>
            <div className="title-container">
              <h2>Shifted to Fans Percent</h2>
              <InfoTooltip>
                <p>
                  <strong>Shifted to Fans Percent</strong> - This compares the number of people shifting favorably
                  towards being a Fan vs shifting away from being a Fan. The higher the percentage, the more customers
                  are shifting over to “Fan” status. 50% means that there are the same number of people shifting
                  favorably towards being a Fan as people shifting away from being a Fan.
                </p>

                <p>
                  You can use this percentage to determine how changes to your application or customer experience affect
                  your existing customers. A low percentage may indicate that a recent change has frustrated many
                  customers. Use Insights to understand and analyze some of the feedback provided, or use Interactions
                  to gather more feedback.
                </p>
                <p>
                  <strong>Shifted to Fan</strong> - A customer who had responded “No” to the Love Dialog previously, but
                  has now responded “Yes” to the most recent one presented.
                </p>
                <p>
                  <strong>Shifted to Risk</strong> - A customer who had responded “Yes” to the Love Dialog previously,
                  but has now responded “No” to the most recent one presented.
                </p>
              </InfoTooltip>
            </div>
            <div className="graph-header-actions">
              <FanSignalsShiftedActionsContainer />
            </div>
          </header>
          <div className="graph-grid-body">
            <FanSignalsAggregateSection
              percentSection
              startDate={startDate}
              endDate={endDate}
              fetchError={this.state.fetchError}
              isLoading={this.state.isLoading}
              graphConfig={this.state.shiftedFanData}
            />
            <section className="graph fan-signals-volume-over-time-graph two-third-width histogram-timeseries-graph">
              <FanSignalsTimeSeriesGraph
                colors={SHIFTED_FAN_PERCENT_COLORS}
                percentSection
                graphConfig={this.state.shiftedFanData}
                graphAnnotations={graphAnnotations}
                fetchError={this.state.fetchError}
                isLoading={this.state.isLoading}
                startDate={startDate}
                endDate={endDate}
              />
            </section>
            <FanSignalsAggregateSection
              startDate={startDate}
              endDate={endDate}
              fetchError={this.state.fetchError}
              isLoading={this.state.isLoading}
              graphConfig={this.state.shiftedFanData}
            />
            <section className="graph fan-signals-volume-over-time-graph two-third-width histogram-timeseries-graph">
              <FanSignalsTimeSeriesGraph
                colors={SHIFTED_FAN_VOLUME_COLORS}
                graphConfig={this.state.shiftedFanData}
                graphAnnotations={graphAnnotations}
                fetchError={this.state.fetchError}
                isLoading={this.state.isLoading}
                startDate={startDate}
                endDate={endDate}
              />
            </section>
          </div>
        </div>

        <div className="graph-grid full-width">
          <header>
            <div className="title-container">
              <h2>New Fans Percent</h2>
              <InfoTooltip>
                <p>
                  <strong>New Fans Percent </strong> - This compares how many of your new customers are Fans vs at risk.
                  The higher the percentage, the greater quantity of new fans exist compared to the total of your new
                  customers. 50% means that there are equal numbers of new customers who are Fans, as well as those who
                  are not, based on their response to the first Love Dialog they are presented.
                </p>
                <p>
                  You can use this percentage to assess how well your initial experience has been for your new
                  customers. A low percentage may indicate that the initial experience hasn’t been positive.
                </p>
                <p>
                  <strong>New Fans</strong> - A customer who responded “Yes” to their first Love Dialog.
                </p>
                <p>
                  <strong>New Risks </strong> - A customer who responded “No” to their first Love Dialog.
                </p>
              </InfoTooltip>
            </div>
          </header>
          <div className="graph-grid-body">
            <FanSignalsAggregateSection
              percentSection
              startDate={startDate}
              endDate={endDate}
              fetchError={this.state.fetchError}
              isLoading={this.state.isLoading}
              graphConfig={this.state.newFanData}
            />
            <section className="graph fan-signals-volume-over-time-graph two-third-width histogram-timeseries-graph">
              <FanSignalsTimeSeriesGraph
                colors={NEW_FAN_PERCENT_COLORS}
                percentSection
                graphConfig={this.state.newFanData}
                graphAnnotations={graphAnnotations}
                fetchError={this.state.fetchError}
                isLoading={this.state.isLoading}
                startDate={startDate}
                endDate={endDate}
              />
            </section>
            <FanSignalsAggregateSection
              startDate={startDate}
              endDate={endDate}
              fetchError={this.state.fetchError}
              isLoading={this.state.isLoading}
              graphConfig={this.state.newFanData}
            />
            <section className="graph fan-signals-volume-over-time-graph two-third-width histogram-timeseries-graph">
              <FanSignalsTimeSeriesGraph
                colors={NEW_FAN_VOLUME_COLORS}
                graphConfig={this.state.newFanData}
                graphAnnotations={graphAnnotations}
                fetchError={this.state.fetchError}
                isLoading={this.state.isLoading}
                startDate={startDate}
                endDate={endDate}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export interface GraphConfig {
  volumeMainSeries: HistogramDataPoint[];
  volumeSecondarySeries: HistogramDataPoint[];
  percentMainSeries: HistogramDataPoint[];
  percentSecondarySeries: HistogramDataPoint[];
  totalMainField: number;
  totalSecondaryField: number;
  mainField: FanSignalResponseStatus;
  secondaryField: FanSignalResponseStatus;
  mainSeriesTitle: string;
  secondarySeriesTitle: string;
  percentHighlightTitle: string;
  volumeHighlightTitle: string;
}

export function transformFanSignalsResponse(fanSignalsRaw: { data: FanSignalDayCount[] }) {
  const shiftedFanData = generateShiftedFanConfig();
  const repeatFanData = generateRepeatFanConfig();
  const newFanData = generateNewFanConfig();

  const fsData = fanSignalsRaw && fanSignalsRaw.data && !isEmpty(fanSignalsRaw.data) ? fanSignalsRaw.data : [];

  fsData.forEach((cur, i) => {
    if (cur) {
      const time = addUtcOffsetToEpoch(new Date(cur.day || 0).getTime());

      enrichTimeseries(cur, shiftedFanData, time, i);
      enrichTimeseries(cur, repeatFanData, time, i);
      enrichTimeseries(cur, newFanData, time, i);
    }
  });
  return {
    shiftedFanData,
    repeatFanData,
    newFanData,
  };
}

export function enrichTimeseries(dayData: FanSignalDayCount, graphSpecific: GraphConfig, time: Date, i: number) {
  const mainFieldCount = dayData[graphSpecific.mainField] || 0;
  const secondaryFieldCount = dayData[graphSpecific.secondaryField] || 0;
  const combinedCount = mainFieldCount + secondaryFieldCount;

  const mainPercent = calculatePercent(mainFieldCount, secondaryFieldCount);

  graphSpecific.totalMainField += mainFieldCount;
  graphSpecific.totalSecondaryField += secondaryFieldCount;

  graphSpecific.percentMainSeries.push({
    x: time,
    y: parseFloat(mainPercent.toFixed(2)),
    meta: {
      label: graphSpecific.mainSeriesTitle,
      originalTotal: combinedCount,
      originalDatum: { date: Number(time), y: mainFieldCount || 0 },
      positionInSeries: i,
    },
  });
  graphSpecific.percentSecondarySeries.push({
    x: time,
    y: parseFloat(mainPercent.toFixed(2)),
    meta: {
      label: graphSpecific.secondarySeriesTitle,
      originalTotal: combinedCount,
      originalDatum: { date: Number(time), y: secondaryFieldCount || 0 },
      positionInSeries: i,
    },
  });
  graphSpecific.volumeMainSeries.push({
    x: time,
    y: mainFieldCount,
    meta: {
      label: graphSpecific.mainSeriesTitle,
      originalTotal: combinedCount,
      originalDatum: { date: Number(time), y: mainFieldCount || 0 },
      positionInSeries: i,
    },
  });
  graphSpecific.volumeSecondarySeries.push({
    x: time,
    y: -secondaryFieldCount,
    meta: {
      label: graphSpecific.secondarySeriesTitle,
      originalTotal: combinedCount,
      originalDatum: { date: Number(time), y: secondaryFieldCount || 0 },
      positionInSeries: i,
    },
  });
}

const mapStateToProps = (state: State): Omit<IFanSignalsPanelProps, 'fetchFanSignalsCounts'> => ({
  appStoreVersions: getCurrentStoreAppVersions(state),
  currentApp: getCurrentApp(state),
  currentAppId: getCurrentAppId(state),
  dateAnnotations: getDateAnnotations(state),
  endDate: getDashboardEndDate(state),
  startDate: getDashboardStartDate(state),
  dateRangeName: getDashboardNamedDate(state),
  fanSignalCounts: getFanSignalsCountsByDashboard(state),
});

export function calculatePercent(mainField: number, secondaryField: number): number {
  if (mainField <= 0 && secondaryField <= 0) {
    return 0;
  }
  return mainField / (mainField + secondaryField);
}

export const FanSignalsContainer = connect<
  Omit<IFanSignalsPanelProps, 'fetchFanSignalsCounts'>,
  Pick<IFanSignalsPanelProps, 'fetchFanSignalsCounts'>
>(
  mapStateToProps,
  (dispatch: Function) => ({
    fetchFanSignalsCounts: (startDate, endDate) => dispatch(fanSignals.fetchFanSignalsCounts(startDate, endDate)),
  })
)(FanSignalsPanel);
FanSignalsContainer.displayName = 'FanSignalsContainer';

export default FanSignalsContainer;

// Utils
function generateEmptyGraphConfig(
  mainField: FanSignalResponseStatus,
  secondaryField: FanSignalResponseStatus,
  mainSeriesTitle: string,
  secondarySeriesTitle: string,
  percentHighlightTitle: string,
  volumeHighlightTitle: string
): GraphConfig {
  return {
    volumeMainSeries: [],
    volumeSecondarySeries: [],
    percentMainSeries: [],
    percentSecondarySeries: [],
    totalMainField: 0,
    totalSecondaryField: 0,
    mainField,
    secondaryField,
    mainSeriesTitle,
    secondarySeriesTitle,
    percentHighlightTitle,
    volumeHighlightTitle,
  };
}

export function generateRepeatFanConfig() {
  return generateEmptyGraphConfig(
    'repeatFans',
    'repeatOpportunities',
    'Repeat Fans',
    'Repeat Risks',
    'Repeat Fans Percent',
    'Total Repeat Customers'
  );
}

export function generateShiftedFanConfig() {
  return generateEmptyGraphConfig(
    'reclaimedFans',
    'lostFans',
    'Shifted To Fan',
    'Shifted To Risk',
    'Shifted to Fans Percent',
    'Total Shifted Customers'
  );
}

export function generateNewFanConfig() {
  return generateEmptyGraphConfig(
    'fans',
    'opportunities',
    'New Fans',
    'New Risks',
    'New Fans Percent',
    'Total New Customers'
  );
}
