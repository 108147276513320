import { ExportInsightData } from '../insights-service';

export enum ExportType {
  all = 'all_export',
  content = 'content_export',
  aggs = 'aggs_export',
  phrases = 'phrases_export',
  tags = 'tags_export',
}

export enum SendType {
  start = 'export_start',
  status = 'export_status',
  query = 'export_query',
  stop = 'export_stop',
}

export enum RespType {
  unknown = 'unknown_request',
  error = 'payload_error',
  state = 'export_state',
  failure = 'downstream_failure',
}

export enum StatusType {
  started = 'started',
  inProgress = 'in_progress',
  completed = 'completed',
  nonExisting = 'non_existing',
}

interface Message {
  $type: SendType | RespType;
}

export interface ExcludeExportFields {
  fields_to_exclude: (keyof ExportInsightData)[];
}

// --- request types
interface StartExport extends Message {
  $type: SendType.start;
  export_type: ExportType;
  parameters: ExportInsightData & ExcludeExportFields;
  owner_id: string; // userId
}
interface StatusExport extends Message {
  $type: SendType.status;
  id: RespState['parameters']['id'];
}
interface StatusExportsUser extends Message {
  $type: SendType.query;
  owner_id: StartExport['owner_id'];
}
interface StopExport extends Message {
  $type: SendType.stop;
  id: RespState['parameters']['id'];
}

export type SendMessage = StartExport | StatusExport | StatusExportsUser | StopExport;

// --- response types
interface RespPayloadUnknown extends Message {
  $type: RespType.unknown;
  request_body: any;
}

interface RespPayloadError extends Message {
  $type: RespType.error;
  error_message: string;
}

export interface RespState extends Message {
  $type: RespType.state;
  status: StatusType;
  current_records_count: number;
  total_records_count: number;
  parameters: {
    id: string;
    export_type: StartExport['export_type'];
    filter_params: StartExport['parameters'];
    owner_id: StartExport['owner_id'];
  };
  url?: string;
}

export type RespMessage = RespState | RespPayloadUnknown | RespPayloadError;
