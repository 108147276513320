import React from 'react';
import { Tag } from 'src/reducers/tags';
import { GtmID } from 'src/types/google-tag-manager';

import { TagsSelectContainer, TagsSelectProps } from './tags-select';

interface RenameTagFormProps extends Pick<TagsSelectProps, 'onChange'> {
  tags: Tag[];
}

export const RenameTagForm: React.FC<RenameTagFormProps> = ({ tags, onChange }) => (
  <div className="new-tag-form rename-form">
    <span>Change tag name</span>
    <TagsSelectContainer onChange={onChange} skipTags={tags} gtmId={GtmID.RenameCardTag} />
  </div>
);

RenameTagForm.displayName = 'RenameTagForm';
