import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  $getSelection,
  $isRangeSelection,
  NodeKey,
  BaseSelection,
  $getNodeByKey,
  TextNode,
  LexicalCommand,
  createCommand,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';

import { bemPrefix } from 'src/utils';
import { InputText, useModalProps, Modal } from 'src/components/molecules';
import { useFormsValidation } from 'src/hooks';
import { $getMatchingSelectedNode, $pruneNode, $selectCurrentWord, $updateLinkNode } from './utils';

import './edit-link-plugin.scss';

const bem = bemPrefix('rich-text-editor-edit-link');

type EditingLink = {
  text: string,
  url: string,
} & ({
  type: 'existing',
  existingLinkKey: NodeKey,
} | {
  type: 'new',
  selection: BaseSelection,
});

export const EDIT_LINK_COMMAND: LexicalCommand<void> = createCommand();

export const EditLinkPlugin: FC<{}> = () => {
  const [editor] = useLexicalComposerContext();

  const [editingLink, setEditingLink] = useState<EditingLink | null>(null);
  const { isOpenModal: isLinkEditModalOpen, showModal: showLinkEditModal, closeModal: closeLinkEditModal } = useModalProps(false);

  useEffect(() => {
    return editor.registerCommand(
      EDIT_LINK_COMMAND,
      () => {
        const existingLinkNode = editor.getEditorState().read(() =>
          $getMatchingSelectedNode((n) => n.getType() === 'link' || n.getType() === 'autolink')
        );

        if (existingLinkNode && $isLinkNode(existingLinkNode)) {
          setEditingLink({
            type: 'existing',
            text: existingLinkNode.getTextContent(),
            url: existingLinkNode.getURL(),
            existingLinkKey: existingLinkNode.getKey(),
          });
          showLinkEditModal();

          return true;
        }

        editor.update(() => {
          const selection = $getSelection();

          if (!selection) return;

          if ($isRangeSelection(selection) && selection.isCollapsed()) {
            // If there is no text selected, select the word around the cursor first.
            $selectCurrentWord();
          }

          setEditingLink({
            type: 'new',
            text: selection.getTextContent(),
            url: '',
            selection,
          });

          showLinkEditModal();
        });

        return true;
      },
      COMMAND_PRIORITY_LOW,
    );
  }, []);

  const onClickApplyLinkEdit = useCallback(() => {
    closeLinkEditModal();

    if (!editingLink) return;

    editor.update(() => {
      if (editingLink.type === 'new') {
        const selection = $getSelection();

        if (!selection) return;

        if (selection.getTextContent() === editingLink.text) {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, editingLink.url);
        } else {
          const newLink = new LinkNode(editingLink.url);
          newLink.append(new TextNode(editingLink.text));

          selection.insertNodes([newLink]);
        }
      } else if (editingLink.type === 'existing') {
        const existingLinkNode = $getNodeByKey(editingLink.existingLinkKey);
        if (!existingLinkNode) return;

        $updateLinkNode(existingLinkNode, editingLink.text, editingLink.url);
      }
    });
  }, [editingLink, editor]);

  const onClickRemoveLink = useCallback(() => {
    closeLinkEditModal();

    if (!editingLink) return;

    editor.update(() => {
      if (editingLink.type === 'existing') {
        const existingLinkNode = $getNodeByKey(editingLink.existingLinkKey);
        if (!existingLinkNode) return;

        $pruneNode(existingLinkNode);
      }
    });
  }, [editingLink, editor]);

  const setLinkText = useCallback((text: string) => {
    if (!editingLink) return;

    const { text: _oldText, ...link } = editingLink;
    setEditingLink({ text, ...link });
  }, [editingLink]);

  const setLinkURL = useCallback((url: string) => {
    if (!editingLink) return;

    const { url: _oldUrl, ...link } = editingLink;
    setEditingLink({ url, ...link });
  }, [editingLink]);

  const { formsRef, formsError, formsAreValid, submitForm } = useFormsValidation([editingLink], onClickApplyLinkEdit);

  return (
    <>
      {isLinkEditModalOpen && editingLink && (
        <Modal
          isOpen={isLinkEditModalOpen}
          className={bem('modal')}
          title={editingLink.type === 'new' ? "Create Link" : "Edit Link"}
          extraActionLabel={editingLink.type === 'existing' ? "Remove Link" : undefined}
          acceptLabel="Apply"
          submitDisabled={!formsAreValid || !editingLink.text || !editingLink.url}
          onExtraAction={onClickRemoveLink}
          onCancel={closeLinkEditModal}
          onSubmit={submitForm}
          isCloseOnEsc
        >
          <div className={bem('modal-form')} ref={formsRef}>
            <form>
              <InputText
                label="Link Text"
                required
                onChange={setLinkText}
                value={editingLink.text}
                errorText="Link text is required."
              />
              <InputText
                label="URL"
                required
                onChange={setLinkURL}
                value={editingLink.url}
                pattern="[ -~]{1,}:/?/?.+"
                placeholder="http://example.com"
                errorText="A valid URL is required."
              />
              {formsError && <div className="form-validation-error">{formsError}</div>}
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};
