import React from 'react';

import './table-pagination.scss';

interface TablePaginationProps {
  itemsLabel?: string;
  showLessCondition: boolean;
  showLessHandler(): void;
  showMoreCondition: boolean;
  showMoreHandler(): void;
}

export const TablePagination: React.FC<TablePaginationProps> = ({
  itemsLabel = 'Items',
  showLessCondition,
  showLessHandler,
  showMoreCondition,
  showMoreHandler,
}) => (
  <div className="pagination clearfix">
    <div className="pull-right">
      {showLessCondition && (
        <button type="button" className="button-normalize show-less" onClick={showLessHandler}>
          {`See Fewer ${itemsLabel}`}
        </button>
      )}
      {showMoreCondition && (
        <button type="button" className="button-normalize show-more" onClick={showMoreHandler}>
          {`See More ${itemsLabel}`}
        </button>
      )}
    </div>
  </div>
);
