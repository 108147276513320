import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../molecules/icons';

const action = (refresh, startDate, endDate) => () => refresh(startDate, endDate);

/**
 * Rendered in place of graphs when downstream error cases trigger
 */
const FetchError = ({ refresh, startDate, endDate }) => {
  let refreshImgLink;
  let refreshTxtLink;
  if (typeof refresh === 'function') {
    refreshImgLink = (
      <button className="button-normalize refresh-img-link" tabIndex={0} onClick={action(refresh, startDate, endDate)}>
        <Icon className="refresh-icon" name="refresh" />
      </button>
    );
    refreshTxtLink = (
      <h3>
        <button className="button-normalize refresh-txt-link" tabIndex={0} onClick={action(refresh, startDate, endDate)}>Please Select to Refresh</button>
      </h3>
    );
  }
  return (
    <div className="fetch-error">
      {refreshImgLink}
      <h2>Server Error Has Occurred</h2>
      {refreshTxtLink}
    </div>
  );
};

FetchError.propTypes = {
  refresh: PropTypes.func,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
};

FetchError.defaultProps = {
  refresh: undefined,
  startDate: undefined,
  endDate: undefined,
};

export default FetchError;
