import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/String.prototype.startsWith';

export const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;
export const email = value => {
  if (value && !emailRegEx.test(value)) {
    return 'Please enter a valid email address.';
  }
  return undefined;
};

export const required = value => {
  if (typeof value === 'undefined' || value === '' || value === null) {
    return 'This field is required.';
  }
  return undefined;
};

export function combine(...validations) {
  return (value) => (
    validations.reduce((msg, validation) => {
      if (msg) return msg;
      return validation(value);
    }, undefined)
  );
}

export const uniqueInvite = (orgMembers) => value => {
  if (!value || typeof value !== 'string') {
    return 'A unique email is required.';
  }
  const orgMemberEmails = orgMembers.map(member => member.email);
  if (orgMemberEmails.includes(value)) {
    return 'This email has already been invited.';
  }
  return undefined;
};

export const startsWith = prefixes => value => {
  if (!value || typeof value !== 'string') {
    return 'This field is required.';
  }
  const prefixTests = prefixes.map((prefix) => value.startsWith(prefix));
  if (!prefixTests.some(test => test === true)) {
    if (prefixes.length === 1) {
      return `This field must begin with ${prefixes[0]}`;
    }
    return `This field must begin with one of: ${prefixes.join(', ')}`;
  }
  return undefined;
};

export const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
