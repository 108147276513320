import React, { useRef, useMemo, useCallback, useLayoutEffect } from 'react';
import classNames from 'classnames';

import { bemPrefix } from 'src/utils';
import { useOnOutsideClick } from 'src/hooks/useOnOutsideClick';
import { Icon, IconName } from 'src/components/molecules';
import { useDropdownProps } from 'src/hooks';
import { GtmID } from 'src/types/google-tag-manager';
import { stripHTML } from 'src/utils/strip_html';
import { IconProps } from '../icons/icon';
import { InputLabel, InputLabelProps } from '../input';
import { RichTextContent } from '../rich-text-editor';

import './dropdown.scss';

export interface DropdownOption extends Record<any, any> {
  label: string;
  id?: string;
  gtmId?: GtmID;
  afterLabelEl?: React.ReactNode;
  value: any;
  disabled?: boolean;
  dataGroup?: string;
  iconName?: IconName;
  isRichTextLabel?: boolean;
}

export interface DropDownProps {
  className?: string;
  optionsTitle?: string;
  title?: string;
  options: DropdownOption[];
  disabled?: boolean;
  required?: InputLabelProps['required'];
  selectedValue: DropdownOption['value'];
  placeholder?: string;
  hideCheckIcon?: boolean;
  customIcon?: IconProps['name'];
  customCloseDropdown?: boolean;
  hasMultipleSelectedValues?: boolean;
  onSelect(value: DropdownOption['value'], option: DropdownOption): void;
  onDropdownOpen?(opened: boolean): void;
}

const bem = bemPrefix('dropdown-component');
const noop = () => null;
const isUndefined = (value: any): boolean => typeof value === 'undefined';

const isSelectedValue = (
  option: DropdownOption,
  selectedValue: DropdownOption['value'] | DropdownOption['value'][],
): boolean => {
  if (!option || isUndefined(option.value)) {
    return false;
  }

  if (Array.isArray(selectedValue)) {
    return selectedValue.includes(option.value);
  }

  return option.value === selectedValue;
};

export const DropDown: React.FC<DropDownProps> = ({
  className = '',
  optionsTitle,
  title,
  required,
  options,
  disabled,
  selectedValue,
  placeholder,
  hideCheckIcon,
  customIcon,
  customCloseDropdown = false,
  hasMultipleSelectedValues = false,
  onSelect,
  onDropdownOpen = noop,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const { isOpenDropdown, closeDropdown, toggleDropdown } = useDropdownProps();

  const handleSelect = (option: DropdownOption) => {
    if (option.disabled) {
      return;
    }
    onSelect(option.value, option);
    !customCloseDropdown && closeDropdown();
  };

  const onToggleClick = useCallback(() => {
    if (disabled) return;
    toggleDropdown();
  }, [disabled, toggleDropdown]);

  useOnOutsideClick([inputRef, dropDownRef], closeDropdown);

  useLayoutEffect(() => {
    onDropdownOpen(isOpenDropdown);
  }, [isOpenDropdown]);

  const selectedOption = useMemo(() => {
    if (hasMultipleSelectedValues) {
      const selectedValuesMap = new Map(selectedValue.map((value: string) => [value, true]));
      const selectedOptions = options.filter((option) => selectedValuesMap.has(option.value));
      return { label: selectedOptions.map((option) => option.label).join(', '), value: 'multiple' };
    }

    const selected = options.find((o) => o.value === selectedValue);
    return (
      selected || {
        label: '',
        value: '',
      }
    );
  }, [selectedValue, options]);

  return (
    <div className={classNames(bem(), className)}>
      {title && <InputLabel className={bem('label')} label={title} required={required} />}
      <div
        className={bem('input', { disabled: !!disabled, opened: isOpenDropdown })}
        ref={inputRef}
        onClick={onToggleClick}
        onKeyDown={noop}
      >
        {selectedOption.label ? (
          <>
            {customIcon && selectedOption.iconName && (
              <Icon
                className={bem('option-item-icon', `${selectedOption.label}`)}
                name={selectedOption.iconName as IconProps['name']}
              />
            )}
            {selectedOption.hasRichTextLabel ? (
              <RichTextContent type="span" html={selectedOption.label} isInline />
            ) : (
              <span>{selectedOption.label}</span>
            )}
          </>
        ) : (
          placeholder && <span className={bem('input-placeholder')}>{placeholder}</span>
        )}
        <Icon
          name={customIcon ? `${customIcon}` : 'chevron'}
          className={classNames(bem('input-icon'), { reversedIcon: isOpenDropdown })}
        />
      </div>
      {isOpenDropdown && (
        <div className={bem('options')} ref={dropDownRef}>
          {optionsTitle && <div className={bem('options-title')}>{optionsTitle}</div>}
          {options.map((option, idx) => (
            <div
              className={classNames(
                bem('option-item', {
                  selected_option: isSelectedValue(option, selectedValue),
                  disabled: !!option.disabled,
                  hideCheckIcon: !!hideCheckIcon,
                  title_option: option.isTitle,
                }),
              )}
              key={`${option.hasRichTextLabel ? stripHTML(option.label) : option.label}_${idx}`}
              onKeyDown={noop}
              gtm-id={option.gtmId}
              custom-value={option.value}
              data-group={option.dataGroup}
              onClick={() => (option.isTitle ? noop : handleSelect(option))}
            >
              {isSelectedValue(option, selectedValue) && !hideCheckIcon && (
                <Icon className={bem('option-item-icon', 'selected')} name="check" />
              )}
              {option.iconName && <Icon className={bem('option-item-icon', 'name')} name={option.iconName} />}
              {option.hasRichTextLabel ? (
                <RichTextContent type="span" gtm-id={option.gtmId} html={option.label} isInline />
              ) : (
                <span gtm-id={option.gtmId}>{option.label}</span>
              )}
              {React.isValidElement(option.afterLabelEl) && option.afterLabelEl}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropDown.displayName = 'DropDown';
