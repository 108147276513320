/* global Raven */
import { RECEIVE_USER, UPDATE_CURRENT_APP_SUCCESS } from '../actions/session';
import analytics from '../data/analytics';
import { RECEIVE_APPS_FOR_ORG } from '../actions/organizations';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case (RECEIVE_USER): {
      // Identify this user in Google Analytics & FullStory
      analytics.identify({
        id: action.payload.userId,
        name: action.payload.name,
        email: action.payload.email,
        customFields: {
          professionalRole_str: action.payload.professionalRole,
        },
      });

      if (typeof Raven !== 'undefined') {
        Raven.setUserContext({
          email: action.payload.email,
          id: action.payload.userId,
        });
      }

      // Set the current app in Google Analytics
      analytics.set('dimension1', action.payload.currentApp);

      // Set user Professional Role in GA
      analytics.set('dimension6', action.payload.professionalRole);

      /* istanbul ignore next */
      if (window.ApptentiveSDK) {
        window.ApptentiveSDK.createConversation();

        window.ApptentiveSDK.identifyPerson({
          unique_token: action.payload.userId,
          name: action.payload.name,
          email: action.payload.email,
        });
      }

      return state;
    }
    case (UPDATE_CURRENT_APP_SUCCESS): {
      // Set the current app in Google Analytics
      analytics.set('dimension1', action.payload.appId);

      return state;
    }
    case RECEIVE_APPS_FOR_ORG: {
      if (action.payload) {
        action.payload.forEach((app) => {
          if (app.id === action.meta.currentAppId) {
            analytics.set('dimension4', action.meta.orgId);
          }
        });
      }

      return state;
    }
    default:
      return state;
  }
};
