import 'url-polyfill';
import { __, assocPath } from 'ramda';
import JWT from '../utils/jwt';
import fullPath from '../utils/full_path';

const atriumUrl = process.env.ATRIUM_URL as string;

export type ApiResponse = Pick<
  Response,
  'ok' | 'json' | 'status' | 'statusText' | 'text' | 'arrayBuffer' | 'blob' | 'headers'
>;

export type AbortFetch = () => void;
export interface AbortableResult<T = any> {
  promise: Promise<T>;
  abort: AbortFetch;
}

export class ApiError extends Error {
  errors: {};
  response: any;

  constructor(error: string | Error, errorJson?: any) {
    let message: string;
    let stack;

    if (typeof error === 'string') {
      message = error;
      stack = new Error().stack;
    } else {
      message = error.message;
      stack = error.stack;
    }

    super(message || 'api error');
    this.name = 'ApiError';
    this.stack = stack;

    if (typeof errorJson === 'object') {
      this.errors = errorJson;
    } else {
      this.response = errorJson;
    }
  }
}

export class HttpUnAuthorizedError extends ApiError {
  constructor(message: string, errorJson?: any) {
    super(message || 'unauthorized', errorJson);
    this.name = 'HttpUnAuthorizedError';
    this.stack = new Error().stack;
  }
}

export class HttpNotFoundError extends ApiError {
  constructor(message: string, errorJson?: any) {
    super(message || 'not found', errorJson);
    this.name = 'HttpNotFoundError';
    this.stack = new Error().stack;
  }
}

export interface ApiOptions {
  jwt?: string;
  body?: BodyInit;
  query?: { [key: string]: any };
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';
  demoApp?: boolean;
  filename?: string;
  errorTitle?: string;
  error?: (ex: Error) => void;
  depth?: number;
  setContentType?: string;
  autoPaginate?: boolean;
  process?: (json: any) => any;
  skipCheckForError?: boolean;
  demoSource?: any;
  throwAbort?: boolean;
  isRetry?: boolean;
}

export const buildError = (statusCode: number | undefined, message: string, errorJson?: any): Error => {
  if (!statusCode && typeof errorJson.statusCode === 'number') {
    statusCode = errorJson.statusCode;
  }
  switch (statusCode) {
    case 401:
      return new HttpUnAuthorizedError(message, errorJson);
    case 404:
      return new HttpNotFoundError(message, errorJson);
    default: {
      return new ApiError(message, errorJson);
    }
  }
};

export const isAbortError = (err: Error | undefined) => !!err && err.name === 'AbortError';
export const isApiError = (err: Error | undefined) => !!err && err.name === 'ApiError';
const MOCK_ABORT: AbortFetch = () => undefined;

function buildFetchPromise(
  serviceUrl: string,
  endpoint: string,
  options: ApiOptions = {},
  signal?: AbortSignal,
): Promise<any> {
  if (options.demoApp) {
    return Promise.resolve(options.demoSource);
  }

  if (options.depth && options.depth >= 50) {
    console.error('fetch depth too deep');
    return Promise.resolve();
  }

  const jwt = options.jwt || JWT.getApiToken();
  const url = new URL(`${serviceUrl}${endpoint}`);

  if (options.query) {
    Object.keys(options.query).forEach((k) => {
      if (options.query) {
        if (Array.isArray(options.query[k])) {
          options.query[k].forEach((v: any) => {
            url.searchParams.append(`${k}[]`, v);
          });
        } else {
          url.searchParams.append(k, options.query[k]);
        }
      }
    });
  }

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  };

  const config: RequestInit = {
    headers,
    credentials: 'include',
    mode: 'cors',
    referrerPolicy: 'origin',
    ...{ signal },
  };

  if (options.setContentType) {
    headers['Content-Type'] = options.setContentType;
  }
  if (options.setContentType === 'delete') {
    delete headers['Content-Type'];
  }

  if (jwt && endpoint !== '/login') {
    headers.Authorization = `Bearer ${jwt}`;
  }
  /* istanbul ignore else */
  if (options.method && options.method !== 'GET') {
    config.method = options.method;
  }

  /* istanbul ignore else */
  if (typeof options.body !== 'undefined') {
    config.body = options.body;
  }

  const getContentTypeHeader = (headerArgs: Headers | Record<string, string>): string => {
    const args = headerArgs instanceof Headers ? headerArgs : new Headers(headerArgs);
    return args.get('Content-Type') || '';
  };
  const isTextType = (headerArgs: Headers) => !!getContentTypeHeader(headerArgs).match('text');
  const isJsonType = (headerArgs: Headers) => !!getContentTypeHeader(headerArgs).match('json');

  return fetch(url.toString(), config)
    .then((response) => {
      if (isTextType(response.headers)) {
        return handleRespText(response);
      }
      if (isJsonType(response.headers)) {
        return handleRespJson(response, endpoint, options);
      }
      return response;
    })
    .catch((ex: any) => {
      // Do not throw for aborted fetch calls
      if (isAbortError(ex)) {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(`API call aborted: ${url.toString()}`);
        }
        if (options.throwAbort) {
          throw ex;
        }
        return;
      }

      if (options.error) {
        options.error(ex);
      }
      // redirect user to login page if 401 is thrown
      if (ex.name === 'HttpUnAuthorizedError') {
        console.warn('downstreamCaller redirecting due to', ex);
        JWT.destroy();
        window.location.replace(fullPath('/login'));
      }
      throw ex;
    });
}

export const downstreamCaller = (serviceUrl: string) => (endpoint: string, options: ApiOptions = {}): Promise<any> => buildFetchPromise(serviceUrl, endpoint, options);

const abortableDownstreamCaller = <T = any>(serviceUrl: string) => (
  endpoint: string,
  options: ApiOptions = {}
): AbortableResult<T> => {
  let abort: AbortFetch;

  // If this is an abortable call, create an AbortController. All modern browsers support this
  // object, but just in case create a mock abort implementation.
  let signal: AbortSignal | undefined;
  if (AbortController) {
    const controller = new AbortController();
    signal = controller.signal;
    abort = controller.abort.bind(controller);
  } else {
    abort = MOCK_ABORT;
  }

  const promise = buildFetchPromise(serviceUrl, endpoint, options, signal);
  return { promise, abort };
};

export const atriumCall = downstreamCaller(atriumUrl);
export const abortableAtriumCall = abortableDownstreamCaller(atriumUrl);

// Checks for several error cases in the response and throws if they are encountered.
export const checkForErrors = async (response: ApiResponse): Promise<ApiResponse> => {
  if (response.ok) return response;

  let json: any;
  try {
    json = await response.json();
  } catch (err: any) {
    json = {};
  }

  if (typeof json.errors === 'object') {
    throw buildError(response.status, 'Validation errors', json.errors);
  }

  // error key is a request level error message, usually closely resembling
  // the status of the response.
  if (json.error) {
    throw buildError(response.status, json.error, response);
  }

  throw new ApiError(response.statusText || 'Fetch Error', json);
};

export function abortAllFetches(aborts: AbortFetch[]) {
  aborts.forEach((abort) => abort());
  aborts.length = 0;
}

function handleResponseParse(err: Error, response: ApiResponse): any {
  if (response.status === 204) {
    return {};
  }

  if (!response.ok) {
    throw buildError(response.status, response.statusText || err.message, response);
  }

  throw new ApiError(err, response);
}

async function handleRespText(response: ApiResponse) {
  if (!response.ok) {
    const error = await response.text();
    throw error;
  }
  return response.text().catch((err) => handleResponseParse(err, response));
}

async function handleRespJson(response: Response, endpoint: string, options: ApiOptions): Promise<any> {
  let json: any;

  // NOTE: We check for errors as the default case, and throw when they are found.
  // However, some routes return errors correctly and we want to show those errors to the user,
  // in those cases we set skipCheckForError on the API call and the errors come through the
  // success action due to the way we construct actions right now.
  if (!options.skipCheckForError) {
    json = await checkForErrors(response).then((resp) => {
      if (resp.status === 204) return {};
      return resp.json();
    });
  } else {
    json = await response.json().catch((err) => handleResponseParse(err, response));
  }

  // pass the result to processor callback if supplied to modify API response
  let results = options.process ? options.process(json) : json;

  // If auto pagination is enabled and the response indicates more data:
  // trigger automatic fetch of subsequent pages.
  if (options.autoPaginate && (json.hasMore || json.has_more)) {
    const responseMinKey = json.minKey || json.min_key;
    // If the response has the same min key as the query then we will get
    // stuck in an infinite loop, abort the chain of auto-pagination.
    if (options.query && options.query.min_key === responseMinKey) {
      throw new Error(
        `Pagination error, new min key ${responseMinKey} matches old ${options.query.min_key}, aborting to avoid infinite loop`
      );
    }
    options = assocPath(['query', 'min_key'], responseMinKey, options);
    options.depth = !options.depth ? 1 : options.depth + 1;
    // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
    results = await atriumCall(endpoint, options);
  }

  return results;
}

export const dedupeBy = (buildReqId: (...params: any[]) => string, apiCall: (...params: any[]) => Promise<any>) => {
  const requests: { [reqId: string]: Promise<any> } = {};
  return (...params: any[]) => {
    const reqId = buildReqId(...params);
    if (!requests[reqId]) {
      requests[reqId] = apiCall(...params);
      requests[reqId].then(() => {
        delete requests[reqId];
      });
    }
    return requests[reqId];
  };
};

export const report = (...messageArgs: any[]) => {
  if (process.env.NODE_ENV !== 'test') {
    console.error(...messageArgs);
  }
};
