export enum NoteActions {
  CREATE_NOTE_PENDING = 'CREATE_NOTE_PENDING',
  CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS',
  CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR',

  UPDATE_NOTE_PENDING = 'UPDATE_NOTE_PENDING',
  UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_ERROR = 'UPDATE_NOTE_ERROR',

  ACTIVATE_NOTE_PENDING = 'ACTIVATE_NOTE_PENDING',
  ACTIVATE_NOTE_SUCCESS = 'ACTIVATE_NOTE_SUCCESS',
  ACTIVATE_NOTE_ERROR = 'ACTIVATE_NOTE_ERROR',

  BOOTSTRAP_NOTES = 'BOOTSTRAP_NOTES',

  GET_NOTES_PENDING = 'GET_NOTES_PENDING',
  GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS',
  GET_NOTES_ERROR = 'GET_NOTES_ERROR',

  GET_NOTE_PENDING = 'GET_NOTE_PENDING',
  GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS',
  GET_NOTE_ERROR = 'GET_NOTE_ERROR',

  DELETE_NOTE_PENDING = 'DELETE_NOTE_PENDING',
  DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS',
  DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR',

  CLONE_NOTE_TO_APP_PENDING = 'CLONE_NOTE_TO_APP_PENDING',
  CLONE_NOTE_TO_APP_SUCCESS = 'CLONE_NOTE_TO_APP_SUCCESS',
  CLONE_NOTE_TO_APP_ERROR = 'CLONE_NOTE_TO_APP_ERROR',
}
