import { atriumCall } from './util';

export const acceptInvitation = (password, professional_role, newsletter, invitation_token) => atriumCall(
  '/invitation',
  {
    method: 'PUT',
    body: JSON.stringify({
      newsletter: Number(newsletter),
      user: {
        invitation_token,
        password,
        professional_role,
      },
    }),
  }
);

export const resendInvitation = (email) => atriumCall(
  '/invitation/resend',
  {
    method: 'POST',
    body: JSON.stringify({ email }),
  }
);
