import { useState, useCallback } from 'react';

export function useDropdownProps(opened: boolean = false) {
  const [isOpenDropdown, setIsOpen] = useState(opened);
  const [viewId, setViewId] = useState('');
  const showDropdown = useCallback(() => setIsOpen(true), []);
  const closeDropdown = useCallback(() => {
    setIsOpen(false);
    setViewId('');
  }, []);
  const toggleDropdown = useCallback(() => (isOpenDropdown ? closeDropdown() : showDropdown()), [isOpenDropdown]);

  return { isOpenDropdown, toggleDropdown, showDropdown, closeDropdown, viewId, setViewId };
}
