import React from 'react';
import { bemPrefix } from 'src/utils';

import './input-label.scss';

export interface InputLabelProps {
  className?: string;
  label: string;
  optional?: boolean;
  required?: boolean;
}

const bem = bemPrefix('input-label');

export const InputLabel: React.FC<InputLabelProps> = ({ className = '', label, required, optional }) => (
  <label className={`${bem()} ${className}`}>
    {required ? (
      <span className={bem('text')}>
        {label}
        <span className={bem('required-marker')}>*</span>
      </span>
    ) : (
      <span className={bem('text')}>{label}</span>
    )}
    {optional && <span className={bem('optional')}>(optional)</span>}
  </label>
);
