import React from 'react';
import PropTypes from 'prop-types';

export class Circle02 extends React.Component {
  static displayName = 'Circle02';
  static propTypes = {
    title: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  };

  static defaultProps = {
    title: '',
    height: 16,
    width: 16,
  };

  componentDidMount() {
    // NOTE: This is the code from Nucleo to animate the SVG. The provided code does not pass linting due to minification.
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = '!function(){function t(t){this.element=t,this.animationId,this.start=null,this.init()}if(!window.requestAnimationFrame){var i=null;window.requestAnimationFrame=function(t,n){var e=(new Date).getTime();i||(i=e);var a=Math.max(0,16-(e-i)),o=window.setTimeout(function(){t(e+a)},a);return i=e+a,o}}t.prototype.init=function(){var t=this;this.animationId=window.requestAnimationFrame(t.triggerAnimation.bind(t))},t.prototype.reset=function(){var t=this;window.cancelAnimationFrame(t.animationId)},t.prototype.triggerAnimation=function(t){var i=this;this.start||(this.start=t);var n=t-this.start;504>n||(this.start=this.start+504),this.element.setAttribute("transform","rotate("+Math.min(n/1.4,360)+" 32 32)");if(document.documentElement.contains(this.element))window.requestAnimationFrame(i.triggerAnimation.bind(i))};var n=document.getElementsByClassName("nc-loop_circle-02-64"),e=[];if(n)for(var a=0;n.length>a;a++)!function(i){e.push(new t(n[i]))}(a);document.addEventListener("visibilitychange",function(){"hidden"==document.visibilityState?e.forEach(function(t){t.reset()}):e.forEach(function(t){t.init()})})}();';
    this.instance.appendChild(s);
  }

  render() {
    return (
      <svg height={this.props.height} width={this.props.width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" ref={el => (this.instance = el)}>
        <title>{this.props.title}</title>
        <g fill="#111111" stroke="#111111" strokeLinecap="square" strokeWidth="2">
          <g className="nc-loop_circle-02-64" transform="rotate(148.3392857142982 32 32)">
            <circle cx="32" cy="32" fill="none" opacity="0.4" r="30" stroke="#111111" />
            <path d="M32,2c16.56854,0,30,13.43146,30,30" fill="none" strokeLinecap="butt" />
          </g>
        </g>
      </svg>
    );
  }
}

export default Circle02;
