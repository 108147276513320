import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'src/components/molecules';
import { EventMetricsSDK } from 'src/utils/event-metrics';
import { Events } from 'src/actions/event-metrics';
import { FanSignalLoveScore } from 'src/services/fan-signals-service';
import { bemPrefix } from 'src/utils';
import { Platform } from 'src/types/core';
import { InfoNotification } from './info-notification';

import './dashboard-love-score.scss';

const bem = bemPrefix('dashboard-love-score');

const noop = () => null;

const optionsList = [
  {
    class: 'above-zero',
    counts: [1, 49],
  },
  {
    class: 'below-zero',
    counts: [-1, -49],
  },
  {
    class: 'positive',
    counts: [50, 100],
  },
  {
    class: 'negative',
    counts: [-50, -100],
  },
  {
    class: 'zero',
    counts: [0],
  },
];

interface LoveScoreCardContainerProps {
  count: number;
  title: string;
  name?: string;
}

const LoveScoreCardContainer: React.FC<LoveScoreCardContainerProps> = ({ count, title, name }) => {
  const titleLengthForSmallScreen = useMemo(() => title.substring(0, 36), [title]);
  const isLongTitleForSmallScreen = useMemo(
    () => document.body.getBoundingClientRect().width < 1400
      && document.body.getBoundingClientRect().width > 600
      && title.length > 36,
    [title]
  );

  const option = optionsList.find((option) => {
    const negativeRange = count < option.counts[0] && count > option.counts[1];
    const positiveRange = count < option.counts[1] && count > option.counts[0];
    const borderNumber = option.counts.includes(count);
    return count < 0 ? borderNumber || negativeRange : borderNumber || positiveRange;
  });
  return (
    <>
      <div>
        <p className={bem('text title')} title={isLongTitleForSmallScreen ? `${title}` : ''}>
          {isLongTitleForSmallScreen ? `${titleLengthForSmallScreen}...` : `${title}`}
        </p>
        <p className={bem('text descr')}>{name || 'No data available'}</p>
      </div>
      <div className={bem('counts-container')}>
        <span className={bem(`counts ${option && option.class}`)}>{count}</span>
        <div className={bem('icon-container')}>
          <Icon name="lovescore" className={bem(`counts-icon ${option && option.class}`)} />
          <span className={bem('counts-points')}>points</span>
        </div>
      </div>
    </>
  );
};

LoveScoreCardContainer.displayName = 'LoveScoreCardContainer';
interface DashboardLoveScoreProps {
  currentAppId: string;
  loveScore: FanSignalLoveScore;
  appTitle: string;
  platform: string;
  sendEvent(event: Events): void;
}

export const DashboardLoveScore: React.FC<DashboardLoveScoreProps> = ({
  currentAppId,
  loveScore,
  appTitle,
  platform,
  sendEvent = noop,
}) => {
  const counts = Math.round(loveScore.score);
  const avarageNumber = Math.round(loveScore.categoryScore);
  const onSendEvent = () => {
    EventMetricsSDK.send(EventMetricsSDK.Metrics.NFS_FAN_SIGNALS_LINK);
    sendEvent(Events.NFS_FAN_SIGNALS_LINK);
  };

  return (
    <div className={bem('container')}>
      <div className={platform === Platform.Web ? bem('card webcontainer') : bem('card')}>
        <div className={bem('header')}>
          <h2 className={bem('title')}>Compare Customer Trends with Net Fan Score</h2>
          <InfoNotification sendEvent={sendEvent} />
        </div>
        <p className={bem('text')}>
          Our Net Fan Score provides a single measure of customer sentiment based on aggregated&#32;
          <Link className={bem('link')} to={`/apps/${currentAppId}/fan_signals`} onClick={onSendEvent}>
            Fan Signals
          </Link>{' '}
          data.
        </p>
      </div>
      {platform === Platform.Web ? (
        <div className={bem('card')}>
          <LoveScoreCardContainer count={counts} title={`${appTitle}`} name={platform} />
        </div>
      ) : (
        <>
          <div className={bem('card')}>
            <LoveScoreCardContainer count={counts} title={`${appTitle}`} name={platform} />
          </div>
          <div className={bem('card')}>
            <LoveScoreCardContainer count={avarageNumber} title="Category Average" name={`${loveScore.categoryName}`} />
          </div>
        </>
      )}
    </div>
  );
};

DashboardLoveScore.displayName = 'DashboardLoveScore';
