import { Action } from 'redux';
import { RatingPromptActions } from 'src/actions/ratings-prompt/ratings-prompt.action-types';

import { RatingPromptState, initialState } from './rating-prompt.state';
import { RatingPromptItem } from './rating-prompt.types';

export type RatingPromptReducerAction = Action<RatingPromptActions> & {
  payload: {
    rating?: RatingPromptItem;
    ratingId?: string;
    active?: boolean;
    appId?: string;
  };
  error?: Error;
};

export const ratingPromptReducer = (
  state: RatingPromptState = initialState,
  action: RatingPromptReducerAction = {} as RatingPromptReducerAction
): RatingPromptState => {
  switch (action.type) {
    case RatingPromptActions.FETCH_RATING_PROMPT_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case RatingPromptActions.ACTIVATE_RATING_PROMPT_PENDING: {
      return {
        ...state,
        activating: true,
      };
    }

    case RatingPromptActions.FETCH_RATING_PROMPT_SUCCESS:
    case RatingPromptActions.SAVE_RATING_PROMPT_SUCCESS:
    case RatingPromptActions.ACTIVATE_RATING_PROMPT_SUCCESS: {
      const rating = action.payload.rating as RatingPromptItem;
      return {
        ...state,
        rating,
        loading: false,
        activating: false,
      };
    }

    case RatingPromptActions.FETCH_RATING_PROMPT_ERROR: {
      return {
        ...state,
        loading: false,
        activating: false,
      };
    }

    default:
      return state;
  }
};
