import { merge } from 'ramda';
import * as actions from '../actions/email_preferences';

const initialState = {
  loading: true,
  fetchError: false,
  emailPreferences: [],
};

const emailPreferences = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.FETCH_EMAIL_PREFERENCES_PENDING:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.FETCH_EMAIL_PREFERENCES_SUCCESS:
      return merge(state, {
        loading: false,
        fetchError: false,
        emailPreferences: action.payload,
      });
    case actions.FETCH_EMAIL_PREFERENCES_FAILURE:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    case actions.UPDATE_USER_EMAIL_PREFERENCES_PENDING:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.UPDATE_USER_EMAIL_PREFERENCES_SUCCESS:
      return merge(state, {
        loading: false,
        fetchError: false,
        emailPreferences: action.payload,
      });
    case actions.UPDATE_USER_EMAIL_PREFERENCES_FAILURE:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    default:
      return state;
  }
};

export default emailPreferences;
