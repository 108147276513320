import { Action } from 'redux';
import { InsightActions } from '../../../actions/insights/insights.action-types';
import { TagsActions } from '../../../actions/tags/tags.action-types';
import { StoreActions, SubscribeDisposer } from '../../../store';
import { TagModel } from '../../../services/tags-service';
import { UPDATE_DASHBOARD_DATE_RANGE } from '../../../actions';

interface Options {
  getFilters(): { tags: string[] };
  setTags(tags: string[]): void;
  removeTag(tag: string): void;
  refreshAggregatedTags(): void;
  applyFilters(): void;
}

type StoreAction = Action & Record<string, any>;

export const subscribeOnStoreActions = (
  storeActions: StoreActions,
  { getFilters, setTags, removeTag, refreshAggregatedTags, applyFilters }: Options
): SubscribeDisposer[] => {
  const getTags = () => [...getFilters().tags];
  const tagInFilters = (name: string) => getTags().includes(name);

  const tagSubs = [
    InsightActions.TAG_ADD_SUCCESS,
    InsightActions.TAG_RENAME_SUCCESS,
    InsightActions.TAG_REMOVE_SUCCESS,
    TagsActions.RENAME_TAG_SUCCESS,
    TagsActions.REMOVE_TAG_SUCCESS,
  ].map((action) => storeActions.on(action, ({ type, payload, meta = {} }: StoreAction) => {
    if (type === TagsActions.RENAME_TAG_SUCCESS && tagInFilters(meta.tagName)) {
      const tags = getTags();
      const newTagName = getNewTagName(meta.newTagName, payload);
      tags.splice(tags.indexOf(meta.tagName), 1, newTagName);
      setTags(tags);
    } else if (type === TagsActions.REMOVE_TAG_SUCCESS && tagInFilters(meta.tagName)) {
      removeTag(meta.tagName);
    }
    refreshAggregatedTags();
  })
  );

  const dashboardSubs = [InsightActions.UPDATE_DATA_PAGE, UPDATE_DASHBOARD_DATE_RANGE].map((action) => storeActions.on(action, () => applyFilters())
  );

  return [...tagSubs, ...dashboardSubs];
};

function getNewTagName(newTagName: string, payload: { tags: TagModel[] }) {
  return payload.tags[0] ? payload.tags[0].tag_name : newTagName;
}
