import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryArea,
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  LineSegment,
  VictoryVoronoiContainer,
} from 'victory';
import { SizeMe } from 'react-sizeme';
import styled from 'styled-components';
import {
  pluck,
  map,
  differenceWith,
  pathOr,
  flatten,
} from 'ramda';
import numeral from 'numeral';
import {
  startOfDay,
  endOfDay,
  subHours,
} from 'date-fns';

import { colors as themeColors } from '../../../theme';
import HoverCard from '../HoverCard';
import chartTheme from './theme';
import ErrorChart from './Error';
import {
  getAllData,
  stackByInteger,
  stackByDate,
  minBoundBy,
  maxBoundBy,
  sortDateAnnotations,
  rangeBoundsProp as boundsProp,
} from '../utils';

const Graph = styled.section`
  position: relative;
  margin-bottom: 36px;
  h3 {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 6px;
    line-height: 19.6px;
    min-height: 19px;
  }

  h4 {
    line-height: 1em;
    color: ${themeColors.smoke};
    font-size: 0.875rem;
    margin: 0;
    font-weight: normal;
    margin-bottom: 13px;

    &:empty {
      margin-bottom: 0;
    }
  }

  .key {
    display: flex;
    justify-content: flex-start;
    color: ${themeColors.shadow};
    margin-bottom: 13px;
    min-height: 1em;

    .label {
      text-transform: uppercase;
      line-height: 14px;
      font-size: 0.7rem;
      font-weight: 300;
      margin-right: 20px;

      .key-color {
        border-radius: 100%;
        content: ' ';
        display: inline-block;
        height: 7px;
        margin-right: 10px;
        width: 7px;
        background: ${themeColors.smoke};
      }
    }
  }

  .annotation-legend {
    margin-top: 10px;
    justify-content: flex-end;
  }

  .chart-container {
    position: relative;
  }

  .overlay,
  .tooltip-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .tooltip {
      position: absolute;
    }
  }
`;

const DATE_ANNOTATION_DATA_PREFIX = 'DATE_ANNOTATION_DATA';

export default class Histogram extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    summary: PropTypes.string,
    /** formats the tick labels for the x-axis */
    xFormat: PropTypes.func,
    /** formats the tick labels for the y-axis */
    yFormat: PropTypes.func,
    /** formats the title of the x value in the hovercard */
    xTitleFormat: PropTypes.func,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
          /* eslint-disable max-len */
          PropTypes.shape({ x: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]).isRequired, y: PropTypes.number.isRequired }),
        ).isRequired,
      }),
    ).isRequired,
    dateAnnotations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired,
        color: PropTypes.string.isRequired,
      }),
    ),
    colors: PropTypes.arrayOf(PropTypes.string),
    overlay: PropTypes.node,
    /** The inner & outer bounds of the X Axis's maximum */
    xMaxBounds: boundsProp,
    /** The inner & outer bounds of the X Axis's minimum */
    xMinBounds: boundsProp,
    /** The inner & outer bounds of the Y Axis's maximum */
    yMaxBounds: boundsProp,
    /** The inner & outer bounds of the Y Axis's minimum */
    yMinBounds: boundsProp,
    renderHoverCard: PropTypes.func,
  };

  static defaultProps = {
    title: null,
    subtitle: null,
    summary: null,
    xFormat: (x) => numeral(x).format('0'),
    yFormat: null,
    xTitleFormat: null,
    dateAnnotations: [],
    colors: chartTheme.legend.colorScale,
    overlay: null,
    xMaxBounds: [1, Infinity],
    xMinBounds: [Infinity, -Infinity],
    yMaxBounds: [1, Infinity],
    yMinBounds: [0, -Infinity],
    renderHoverCard: ({ ...args }) => <HoverCard {...args} />,
  };

  static height = 164;

  static defaultWidth = 891;

  static getDerivedStateFromError(_error) {
    return { renderError: true };
  }

  static addMetaDataToPoints = (xScale, yScale, colors) => map(({
    x, y, childName: label, _stack, meta,
  }) => ({
    label,
    x,
    y,
    xPos: xScale(x),
    yPos: yScale(y),
    color: colors[_stack - 1],
    meta,
  }));

  static structureLabel = point => JSON.stringify({ value: point.y, label: point.childName });

  state = { renderError: false, hover: false, tooltipData: [] };

  chartRef = React.createRef();

  renderErrorChart = () => {
    const {
      xFormat, yFormat, xMinBounds, xMaxBounds, yMinBounds, yMaxBounds,
    } = this.props;
    return (
      <ErrorChart
        xFormat={xFormat}
        yFormat={yFormat}
        xMinBounds={xMinBounds}
        xMaxBounds={xMaxBounds}
        yMinBounds={yMinBounds}
        yMaxBounds={yMaxBounds}
      />
    );
  };

  determineAxesScales = (series) => {
    const initialDataPoint = pathOr({ x: 0, y: 0 }, ['0', 'data', '0'], series);
    const xScale = initialDataPoint.x.getMonth ? 'time' : 'linear';
    const yScale = initialDataPoint.y.getMonth ? 'time' : 'linear';
    return { x: xScale, y: yScale };
  };

  setHoverData = (points, containerProps) => {
    const { colors } = this.props;
    const {
      scale: { x: xScale, y: yScale },
    } = containerProps;
    let tooltipData = Histogram.addMetaDataToPoints(xScale, yScale, colors)(points);
    tooltipData = tooltipData.filter(series => !series.label.includes(DATE_ANNOTATION_DATA_PREFIX));
    if (tooltipData && tooltipData.length > 0) {
      this.setState(() => ({ hover: true, tooltipData }));
    }
  };

  unsetHoverData = points => {
    this.setState(state => {
      if (state.tooltipData.length === 0) return state;
      // We ignore xScale, yScale during equality check since they can be computed
      const pointIsEqual = (current, toRemove) => current.x === toRemove.x
        && current.y === toRemove.y
        && current.label === toRemove.childName;
      // Remove data points no longer active
      const tooltipData = differenceWith(pointIsEqual, state.tooltipData, points);
      const hover = tooltipData.length > 0;
      return { hover, tooltipData };
    });
  };

  filterDateAnnotations = (xDomain) => {
    const { dateAnnotations } = this.props;
    if (!dateAnnotations) return null;

    const minDate = new Date(xDomain[0]);
    const maxDate = new Date(xDomain[1]);

    // Only take the annotations that overlap the x-axis range. If the range extends before or beyond the
    // bounds of the chart, then cut them off at the start or end.
    return dateAnnotations.filter(annotation => (annotation.endDate ? annotation.endDate : annotation.startDate) >= minDate && annotation.startDate <= maxDate)
      .map(annotation => ({
        ...annotation,
        startDate: annotation.startDate < minDate ? minDate : startOfDay(annotation.startDate),
        endDate: annotation.endDate > maxDate ? maxDate : endOfDay(annotation.endDate),
      }));
  };

  renderEventLine = (data, i) => {
    const styles = {
      labelFontSize: 11,
      labelFontColor: '#b8b8b8',
      labeldX: 3,
      labeldY: 8,
      lineStrokeWidth: 1,
    };
    const labelComponentProps = {
      style: { fontSize: styles.labelFontSize },
      angle: 90,
      textAnchor: 'start',
      verticalAnchor: 'middle',
      dx: styles.labeldX,
      dy: styles.labeldY,
    };
    const lineProps = {
      // Add index to nameKey to avoid duplicate component warning - despite providing key as an index
      name: `line-${DATE_ANNOTATION_DATA_PREFIX}${i}`,
      key: i,
      data,
      style: { data: { stroke: '#b8b8b8', strokeWidth: styles.lineStrokeWidth } },
      // Labels don't render without empty labels prop
      labels: () => null,
      labelComponent: <VictoryLabel {...labelComponentProps} />,
    };
    return <VictoryLine {...lineProps} />;
  };

  renderEventLines = (events, xDomain, yDomain) => {
    if (!events || events.length === 0) return null;
    return events.map((event, i) => {
      // Only add label to top data point of line
      const data = [
        { x: event.startDate, y: yDomain[0] },
        { x: event.startDate, y: yDomain[1], label: event.label },
      ];
      return this.renderEventLine(data, i);
    });
  };

  renderDateAnnotations = (dateAnnotations, xDomain, yDomain) => {
    if (!dateAnnotations || dateAnnotations.length === 0) return null;
    return sortDateAnnotations(dateAnnotations).map((annotation, i) => {
      // Subtract 12 hours from the dates so that the area plot goes half way
      // between the daily bars of the bar chart.
      const data = [
        { x: subHours(annotation.startDate, 12), y: yDomain[1], y0: yDomain[0] },
        { x: subHours(annotation.endDate, 12), y: yDomain[1], y0: yDomain[0] },
      ];
      return <VictoryArea name={`area-${DATE_ANNOTATION_DATA_PREFIX}${i}`} key={annotation.id} data={data} style={{ data: { fill: annotation.color } }} />;
    });
  };

  renderAnnotationsLegend = (dateAnnotations) => {
    if (!dateAnnotations) return null;
    return (
      <div className="key annotation-legend">
        {dateAnnotations.map(annotation => (
          <div key={annotation.id} className="label">
            <div className="key-color" style={{ backgroundColor: annotation.color }} />
            {annotation.label}
          </div>
        ))}
      </div>
    );
  };

  renderChart = () => {
    const {
      series,
      xFormat,
      yFormat,
      colors,
      xMinBounds,
      xMaxBounds,
      yMinBounds,
      yMaxBounds,
    } = this.props;

    const scale = this.determineAxesScales(series);
    const stackByFunc = scale.x === 'time' ? stackByDate : stackByInteger;
    const stackedSeries = stackByFunc('x', 'y')(getAllData(series));
    const yValues = flatten(pluck('y')(stackedSeries));
    const xValues = pluck('x')(stackedSeries);
    const xDomain = [minBoundBy(...xMinBounds, xValues), maxBoundBy(...xMaxBounds, xValues)];
    const yDomain = [minBoundBy(...yMinBounds, yValues), maxBoundBy(...yMaxBounds, yValues)];
    const allDateAnnotations = this.filterDateAnnotations(xDomain);
    const dateAnnotations = allDateAnnotations.filter(annotation => annotation.type !== 'appRelease');
    const eventsAsLines = allDateAnnotations.filter(annotation => annotation.type === 'appRelease');
    // Suppress tick labels before xDomain
    const protectedXFormat = (x) => {
      if (x < xDomain[0]) {
        return '';
      }
      return xFormat(x);
    };
    // eslint-disable-next-line react/no-multi-comp
    class TickComp extends React.PureComponent {
      // Needs to be in the form of a React component with render()
      // Suppress tick lines before xDomain
      render() {
        const { datum, minValue } = this.props;
        return datum < minValue ? null : <LineSegment {...this.props} type="tick" />;
      }
    }
    TickComp.propTypes = {
      datum: PropTypes.number.isRequired,
      minValue: PropTypes.number.isRequired,
    };

    const container = (
      <VictoryVoronoiContainer
        voronoiDimension="x"
        activateData={false}
        activateLabel={false}
        onActivated={this.setHoverData}
        onDeactivated={this.unsetHoverData}
        labels={Histogram.structureLabel}
        // TODO: Figure out how to skip this auto-magical label
        // Currently we need to pass some empty element here so that the container does
        // not generate a default label.
        labelComponent={<></>}

      />
    );
    return (
      <SizeMe>
        {({ size }) => {
          const width = size.width ? size.width : Histogram.defaultWidth;
          // Dynamically calculate xDomain padding based on information from https://formidable.com/open-source/victory/docs/victory-bar#barratio
          const barRatio = 0.7;
          const xDomainPadding = (barRatio * width) / (xValues.length);
          return (
            <div className="chart-container" ref={this.chartRef}>
              {/* By default Victory wants to enforce aspect ratio. We only want to resize width */}
              <VictoryChart
                height={Histogram.height}
                width={width}
                padding={{ left: 40, bottom: 24 }}
                domainPadding={{ x: [xDomainPadding, xDomainPadding], y: [0, 10] }}
                theme={chartTheme}
                scale={scale}
                domain={{ x: xDomain, y: yDomain }}
                containerComponent={container}
              >
                {this.renderDateAnnotations(dateAnnotations, xDomain, yDomain)}
                <VictoryAxis
                  data-test="y-axis"
                  dependentAxis
                  crossAxis={false}
                  tickFormat={yFormat}
                  tickLabelComponent={<VictoryLabel data-test="y-axis-label" dx={-3} dy={0} />}
                  tickCount={5}
                  style={{
                    axis: { strokeWidth: 0 },
                    grid: { stroke: themeColors.smoke, size: 5, strokeDasharray: 4 },
                    tickLabels: { padding: 0 },
                  }}
                />
                <VictoryAxis
                  data-test="x-axis"
                  className="axis x-axis"
                  tickComponent={<TickComp minValue={xDomain[0]} />}
                  tickLabelComponent={<VictoryLabel data-test="x-axis-label" dy={-5} />}
                  // eslint-disable-next-line react/jsx-no-bind
                  tickFormat={protectedXFormat}
                  style={{
                    ticks: { stroke: themeColors.smoke, size: 10 },
                    tickLabels: { padding: 5 },
                  }}
                  orientation="bottom"
                  offsetY={24}
                  tickCount={Math.max(1, ...series.map(s => s.data.length))}
                  fixLabelOverlap
                />
                <VictoryStack colorScale={colors}>
                  {series
                    .filter(({ data }) => data.length > 0)
                    .map(({ label, color, data }) => (
                      <VictoryBar
                        name={label}
                        data-test="bar-series"
                        key={label}
                        data={data}
                        x="x"
                        y="y"
                        color={color}
                        barRatio={barRatio}
                      />
                    ))}
                </VictoryStack>
                {this.renderEventLines(eventsAsLines, xDomain, yDomain)}
              </VictoryChart>
              {this.renderAnnotationsLegend(dateAnnotations)}
              <div className="tooltip-container">{this.renderTooltip()}</div>
            </div>
          );
        }}
      </SizeMe>
    );
  };

  /**
   * A container for the chart tooltip. For the most part it exclusively deals
   * with positioning the tooltip and leaves rendering details to the HoverCard
   * component.
   *
   * @memberof Histogram
   */
  renderTooltip = () => {
    const { xFormat, xTitleFormat, renderHoverCard } = this.props;
    const { hover, tooltipData } = this.state;
    if (!hover) return null;
    // the y position of the top most bar in the stack
    const yPos = Math.min(Histogram.height, ...pluck('yPos', tooltipData));
    // for now we assume all points have the same x so we can just grab the first
    const { xPos, x } = tooltipData[0] || 0;
    const chartRect = this.chartRef.current
      ? this.chartRef.current.getBoundingClientRect()
      : {
        top: 0,
        left: 0,
        height: 0,
        width: 0,
      };
    const windowMidX = window.innerWidth / 2;
    const windowMidY = window.innerHeight / 2;
    const absX = chartRect.left + xPos;
    const absY = chartRect.top + yPos;
    const style = {
      display: hover ? 'block' : 'none',
      ...(absX > windowMidX ? { right: `${chartRect.width - xPos}px` } : { left: `${xPos}px` }),
      ...(absY > windowMidY ? { bottom: `${chartRect.height - yPos}px` } : { top: `${yPos}px` }),
    };
    const hoverCardData = {
      ratiogram: true,
      title: xTitleFormat ? xTitleFormat(x) : xFormat(x),
      series: tooltipData,
    };
    return (
      <div className="tooltip" style={style}>
        {renderHoverCard(hoverCardData)}
      </div>
    );
  };

  render() {
    const {
      title, subtitle, series, summary, colors, overlay,
    } = this.props;
    const { renderError } = this.state;
    return (
      <Graph className="graph-histogram">
        {title && <h3>{title}</h3>}
        <h4>{subtitle}</h4>
        {/* TODO: investigate future use of VictoryLegend so we don't need to manual set colors */}
        <div className="key">
          {series.map(({ label, color }, i) => (
            <div key={label} className="label">
              <div className="key-color" style={{ background: color || colors[i] }} />
              {label}
            </div>
          ))}
        </div>
        <div className="summary">{summary}</div>
        {renderError ? this.renderErrorChart() : this.renderChart()}
        {overlay && <div className="overlay">{overlay}</div>}
      </Graph>
    );
  }
}
