import React, { useState, useCallback } from 'react';
import { bemPrefix } from 'src/utils';
import { Icon, IconName } from 'src/components/molecules';
import { LocalStorage } from 'src/services';

import './feature_overview.scss';

const bem = bemPrefix('feature-overview');

export interface Options {
  title: string;
  icon: IconName;
  description: JSX.Element | string;
}

export interface FeatureOverviewProps {
  options: Options[];
  name: string;
  title?: string;
}

export const FeatureOverview: React.FC<FeatureOverviewProps> = ({ options, name, title = 'Feature Overview' }) => {
  const [isOpen, setIsOpen] = useState(() => {
    const open = LocalStorage.getValue(name);
    return open === undefined ? true : open;
  });

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prev: boolean) => {
      LocalStorage.setValue(name, !prev);
      return !prev;
    });
  }, [isOpen]);

  return (
    <>
      <div className={bem('container', { open: isOpen })} onClick={toggleIsOpen} onKeyPress={toggleIsOpen}>
        <div className={bem('container-title')}>
          <span>{title}</span>
        </div>
        <Icon className={bem('container-icon')} name={isOpen ? 'chevronUp' : 'chevron'} />
      </div>
      {isOpen && (
        <>
          {options.map(({ title, icon, description }: Options) => {
            return (
              <div key={title} className={bem('text-container')}>
                <span className={bem('title')}>{title}</span>
                <Icon name={icon} className={bem('text-icon')} />
                <p>{description}</p>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

FeatureOverview.displayName = 'FeatureOverview';
