import { assocPath, mergeDeepLeft } from 'ramda';

import { FETCH_APP_VERSIONS_SUCCESS } from '../actions/app_versions';
import { FETCH_STORE_APP_SUCCESS } from '../actions/store_app';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_STORE_APP_SUCCESS:
      return mergeDeepLeft({ [action.meta.storeId]: action.payload }, state);
    case FETCH_APP_VERSIONS_SUCCESS:
      return assocPath([action.meta.storeAppId, 'appVersions'], action.payload, state);
    default:
      return state;
  }
};
