import IWebSDKProfile from '../types/web_sdk_profile';

export default function buildWebSDKProfile(properties: object, featuresList: string[] = []): IWebSDKProfile {
  const defaults = {
    cortex_link: '',
    currentAppId: '',
    currentAppRole: '',
    currentOrgId: '',
    currentOrgTier: '',
    professionalRole: '',
    currentAppTitle: '',
    currentOrgName: '',
  };

  const propertiesWithFeatures = featuresList.reduce(
    (props: any, feature: string) => {
      props[`feature_${feature}`] = true;
      return props;
    },
    { ...properties }
  );

  return Object.assign(defaults, propertiesWithFeatures);
}
