import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../molecules';

/* istanbul ignore next */
const undefinedCloseFunc = () => {};
const Close = ({ closeCB }) => {
  if (closeCB !== undefinedCloseFunc) {
    return (
      <button
        className="close-notification button-normalize"
        onClick={closeCB}
      >
        <Icon name="closeBold" />
      </button>
    );
  }
  return null;
};

Close.propTypes = {
  closeCB: PropTypes.func.isRequired,
};

const Actions = ({ children, closeCB }) => (
  <div className="right actions">
    {children}
    <Close closeCB={closeCB} />
  </div>
);

Actions.propTypes = {
  children: PropTypes.node,
  closeCB: PropTypes.func,
};

Actions.defaultProps = {
  closeCB: undefinedCloseFunc,
};

export default Actions;
