import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { daysAgo } from 'src/utils/time';
import FetchError from './fetch_error';
import LoadingGraph from './loading';
import { isNetlifyProd } from '../../utils/environment';

// eslint-disable-next-line quotes
const legacyFetchWarning = `GraphContainer Graphs should no longer rely on date prop changes to trigger a \
re-fetch, fetchDataCB should now register a new epic that tracks qualifying actions in order to trigger the \
re-fetch. You can test this behavior by setting the legacyFetch prop to false.`;

/**
 * Component that draws the shared structure and edge cases for the graph types
 * as well as fetches the data.
 */
export default class GraphContainer extends Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    overlay: PropTypes.element,
    loading: PropTypes.bool,
    fetchError: PropTypes.bool,
    fetchDataCB: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    legacyFetch: PropTypes.bool,
    inputRef: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    subtitle: '',
    overlay: null,
    loading: false,
    fetchError: false,
    fetchDataCB: () => {},
    className: '',
    children: null,
    startDate: daysAgo(30).valueOf(),
    endDate: new Date().valueOf(),
    legacyFetch: true,
  };

  componentDidMount() {
    this.props.fetchDataCB(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    // older graph types still trigger a re-fetch based on changes in props
    // this should now be handled via observable epics.
    const equalDates = (oldDate, newDate) => oldDate.valueOf() === newDate.valueOf();
    if (
      prevProps.legacyFetch &&
      (!equalDates(prevProps.startDate, this.props.startDate) ||
        !equalDates(prevProps.endDate, this.props.endDate) ||
        (this.props.loading && !prevProps.loading))
    ) {
      if (!isNetlifyProd()) {
        console.warn(legacyFetchWarning);
      }
      this.props.fetchDataCB(this.props.startDate, this.props.endDate);
    }
  }

  render() {
    const { title, subtitle, overlay, loading, fetchError, fetchDataCB, startDate, endDate } = this.props;

    let content;
    if (fetchError && !overlay) {
      content = <FetchError refresh={fetchDataCB} startDate={startDate} endDate={endDate} />;
    } else {
      content = this.props.children;
    }

    return (
      <section ref={this.props.inputRef} className={`graph ${this.props.className}`}>
        {title ? <h3>{title}</h3> : null}
        <h4>{subtitle}</h4>
        {overlay && !loading ? <div className="overlay">{overlay}</div> : null}
        {loading ? <LoadingGraph /> : null}
        {content}
      </section>
    );
  }
}
