import { atriumCall, report } from './util';

export const fetchCurrentUser = () => atriumCall('/users/current', {
  error: e => report('[API] Error fetching current user', e),
});

export const setUIState = (key, value) => atriumCall('/user', {
  method: 'PUT',
  body: JSON.stringify({
    ui_state: {
      [key]: value,
    },
  }),
});

export const fetchNotices = userId => atriumCall(`/users/${userId}/notices`, {
  process: json => json.map(notice => ({
    ...notice,
    read_at: notice.read_at && new Date(notice.read_at),
    created_at: notice.created_at && new Date(notice.created_at),
  })),
  error: e => report('[API] Error fetching notices for user', userId, e),
});

export const readNotification = (userId, notification) => atriumCall(`/users/${userId}/notices/${notification.id}`, {
  method: 'PUT',
  body: JSON.stringify({
    notice: {
      ...notification,
      read_at: notification.read_at || new Date(),
    },
  }),
  error: e => report('[API] Error updating notification', notification, 'for', userId, e),
});

const unreadNotification = notification => !(notification.read_at instanceof Date) || Number.isNaN(notification.read_at.valueOf());

export const readNotifications = (userId, notifications) => Promise.all(
  notifications
    .filter((item) => item.id && unreadNotification(item))
    .map(notification => readNotification(userId, notification))
);

export const deleteNotification = (userId, notificationId) => atriumCall(`/users/${userId}/notices/${notificationId}`, {
  method: 'DELETE',
  error: e => report('[API] Error deleting notification', notificationId, 'for', userId, e),
});

export const updateCurrentApp = (current_app_id) => (
  atriumCall('/user', {
    method: 'PUT',
    body: JSON.stringify({ current_app_id }),
    error: e => report('[API] Error Updating Current App', e),
  }));

export const updateUser = (body) => (
  atriumCall('/user', {
    method: 'PUT',
    body: JSON.stringify(body),
    error: e => report('[API] Error Updating User', e),
    skipCheckForError: true,
  }));

export const fetchEmailPreferences = (userId) => atriumCall(`/users/${userId}/email-preferences`, {
  error: e => report('[API] Error Fetching Email Preferences', e),
});

export const updateUserEmailPreferences = (userId, apps) => atriumCall(`/users/${userId}/email-preferences`, {
  method: 'PUT',
  body: JSON.stringify({ apps }),
  error: e => report('[API] Error Updating User Email Preferences', e),
});

export const fetchAppMemberships = (userId) => atriumCall(`/users/${userId}/app-memberships`, {
  error: e => report('[API] Error Fetching App Memberships', e),
});

/**
* Make a call to atrium to create a new Download in the Download Center.
*
* @param {string} userId The User BSON ID to create the download for.
* @param {DownloadCenterDownload} download The Download object.
*/
export const createDownload = (userId, download) => atriumCall(`/users/${userId}/downloads`, {
  method: 'POST',
  body: JSON.stringify(download),
  error: e => report(`[API] Error Creating Download for ${userId}`, e),
});

export const fetchDownloads = (userId) => atriumCall(`/users/${userId}/downloads`, {
  error: e => report(`[API] Error Fetching Downloads for ${userId}`, e),
});

export const retryDownload = (userId, download) => atriumCall(`/users/${userId}/downloads/${download.id}/retry`, {
  method: 'POST',
  body: JSON.stringify(download),
  error: e => report(`[API] Error Retrying Download for ${userId} with Download ${download.id}`, e),
});

export const updateDownload = (userId, download) => atriumCall(`/users/${userId}/downloads/${download.id}`, {
  method: 'PUT',
  body: JSON.stringify(download),
  error: e => report(`[API] Error Updating Download for ${userId} with Download ${download.id}`, e),
});

export const deleteDownload = (userId, download_id) => atriumCall(`/users/${userId}/downloads/${download_id}`, {
  method: 'DELETE',
  body: JSON.stringify({ id: download_id }),
  error: e => report(`[API] Error Deleting Download for ${userId} with Download ${download_id}`, e),
});

export const exportDownload = (userId, download_id) => atriumCall(`/users/${userId}/downloads/${download_id}/download-file`, {
  error: e => report(`[API] Error Exporting Download for ${userId} with Download ${download_id}`, e),
});
