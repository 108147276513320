import { assoc, merge } from 'ramda';
import * as SessionActions from '../actions/session';
import { UPDATE_NUM_NEW_DOWNLOADS } from '../actions/downloads';
import { UPDATE_USER_SUCCESS, RECEIVED_CURRENT_USER_FEATURES } from '../actions';

// README: User interface /src/types/core.ts#L41
const initialState = {
  features: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RECEIVED_CURRENT_USER_FEATURES:
      return assoc('features', action.payload, state);
    case (SessionActions.RECEIVE_USER):
      return merge(state, {
        id: action.payload.userId,
        name: action.payload.name,
        email: action.payload.email,
        companyTelephone: action.payload.companyTelephone,
        professionalRole: action.payload.professionalRole,
        timeZone: action.payload.timeZone,
        uiState: action.payload.uiState,
        custom_data: {
          'User ID': action.payload.userId,
        },
      });
    case SessionActions.SET_UI_STATE_SUCCESS:
      return merge(state, {
        uiState: {
          ...state.uiState,
          [action.payload.key]: action.payload.value,
        },
      });
    case UPDATE_USER_SUCCESS:
      return merge(state, action.payload);
    case UPDATE_NUM_NEW_DOWNLOADS:
      return merge(state, {
        uiState: {
          ...state.uiState,
          numNewDownloads: action.payload.numNewDownloads,
        },
      });
    default:
      return state;
  }
};
