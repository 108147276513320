export enum RatingPromptActions {
  FETCH_RATING_PROMPT_PENDING = 'FETCH_RATING_PROMPT_PENDING',
  FETCH_RATING_PROMPT_SUCCESS = 'FETCH_RATING_PROMPT_SUCCESS',
  FETCH_RATING_PROMPT_ERROR = 'FETCH_RATING_PROMPT_ERROR',

  SAVE_RATING_PROMPT_PENDING = 'SAVE_RATING_PROMPT_PENDING',
  SAVE_RATING_PROMPT_SUCCESS = 'SAVE_RATING_PROMPT_SUCCESS',
  SAVE_RATING_PROMPT_ERROR = 'SAVE_RATING_PROMPT_ERROR',

  ACTIVATE_RATING_PROMPT_PENDING = 'ACTIVATE_RATING_PROMPT_PENDING',
  ACTIVATE_RATING_PROMPT_SUCCESS = 'ACTIVATE_RATING_PROMPT_SUCCESS',
  ACTIVATE_RATING_PROMPT_ERROR = 'ACTIVATE_RATING_PROMPT_ERROR',
}
