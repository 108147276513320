import React from 'react';
import numeral from 'numeral';
import SimpleCallout from '../../components/graphs/simple_callout';
import { GraphConfig } from './fan_signals_panel';

export interface IFanSignalsChartProps {
  percentSection?: boolean;
  isLoading: boolean;
  fetchError: boolean;
  startDate: number;
  endDate: number;
  graphConfig: GraphConfig;
}

const FanSignalsAggregateSection: React.FC<IFanSignalsChartProps> = (props) => {
  const {
    totalMainField,
    totalSecondaryField,
    mainSeriesTitle,
    secondarySeriesTitle,
    percentHighlightTitle,
    volumeHighlightTitle,
  } = props.graphConfig;
  let highlightValue: string;
  let highlightTitle: string;
  let calloutData: any[] = [];
  if (props.isLoading) {
    highlightValue = 'N/A';
  } else if (props.percentSection) {
    highlightValue = `${numeral(totalMainField / (totalMainField + totalSecondaryField)).format('0%')}`;
    highlightTitle = percentHighlightTitle;
    calloutData = [{ count: highlightValue, label: highlightTitle }, { count: '', label: '' }];
  } else {
    highlightValue = `${numeral(totalMainField + totalSecondaryField).format('0,0')}`;
    highlightTitle = volumeHighlightTitle;
    calloutData = [
      { count: highlightValue, label: highlightTitle },
      { count: '', label: '' },
      [{ count: totalMainField, label: mainSeriesTitle }],
      [{ count: totalSecondaryField, label: secondarySeriesTitle }],
    ];
  }

  return React.createElement(SimpleCallout, {
    className: 'love-ratio-aggregation one-third-width',
    loading: props.isLoading,
    startDate: props.startDate,
    endDate: props.endDate,
    series: calloutData,
    labels: [mainSeriesTitle, secondarySeriesTitle],
    // TypeScript compiler is requiring that the props below are
    // provided a value even though we are just using the defaults.
    fetchError: props.fetchError,
    fetchDataCB: () => {},
    withPercent: false,
    customLabel: null,
    tooltipContent: null,
  });
};

export default FanSignalsAggregateSection;
