import { RatingFlowItem, LoveDialogStats } from 'src/reducers/love-dialog/love-dialog.types';

export interface LoveDialogState {
  loading: boolean;
  activating: boolean;
  ratingFlow: RatingFlowItem | undefined;
  stats: Record<string, LoveDialogStats>;
}

export const defaultStats = {
  loading: false,
  activating: false,
  error: false,
  currentData: [],
  prevData: [],
}

export const initialState: LoveDialogState = {
  loading: false,
  activating: false,
  ratingFlow: undefined,
  stats: {
    primary: defaultStats,
  },
};
