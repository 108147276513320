export const checkForErrors = (response) => {
  if (response.error) {
    const error = new Error(response.error);
    error.response = response;
    throw error;
  }
  return response;
};

export default checkForErrors;
