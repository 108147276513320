import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { pipe, uniqBy, sortBy, reject, contains, isEmpty } from 'ramda';

// actions
import { tags } from 'src/actions/tags';

// selectors
import { getRecentTags, getAppTags, getMostUsedTags } from 'src/selectors/tags';

import { Tag } from 'src/reducers/tags';
import { State } from 'src/reducers';

import { SelectableInput, SelectableInputProps } from './select';
import { onTagNameKeyDownValidation } from './tag-form-utils';

export interface TagsSelectProps {
  mostUsedTags: Tag[];
  appTags: Tag[];
  recentTags: Tag[];
  skipTags: Tag[];
  gtmId?: SelectableInputProps['gtmId'];
  onChange(item: never): void;
  fetchTagsData(): void;
}

const rejectIn = (items: Tag[]) => (tag: Tag) => contains(tag, items);

export const TagsSelect: React.FC<TagsSelectProps> = function TagsSelect({
  mostUsedTags,
  appTags,
  recentTags,
  skipTags = [],
  gtmId,
  onChange,
  fetchTagsData,
}) {
  const firstTags = reject(rejectIn(skipTags), recentTags);
  const tagsForSelect = pipe(
    uniqBy(({ name }) => name) as any,
    sortBy(({ name }) => name.toLowerCase()),
    reject(rejectIn([...skipTags, ...recentTags])),
  )([...(mostUsedTags as any), ...(appTags as any)]);

  useEffect(() => {
    if (isEmpty(appTags)) {
      fetchTagsData();
    }
  }, [appTags]);

  return (
    <div className="tags-select">
      <SelectableInput
        gtmId={gtmId}
        options={[...firstTags, ...(tagsForSelect as any)]}
        onChange={onChange}
        onKeyDown={onTagNameKeyDownValidation}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  mostUsedTags: getMostUsedTags(state),
  appTags: getAppTags(state),
  recentTags: getRecentTags(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTagsData: () => {
    dispatch(tags.fetchAppTags());
  },
});

export const TagsSelectContainer = connect<
  Pick<TagsSelectProps, 'mostUsedTags' | 'appTags' | 'recentTags'>,
  Pick<TagsSelectProps, 'fetchTagsData'>,
  Pick<TagsSelectProps, 'skipTags' | 'onChange'>
>(
  mapStateToProps,
  mapDispatchToProps,
)(TagsSelect);
