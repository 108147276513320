import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Base from './Base';

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Dots = styled(Base)`
  font-size: 1.2em;
  .spinner {
    margin: 0 auto;
    padding-right: 0.5em;
    text-align: center;
    display: inline-block;
    > div {
      width: 25px;
      height: 25px;
      background-color: #333;
      margin: 10px;
      border-radius: 100%;
      display: inline-block;
      animation: ${bounceDelay} 1.4s infinite ease-in-out;
      /* Prevent first frame from flickering when animation starts */
      animation-fill-mode: both;
    }
    .bounce1 {
      animation-delay: -0.32s;
    }
    .bounce2 {
      animation-delay: -0.16s;
    }
  }
`;

const Loading = ({ subject }) => (
  <Dots>
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
    {`Loading ${subject}...`}
  </Dots>
);
Loading.propTypes = {
  subject: PropTypes.string,
};
Loading.defaultProps = {
  subject: '',
};
export default Loading;
