import React, { Component } from 'react';

import Tooltip from './tooltip';

import '../../styles/molecules/info_tooltip.scss';
import '../../styles/molecules/question_icon.scss';

class InfoTooltip extends Component {
  static propTypes = Tooltip.propTypes;

  static defaultProps = {
    children: null,
  };

  state = {
    tooltipVisible: false,
  };

  toggleTooltip = () => {
    this.setState({ tooltipVisible: !this.state.tooltipVisible });
  };

  closeTooltip = () => {
    this.setState({ tooltipVisible: false });
  };

  render() {
    let tooltip = null;
    const { children, ...rest } = this.props;
    if (this.state.tooltipVisible) {
      tooltip = (
        <Tooltip closeTooltip={this.closeTooltip} {...rest}>
          {children}
        </Tooltip>
      );
    }

    return (
      <div className="info-tooltip-container">
        <button type="button" className="button-normalize button-question-icon" onClick={this.toggleTooltip} />
        {tooltip}
      </div>
    );
  }
}

export default InfoTooltip;
