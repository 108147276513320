import React, { useRef, useEffect } from 'react';
import { Chart, ChartData, ChartType, ChartOptions } from 'chart.js';

import './chart-wrapper.scss';

export interface ChartProps {
  className?: string;
  data: ChartData;
  noLegend?: boolean;
  noTooltip?: boolean;
  maintainAspectRatio?: boolean;
  scales?: ChartOptions['scales'];
  options?: ChartOptions;
}

interface ChartWrapperProps extends ChartProps {
  type: ChartType;
}

export const ChartWrapper: React.FC<ChartWrapperProps> = ({
  className = '',
  type,
  data,
  maintainAspectRatio = false,
  noLegend,
  noTooltip,
  scales,
  options = {},
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let chart: Chart | null;

    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d') as CanvasRenderingContext2D;

      chart = new Chart(ctx, {
        type,
        data,
        options: {
          maintainAspectRatio,
          legend: { display: !noLegend },
          tooltips: {
            enabled: !noTooltip,
            backgroundColor: '#FFF',
            titleFontColor: '#333333',
            bodyFontColor: '#333333',
            footerFontColor: '#333333',
            borderColor: '#E2E2E2',
            borderWidth: 1,
            cornerRadius: 4,
          },
          scales,
          ...options,
        },
      });
    }

    return () => {
      if (chart) {
        chart.destroy();
        chart = null;
      }
    };
  }, [canvasRef.current, type, data, noLegend, noTooltip, scales, options]);

  return (
    <div className={`chart-wrapper chart-wrapper--${type} ${className}`}>
      <canvas ref={canvasRef} />
    </div>
  );
};
