import { createSelector } from 'reselect';
import { State } from 'src/reducers';
import { LoveDialogState } from 'src/reducers/love-dialog/love-dialog.state';

export const getLoveDialog = (state: State): LoveDialogState => state.loveDialog;

export const getLoveDialogRatingFlow = createSelector(
  getLoveDialog,
  (data) => (data.ratingFlow ? { ...data.ratingFlow } : undefined)
);

export const getIsRatingFlowLoading = createSelector(
  getLoveDialog,
  (data) => data.loading
);

export const getEventStats = createSelector(
  getLoveDialog,
  (data) => data.stats
);

export const getIsRatingFlowActivating = createSelector(
  getLoveDialog,
  (data) => data.activating
);
