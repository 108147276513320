import React from 'react';

const LoadingGraph = () => (
  <div className="loading-dots">
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
    Loading Graph...
  </div>
);

export default LoadingGraph;
