import React from 'react';
import numeral from 'numeral';

import { Histogram } from 'src/apptentive_ui/styled/charts';
import { Loading } from 'src/apptentive_ui/styled/overlays';
import { Time } from 'src/utils';
import { renderPercentHovercard, renderVolumeHovercard } from 'src/utils/hovercard_helper';
import { HistogramDataPoint, IDateAnnotation, HistogramTimeSeries } from 'src/types/core';
import { GraphConfig } from './fan_signals_panel';

export interface IFanSignalsChartProps {
  percentSection?: boolean;
  isLoading: boolean;
  fetchError: boolean;
  startDate: number;
  endDate: number;
  graphAnnotations?: IDateAnnotation[];
  colors: string[];
  graphConfig: GraphConfig;
}

const FanSignalsTimeSeriesGraph: React.FC<IFanSignalsChartProps> = (props) => {
  const { percentSection, colors, graphConfig, graphAnnotations, startDate, endDate } = props;
  const { mainSeriesTitle, secondarySeriesTitle } = graphConfig;

  let yFormat: (y: number) => string;
  let renderHoverCard: any;
  let mainSeriesFormatted: HistogramTimeSeries;
  let secondarySeriesFormatted: HistogramTimeSeries;
  let series: HistogramTimeSeries[] = [];

  if (percentSection) {
    mainSeriesFormatted = addMetadata(graphConfig.percentMainSeries, colors[0], mainSeriesTitle);
    secondarySeriesFormatted = addMetadata(graphConfig.percentSecondarySeries, colors[1], secondarySeriesTitle);
    yFormat = (y: number) => `${y * 100}%`;
    renderHoverCard = renderPercentHovercard({ summaryTitle: 'Fan Signals', hiddenSeries: [secondarySeriesFormatted] });
    series = [mainSeriesFormatted];
  } else {
    mainSeriesFormatted = addMetadata(graphConfig.volumeMainSeries, colors[0], mainSeriesTitle);
    secondarySeriesFormatted = addMetadata(graphConfig.volumeSecondarySeries, colors[1], secondarySeriesTitle);
    yFormat = (y) => numeral(Math.abs(y)).format('0a');
    renderHoverCard = renderVolumeHovercard({ summaryTitle: 'Love Dialog Volume' });
    series = [mainSeriesFormatted, secondarySeriesFormatted];
  }

  return React.createElement(Histogram, {
    className: 'fans-signals-over-time-graph',
    overlay: props.isLoading ? React.createElement(Loading, { subject: 'Graph' }) : null,
    series,
    colors,
    xFormat: (x: Date) => Time.create(x)
      .format('MMM DD')
      .toUpperCase(),
    xTitleFormat: (x: Date) => Time.create(x).format('MMM DD, YYYY'),
    yFormat,
    hoverTitle: 'Fan Signals',
    startDate,
    endDate,
    fetchError: props.fetchError,
    offset: 'expand',
    renderHoverCard,
    dateAnnotations: graphAnnotations,
  });
};

function addMetadata(data: HistogramDataPoint[], color: string, label: string): HistogramTimeSeries {
  return {
    label,
    data,
    color,
  };
}

export default FanSignalsTimeSeriesGraph;
