import moment from 'moment';
import { map, times, isEmpty, sortBy } from 'ramda';

import { Time } from 'src/utils/time';
import { isTestMode } from 'src/utils/environment';
import { AggsSentiment, AggsRating } from 'src/reducers/insights';

// demo data
import {
  ratingsDialogDemoData,
  ratingsDemoDataIOS,
  ratingsDemoDataIOSUniq,
  ratingsDemoDataAndroid,
  ratingsDemoDataAndroidUniq,
  insightsDemoData,
  insightsFiltersData,
  insightsPhrases,
  retentionDemoData,
  fanSignalsDemoData,
  LoveScoreDemoData,
} from './demo-data';

const NOW_TIME = Date.now();

/**
 * @param {*} value string|number
 * @return number
 */
const convertToDateNum = (value) => {
  if (typeof value === 'string') {
    return new Date(value).valueOf();
  }
  return value;
};

/**
 * @param {*} data Record<string, number>[]
 * @param {*} dateFields Array<keyof typeof Item> // date fields number
 */
const updateMockedDates = (data, dateFields = []) => {
  let diffTime = 0;
  if (isEmpty(dateFields)) {
    return data;
  }
  const sortedData = sortBy(
    (item) => -item[dateFields[0]],
    data.map((item) => ({
      ...item,
      ...dateFields.reduce((res, field) => {
        res[field] = convertToDateNum(item[field]);
        return res;
      }, {}),
    })),
  );

  return sortedData.reduce((res, item) => {
    const updateFields = dateFields.reduce((acc, field) => {
      acc[field] = diffTime ? item[field] + diffTime : NOW_TIME;
      return acc;
    }, {});

    if (!res[0]) {
      diffTime = NOW_TIME - item[dateFields[0]];
    }
    res.push({
      ...item,
      ...updateFields,
    });
    return res;
  }, []);
};

const getRandomNumber = (base, delta) => base + Math.floor(Math.random() * delta);

// Check localStorage for a value and a type to parse as, otherwise fallback to a given value.
export const checkLS = (key, fallback, type = 'number') => {
  const value = localStorage.getItem(key);
  if (value) {
    if (type === 'number') {
      const numericValue = parseInt(value, 10);
      if (!Number.isNaN(numericValue)) {
        return numericValue;
      }
    }
    if (type === 'json') {
      try {
        return JSON.parse(value);
      } catch (error) {
        /* istanbul ignore next */
        console.error('Error Parsing JSON Value:', value, error);
      }
    }
    return value;
  }

  if (!isTestMode() && !value && type === 'json') {
    localStorage.setItem(key, JSON.stringify(fallback));
  }

  return fallback;
};

// Scale a given number (unscaledNum) from a given range (minSpan/maxSpan) into another range (minAllowed/maxAllowed).
export const scaleBetween = (unscaledNum, minAllowed, maxAllowed, minSpan, maxSpan) =>
  ((maxAllowed - minAllowed) * (unscaledNum - minSpan)) / (maxSpan - minSpan) + minAllowed;

/* begin ratingsDialogDemoData */
// util
const getCount = (data) => data.map((d) => d.y).reduce((a, b) => a + b, 0);
const distributeData = (data, startDate, endDate) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  return times(
    (i) => ({
      date: startMoment.add(i, 'days').valueOf(),
      y: data[i + 1],
    }),
    endMoment.diff(startMoment, 'days') + 1, // including last selected day
  );
};

// overtime
// get appleOvertime from localStorage or local const
const generateRatingsDialogOvertimeDemoData = (startDate, endDate) =>
  map(
    (data) => distributeData(data, startDate, endDate),
    checkLS('fetchRatingsDialogOvertime', ratingsDialogDemoData.ratingsDialogOvertime, 'json'),
  );

export const generateRatingsDialogOvertimeDemoResponse = (startDate, endDate) => {
  const { rate, remind, decline } = generateRatingsDialogOvertimeDemoData(startDate, endDate);
  return {
    type: 'RATINGS_DIALOG_TIMESERIES',
    data: [
      { label: 'rating_dialog.rate', datums: rate },
      { label: 'rating_dialog.remind', datums: remind },
      { label: 'rating_dialog.decline', datums: decline },
    ],
  };
};

export const fetchRatingsDialogOvertimeDemoData = (...params) =>
  Promise.resolve(generateRatingsDialogOvertimeDemoResponse(...params));

// generate aggregation based on overtime
const generateRatingsDialogAggregationDemoData = (startDate, endDate) => {
  const { rate, remind, decline } = generateRatingsDialogOvertimeDemoData(startDate, endDate);
  return {
    rate: getCount(rate),
    remind: getCount(remind),
    decline: getCount(decline),
  };
};

export const generateRatingsDialogAggregationDemoResponse = (startDate, endDate, lSKey) => {
  // get aggregation from localStorage or local generator
  const { rate, remind, decline } = checkLS(
    lSKey,
    generateRatingsDialogAggregationDemoData(startDate, endDate),
    'json',
  );
  return {
    type: 'RATINGS_DIALOG_AGGREGATE',
    data: [
      { label: 'rating_dialog.rate', count: rate },
      { label: 'rating_dialog.remind', count: remind },
      { label: 'rating_dialog.decline', count: decline },
    ],
  };
};

export const fetchRatingsDialogAggregationDemoData = (...params) =>
  Promise.resolve(generateRatingsDialogAggregationDemoResponse(...params));

// appleOvertime
// get appleOvertime from localStorage or local const
const generateAppleRatingsDialogOvertimeDemoData = (startDate, endDate) =>
  map(
    (data) => distributeData(data, startDate, endDate),
    checkLS('fetchAppleRatingsDialogOvertime', ratingsDialogDemoData.inAppRatingsDialogOvertime, 'json'),
  );

export const generateAppleRatingsDialogOvertimeDemoResponse = (startDate, endDate) => {
  const { shown, not_shown, fallback } = generateAppleRatingsDialogOvertimeDemoData(startDate, endDate);
  return {
    type: 'APPLE_RATINGS_DIALOG_TIMESERIES',
    data: [
      { label: 'apple_rating_dialog.shown', datums: shown },
      { label: 'apple_rating_dialog.not_shown', datums: not_shown },
      { label: 'apple_rating_dialog.fallback', datums: fallback },
    ],
  };
};

export const fetchAppleRatingsDialogOvertimeDemoData = (...params) =>
  Promise.resolve(generateAppleRatingsDialogOvertimeDemoResponse(...params));

// generate appleAggregation based on appleOvertime
const generateAppleRatingsDialogAggregationDemoData = (startDate, endDate) => {
  const { shown, not_shown, fallback } = generateAppleRatingsDialogOvertimeDemoData(startDate, endDate);
  const { rate } = generateRatingsDialogAggregationDemoData(startDate, endDate);
  return {
    shown: getCount(shown),
    not_shown: getCount(not_shown),
    fallback: getCount(fallback),
    rate,
  };
};

export const generateAppleRatingsDialogAggregationDemoResponse = (startDate, endDate, lSKey) => {
  // get appleAggregation from localStorage or local generator
  const { shown, not_shown, fallback, rate } = checkLS(
    lSKey,
    generateAppleRatingsDialogAggregationDemoData(startDate, endDate),
    'json',
  );
  return {
    type: 'APPLE_RATINGS_DIALOG_AGGREGATE',
    data: [
      { label: 'apple_rating_dialog.shown', count: shown },
      { label: 'apple_rating_dialog.not_shown', count: not_shown },
      { label: 'apple_rating_dialog.fallback', count: fallback },
      { label: 'rating_dialog.rate', count: rate },
    ],
  };
};

export const fetchAppleRatingsDialogAggregationDemoData = (...params) =>
  Promise.resolve(generateAppleRatingsDialogAggregationDemoResponse(...params));
/* end ratingsDialogDemoData */

// inAppOvertime
// get inAppOvertime from localStorage or local const
const generateInAppRatingsDialogOvertimeDemoData = (startDate, endDate) =>
  map(
    (data) => distributeData(data, startDate, endDate),
    checkLS('fetchInAppRatingsDialogOvertime', ratingsDialogDemoData.inAppRatingsDialogOvertime, 'json'),
  );

export const generateInAppRatingsDialogOvertimeDemoResponse = (startDate, endDate) => {
  const { shown, not_shown, fallback } = generateInAppRatingsDialogOvertimeDemoData(startDate, endDate);
  return {
    type: 'IN_APP_RATINGS_DIALOG_TIMESERIES',
    data: [
      { label: 'in_app_rating_dialog.shown', datums: shown },
      { label: 'in_app_rating_dialog.not_shown', datums: not_shown },
      { label: 'rating_dialog.launch', datums: fallback },
    ],
  };
};

export const fetchInAppRatingsDialogOvertimeDemoData = (...params) =>
  Promise.resolve(generateInAppRatingsDialogOvertimeDemoResponse(...params));

// generate inAppAggregation based on inAppOvertime
const generateInAppRatingsDialogAggregationDemoData = (startDate, endDate) => {
  const { shown, not_shown, fallback } = generateInAppRatingsDialogOvertimeDemoData(startDate, endDate);
  const { rate } = generateRatingsDialogAggregationDemoData(startDate, endDate);
  return {
    shown: getCount(shown),
    not_shown: getCount(not_shown),
    fallback: getCount(fallback),
    rate,
  };
};

export const generateInAppRatingsDialogAggregationDemoResponse = (startDate, endDate, lSKey) => {
  // get InAppAggregation from localStorage or local generator
  const { shown, not_shown, fallback, rate } = checkLS(
    lSKey,
    generateInAppRatingsDialogAggregationDemoData(startDate, endDate),
    'json',
  );
  return {
    type: 'IN_APP_RATINGS_DIALOG_AGGREGATE',
    data: [
      { label: 'in_app_rating_dialog.shown', count: shown },
      { label: 'in_app_rating_dialog.not_shown', count: not_shown },
      { label: 'rating_dialog.launch', count: fallback },
      { label: 'rating_dialog.rate', count: rate },
    ],
  };
};

export const fetchInAppRatingsDialogAggregationDemoData = (...params) =>
  Promise.resolve(generateInAppRatingsDialogAggregationDemoResponse(...params));
/* end ratingsDialogDemoData */

/* begin ratingsDemoDataIOS */
export const generateRatingsDemoResponse = (page_size, store) => {
  const demoData = store === 'itunes' ? ratingsDemoDataIOS.data : ratingsDemoDataAndroid.data;
  const updatedData = updateMockedDates(demoData, ['store_observed_time', 'ingest_time']);

  return {
    type: 'RATINGS_HISTOGRAMS',
    data: updatedData,
    min_key: 0,
    page_size,
    has_more: false,
  };
};

export const fetchRatingsDemoData = (...params) => Promise.resolve(generateRatingsDemoResponse(...params));

export const generateRatingsUniqueDemoResponse = (page_size, store) => {
  const demoData = store === 'itunes' ? ratingsDemoDataIOSUniq.data : ratingsDemoDataAndroidUniq.data;
  const updatedData = updateMockedDates(demoData, ['store_observed_time', 'ingest_time']);

  return {
    type: 'RATINGS_HISTOGRAMS_UNIQUE',
    data: updatedData,
    min_key: 0,
    page_size,
    has_more: false,
  };
};

export const fetchRatingsUniqueDemoData = (...params) => Promise.resolve(generateRatingsUniqueDemoResponse(...params));
/* end ratingsDemoDataIOS */

/* begin loveRatioDemoData */
export const generateLoveRatioOverTimeDemoResponse = (startDate, endDate = Date.now()) => {
  // NOTE: This does not include counts, only yes vs no, so anything other than that is not counted here.
  const oneDay = 86400000;
  const yesLaunchesCoefficient = 0.4;
  const yesLaunchesDelta = 0.25;
  const noResponseCoefficient = 0.2;
  const durationInDays = Math.max(
    Math.round((new Date(endDate).valueOf() - new Date(startDate).valueOf()) / oneDay) - 1,
    0,
  );
  const yesDatums = [];
  const noDatums = [];
  const launchDatums = [];

  times((n) => {
    const date = new Date(startDate).getTime() + n * oneDay;
    const launches = getRandomNumber(8000, 2000);
    launchDatums.push({ date, y: Math.round(launches) });
    const yesLaunches = getRandomNumber(launches * yesLaunchesCoefficient, launches * yesLaunchesDelta);
    const nonResp = launches * noResponseCoefficient;
    let noLaunches = launches - yesLaunches - nonResp;
    yesDatums.push({ date, y: Math.round(yesLaunches) });
    if (yesLaunches <= 0) {
      noLaunches = 0;
    }
    noDatums.push({ date, y: Math.round(noLaunches) });
  }, durationInDays);

  return {
    data: [
      {
        label: 'enjoyment_dialog.launch',
        datums: launchDatums,
      },
      {
        label: 'enjoyment_dialog.yes',
        datums: yesDatums,
      },
      {
        label: 'enjoyment_dialog.no',
        datums: noDatums,
      },
    ],
  };
};

export const fetchLoveDialogEventsDemoData = (startDate, endDate, isPrevPeriod) => {
  if (isPrevPeriod) {
    const data = generateLoveRatioOverTimeDemoResponse(startDate, endDate).data.map(item => ({
      ...item,
      datums: item.datums.map(datum => ({ ...datum, y: Math.round(datum.y * 0.7) })),
    }));
    return Promise.resolve({ data });
  }

  return Promise.resolve(generateLoveRatioOverTimeDemoResponse(startDate, endDate));
};
/* end loveRatioDemoData */

/* begin insightsDemoData */
/**
 * @param {*} types AggTypes[]
 */
export const generateInsightsIntlDemoResponse = (types = []) => {
  const allInsights = [
    ...insightsDemoData.reviews,
    ...insightsDemoData.data,
  ];
  const data = checkLS('fetchInsightsIntl-reviews', allInsights, 'json');
  const reviews = updateMockedDates(data, ['created_on_date', 'ingest_time']).filter(item => types.includes(item.type));

  const getDistribution = (reviewData, propName) => [
    ...reviewData
      .reduce((accum, { [propName]: propValue }) => {
        const entry = accum.get(propValue);
        if (!entry) {
          accum.set(propValue, { [propName]: propValue, count: 1 });
        } else {
          entry.count += 1;
        }
        return accum;
      }, new Map())
      .values(),
  ];

  const ratingMap = {
    1: AggsRating.ONE,
    2: AggsRating.TWO,
    3: AggsRating.THREE,
    4: AggsRating.FOUR,
    5: AggsRating.FIVE,
  };

  return {
    insights: {
      per: 50,
      page: 0,
      total: reviews.length,
      data: reviews,
    },
    avg_sentiment: reviews.reduce((accum, { sentiment_compound }) => accum + sentiment_compound, 0) / reviews.length,
    ratings_distribution: sortBy(item => item.rating, getDistribution(reviews, 'rating'))
      .filter(item => item.rating)
      .map(item => ({
        ...item,
        rating: ratingMap[item.rating],
      })),
    sentiment_distribution: [
      ...reviews
        .reduce((accum, { sentiment_compound }) => {
          let sentimentType;
          if (sentiment_compound < 0) {
            sentimentType = AggsSentiment.NEGATIVE; // '-1000.0-0.0';
          } else if (sentiment_compound >= 0 && sentiment_compound < 600) {
            sentimentType = AggsSentiment.NEUTRAL; // '0.0-600.0';
          } else {
            sentimentType = AggsSentiment.POSITIVE; // '600.0-*';
          }
          const entry = accum.get(sentimentType);
          if (!entry) {
            accum.set(sentimentType, { sentiment: sentimentType, count: 1 });
          } else {
            entry.count += 1;
          }
          return accum;
        }, new Map())
        .values(),
    ],
    count_histogram: getDistribution(
      reviews.map(item => ({
        ...item,
        date: Time.format(item.created_on_date, Time.FORMAT.YYYY_MM_DD),
      })),
      'date',
    ),
    type_distribution: getDistribution(allInsights, 'type'),
  };
};

/**
 * @param {*} types AggsType[]
 */
export const fetchInsightsIntlDemoData = (types) => Promise.resolve(
  generateInsightsIntlDemoResponse(types)
);
/* end insightsDemoData */

/* begin insights side filters */
export const fetchInsightsTagsDemoData = () => {
  return Promise.resolve(insightsFiltersData.tags);
};

export const fetchInsightsFsDemoData = () => {
  return Promise.resolve(insightsFiltersData.fanSignals);
};

export const fetchInsightsSurveysDemoData = () => {
  return Promise.resolve(insightsFiltersData.surveys);
};

export const fetchPhrasesDemoData = () => Promise.resolve(insightsPhrases);
/* end insights side filters */

/* begin retentionDemoData */
const generateRetentionDemoData = (startDate, endDate, data) => {
  const dateFormat = 'Y-M-D';
  const startMonthMoment = moment.utc(`${startDate}`, dateFormat).startOf('month');

  return Object.values(data)
    .map(({ total, retained: retainedData }, rowIndex) => {
      const originStartMoment = startMonthMoment.clone().add(rowIndex, 'months');
      const originStartDate = originStartMoment.startOf('month').toISOString();
      const originEndDate = originStartMoment.endOf('month').toISOString();
      const origin = `${originStartDate}/${originEndDate}`;

      return Object.values(retainedData)
        .map((retained, colIndex) => {
          const lost = total - retained;
          const percentage = (retained / total) * 100;
          const bucketStartMoment = startMonthMoment.clone().add(rowIndex + colIndex, 'months');
          const bucketStartDate = bucketStartMoment.startOf('month').toISOString();
          const bucketEndDate = bucketStartMoment.endOf('month').toISOString();
          const bucket = `${bucketStartDate}/${bucketEndDate}`;

          return {
            lost,
            retained,
            percentage,
            origin,
            bucket,
            timestamp: bucketStartDate,
          };
        });
    });
};

export const generateRetentionDemoResponse = (startDate, endDate) => {
  const data = checkLS('fetchRetention', retentionDemoData, 'json');
  const retention = generateRetentionDemoData(startDate, endDate, data);

  return {
    id: 'demo',
    retention,
  };
};

export const fetchRetentionDemoData = (...params) => Promise.resolve(
  generateRetentionDemoResponse(...params)
);
/* end retentionDemoData */

/* begin activeUsersDemoData */
export const generateActiveUsersDemoData = (startDate, endDate, period) => {
  const monthlyUsersAmount = 1800000;
  const monthlyDelta = 500000;
  const baseUsersAmount = period === 'daily' ? monthlyUsersAmount / 10 : monthlyUsersAmount;
  const baseUsersDelta = period === 'daily' ? monthlyDelta / 10 : monthlyDelta;

  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const demoData = times(
    (i) => ({
      date: startMoment.add(i, 'days').valueOf(),
      y: getRandomNumber(baseUsersAmount, baseUsersDelta),
    }),
    endMoment.diff(startMoment, 'days') + 1, // including last selected day
  );

  const datums = checkLS(`fetch${period}ActiveUsers`, demoData, 'json');

  return Promise.resolve({
    type: "ACTIVE_USERS",
    data: [
      {
        label: "active_users",
        datums,
      },
    ],
  });
};

/* end activeUsersDemoData */

/* begin fanSignalsDemoData */
export const generateFanSignalsDemoData = (appId, startDate, endDate) => {
  const currentDay = Time.create.utc().startOf('day').add(1, 'day');
  const data = fanSignalsDemoData.data
    .reduceRight((res, item) => (res.concat({
      ...item,
      appId,
      day: currentDay.add(-1, 'day').toISOString(),
    })), []);
  const period = Time.diffDays(startDate, endDate) + 1;
  data.splice(period);
  return { data: data.reverse() };
};

export const fetchFanSignalsDemoData = (appId, startDate, endDate) => Promise.resolve(
  generateFanSignalsDemoData(appId, startDate, endDate)
);
/* end fanSignalsDemoData */

/* begin loveScoreDemoData */
export const generateLoveScoreDemoData = (appId) => {
  const data = {
    ...LoveScoreDemoData.data,
    appId,
  };
  return { data };
};

export const fetchLoveScoreDemoData = (appId) => Promise.resolve(
  generateLoveScoreDemoData(appId)
);

/* end loveScoreDemoData */
