import React from 'react';
import { bemPrefix, SortOrder } from '../../../utils';
import Chevron from '../../assets/chevron.jsx.svg';

import './sort-toggler.scss';

export { SortOrder };

export interface SortTogglerProps {
  order?: SortOrder | undefined;
}

const bem = bemPrefix('sort-toggler');

export const SortToggler: React.FC<SortTogglerProps> = ({ order }) => (
  <div className={bem('', order)}>
    <div className={bem('chevron-up')}>
      <Chevron />
    </div>
    <div className={bem('chevron-down')}>
      <Chevron />
    </div>
  </div>
);
