import { createSelector } from 'reselect';
import { find, propEq } from 'ramda';

const getBaseState = state => state.groups || {};

export const getLoading = createSelector(
  getBaseState,
  baseState => baseState.loading || false
);
export const getFetchError = createSelector(
  getBaseState,
  baseState => baseState.fetchError || false
);

export const getGroups = createSelector(
  getBaseState,
  baseState => baseState.groups || []
);
export const getGroupSelected = createSelector(
  getBaseState,
  baseState => baseState.groupSelected || []
);
export const getShowGroupActions = createSelector(
  getBaseState,
  baseState => baseState.showGroupActions || false
);
export const getCurrentGroupId = createSelector(
  getBaseState,
  baseState => baseState.id || ''
);
export const getCurrentGroupConversations = createSelector(
  getBaseState,
  baseState => baseState.conversations || []
);

export const getGroup = createSelector(
  getCurrentGroupId,
  getGroups,
  (groupId, groups) => find(propEq('id', groupId))(groups) || {}
);
