import { Rest, API } from 'src/api';
import { EventMetricsPayload } from 'src/types/core';
import { BaseService } from './base-service';

export class EventMetricsService extends BaseService {
  static sendEvent(event: EventMetricsPayload) {
    return Rest.httpPost(API.EVENT_METRICS.SEND, {
      body: event,
      errorTitle: '[API] Error Sending Event Metrics',
    }).promise;
  }
}
