import { RatingActions, RatingReducerAction } from 'src/actions/star-ratings';
import { DEFAULT_STAR_RATING_STATE, StarRatingsState } from './star-rating.reducer';

export const starRatingsUnique = (state: StarRatingsState = DEFAULT_STAR_RATING_STATE, action: RatingReducerAction = {} as RatingReducerAction) => {
  switch (action.type) {
    case RatingActions.FETCH_RATINGS_UNIQUE_PENDING: {
      return {
        ...state,
        loading: true,
        fetchError: false,
      };
    }
    case RatingActions.FETCH_RATINGS_UNIQUE_ERROR: {
      return {
        ...state,
        loading: false,
        fetchError: true,
      };
    }
    case RatingActions.FETCH_RATINGS_UNIQUE_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        loading: false,
        fetchError: false,
        data: payload.json?.data,
      };
    }
    default:
      return state;
  }
};
