import { assoc, merge, propEq, reject } from 'ramda';
import * as actions from '../actions/app_memberships';
import * as appMemberActions from '../actions/app_members';

const initialState = {
  loading: true,
  fetchError: false,
  appMemberships: [],
};

const appMemberships = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.FETCH_APP_MEMBERSHIPS_PENDING:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.FETCH_APP_MEMBERSHIPS_SUCCESS:
      return merge(state, {
        loading: false,
        fetchError: false,
        appMemberships: action.payload,
      });
    case actions.FETCH_APP_MEMBERSHIPS_FAILURE:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    case appMemberActions.REMOVE_APP_MEMBER_SUCCESS:
      return assoc(
        'appMemberships',
        reject(propEq('id', action.meta.appId), state.appMemberships),
        state
      );
    default:
      return state;
  }
};

export default appMemberships;
