import React from 'react';
import { DropDown, DropDownProps } from './dropdown';

import './simple-dropdown.scss';

interface SimpleDropdownProps extends Omit<DropDownProps, 'hideCheckIcon' | 'selectedValue'> {
  selectedValue?: DropDownProps['selectedValue'];
}

export const SimpleDropdown: React.FC<SimpleDropdownProps> = ({ className = '', selectedValue = '', ...props }) => (
  <DropDown className={`simple-dropdown ${className}`} selectedValue={selectedValue} {...props} hideCheckIcon />
);
