import { Action } from 'redux';

import { Time, DateLastPeriod, CustomPeriodName, daysAgo } from 'src/utils/time';
import { GetState, DispatchFunc } from 'src/reducers';
import { getCurrentApp, getCurrentAppId } from 'src/selectors/current_app';
import { getDashboardDateRange, getDashboardNamedDate } from 'src/selectors/dashboard';
import { FanSignalStatus, FanSignalsExportType } from 'src/reducers/insights';
import { createDownload } from 'src/actions/downloads';
import { getCurrentUserId } from 'src/selectors/user';
import { FanSignalsService, FanSignalDayCount, FanSignalLoveScore } from 'src/services/fan-signals-service';
import sdkEngage from '../utils/sdk_engage';

export type FsExportConfig = {
  exportType: FanSignalsExportType;
  statuses: FanSignalStatus[];
  startDate: number;
  endDate: number;
  isUnlimitedExport?: boolean;
};

export enum FanSignalActions {
  FETCH_FS_COUNTS = 'FETCH_FS_COUNTS',
  FETCH_FS_COUNTS_SUCCESS = 'FETCH_FS_COUNTS_SUCCESS',
  FETCH_FS_COUNTS_ERROR = 'FETCH_FS_COUNTS_ERROR',

  FETCH_LOVE_SCORE_PENDING = 'FETCH_LOVE_SCORE_PENDING',
  FETCH_LOVE_SCORE_SUCCESS = 'FETCH_LOVE_SCORE_SUCCESS',
  FETCH_LOVE_SCORE_ERROR = 'FETCH_LOVE_SCORE_ERROR',
}

export type FanSignalReducerAction = Action<FanSignalActions> & {
  payload: Partial<{
    appId: string;
    startDate: number;
    endDate: number;
    counts: FanSignalDayCount[];
    love_score: FanSignalLoveScore;
    lastPeriod: DateLastPeriod | CustomPeriodName;
  }>;
  error?: Error;
};

type FanSignalsDispatch = DispatchFunc<FanSignalReducerAction>;

export const fanSignals = {
  runExport: ({ exportType, statuses, startDate, endDate, isUnlimitedExport }: FsExportConfig) => {
    return async (dispatch: FanSignalsDispatch, getState: GetState) => {
      const state = getState();
      const app = getCurrentApp(state);
      const currentUserId = getCurrentUserId(state);
      const currentDate = new Time();
      const dateNow = currentDate.format(Time.FORMAT.YYYY_MM_DD);
      const timeNow = currentDate.format('HH:mm:ss');

      let end_date = Time.format(endDate, Time.FORMAT.YYYY_MM_DD) || undefined;
      let start_date = Time.format(startDate, Time.FORMAT.YYYY_MM_DD) || undefined;
      const duration = Time.diffDays(startDate, new Date());

      if (!isUnlimitedExport && duration > 30) {
        const end = new Date();
        start_date = Time.format(
          new Date(end.getFullYear(), end.getMonth(), end.getDate() - 29).valueOf(),
          Time.FORMAT.YYYY_MM_DD
        );
        end_date = Time.format(new Date().valueOf(), Time.FORMAT.YYYY_MM_DD);
      }

      const params = {
        start_date,
        end_date,
        requested_states: statuses,
        fs_export_type: exportType,
      };

      if (exportType === FanSignalsExportType.CURRENT_SIGNAL && isUnlimitedExport) {
        delete params.end_date;
        delete params.start_date;
      }

      const reportParams = {
        app_id: app.id,
        app_name: app.title,
        user_id: currentUserId,
        date_requested: new Date(),
        filename: `Fan_Signal_Export_App_${app.title}_${dateNow}_${timeNow}.csv`,
        filesize: 0,
        parameters: JSON.stringify(params),
        status: 'running',
        type: 'FanSignal',
      };

      dispatch(createDownload(currentUserId, reportParams) as any);
      sdkEngage('fan_signals_exported_file');
    };
  },

  fetchFanSignalsCounts: (startDate: number, endDate: number, lastPeriod?: DateLastPeriod | CustomPeriodName) => {
    return async (
      dispatch: FanSignalsDispatch,
      getState: GetState
    ): Promise<FanSignalDayCount[] | FanSignalReducerAction> => {
      const state = getState();
      const appId = getCurrentAppId(state);
      lastPeriod = lastPeriod || getDashboardNamedDate(state);

      dispatch({ type: FanSignalActions.FETCH_FS_COUNTS, payload: { appId, startDate, endDate, lastPeriod } });

      return FanSignalsService.fetchFanSignalsTimeseries(appId, startDate, endDate)
        .promise.then((resp) => {
          dispatch({
            type: FanSignalActions.FETCH_FS_COUNTS_SUCCESS,
            payload: { appId, counts: resp.data, lastPeriod },
          });
          return resp.data;
        })
        .catch((err) => dispatch({
          type: FanSignalActions.FETCH_FS_COUNTS_ERROR,
          payload: { appId, lastPeriod },
          error: err,
        })
        );
    };
  },

  fetchDashboardRangeFanSignalsCounts: () => {
    return async (
      dispatch: FanSignalsDispatch,
      getState: GetState
    ): Promise<FanSignalDayCount[] | FanSignalReducerAction> => {
      const state = getState();
      const { startDate, endDate } = getDashboardDateRange(state);
      return dispatch(fanSignals.fetchFanSignalsCounts(startDate, endDate));
    };
  },

  fetchDashboardFanSignalsCountsByThirtyDaysPeriod: () => {
    return async (
      dispatch: FanSignalsDispatch,
      getState: GetState
    ): Promise<FanSignalDayCount[] | FanSignalReducerAction> => {
      const state = getState();
      const { endDate } = getDashboardDateRange(state);
      const startDate = daysAgo(30).valueOf();
      return dispatch(fanSignals.fetchFanSignalsCounts(startDate, endDate, DateLastPeriod.DAYS_30));
    };
  },

  fetchLoveScore: () => {
    return async (
      dispatch: FanSignalsDispatch,
      getState: GetState
    ): Promise<FanSignalLoveScore | FanSignalReducerAction> => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const { startDate, endDate } = getDashboardDateRange(state);

      dispatch({ type: FanSignalActions.FETCH_LOVE_SCORE_PENDING, payload: { appId, startDate, endDate } });

      return FanSignalsService.fetchLoveScore(appId, startDate, endDate)
        .promise.then((resp) => {
          dispatch({
            type: FanSignalActions.FETCH_LOVE_SCORE_SUCCESS,
            payload: { appId, love_score: resp.data },
          });
          return resp.data;
        })
        .catch((err) => dispatch({
          type: FanSignalActions.FETCH_LOVE_SCORE_ERROR,
          payload: { appId },
          error: err,
        })
        );
    };
  },
};
