import React from 'react';
import { InputCheckbox } from 'src/components/molecules';
import { bemPrefix } from 'src/utils';
import { ItemOptions } from './customer_attributes_list';

import './customer_attributes.scss';

const bem = bemPrefix('customer-attributes');

const CustomerAttributesItem: React.FC<{
  item: ItemOptions;
  onToggle: (checked: boolean, itemName: string) => void;
}> = ({ item, onToggle }) => {
  const toggleCheck = (checked: boolean) => {
    onToggle(checked, item.name);
  };

  return (
    <div className={bem('option')}>
      <InputCheckbox
        onChange={toggleCheck}
        checked={item.primary || item.checked}
        disabled={item.disabled}
        label={item.primary ? `${item.name} (Current Customer ID)` : item.name}
      />
    </div>
  );
};

CustomerAttributesItem.displayName = 'CustomerAttributesItem';

export default CustomerAttributesItem;
