import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const Series = ({ data, xScale, yScale, order, customClass }) => {
  const path = d3.line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.y))
    .defined(d => !Number.isNaN(parseInt(d.y, 10)))
    .curve(d3.curveLinear);

  return (
    <path
      d={path(data)}
      className={`line-path-${order} series-${order}-color ${customClass}`}
      strokeWidth={2}
      fill="none"
    />
  );
};

Series.defaultProps = {
  customClass: '',
};

Series.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint is unable to see the use of these props within util functions
      /* eslint-disable react/no-unused-prop-types */
      date: PropTypes.number,
      y: PropTypes.number,
      /* eslint-enable react/no-unused-prop-types */
    })
  ).isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  order: PropTypes.number.isRequired,
  customClass: PropTypes.string,
};

export default Series;
