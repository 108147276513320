import React from 'react';
import Overlay from './Base';

const ServerError = () => (
  <Overlay>
    <h2>An Error or Issue has occurred</h2>
    <h3>Apptentive has been Notified</h3>
  </Overlay>
);
export default ServerError;
