// GET app-store/store/itunes/apps/331177714/ratings-histograms/unique?page_size=200
export default {
  data: [
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ZW',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ZM',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ZA',
      ingest_time: 1591622054390,
      store_observed_time: 1591488000000,
      all_ratings: { 5: 70, 1: 19, 2: 10, 3: 9, 4: 49 },
      all_ratings_average: 4.0,
      all_ratings_count: 157,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '14.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'YE',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'XK',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'VU',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'VN',
      ingest_time: 1602854051095,
      store_observed_time: 1602720000000,
      all_ratings: { 5: 48, 1: 0, 2: 0, 3: 1, 4: 6 },
      all_ratings_average: 5.0,
      all_ratings_count: 55,
      current_ratings: { 5: 48, 1: 0, 2: 0, 3: 1, 4: 6 },
      current_ratings_average: 5.0,
      current_ratings_count: 55,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'VG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'VE',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'VC',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'UZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'UY',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'US',
      ingest_time: 1605618850069,
      store_observed_time: 1605484800000,
      all_ratings: { 5: 2408, 1: 92, 2: 26, 3: 111, 4: 459 },
      all_ratings_average: 4.5,
      all_ratings_count: 3096,
      current_ratings: { 5: 2266, 1: 24, 2: 16, 3: 82, 4: 420 },
      current_ratings_average: 4.5,
      current_ratings_count: 2808,
      version: '15.7.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'UG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'UA',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TW',
      ingest_time: 1596633252020,
      store_observed_time: 1596499200000,
      all_ratings: { 5: 118, 1: 1, 2: 0, 3: 2, 4: 11 },
      all_ratings_average: 5.0,
      all_ratings_count: 132,
      current_ratings: { 5: 118, 1: 1, 2: 0, 3: 2, 4: 11 },
      current_ratings_average: 5.0,
      current_ratings_count: 132,
      version: '14.10.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TT',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TR',
      ingest_time: 1604236453244,
      store_observed_time: 1604102400000,
      all_ratings: { 5: 17, 1: 0, 2: 0, 3: 0, 4: 2 },
      all_ratings_average: 5.0,
      all_ratings_count: 19,
      current_ratings: { 5: 17, 1: 0, 2: 0, 3: 0, 4: 2 },
      current_ratings_average: 5.0,
      current_ratings_count: 19,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TO',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TN',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TJ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TH',
      ingest_time: 1603804459063,
      store_observed_time: 1603670400000,
      all_ratings: { 5: 65, 1: 0, 2: 0, 3: 4, 4: 9 },
      all_ratings_average: 5.0,
      all_ratings_count: 78,
      current_ratings: { 5: 65, 1: 0, 2: 0, 3: 4, 4: 9 },
      current_ratings_average: 5.0,
      current_ratings_count: 78,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TD',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'TC',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SV',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ST',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SR',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SN',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SL',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SK',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SI',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SG',
      ingest_time: 1605532451637,
      store_observed_time: 1605398400000,
      all_ratings: { 5: 21, 1: 0, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 24,
      current_ratings: { 5: 21, 1: 0, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 24,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SE',
      ingest_time: 1597324449153,
      store_observed_time: 1597190400000,
      all_ratings: { 5: 5, 1: 0, 2: 0, 3: 0, 4: 1 },
      all_ratings_average: 5.0,
      all_ratings_count: 6,
      current_ratings: { 5: 5, 1: 0, 2: 0, 3: 0, 4: 1 },
      current_ratings_average: 5.0,
      current_ratings_count: 6,
      version: '15.0.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SC',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SB',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'SA',
      ingest_time: 1598361250793,
      store_observed_time: 1598227200000,
      all_ratings: { 5: 17, 1: 0, 2: 1, 3: 2, 4: 2 },
      all_ratings_average: 4.5,
      all_ratings_count: 22,
      current_ratings: { 5: 17, 1: 0, 2: 1, 3: 2, 4: 2 },
      current_ratings_average: 4.5,
      current_ratings_count: 22,
      version: '15.1.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'RW',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'RU',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'RS',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'RO',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'QA',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PY',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PW',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PT',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PL',
      ingest_time: 1545139220623,
      store_observed_time: 1545004800000,
      all_ratings: { 5: 15, 1: 4, 2: 2, 3: 2, 4: 5 },
      all_ratings_average: 4.0,
      all_ratings_count: 28,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '11.1.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PK',
      ingest_time: 1582204450389,
      store_observed_time: 1582070400000,
      all_ratings: { 5: 10, 1: 0, 2: 0, 3: 0, 4: 2 },
      all_ratings_average: 5.0,
      all_ratings_count: 12,
      current_ratings: { 5: 10, 1: 0, 2: 0, 3: 0, 4: 2 },
      current_ratings_average: 5.0,
      current_ratings_count: 12,
      version: '13.9.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PH',
      ingest_time: 1598361250793,
      store_observed_time: 1598227200000,
      all_ratings: { 5: 20, 1: 0, 2: 0, 3: 1, 4: 2 },
      all_ratings_average: 5.0,
      all_ratings_count: 23,
      current_ratings: { 5: 20, 1: 0, 2: 0, 3: 1, 4: 2 },
      current_ratings_average: 5.0,
      current_ratings_count: 23,
      version: '15.1.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PE',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'PA',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'OM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NZ',
      ingest_time: 1601644450760,
      store_observed_time: 1601510400000,
      all_ratings: { 5: 53, 1: 0, 2: 1, 3: 0, 4: 5 },
      all_ratings_average: 5.0,
      all_ratings_count: 59,
      current_ratings: { 5: 53, 1: 0, 2: 1, 3: 0, 4: 5 },
      current_ratings_average: 5.0,
      current_ratings_count: 59,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NR',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NP',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NO',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NL',
      ingest_time: 1605100452115,
      store_observed_time: 1604966400000,
      all_ratings: { 5: 281, 1: 4, 2: 5, 3: 19, 4: 81 },
      all_ratings_average: 4.5,
      all_ratings_count: 390,
      current_ratings: { 5: 268, 1: 3, 2: 2, 3: 18, 4: 81 },
      current_ratings_average: 4.5,
      current_ratings_count: 372,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NI',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NE',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'NA',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MY',
      ingest_time: 1604841253590,
      store_observed_time: 1604707200000,
      all_ratings: { 5: 41, 1: 0, 2: 0, 3: 0, 4: 10 },
      all_ratings_average: 5.0,
      all_ratings_count: 51,
      current_ratings: { 5: 41, 1: 0, 2: 0, 3: 0, 4: 10 },
      current_ratings_average: 5.0,
      current_ratings_count: 51,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MX',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MW',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MV',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MU',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MT',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MS',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MR',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MO',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MN',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MM',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ML',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MK',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ME',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MD',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'MA',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LY',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LV',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LU',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LT',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LR',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LK',
      ingest_time: 1580822069532,
      store_observed_time: 1580688000000,
      all_ratings: { 5: 8, 1: 0, 2: 0, 3: 0, 4: 1 },
      all_ratings_average: 5.0,
      all_ratings_count: 9,
      current_ratings: { 5: 8, 1: 0, 2: 0, 3: 0, 4: 1 },
      current_ratings_average: 5.0,
      current_ratings_count: 9,
      version: '13.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LC',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LB',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'LA',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KY',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KW',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KR',
      ingest_time: 1605359652565,
      store_observed_time: 1605225600000,
      all_ratings: { 5: 95, 1: 0, 2: 1, 3: 4, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 103,
      current_ratings: { 5: 95, 1: 0, 2: 1, 3: 4, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 103,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KN',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KH',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'KE',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'JP',
      ingest_time: 1605446053218,
      store_observed_time: 1605312000000,
      all_ratings: { 5: 54, 1: 0, 2: 1, 3: 2, 4: 6 },
      all_ratings_average: 5.0,
      all_ratings_count: 63,
      current_ratings: { 5: 54, 1: 0, 2: 1, 3: 2, 4: 6 },
      current_ratings_average: 5.0,
      current_ratings_count: 63,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'JO',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'JM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IT',
      ingest_time: 1584018862784,
      store_observed_time: 1583884800000,
      all_ratings: { 5: 14, 1: 1, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 4.5,
      all_ratings_count: 18,
      current_ratings: { 5: 14, 1: 1, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 4.5,
      current_ratings_count: 18,
      version: '14.0.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IS',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IQ',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IN',
      ingest_time: 1605446053218,
      store_observed_time: 1605312000000,
      all_ratings: { 5: 193, 1: 2, 2: 0, 3: 12, 4: 30 },
      all_ratings_average: 4.5,
      all_ratings_count: 237,
      current_ratings: { 5: 193, 1: 2, 2: 0, 3: 12, 4: 30 },
      current_ratings_average: 4.5,
      current_ratings_count: 237,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IL',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'IE',
      ingest_time: 1589980452011,
      store_observed_time: 1589846400000,
      all_ratings: { 5: 13, 1: 0, 2: 0, 3: 1, 4: 3 },
      all_ratings_average: 4.5,
      all_ratings_count: 17,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '14.5.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ID',
      ingest_time: 1605014053206,
      store_observed_time: 1604880000000,
      all_ratings: { 5: 39, 1: 0, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 5.0,
      all_ratings_count: 42,
      current_ratings: { 5: 39, 1: 0, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 5.0,
      current_ratings_count: 42,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'HU',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'HR',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'HN',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'HK',
      ingest_time: 1604927651281,
      store_observed_time: 1604793600000,
      all_ratings: { 5: 65, 1: 0, 2: 0, 3: 1, 4: 8 },
      all_ratings_average: 5.0,
      all_ratings_count: 74,
      current_ratings: { 5: 65, 1: 0, 2: 0, 3: 1, 4: 8 },
      current_ratings_average: 5.0,
      current_ratings_count: 74,
      version: '15.6.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GY',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GW',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GT',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GR',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GH',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GE',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GD',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GB',
      ingest_time: 1603458851635,
      store_observed_time: 1603324800000,
      all_ratings: { 5: 105, 1: 2, 2: 0, 3: 1, 4: 21 },
      all_ratings_average: 5.0,
      all_ratings_count: 129,
      current_ratings: { 5: 105, 1: 2, 2: 0, 3: 1, 4: 21 },
      current_ratings_average: 5.0,
      current_ratings_count: 129,
      version: '15.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'GA',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'FR',
      ingest_time: 1602422050874,
      store_observed_time: 1602288000000,
      all_ratings: { 5: 28, 1: 0, 2: 0, 3: 2, 4: 6 },
      all_ratings_average: 4.5,
      all_ratings_count: 36,
      current_ratings: { 5: 28, 1: 0, 2: 0, 3: 2, 4: 6 },
      current_ratings_average: 4.5,
      current_ratings_count: 36,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'FM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'FJ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'FI',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'ES',
      ingest_time: 1575983648503,
      store_observed_time: 1575849600000,
      all_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 1 },
      all_ratings_average: 5.0,
      all_ratings_count: 8,
      current_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 1 },
      current_ratings_average: 5.0,
      current_ratings_count: 8,
      version: '13.5.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'EG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'EE',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'EC',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'DZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'DO',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'DM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'DK',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'DE',
      ingest_time: 1604668451351,
      store_observed_time: 1604534400000,
      all_ratings: { 5: 35, 1: 0, 2: 0, 3: 2, 4: 5 },
      all_ratings_average: 5.0,
      all_ratings_count: 42,
      current_ratings: { 5: 35, 1: 0, 2: 0, 3: 2, 4: 5 },
      current_ratings_average: 5.0,
      current_ratings_count: 42,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CY',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CV',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CR',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CO',
      ingest_time: 1595596452079,
      store_observed_time: 1595462400000,
      all_ratings: { 5: 8, 1: 0, 2: 0, 3: 0, 4: 2 },
      all_ratings_average: 5.0,
      all_ratings_count: 10,
      current_ratings: {},
      current_ratings_average: null,
      current_ratings_count: null,
      version: '14.9.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CN',
      ingest_time: 1605618850069,
      store_observed_time: 1605484800000,
      all_ratings: { 5: 813, 1: 0, 2: 0, 3: 6, 4: 34 },
      all_ratings_average: 5.0,
      all_ratings_count: 853,
      current_ratings: { 5: 813, 1: 0, 2: 0, 3: 6, 4: 34 },
      current_ratings_average: 5.0,
      current_ratings_count: 853,
      version: '15.7.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CM',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CL',
      ingest_time: 1601910590215,
      store_observed_time: 1601769600000,
      all_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 5.0,
      all_ratings_count: 7,
      current_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 5.0,
      current_ratings_count: 7,
      version: '15.4.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CI',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CH',
      ingest_time: 1594905266995,
      store_observed_time: 1594771200000,
      all_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 2 },
      all_ratings_average: 5.0,
      all_ratings_count: 9,
      current_ratings: { 5: 7, 1: 0, 2: 0, 3: 0, 4: 2 },
      current_ratings_average: 5.0,
      current_ratings_count: 9,
      version: '14.9.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CD',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'CA',
      ingest_time: 1603026851799,
      store_observed_time: 1602892800000,
      all_ratings: { 5: 45, 1: 0, 2: 0, 3: 0, 4: 8 },
      all_ratings_average: 5.0,
      all_ratings_count: 53,
      current_ratings: { 5: 45, 1: 0, 2: 0, 3: 0, 4: 8 },
      current_ratings_average: 5.0,
      current_ratings_count: 53,
      version: '15.4.5',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BY',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BW',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BT',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BS',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BR',
      ingest_time: 1603977250358,
      store_observed_time: 1603843200000,
      all_ratings: { 5: 36, 1: 0, 2: 0, 3: 0, 4: 1 },
      all_ratings_average: 5.0,
      all_ratings_count: 37,
      current_ratings: { 5: 36, 1: 0, 2: 0, 3: 0, 4: 1 },
      current_ratings_average: 5.0,
      current_ratings_count: 37,
      version: '15.6.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BO',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BN',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BJ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BH',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BF',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BE',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BB',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'BA',
      ingest_time: 1587561276459,
      store_observed_time: 1587427200000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '14.3.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AZ',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AU',
      ingest_time: 1605618850069,
      store_observed_time: 1605484800000,
      all_ratings: { 5: 682069, 1: 4711, 2: 3951, 3: 30861, 4: 132942 },
      all_ratings_average: 4.5,
      all_ratings_count: 854534,
      current_ratings: { 5: 658261, 1: 2942, 2: 3204, 3: 29418, 4: 127738 },
      current_ratings_average: 4.5,
      current_ratings_count: 821563,
      version: '15.7.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AT',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AR',
      ingest_time: 1602681273718,
      store_observed_time: 1602547200000,
      all_ratings: { 5: 8, 1: 0, 2: 0, 3: 0, 4: 3 },
      all_ratings_average: 4.5,
      all_ratings_count: 11,
      current_ratings: { 5: 8, 1: 0, 2: 0, 3: 0, 4: 3 },
      current_ratings_average: 4.5,
      current_ratings_count: 11,
      version: '15.4.1',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AO',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AM',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AL',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AI',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AG',
      ingest_time: 1554988466440,
      store_observed_time: 1554854400000,
      all_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 0.0,
      all_ratings_count: 0,
      current_ratings: { 5: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 0.0,
      current_ratings_count: 0,
      version: '11.8.0',
    },
    {
      store_id: '336693856',
      store: 'itunes',
      region: 'AE',
      ingest_time: 1601644450760,
      store_observed_time: 1601510400000,
      all_ratings: { 5: 9, 1: 0, 2: 0, 3: 0, 4: 0 },
      all_ratings_average: 5.0,
      all_ratings_count: 9,
      current_ratings: { 5: 9, 1: 0, 2: 0, 3: 0, 4: 0 },
      current_ratings_average: 5.0,
      current_ratings_count: 9,
      version: '15.4.0',
    },
  ],
  type: 'RATINGS_HISTOGRAMS_UNIQUE',
  page_size: 200,
  has_more: false,
  min_key: 0,
};
