import React, { useCallback, useMemo, useRef, useLayoutEffect } from 'react';
import { flatten } from 'ramda';
import { bemPrefix } from 'src/utils';
import { InfoText } from 'src/components/molecules/info-text/info-text';
import { useFieldBlurHighlight } from 'src/components/molecules/input';

import './select-option.scss';

export type OptionItem<T = any> = React.OptionHTMLAttributes<T>;

interface GroupOptionItems<T = any> extends React.OptgroupHTMLAttributes<T> {
  options: OptionItem<T>[];
}

export interface SelectOptionProps extends React.SelectHTMLAttributes<any> {
  className?: string;
  label?: string;
  instructions?: string;
  message?: string;
  selectFirstLabel?: string;
  errorText?: string;
  helpText?: string;
  options: OptionItem[] | GroupOptionItems[];
  value?: OptionItem['value'];
  fireInitChange?: boolean;
  onChangeValue?(value: OptionItem['value'], option?: OptionItem): void;
}

const bem = bemPrefix('select-option');
const noop = () => null;
const isGroupedOption = (item: OptionItem | GroupOptionItems): item is GroupOptionItems =>
  Array.isArray((item as any).options);

const getFlattenOptions = (options: SelectOptionProps['options']) =>
  flatten(
    (options as any).map((option: OptionItem | GroupOptionItems) => {
      if (isGroupedOption(option)) {
        return [...option.options];
      }
      return option;
    }) as OptionItem[],
  );

const OptionElement: React.FC<OptionItem> = ({ label, ...item }) => <option {...item}>{label}</option>;

export const SelectOption: React.FC<SelectOptionProps> & { selectorSelect: string } = ({
  className = '',
  id,
  label,
  instructions,
  options,
  selectFirstLabel,
  fireInitChange,
  helpText,
  errorText,
  onChange = noop,
  onChangeValue = noop,
  ...props
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { focused, onBlurEvent } = useFieldBlurHighlight();

  const onSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value) {
        const flatOptions = getFlattenOptions(options);
        const data = flatOptions.find(({ value }) => value === e.target.value);
        onChangeValue(e.target.value, data);
      }
      onChange(e);
    },
    [onChange, onChangeValue, options],
  );

  const items = useMemo(() => {
    if (selectFirstLabel) {
      const flatOptions = getFlattenOptions(options);
      return [
        {
          label: selectFirstLabel,
          selected: !props.value && !flatOptions.find(({ selected }) => selected),
          disabled: true,
        },
        ...options,
      ];
    }
    return options;
  }, [options, props.value, selectFirstLabel]);

  const flatItems = useMemo(() => getFlattenOptions(options), [options]);
  const isInvalid =
    props.required &&
    !flatItems.find((item: OptionItem) => (props.value ? props.value === item.value : item.selected && item.value));

  useLayoutEffect(() => {
    if (!selectRef.current || !fireInitChange) return;
    if (selectRef.current.value && props.value && selectRef.current.value !== String(props.value)) {
      selectRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }, [selectRef.current, props.value, fireInitChange]);

  return (
    <div className={`${bem()} ${className}`}>
      {label && (
        <label className={bem('label')} htmlFor={id}>
          {label}
        </label>
      )}
      {instructions && <div className={bem('instructions')}>{instructions}</div>}
      <select
        className={bem('select', { invalid: !!isInvalid, focused })}
        onChange={onSelectChange}
        onBlur={onBlurEvent}
        {...props}
        ref={selectRef}
      >
        {items.map((item, idx) =>
          isGroupedOption(item) ? (
            <optgroup key={`${String(item.label)}_${idx}`} label={item.label}>
              {item.options.map((option, i) => (
                <OptionElement key={`${String(option.label)}_${idx}_${i}`} {...option} />
              ))}
            </optgroup>
          ) : (
            <OptionElement key={`${String(item.label)}_${idx}`} {...item} />
          ),
        )}
      </select>
      {isInvalid && errorText && <InfoText className={bem('error-text')} text={errorText} />}
      {helpText && <InfoText className={bem('help-text')} text={helpText} />}
    </div>
  );
};

SelectOption.displayName = 'SelectOption';
SelectOption.selectorSelect = `.${bem()}__select`;
