/* eslint-disable arrow-body-style */
import { isEmpty } from 'ramda';
import { GetState, DispatchFunc } from 'src/reducers';
import { PromptsMultiAppsReducerAction, PromptMultiAppsItem, PromptModel } from 'src/reducers/prompts-multi-apps';
import { getCurrentOrgId } from 'src/selectors/current_app';
import { PromptMultiAppsService } from 'src/services';
import { PromptActions } from 'src/actions/prompts-multi-apps/prompts-multi-apps.action-types';
import { getPromptById, getPromptList, getPromptsListIsLoading } from 'src/selectors/prompts-multi-apps.selectors';
import { surveys as surveysMultiApps } from 'src/actions/surveys-multi-apps/survey-multi-apps.actions';
import { AppInteractionData } from 'src/types/core';

type PromptDispatch = DispatchFunc<PromptsMultiAppsReducerAction>;

export const prompts = {
  createPrompt: (prompt: PromptMultiAppsItem) => {
    return (dispatch: PromptDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());
      const promptPayload = PromptModel.convertPromptToPayload(prompt);

      dispatch({ type: PromptActions.CREATE_PROMPT_MULTI_APPS_PENDING, payload: { prompt: promptPayload } });

      return PromptMultiAppsService.save(orgId, promptPayload)
        .then((data) =>
          dispatch({
            type: PromptActions.CREATE_PROMPT_MULTI_APPS_SUCCESS,
            payload: { prompt: PromptModel.convertPayloadToPrompt(data) },
          }),
        )
        .catch((error) => {
          const errorMessage = Array.isArray(error.errors) ? error.errors : 'Failed to create a prompt';
          dispatch({ type: PromptActions.CREATE_PROMPT_MULTI_APPS_ERROR, payload: { prompt, errorMessage }, error });
        });
    };
  },
  savePrompt: (prompt: PromptMultiAppsItem) => {
    return (dispatch: PromptDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());
      const promptPayload = PromptModel.convertPromptToPayload(prompt);

      dispatch({ type: PromptActions.UPDATE_PROMPT_MULTI_APPS_PENDING, payload: { prompt: promptPayload } });

      return PromptMultiAppsService.save(orgId, promptPayload)
        .then((data) =>
          dispatch({
            type: PromptActions.UPDATE_PROMPT_MULTI_APPS_SUCCESS,
            payload: { prompt: PromptModel.convertPayloadToPrompt(data) },
          }),
        )
        .catch((error) => {
          const errorMessage = Array.isArray(error.errors) ? error.errors : 'Failed to update a prompt';
          dispatch({ type: PromptActions.UPDATE_PROMPT_MULTI_APPS_ERROR, payload: { prompt, errorMessage }, error });
        });
    };
  },

  activatePrompt: (id: string, interactionData: AppInteractionData[]) => {
    return (dispatch: PromptDispatch, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(state);
      const prompt = getPromptById(state, id) as PromptMultiAppsItem;

      dispatch({ type: PromptActions.ACTIVATE_PROMPT_MULTI_APPS_PENDING, payload: { promptId: prompt.id } });

      return PromptMultiAppsService.save(orgId, { ...prompt, interactionData })
        .then((data) => {
          dispatch({
            type: PromptActions.ACTIVATE_PROMPT_MULTI_APPS_SUCCESS,
            payload: { prompt: PromptModel.convertPayloadToPrompt(data) },
          });
        })
        .catch((error) =>
          dispatch({ type: PromptActions.ACTIVATE_PROMPT_MULTI_APPS_ERROR, payload: { promptId: prompt.id }, error }),
        );
    };
  },

  bootstrapNotes: () => {
    return (dispatch: PromptDispatch, getState: GetState) => {
      const state = getState();
      const list = getPromptList(state);
      const isLoading = getPromptsListIsLoading(state);

      dispatch({ type: PromptActions.BOOTSTRAP_PROMPT_MULTI_APPSS, payload: {} });

      if (!isLoading && isEmpty(list)) {
        return dispatch(prompts.fetchPrompts());
      }

      return Promise.resolve();
    };
  },

  fetchPrompts: () => {
    return (dispatch: PromptDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      dispatch({ type: PromptActions.GET_PROMPT_MULTI_APPSS_PENDING, payload: {} });

      return PromptMultiAppsService.fetchPrompts(orgId)
        .then((data) =>
          dispatch({
            type: PromptActions.GET_PROMPT_MULTI_APPSS_SUCCESS,
            payload: { prompts: (data || []).map((prompt) => PromptModel.convertPayloadToPrompt(prompt)) },
          }),
        )
        .catch((error) => dispatch({ type: PromptActions.GET_PROMPT_MULTI_APPSS_ERROR, error, payload: {} }));
    };
  },

  fetchPrompt: (promptId: string) => {
    return (dispatch: PromptDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      dispatch({ type: PromptActions.GET_PROMPT_MULTI_APPS_PENDING, payload: { promptId } });

      return PromptMultiAppsService.fetchPrompt(orgId, promptId)
        .then((data) =>
          dispatch({
            type: PromptActions.GET_PROMPT_MULTI_APPS_SUCCESS,
            payload: { prompt: PromptModel.convertPayloadToPrompt(data) },
          }),
        )
        .catch((error) => dispatch({ type: PromptActions.GET_PROMPT_MULTI_APPS_ERROR, error, payload: { promptId } }));
    };
  },

  deletePrompt: (promptId: string) => {
    return (dispatch: PromptDispatch, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(getState());

      dispatch({ type: PromptActions.DELETE_PROMPT_MULTI_APPS_PENDING, payload: { promptId } });

      const removedPrompt = getPromptById(state, promptId) as PromptMultiAppsItem;
      const survIds = PromptModel.getActionsSurveyIds(removedPrompt);

      return PromptMultiAppsService.deletePrompt(orgId, promptId)
        .then((data) => {
          dispatch({
            type: PromptActions.DELETE_PROMPT_MULTI_APPS_SUCCESS,
            payload: { promptId, prompt: PromptModel.convertPayloadToPrompt(data) },
          });
          dispatch(surveysMultiApps.fetchSurveysByIds(survIds));
        })
        .catch((error) =>
          dispatch({ type: PromptActions.DELETE_PROMPT_MULTI_APPS_ERROR, payload: { promptId }, error }),
        );
    };
  },

  clonePromptToApps: (promptId: string, appIds: string[], options: { cloneTargeting: boolean }) => {
    return (dispatch: PromptDispatch) => {
      appIds.forEach((appId) => dispatch(prompts.clonePromptToApp(promptId, appId, options)));
    };
  },

  clonePromptToApp: (promptId: string, appId: string, options: { cloneTargeting: boolean }) => {
    return (dispatch: PromptDispatch) => {
      dispatch({ type: PromptActions.CLONE_PROMPT_MULTI_APPS_TO_APP_PENDING, payload: { appId, promptId } });

      return PromptMultiAppsService.clonePromptToApp(appId, promptId, options)
        .then((data) => {
          dispatch({
            type: PromptActions.CLONE_PROMPT_MULTI_APPS_TO_APP_SUCCESS,
            payload: { appId, promptId, prompt: data },
          });
        })
        .catch((error) =>
          dispatch({ type: PromptActions.CLONE_PROMPT_MULTI_APPS_TO_APP_ERROR, payload: { appId, promptId }, error }),
        );
    };
  },
};
