import { merge, path } from 'ramda';
import { FETCH_ROLLUP_PENDING, FETCH_ROLLUP_SUCCESS, FETCH_ROLLUP_FAILURE } from '../actions/rollup';

const initialState = {
  error: false,
  loading: true,
  data: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ROLLUP_PENDING: {
      return merge(state, {
        error: false,
        loading: true,
      });
    }
    case FETCH_ROLLUP_SUCCESS: {
      return merge(state, {
        error: false,
        loading: false,
        data: path(['payload', 'rollup'], action),
      });
    }
    case FETCH_ROLLUP_FAILURE: {
      return merge(state, {
        error: true,
        loading: false,
      });
    }
    default:
      return state;
  }
};
