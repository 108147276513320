import { API, Rest } from 'src/api';
import { fetchRatingsDemoData, fetchRatingsUniqueDemoData } from 'src/api/demo_util';
import { StarRating } from 'src/reducers/dashboard/star-rating.reducer';
import { isDemoEnv } from 'src/utils/environment';
import { BaseService } from './base-service';

export interface FetchStarRatingsResp {
  data: StarRating[];
  has_more: boolean;
  min_key: number;
  page_size: number;
  type: string;
}

export interface fetchRatingsPayload {
  store: string;
  storeAppId: string;
  start: number;
  end: number;
  isRegionUS: boolean;
  minKey: number;
}

export type fetchStarRatingsApiCall = (payload: fetchRatingsPayload) => Promise<FetchStarRatingsResp>;

export class AppStoreService extends BaseService {
  static fetchStarRatings: fetchStarRatingsApiCall = ({ store, storeAppId, start, end, isRegionUS, minKey }) => {
    if (isDemoEnv()) {
      return fetchRatingsDemoData(2000, store);
    }

    return Rest.httpGet(API.APP_STORE.GET_RATINGS(store, storeAppId, start, end, isRegionUS, minKey), {
      errorTitle: `[API] Error Fetching Star Ratings for store: ${store} with storeAppId ${storeAppId}`,
    }).promise;
  };

  static fetchStarRatingsUnique: fetchStarRatingsApiCall = ({ store, storeAppId, isRegionUS, minKey }) => {
    if (isDemoEnv()) {
      return fetchRatingsUniqueDemoData(200, store);
    }

    return Rest.httpGet(API.APP_STORE.GET_RATINGS_UNIQUE(store, storeAppId, isRegionUS, minKey), {
      errorTitle: `[API] Error Fetching Star Ratings Unique for store: ${store} with storeAppId ${storeAppId}`,
    }).promise;
  };
}
