import { GetState, DispatchFunc } from 'src/reducers';

import { LoveDialogEventTypes, LoveDialogModel, LoveDialogReducerAction, RatingFlowItem, RatingMessageCenter } from 'src/reducers/love-dialog';
import { getCurrentAppId, getCurrentAppPlatform } from 'src/selectors/current_app';
import { getLoveDialogRatingFlow, getIsRatingFlowLoading } from 'src/selectors/love-dialog.selectors';
import { LoveDialogService, EventStateService } from 'src/services';
import { GetEventsResponse } from 'src/services/event-stats-service';
import { EventDistributionData } from 'src/types/core';
import { localDateString } from 'src/utils/date_format';

import { LoveDialogActions } from './love-dialog.action-types';

type LoveDialogDispatch = DispatchFunc<LoveDialogReducerAction>;

const getEventStatsFromResp = (resp: GetEventsResponse):EventDistributionData[] => {
  let eventsDistribution:EventDistributionData[] = resp.data.map(statsObj => {
    const count = statsObj.datums.map(statsPerDay => statsPerDay.y).reduce((summ, currEl) => summ + currEl)
    return { ...statsObj, count }
  })

  let totalCount = eventsDistribution.find(el => el.label === LoveDialogEventTypes.LAUNCH) as EventDistributionData
  const totalCountNum = totalCount?.count || 0
  const yesCountNum = eventsDistribution.find(el => el.label === LoveDialogEventTypes.YES)?.count || 0
  const noCountNum = eventsDistribution.find(el => el.label === LoveDialogEventTypes.NO)?.count || 0

  if (yesCountNum + noCountNum > totalCountNum) {
    totalCount = { ...totalCount, count: yesCountNum + noCountNum }
    eventsDistribution = [...eventsDistribution.filter(el => el.label !== LoveDialogEventTypes.LAUNCH), totalCount]
  }

  return eventsDistribution
}

export const loveDialog = {
  bootstrapFlows: () => {
    return (dispatch: LoveDialogDispatch, getState: GetState) => {
      dispatch({ type: LoveDialogActions.BOOTSTRAP_LD_FLOWS, payload: {} });
      const state = getState();
      const flows = getLoveDialogRatingFlow(state);
      const isLoading = getIsRatingFlowLoading(state);

      if (!isLoading && !flows) {
        return dispatch(loveDialog.fetchFlows());
      }

      return Promise.resolve();
    };
  },

  fetchFlows: () => {
    return (dispatch: LoveDialogDispatch, getState: GetState) => {
      const app = getState();
      const appId = getCurrentAppId(app);
      const platform = getCurrentAppPlatform(app)

      dispatch({ type: LoveDialogActions.GET_LD_FLOWS_PENDING, payload: { appId } });

      return LoveDialogService.fetchFlows(appId)
        .then((data) => {
          dispatch({
            type: LoveDialogActions.GET_LD_FLOWS_SUCCESS,
            payload: { flows: LoveDialogModel.handleTargetingOptions(data, platform) },
          });
        })
        .catch((error) => dispatch({ type: LoveDialogActions.GET_LD_FLOWS_ERROR, payload: { appId }, error }));
    };
  },

  activate: (flowId: RatingFlowItem['id'], active: boolean) => {
    return (dispatch: LoveDialogDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());
      dispatch({ type: LoveDialogActions.ACTIVATE_LOVE_DIALOG_PENDING, payload: { flowId, appId, active } });

      return LoveDialogService.activate(appId, flowId, active)
        .then((data) => {
          dispatch({ type: LoveDialogActions.ACTIVATE_LOVE_DIALOG_SUCCESS, payload: { flow: data, appId } });
        })
        .catch((error) => {
          dispatch({ type: LoveDialogActions.ACTIVATE_LOVE_DIALOG_ERROR, payload: { flowId, appId, active }, error });
        });
    };
  },

  saveLoveDialog: (flow: RatingFlowItem) => {
    return (dispatch: LoveDialogDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: LoveDialogActions.SAVE_LOVE_DIALOG_PENDING, payload: { flow, appId } });

      return LoveDialogService.saveLoveDialog(appId, flow)
        .then((data) => {
          dispatch({ type: LoveDialogActions.SAVE_LOVE_DIALOG_SUCCESS, payload: { flow: data, appId } });
        })
        .catch((error) => dispatch({ type: LoveDialogActions.SAVE_LOVE_DIALOG_ERROR, payload: { flow, appId }, error })
        );
    };
  },

  saveMessageCenter: (flowId: string, messageCenter: RatingMessageCenter) => {
    return (dispatch: LoveDialogDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: LoveDialogActions.SAVE_MESSAGE_CENTER_PENDING, payload: { flowId, appId } });

      return LoveDialogService.saveMessageCenter(appId, flowId, messageCenter)
        .then((data) => dispatch({ type: LoveDialogActions.SAVE_MESSAGE_CENTER_SUCCESS, payload: { flow: data, appId } }))
        .catch((error) => dispatch({ type: LoveDialogActions.SAVE_MESSAGE_CENTER_ERROR, payload: { appId }, error }))
    }
  },

  fetchStats: (interaction_id: string, start: number, end: number, isPrevPeriod = false) => {
    return (dispatch: LoveDialogDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({
        type: isPrevPeriod ? LoveDialogActions.FETCH_PREV_STATS_PENDING : LoveDialogActions.FETCH_STATS_PENDING,
        payload: { interaction_id },
      });

      const getEventApiCall = interaction_id === 'primary'
        ? EventStateService.getLDEvents(appId, localDateString(start), localDateString(end), isPrevPeriod)
        : EventStateService.getEventsByIntId(appId, interaction_id, localDateString(start), localDateString(end))

      return getEventApiCall
        .then((resp) => {
          dispatch({
            type: isPrevPeriod ? LoveDialogActions.FETCH_PREV_STATS_SUCCESS : LoveDialogActions.FETCH_STATS_SUCCESS,
            payload: { interaction_id, stats: getEventStatsFromResp(resp) },
          });
        })
        .catch((error) => dispatch({
          type: isPrevPeriod ? LoveDialogActions.FETCH_PREV_STATS_ERROR : LoveDialogActions.FETCH_STATS_ERROR,
          payload: { interaction_id },
          error,
        }));
    }
  },
};
