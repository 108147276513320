/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AttributesDropdownContainer } from 'src/settings/customer_id/attributes-dropdown';
import { Modal, Button } from 'src/components/molecules';
import { bemPrefix } from 'src/utils';
import { StoreActions } from 'src/store';
import { AppDataActions } from 'src/actions/app-data';

import { ContactForm } from './customer-id-dialog-contact-form';
import { CustomerIdActionsContainer, CustomerIdActionsProps } from './customer-id-dialog-actions';

import './customer-id-setup-dialog.scss';

export interface CustomerIdSetupDialogProps {
  showModal: boolean;
  shiftedCounts: CustomerIdActionsProps['shiftedCounts'];
  onCloseModal(): void;
  isExport?: boolean;
  isReporter?: boolean;
}

const bem = bemPrefix('customer-id-setup-dialog');

enum View {
  Initial = '',
  Contact = 'contact',
  Actions = 'actions',
  Message = 'message',
  Export = 'export',
}

const getViewTitle = (view: View, isfanSignalCounts?: boolean) => {
  switch (view) {
    case View.Contact:
      return 'Contact Us';
    case View.Actions:
      return isfanSignalCounts ? 'Take action on your data' : null;
    case View.Message:
      return 'Thank you';
    case View.Export:
      return 'Enable Fan Signal Exports';
    default:
      return 'Customer ID Setup';
  }
};

const SelectCIDFrom: React.FC<{ onContact(): void }> = ({ onContact }) => {
  return (
    <>
      <div className={bem('text-desc')}>
        To better use Fan Signals, select which unique attribute will define customer profiles in our system.
      </div>
      <AttributesDropdownContainer />
      <div className={bem('footer-links')}>
        <Link className="link" to="/apps/current/settings/customer-id">
          Why is this important?
        </Link>
        {ApptentiveSDK && (
          <span className="link" onClick={onContact}>
            Don&apos;t see your ID?
          </span>
        )}
      </div>
    </>
  );
};
SelectCIDFrom.displayName = 'SelectCIDFrom';

const SelectCIDExportFrom: React.FC<{ onContact(): void, isReporter?: boolean }> = ({ onContact, isReporter }) => (
  <>
    <div className={bem('text-desc')}>
      {`Exports first requires setup of a Customer ID${isReporter ? ' by a collaborator or admin.' : `. Select a person custom data from your organization’s supplied
      list now, it can be updated later in Settings.`}`}
    </div>
    <AttributesDropdownContainer />
    <div className={bem('footer-links')}>
      <Link className="link" to="/apps/current/settings/customer-id">
        Why is this important?
      </Link>
      {ApptentiveSDK && (
        <span className="link" onClick={onContact}>
          Don&apos;t see your ID?
        </span>
      )}
    </div>
  </>
);

SelectCIDExportFrom.displayName = 'SelectCIDExportFrom';

const ThankYouModal: React.FC<{ onCloseModal(): void }> = ({ onCloseModal }) => {
  return (
    <>
      <div className={bem('desc')}>
        Your feedback is our top priority. We`ll be in touch soon to assist with your customer ID conserns
      </div>
      <Button color="primary" onClick={onCloseModal} className={bem('contact-submit')}>
        Close
      </Button>
    </>
  );
};

export const CustomerIdSetupDialog: React.FC<CustomerIdSetupDialogProps> = ({
  showModal,
  shiftedCounts,
  onCloseModal,
  isExport = false,
  isReporter,
}) => {
  const [view, setView] = useState<View>(isExport ? View.Export : View.Initial);
  const onContact = useCallback(() => setView(View.Contact), []);

  useEffect(() => setView(isExport ? View.Export : View.Initial), [showModal]);

  const onSubmitContact = useCallback(() => setView(View.Message), []);

  useEffect(() => {
    const store = StoreActions.getInstance();
    const sub = store.on(AppDataActions.SET_APP_PRIMARY_CID_KEY_SUCCESS, () => {
      setTimeout(() => setView(View.Actions), 1000);
    });
    return () => sub.dispose();
  }, []);

  useEffect(() => {
    let timeId: number;
    if (view === View.Message) {
      timeId = window.setTimeout(onCloseModal, 3000);
    }
    return () => clearTimeout(timeId);
  }, [view]);

  if (view === View.Actions && !shiftedCounts) {
    return null;
  }

  return (
    <>
      <Modal
        className={bem()}
        isOpen={showModal}
        title={getViewTitle(view, !!shiftedCounts)}
        onCancel={onCloseModal}
        isCloseOnEsc
      >
        {view === View.Initial && <SelectCIDFrom onContact={onContact} />}
        {view === View.Export && <SelectCIDExportFrom onContact={onContact} isReporter={isReporter} />}
        {view === View.Contact && <ContactForm className={bem()} onSubmit={onSubmitContact} />}
        {view === View.Actions && (
          <CustomerIdActionsContainer className={bem()} shiftedCounts={shiftedCounts} onAction={onCloseModal} />
        )}
        {view === View.Message && <ThankYouModal onCloseModal={onCloseModal} />}
      </Modal>
    </>
  );
};

CustomerIdSetupDialog.displayName = 'CustomerIdSetupDialog';
