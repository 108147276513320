import {
  reduce,
  min,
  max,
  compose,
  flatten,
  pluck,
  curry,
  reduceBy,
  prop,
  map,
  zipObj,
  toPairs,
  lensProp,
  over,
} from 'ramda';
import {
  isSameDay,
} from 'date-fns';

export { default as rangeBoundsProp } from './rangeBoundsProp';

/**
 * Flattens all data into a single series
 */
export const getAllData = compose(
  flatten,
  pluck('data'),
);

/**
 * Sum all values by a given keyField.
 *
 * @param {string} keyField The field you wish to group and sum your values on
 * @param {string} valueField The field you associate with a value and want to sum together
 * @param {function} valueParseFunc The function to apply to parse the keyField
 * @returns {function(Object<string, number>[]): Object<string, number[]>[]} A reduced set of data
 * points with all values associated with a matching key summed
 * into an array containing the sum of positive numbers, and sum of negative numbers
 * @example
 *   // returns [{ x: 1, y: [20, -10] }, { x: 2, y: [30] }]
 *   stackBy('x', 'y')([{ x: 1, y: 20 }, { x: 2, y: 30 }, { x: 1, y: -10 }])
 */
const stackBy = (keyField, valueField, valueParseFunc = Number.parseInt) => {
  const sumValueField = (agg, data) => {
    const dataPoint = prop(valueField, data);
    const aggKey = dataPoint < 0 ? 'neg' : 'pos';
    return {
      ...agg,
      [aggKey]: agg[aggKey] + dataPoint,
    };
  };
  const convertToFlattenable = obj => [obj.pos, obj.neg];
  const convertToSeries = compose(
    map(over(lensProp(keyField), valueParseFunc)),
    map(zipObj([keyField, valueField])),
    toPairs,
  );
  return compose(
    convertToSeries,
    map(convertToFlattenable),
    reduceBy(sumValueField, { neg: 0, pos: 0 }, prop(keyField)),
  );
};

/**
 * Parses value field as an epoch date
 */
export const stackByDate = (keyField, valueField) => {
  const valueParseFunc = x => Number(new Date(x));
  return stackBy(keyField, valueField, valueParseFunc);
};

/**
 * Parses value field as an integer
 */
export const stackByInteger = (keyField, valueField) => {
  const valueParseFunc = x => Number.parseInt(x, 10);
  return stackBy(keyField, valueField, valueParseFunc);
};

/**
 * Finds the minimum value of an array of values but bounded by an inner (always at least as low as)
 * and an outer (cannot be lower than).
 * @param {number} inner the result will always be at least this low
 * @param {number} outer the result will never be lower than this
 * @param {number[]} values finds the minimum value of this array
 * @returns {number} the lowest value from the values OR bound by the inner or outer bounds
 *
 * @example
 *    // returns 1 (minimum of values, within bounds)
 *    minBoundBy(10, -10, [1,2,3,4])
 * @example
 *    // returns 0 (inner bound)
 *    minBoundBy(0, -10, [1,2,3,4])
 * @example
 *    // returns -10 (outer bound)
 *    minBoundBy(0, -10, [1,2,3,4,-30])
 */
export const minBoundBy = curry((inner, outer, values) => {
  if (inner < outer) throw new Error('inner bound must be greater than outer bound');
  return compose(
    max(outer),
    reduce(min, inner),
  )(values);
});

/**
 * Finds the maximum value of an array of values but bounded by an inner (always at least)
 * and an outer (cannot be higher than).
 * @param {number} inner the result will always be at least this
 * @param {number} outer the result will never be higher than this
 * @param {number[]} values finds the maximum value of this array
 * @returns {number} the highest value from the values OR bound by the inner or outer bounds
 *
 * @example
 *    // returns 4 (max of values, within bounds)
 *    maxBoundBy(0, 10, [1,2,3,4])
 * @example
 *    // returns 5 (inner bound)
 *    minBoundBy(5, 10, [1,2,3,4])
 * @example
 *    // returns 10 (outer bound)
 *    minBoundBy(0, 10, [1,2,3,4,30])
 */
export const maxBoundBy = curry((inner, outer, values) => {
  if (inner > outer) throw new Error('inner bound must be less than outer bound');
  return compose(
    min(outer),
    reduce(max, inner),
  )(values);
});

/**
 * Sorts date annotations by startDate, adds single day events on top of everything else
 * @param {object[]} dateAnnotations An array of date annotation objects
 */

export const sortDateAnnotations = (dateAnnotations) => {
  const dayEvents = dateAnnotations.filter(ann => isSameDay(ann.startDate, ann.endDate));
  const multipleDayEvents = dateAnnotations.filter(ann => !isSameDay(ann.startDate, ann.endDate));
  multipleDayEvents.sort((a, b) => a.startDate > b.startDate);
  return multipleDayEvents.concat(dayEvents);
};
