import { getAppParams, IAppParams } from '../utils/app_params';

// selectors
import { getCurrentUserId } from '../../selectors/user';
import { getCurrentOrgId } from '../../selectors/current_app';
import { GetState } from '../../reducers';
import { TagBaseParams } from '../../services/tags-service';

export const getTagParams = <T = Partial<TagBaseParams> & Pick<TagBaseParams, 'tagName'>>(
  baseParams: T,
  getState: GetState
): T & IAppParams & { userId: string; orgId: string } => {
  const state = getState();
  const userId = getCurrentUserId(state);
  const orgId = getCurrentOrgId(state);
  const appParams = getAppParams(getState);
  return {
    userId,
    orgId,
    ...appParams,
    ...baseParams,
  };
};
