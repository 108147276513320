import { assoc } from 'ramda';

import { NOTIFICATION_BANNER_CLOSE } from '../actions/notification_banner';

const initialState = {
  open: false,
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case NOTIFICATION_BANNER_CLOSE:
      return assoc(action.payload, false, state);
    default:
      return state;
  }
};
