import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import classnames from 'classnames';

import HoverInfo from '../molecules/hover-info';

class HoverOverlay extends Component {
  static propTypes = {
    className: PropTypes.string,
    closestPoints: PropTypes.func.isRequired,
    coordinates: PropTypes.func.isRequired,
    coordsToPoint: PropTypes.func.isRequired,
    detailFormat: PropTypes.func,
    displayFormat: PropTypes.func,
    forFormat: PropTypes.func,
    histogram: PropTypes.bool,
    mouseCoords: PropTypes.func.isRequired,
    renderSummary: PropTypes.func,
    summaryFormat: PropTypes.func,
    title: PropTypes.string,
    coloredTitle: PropTypes.bool,
  };

  static defaultProps = {
    detailFormat: v => v.toString(),
    displayFormat: v => v.toString(),
    forFormat: v => v,
    histogram: false,
    summaryFormat: v => `${v}`,
    title: '',
  };

  state = {
    tooltipXVal: null,
    xPos: 0,
    yPos: 0,
    captureAreaWidth: 0,
    captureAreaHeight: 0,
    leftOrRight: 'right',
    topOrBottom: 'bottom',
    tooltipData: this.props.closestPoints(0, 0).map(point => {
      const value = typeof point.value !== 'undefined' ? point.value : point.y;
      return {
        ...point,
        value,
        display: this.props.displayFormat(value),
        detail: this.props.detailFormat(value),
      };
    }),
    hasData: false,
  };

  updateTooltip = capture => {
    const { coordsToPoint, coordinates, mouseCoords, displayFormat, detailFormat } = this.props;

    const closestTo = (origin) => (valueA, valueB) => {
      if (Math.abs(origin.valueOf() - (valueA || 0).valueOf()) > Math.abs(origin.valueOf() - (valueB || 0).valueOf())) {
        return valueB;
      }
      return valueA;
    };

    // TODO: Remove reliance on d3.select to make this easier to test via
    // Enzyme's simulated events which don't seem to trigger the below.
    let debouncedUpdate;
    d3.select(capture).on('mousemove', () => {
      if (debouncedUpdate) clearTimeout(debouncedUpdate);
      // This can't be inside debounced timeout since it will loose the context
      const mouseCoordinates = mouseCoords();

      debouncedUpdate = setTimeout(() => {
        const [mouseXValue, mouseYValue] = coordsToPoint(...mouseCoordinates);
        const points = this.props.closestPoints(mouseXValue);
        const capturePosition = capture.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        const dynamicTitle = (points[0] && points[0].title) || '';

        if (typeof mouseXValue === 'undefined' || typeof mouseYValue === 'undefined') return;
        const closestYPos = points.map((d) => d.y).reduce(closestTo(mouseYValue), 0);
        const closestXPos = points.map(d => d.x).reduce(closestTo(mouseXValue), 0);

        const [xPos, yPos] = coordinates(closestXPos, closestYPos);
        if (typeof closestXPos === 'undefined' || typeof closestYPos === 'undefined') return;

        const absoluteMouseX = capturePosition.left + xPos;
        const absoluteMouseY = capturePosition.top + yPos;
        const topOrBottom = absoluteMouseY < (viewportHeight / 2) ? 'bottom' : 'top';
        const leftOrRight = absoluteMouseX < (viewportWidth / 2) ? 'right' : 'left';
        this.setState({
          hasData: true,
          tooltipXVal: closestXPos,
          xPos,
          yPos: absoluteMouseY >= 0 ? yPos : yPos + Math.abs(absoluteMouseY),
          leftOrRight,
          topOrBottom,
          dynamicTitle,
          captureAreaWidth: capturePosition.width,
          captureAreaHeight: capturePosition.height,
          tooltipData: points.map(point => {
            const value = typeof point.value !== 'undefined' ? point.value : point.y;
            return {
              ...point,
              value,
              display: displayFormat(value),
              detail: point.detail !== 'undefined' ? point.detail : detailFormat(value),
            };
          }),
        });
      }, 100);
    });
  };

  render() {
    const { forFormat, summaryFormat } = this.props;
    const { xPos, yPos } = this.state;

    const width = this.state.captureAreaWidth;
    const height = this.state.captureAreaHeight;
    const distanceFromTop = yPos;
    const distanceFromLeftOfGraph = xPos;
    const distanceFromRightofGraph = width - xPos;
    const leftPos = distanceFromLeftOfGraph;
    const rightPos = distanceFromRightofGraph;

    const tooltipStyles = {
      left: this.state.leftOrRight === 'right' ? `${leftPos}px` : null,
      right: this.state.leftOrRight === 'left' ? `${rightPos}px` : null,
      top: this.state.topOrBottom === 'bottom' ? `${distanceFromTop}px` : null,
      bottom: this.state.topOrBottom === 'top' ? `${height - distanceFromTop}px` : null,
    };

    const tooltipClasses = classnames({
      tooltip: true,
      data: this.state.hasData,
    });

    return (
      <div
        className="mouse-capture"
        pointerEvents="all"
        ref={(capture) => this.updateTooltip(capture)}
      >
        <div
          className={tooltipClasses}
          style={tooltipStyles}
          ref={(tooltip) => { this.tooltipNode = tooltip; }}
        >
          <HoverInfo
            coloredTitle={this.props.coloredTitle}
            className={this.props.className}
            tooltipXVal={this.state.tooltipXVal}
            infoOn={forFormat(this.state.tooltipXVal)}
            infoFor={this.state.dynamicTitle || this.props.title}
            infoSummary={summaryFormat(this.state.tooltipData)}
            series={this.state.tooltipData}
            histogram={this.props.histogram}
            renderSummary={this.props.renderSummary}
          />
        </div>
      </div>
    );
  }
}

export default HoverOverlay;
