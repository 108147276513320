import { Rest, API } from 'src/api';
import { SentimentData } from 'src/reducers/insights/insights.types'
import { BaseService } from './base-service';

export class ElasticSearchService extends BaseService {
  static updateSentiment(payload: SentimentData) {
    return Rest.httpPut(API.INSIGHTS.UPDATE_SENTIMENT, {
      body: payload,
      errorTitle: '',
    }).promise;
  }
}
