import { head, pickBy, values } from 'ramda';
import { BasicIntegration } from 'src/types/core';

export const integrationDefaults: Record<string, BasicIntegration> = {
  aws_sns: {
    active: false,
    type: 'aws_sns',
    access_key_id: null,
    secret_access_key: null,
    app_arn: null,
    min_period: null,
    delay: null,
    sound_enabled: false,
    sound_value: 'default',
    monitor_hook: false,
    hook_notice_recipients: null,
  },
  apptentive_push: {
    active: false,
    type: 'apptentive_push',
    gcm_api_key: null, // write-only
    apn_bundle: null, // write-only
    sandbox_apn_bundle: null, // write-only
    apn_pass: null, // write-only
    sandbox_apn_pass: null, // write-only
    provider: null, // read-only, "GCM" or "APNS"
    app_arn: null,
    sandbox_app_arn: null,
    sound_enabled: false,
    sound_value: 'default',
    monitor_hook: false,
    hook_notice_recipients: null,
  },
  urban_airship: {
    active: false,
    type: 'urban_airship',
    application_key: null,
    application_secret: null,
    master_secret: null,
    min_period: null,
    delay: null,
    badge_enabled: false,
    badge_value: 'auto',
    sound_enabled: false,
    sound_value: 'default',
  },
  slack: {
    active: false,
    type: 'slack',
    webhook_url: null,
    channel: null,
    username: null,
  },
  user_voice: {
    active: false,
    type: 'user_voice',
    api_key: null,
    api_secret: null,
    subdomain: null,
    sso_key: null,
    ticket_subject: 'App Feedback',
    custom_fields: {},
    delay: 0, // NOTE: For non Notifcation integrations this is always set to 0
  },
  zendesk: {
    active: false,
    type: 'zendesk',
    subdomain: null,
    username: null,
    auth_token: null,
    fallback_username: 'App User',
    ticket_subject: 'App Feedback',
    monitor_hook: false,
    hook_notice_recipients: null,
    labels: [],
    custom_fields: {},
    delay: 0,
    add_conv_link: false,
  },
  email_notification: {
    active: false,
    type: 'email_notification',
    min_period: null,
    delay: null,
  },
  parse: {
    active: false,
    type: 'parse',
    application_id: null,
    rest_api_key: null,
    badge_enabled: false,
    badge_value: 'auto',
    sound_enabled: false,
    sound_value: 'default',
  },
  salesforce: {
    active: false,
    type: 'salesforce',
    instance_url: null,
    username: null,
    password: null,
    custom_fields: {},
    labels: null,
    hook_notice_recipients: null,
    monitor_hook: false,
  },
  apptimize: {
    active: false,
    type: 'apptimize',
  },
  fs_webhook: {
    active: false,
    type: 'fs_webhook',
    id: '',
    webhook_url: '',
    fs_state: [],
    isLaunched: false,
  },
  right_now: {
    active: false,
    type: 'right_now',
    username: null,
    password: null,
    wsdl_url: null,
    queue_id: null,
    channel_id: null,
    incident_subject: '',
  },
};

export const isIntegrationType = (type: string) => (value: any, _key: any) => value.type === type;
export const getIntegration = (type: string, integrations: Record<string, BasicIntegration>) => head(values(pickBy(isIntegrationType(type), integrations) as any)) || integrationDefaults[type];
export const isIntegrationActive = (integration: BasicIntegration) => integration && integration.active;
