import React from 'react';
import { TablePagination } from '../table-pagination';

interface TableListPaginationProps {
  itemsCount: number;
  itemsLabel?: string;
  visibleItemCount: number;
  defaultCount: number;
  onMore(): void;
  onLess(): void;
}

export const TableListPagination: React.FC<TableListPaginationProps> = ({
  defaultCount,
  itemsCount,
  itemsLabel,
  visibleItemCount,
  onMore,
  onLess,
}) => (
  <TablePagination
    itemsLabel={itemsLabel}
    showLessCondition={itemsCount > defaultCount && visibleItemCount > defaultCount}
    showLessHandler={onLess}
    showMoreCondition={itemsCount > visibleItemCount}
    showMoreHandler={onMore}
  />
);
