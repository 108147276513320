import { AggsSentiment, AggsRating, AggsType } from './insights.enum';
import { ICurrentSearch, IInsight, PhrasesData, SavedInsight, AggData } from './insights.types';

export interface InsightsState {
  currentSearch: ICurrentSearch;

  // aggs
  aggs: AggData;
  fetchAggsPending: boolean;
  fetchAggsError: boolean;

  // data
  data: IInsight[];
  fetchDataPending: boolean;
  fetchDataError: boolean;
  dataPagination: {
    page: number;
    per: number;
    total: number;
    $pristine: boolean;
  };

  // phrases
  phrases: PhrasesData;
  fetchPhrasesPending: boolean;
  fetchPhrasesError: boolean;
  updatePhrasePending: boolean;
  updatePhraseError: boolean;

  exportPending: boolean;

  savedInsights: SavedInsight[];
  savedInsightsPending: boolean;
}

export const initialState: InsightsState = {
  currentSearch: {
    regions: [],
    types: [AggsType.REVIEW, AggsType.MESSAGE, AggsType.SURVEY],
    sentiment: AggsSentiment.ALL,
    rating: AggsRating.ALL,
    phrases: [],
    tags: [],
    fs_state: [],
    text: '',
    survey_title: [],
  },

  // aggs
  aggs: {
    histogram: [],
    ratingDistribution: [],

    // sentiment
    sentimentDistribution: [],
    fetchSentimentDistributionPending: false,
    fetchSentimentDistributionError: false,

    // fan signals
    fanSignalsDistribution: [],
    fetchFanSignalsDistributionPending: false,
    fetchFanSignalsDistributionError: false,

    // survey names
    surveyNamesDistribution: [],
    fetchSurveyNamesDistributionPending: false,
    fetchSurveyNamesDistributionError: false,

    // types
    typesDistribution: {
      review: 0,
      message: 0,
      survey: 0,
    },
    fetchTypesDistributionPending: false,
    fetchTypesDistributionError: false,

    // regions
    regions: [],
    fetchRegionsPending: false,
    fetchRegionsError: false,

    regionsDistribution: {},
    fetchRegionsDistributionPending: false,
    fetchRegionsDistributionError: false,
  },
  fetchAggsPending: false,
  fetchAggsError: false,

  // data
  data: [],
  dataPagination: {
    page: 0,
    per: 0,
    total: 0,
    $pristine: true,
  },
  fetchDataPending: false,
  fetchDataError: false,

  // phrases
  phrases: {
    pinned: [],
    default: [],
    muted: [],
  },
  fetchPhrasesPending: false,
  fetchPhrasesError: false,
  updatePhrasePending: false,
  updatePhraseError: false,

  exportPending: false,

  savedInsights: [],
  savedInsightsPending: false,
};
