import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';
import { DialogButtons, DialogButtonsProps } from '../dialog/dialog-buttons';
import { Icon } from '../icons';

import './modal.scss';

export interface ModalProps extends Pick<ReactModal.Props, 'onAfterOpen' | 'onAfterClose'>, DialogButtonsProps {
  title?: React.ReactNode;
  isOpen: ReactModal.Props['isOpen'];
  className?: string;
  isCloseOnEsc?: boolean;
  hasCloseIcon?: boolean;
  children?: React.ReactNode;
}

const CLASS_NAME = 'app-modal-dialog';
const bem = bemPrefix(CLASS_NAME);

// README: http://reactcommunity.org/react-modal/accessibility/#app-element
if (document.getElementById('dashboard-root')) {
  ReactModal.setAppElement('#dashboard-root');
}

export const Modal: React.FC<ModalProps> & { className: string } = ({
  title,
  isOpen,
  extraActionLabel,
  acceptLabel,
  cancelLabel,
  onSubmit,
  onCancel,
  onExtraAction,
  onAfterOpen,
  onAfterClose,
  children,
  className,
  isCloseOnEsc = false,
  hasCloseIcon = true,
  submitColor,
  submitDisabled,
  gtmId = GtmID.None,
}) => (
  <ReactModal
    className={classNames(bem(), className)}
    portalClassName={bem('portal')}
    bodyOpenClassName={bem('body-open')}
    htmlOpenClassName={bem('html-open')}
    overlayClassName={bem('overlay')}
    onAfterOpen={onAfterOpen}
    onAfterClose={onAfterClose}
    isOpen={isOpen}
    onRequestClose={onCancel}
    shouldFocusAfterRender
    shouldCloseOnOverlayClick
    shouldCloseOnEsc={isCloseOnEsc}
    appElement={document.getElementById('dashboard-root') || undefined}
  >
    {title && (
      <div className={bem('title')}>
        <div>{title}</div>
        {hasCloseIcon && <Icon className={bem('close-icon')} name="close" onClick={onCancel} />}
      </div>
    )}
    {children && <div className={bem('body')}>{children}</div>}
    {onSubmit && (
      <DialogButtons
        gtmId={gtmId}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onExtraAction={onExtraAction}
        acceptLabel={acceptLabel}
        cancelLabel={cancelLabel}
        extraActionLabel={extraActionLabel}
        submitColor={submitColor}
        submitDisabled={submitDisabled}
      />
    )}
  </ReactModal>
);

Modal.defaultProps = {
  className: '',
  isOpen: false,
  onAfterOpen: () => null,
  onAfterClose: () => null,
};
Modal.displayName = 'Modal';
Modal.className = CLASS_NAME;

export function useModalProps(opened: boolean = false) {
  const [isOpenModal, setIsOpen] = useState(opened);
  const showModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const toggleModal = useCallback(() => setIsOpen(!isOpenModal), [isOpenModal]);

  return { isOpenModal, toggleModal, showModal, closeModal };
}
