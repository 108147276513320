import React, { useCallback } from 'react';

import './customer-care-button.scss';

export const CustomerCareButton: React.FC<{ text?: string }> = ({ text = "Customer Success Team" }) => {
  const showMessageCenter = useCallback(() => {
    if (ApptentiveSDK) {
      ApptentiveSDK.showMessageCenter();
    }
  }, []);
  return (
    <span className="customer-care-button" onClick={showMessageCenter}>
      {text}
    </span>
  );
};
