import { Action } from 'redux';
import { InitiatorActions } from 'src/actions/initiators/initiators.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import { GetEventsResponse } from 'src/services/event-stats-service';

import { initialState, InitiatorsState } from './initiators.state';
import { InitiatorItem } from './initiators.types';

export type InitiatorsReducerAction = Action<
  InitiatorActions | typeof UPDATE_CURRENT_APP_PENDING | typeof UPDATE_CURRENT_APP_SUCCESS
> & {
  payload: {
    initiator?: InitiatorItem;
    initiators?: InitiatorItem[];
    initiatorId?: string;
    initiatorIds?: string[];
    appId?: string;
    errorMessage?: string;
    eventsTimeSeries?: GetEventsResponse;
  };
  error?: Error;
};

export const initiatorsReducer = (
  state: InitiatorsState = initialState,
  action: InitiatorsReducerAction = {} as InitiatorsReducerAction,
): InitiatorsState => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_PENDING:
    case UPDATE_CURRENT_APP_SUCCESS: {
      return {
        ...state,
        ...initialState,
      };
    }
    case InitiatorActions.GET_INITIATORS_PENDING: {
      return { ...state, loading: true };
    }
    case InitiatorActions.GET_INITIATORS_SUCCESS: {
      const { initiators = [] } = action.payload;
      return {
        ...state,
        initiators,
        loading: false,
      };
    }

    case InitiatorActions.GET_INITIATORS_ERROR: {
      return { ...state, loading: false };
    }

    case InitiatorActions.GET_INITIATORS_BY_IDS_PENDING: {
      return {
        ...state,
        loadingIds: [...state.loadingIds, ...(action.payload.initiatorIds || [])],
      };
    }
    case InitiatorActions.GET_INITIATORS_BY_IDS_SUCCESS: {
      const updatedInitiators = new Map(
        (action.payload.initiators || []).map((initiator) => [initiator.id, initiator]),
      );

      // Replace any existing initiators with freshly loaded ones.
      const initiators = state.initiators.map((initiator) => {
        const updatedInitiator = updatedInitiators.get(initiator.id);

        if (updatedInitiator) {
          updatedInitiators.delete(initiator.id);
          return updatedInitiator;
        }

        return initiator;
      });

      // Add any initiators that didn't already exist in the state.
      for (const updatedInitiator of updatedInitiators.values()) {
        initiators.push(updatedInitiator);
      }

      return {
        ...state,
        initiators,
        loadingIds: [...state.loadingIds.filter((id) => !updatedInitiators.has(id))],
      };
    }
    case InitiatorActions.GET_INITIATORS_BY_IDS_ERROR: {
      const initiatorIds = new Set(action.payload.initiatorIds);
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => !initiatorIds.has(id)),
      };
    }

    case InitiatorActions.ACTIVATE_INITIATOR_PENDING: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        activatingIds: [...state.activatingIds, initiatorId],
      };
    }

    case InitiatorActions.GET_INITIATOR_PENDING: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        loadingIds: [...state.loadingIds, initiatorId],
      };
    }

    case InitiatorActions.GET_INITIATOR_SUCCESS:
    case InitiatorActions.UPDATE_INITIATOR_SUCCESS:
    case InitiatorActions.ACTIVATE_INITIATOR_SUCCESS: {
      const { initiator } = action.payload as { initiator: InitiatorItem };
      const data = [...state.initiators];
      const idx = data.findIndex((item) => item.id === initiator.id);
      if (idx >= 0) {
        data.splice(idx, 1, initiator);
      } else {
        data.unshift(initiator);
      }
      return {
        ...state,
        initiators: data,
        loadingIds: state.loadingIds.filter((id) => id !== initiator.id),
        activatingIds: state.activatingIds.filter((id) => id !== initiator.id),
      };
    }

    case InitiatorActions.GET_INITIATOR_ERROR: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => id !== initiatorId),
        activatingIds: state.activatingIds.filter((id) => id !== initiatorId),
      };
    }

    case InitiatorActions.DELETE_INITIATOR_SUCCESS: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        initiators: state.initiators.filter((item) => item.id !== initiatorId),
      };
    }

    case InitiatorActions.FETCH_INITIATOR_EVENTS_TIME_SERIES_PENDING: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        loadingTimeSeriesIds: [...state.loadingTimeSeriesIds, initiatorId],
      };
    }

    case InitiatorActions.FETCH_INITIATOR_EVENTS_TIME_SERIES_SUCCESS: {
      const { initiatorId = '', eventsTimeSeries } = action.payload;
      const data = [...state.initiators];
      const idx = data.findIndex((item) => item.id === initiatorId);
      if (idx >= 0) {
        data[idx].eventsTimeSeries = eventsTimeSeries;
      }
      return {
        ...state,
        initiators: data,
        loadingTimeSeriesIds: state.loadingTimeSeriesIds.filter((id) => id !== initiatorId),
      };
    }

    case InitiatorActions.FETCH_INITIATOR_EVENTS_TIME_SERIES_ERROR: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        loadingTimeSeriesIds: state.loadingTimeSeriesIds.filter((id) => id !== initiatorId),
      };
    }

    default:
      return state;
  }
};
